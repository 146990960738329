export type CardType =
  | 'American Express'
  | 'Discover'
  | 'MasterCard'
  | 'Visa'
  | 'Diners Club'
  | 'JCB'
  | 'Unknown';

export type Currency = 'USD' | 'GBP' | 'AUD';

export type Provider = 'Stripe' | 'Recurly';

export interface PaymentDetails {
  cardType: CardType;
  fourLastDigits: string;
  expirationMonth: number;
  expirationYear: number;
  provider: Provider;
  isLink?: boolean;
}

export interface LineItem {
  name: string;
  amount: number;
  includedInSavings: boolean;
  creditable: boolean;
  currency: Currency;
  includedInTotal: boolean;
  isRecurringDiscount?: boolean;
  shouldTotalBeZero?: boolean;
}

export interface InvoiceLineItem {
  start: Date;
  end: Date;
  description: string;
  total: string;
  amount: number;
  recurringCount?: number;
  plan?: string;
  metadata?: Record<string, unknown>;
  therapistFullName?: string | null;
  dateOfService?: string | null;
}

export enum BillingFrequency {
  Monthly,
  Quarterly,
  Biannual,
}

export enum DescriptionStyle {
  bold = 'bold',
  none = 'none',
}

export interface DescriptionLine {
  text: string;
  style?: DescriptionStyle;
}

export interface OfferPrice {
  amount: number;
  originalPrice?: number;
  symbol: string;
  unit?: string;
  currency: Currency;
  discountDurationWeeks?: number;
}

export type BillingCycleUnit = 'month' | 'day' | 'one-time';
export interface BillingPrice {
  amount: number;
  cycleValue?: number;
  unit: BillingCycleUnit;
  currency: Currency;
}

export enum PlanIcon {
  messaging = 'messaging',
  messaging1lvs = 'messaging1lvs',
  messaging4lvs = 'messaging4lvs',
  video = 'video',
  psychiatry = 'psychiatry',
}

export interface PlanData {
  id: number;
  frequency?: BillingFrequency;
  discountPercent?: string;
  savings?: number;
  description: DescriptionLine[];
  offerPrice?: OfferPrice;
  billingPrice: BillingPrice;
  displayPaymentPeriod: string;
  icon?: PlanIcon;
  displayName: string;
  badge?: string;
  allowCoupons?: boolean;
  sessionDetails?: SessionDetails;
  oopPromoExperiment?: boolean;
}

export interface ExperimentTherapyData {
  planId: number;
  displayName: string;
  therapyName: string;
  therapyImage: () => JSX.Element;
  therapyInfo: string;
  therapyBullets: ReadonlyArray<string>;
  backgroundColor: string;
  dataQA: string;
  newPrice?: number;
  originalPrice?: number;
  currency?: Currency;
  unit?: string;
}

export interface SessionDetails {
  video: SessionDetailsSingleModality;
  audio: SessionDetailsSingleModality;
  chat: SessionDetailsSingleModality;
  messaging: SessionDetailsSingleModality;
}

export interface SessionDetailsSingleModality {
  title: string;
  subtitle: string;
}

export interface RoomPlanInfo {
  id: number;
  // eslint-disable-next-line camelcase
  plan_id?: number;
  billingChargePriceUSD: number;
  billingCycleUnit: string;
  billingCycleValue: number;
  planDisplayJSON?: Record<string, any>;
  currency: Currency;
}

export interface Discount {
  amount: number;
  percentage?: number;
}

export interface ValidatedCoupon {
  amount: number;
  code: string;
  isRecurring: boolean;
}

export interface CouponState {
  errorMessage?: string;
  status: 'ready' | 'validating' | 'error' | 'valid';
  validatedCoupon?: ValidatedCoupon;
}

export interface ChangePlanCheckoutInfo {
  customerBalanceCents: number;
  prorationAmountCents: number;
  renewDate?: Date;
}

export type InvoiceStatus = 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';

export type ChargeType =
  | 'subscription'
  | 'videoCredit'
  | 'session'
  | 'noShow'
  | 'lateCancellation'
  | 'postSession'
  | 'copay'
  | 'videoCreditBundle'
  | 'setupFee'
  | 'holdCharge'
  | 'BPOClaim';

export interface ClientTransaction {
  amount: number;
  currency: Currency;
  dateOfService?: string;
  chargeType: ChargeType;
  therapistFullName?: string;
  invoiceID?: string;
  invoiceLineItems: InvoiceLineItem[];
  isAggregated: boolean;
}
