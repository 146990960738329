import View from '../../../components/View';
import { Big, Tiny } from '../../../components/Typography';
import TouchableView from '../../../components/TouchableView';
import RadioButton from '../../../components/RadioButton';
import styled, { EmotionStyle } from '../../../core/styled';

export interface OptionBlockProps<OptionValue> {
  isActive: boolean;
  displayTitle: string;
  description: string | JSX.Element;
  icon?: JSX.Element;
  value: OptionValue;
  style?: EmotionStyle;
  onHandlePress: (type: OptionValue) => void;
  disabled?: boolean;
  forceIdleState?: boolean;
  onDisablePress?: (type?: OptionValue) => void;
  radioButtonContainerStyle?: EmotionStyle;
  iconContainerStyle?: EmotionStyle;
  textContainerStyle?: EmotionStyle;
  titleStyle?: EmotionStyle;
  questionMarkWhenDisabled?: boolean;
  dataQa?: string;
}

const OptionWrapper = styled(TouchableView)<{
  index: number;
}>(({ index, theme: { colors } }) => {
  return {
    width: 335,
    height: 100,
    borderRadius: 10,
    justifyContent: 'space-between',
    marginTop: index !== 0 ? 10 : 0,
    border: `1px solid ${colors.a11yLinkWaterGrey}`,
  };
});

const OptionBlock = <OptionValue extends unknown = string>({
  isActive,
  icon,
  displayTitle,
  description,
  value,
  style,
  onHandlePress,
  disabled = false,
  onDisablePress = () => null,
  radioButtonContainerStyle = {},
  iconContainerStyle = {},
  textContainerStyle = {},
  titleStyle = {},
  dataQa,
  questionMarkWhenDisabled = false,
}: OptionBlockProps<OptionValue>) => (
  <OptionWrapper
    row
    index={0}
    tabIndex={-1}
    style={{ display: 'flex', marginBottom: 10, opacity: disabled ? 0.5 : 1, ...style }}
    onPress={() => (disabled ? onDisablePress(value) : onHandlePress(value))}
    dataQa={`${dataQa}-${value}`}
  >
    <View row>
      {icon && (
        <View row align="center" style={{ marginLeft: 27, ...iconContainerStyle }}>
          {icon}
        </View>
      )}
      <View style={{ margin: 30, ...(icon && { marginLeft: 17 }), ...textContainerStyle }}>
        <Big style={titleStyle}>{displayTitle}</Big>
        {typeof description === 'string' ? <Tiny>{description}</Tiny> : description}
      </View>
    </View>
    <View
      row
      align="center"
      justify="center"
      style={{
        margin: 30,
        ...radioButtonContainerStyle,
      }}
    >
      <RadioButton
        isActive={isActive}
        onPress={() => !disabled && onHandlePress(value)}
        disabled={disabled}
        onDisablePress={() => onDisablePress(value)}
        dataQa={`${dataQa}-radio`}
        questionMarkWhenDisabled={questionMarkWhenDisabled}
      />
    </View>
  </OptionWrapper>
);

export default OptionBlock;
