import { useHistory } from 'react-router';
import { Screen, Spinner, useEmotionTheme } from '@talkspace/react-toolkit';
import { QMSessionTypeEnum } from 'ts-frontend/types';
import { GenericWizardWelcomeScreen } from 'stepWizard';
import coverageShield from '@/assets/coverageShield.svg';
import QuickmatchFrame from '@/containers/QuickmatchFrame';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import useQueryReturningUser from '@/hooks/useQueryReturningUser';
import { getUserData } from '@/auth/helpers/token';
import { buildQmFlowPath } from '@/welcomeBackReturningUser/qmFlowPathBuilder';
import ErrorModal from '@/clientChat/components/ErrorModal';
import useNavShellData from '@/hooks/useNavShellData';

const WelcomeBackReturningUser = () => {
  const { colors } = useEmotionTheme();
  const { id: clientUserID } = getUserData();
  const history = useHistory();
  const { clientInfo } = useNavShellData();
  const { data: returningUserData, isLoading, error } = useQueryReturningUser({ clientUserID });

  const qmFlowPath = buildQmFlowPath({
    source: 'update-coverage',
    updateCoverageRoomID: 0,
    serviceType: 'psychotherapy',
    skipServiceSelection: true,
    previousTherapistId: 0,
  });
  const closeModal = useCloseModal();

  if (isLoading) {
    return (
      <Screen onCloseButtonClick={closeModal} title="Update Coverage">
        <Spinner />
      </Screen>
    );
  }

  if (!clientInfo?.shouldTriggerReturningUserFlow || error || !returningUserData) {
    return (
      <Screen showCloseButton={false}>
        <ErrorModal
          error={' '}
          errorMessage="Something went wrong. Please try again."
          onButtonPress={() => history.replace('/home')}
        />
      </Screen>
    );
  }

  const screenTitle = 'Update Coverage';
  const providerName =
    `${returningUserData.therapist.firstName} ${returningUserData.therapist.lastName}` || '';
  const integrationPartnerName = returningUserData.integrationPartnerName || '';
  const description = `We received your request to book a session through ${integrationPartnerName}. To keep your appointment with ${providerName}, we'll need your most up-to-date insurance information.`;

  return (
    <Screen
      onCloseButtonClick={closeModal}
      title={screenTitle}
      containerDataQa="welcomeBackReturningUserScreen"
      backButtonDataQa="welcomeBackReturningUserBackButton"
    >
      <QuickmatchFrame
        frameTitle={screenTitle}
        autoStart={false}
        qmFlowPath={qmFlowPath}
        sessionType={QMSessionTypeEnum.UpdateCoverage}
        render={({ handleLaunchButton }) => (
          <GenericWizardWelcomeScreen
            title="We need to update your insurance coverage"
            description={description}
            onButtonPress={handleLaunchButton}
            buttonText="Continue"
            imageURL={coverageShield}
            imageHeight={160}
            imageWidth={260}
            imageXPos={60}
            imageYPos={50}
            imageMobileXPos={0}
            imageMobileYPos={140}
            backgroundColor={colors.transparent}
            graphicCardStyles={{ boxShadow: 'none' }}
            buttonDataQa="welcomeBackReturningUserCTAButton"
          />
        )}
      />
    </Screen>
  );
};

export default WelcomeBackReturningUser;
