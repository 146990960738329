import { useFlags } from 'launchDarkly/FlagsProvider';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';

const useShowOnboardingImprovements = (roomID: number | string, id: number) => {
  const { onboardingImprovementsMilestone1 } = useFlags();
  const { data: businessLine } = useQueryBusinessLine(roomID, id);
  const { isBH } = businessLine || {};
  const shouldSeeBHOnboardingImprovements = isBH && onboardingImprovementsMilestone1;

  return shouldSeeBHOnboardingImprovements;
};

export default useShowOnboardingImprovements;
