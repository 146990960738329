import { ChargeType } from 'ts-frontend/types';

export const TALK100_COUPON = 'TALK100';
export const TALK100_COUPON_AMOUNT = 100;
export const PSYCHIATRY_FLOW_ID_68 = 68; // Psychiatry FLOW_ID 68
export const PSYCHIATRY_FLOW_ID_102 = 102; // Psychiatry FLOW_ID 102
export const OON_DISCOUNT_COUPONS = ['INS100', 'INS120', 'INS160'];

export const chargeTypeNames: Record<ChargeType, string> = {
  copay: 'Copay',
  holdCharge: 'Hold charge',
  lateCancellation: 'Late cancellation',
  noShow: 'No show',
  postSession: 'Remaining balance',
  session: 'Session',
  setupFee: 'Setup fee',
  subscription: 'Subscription',
  videoCredit: 'Video credit',
  videoCreditBundle: 'Video credit bundle',
  BPOClaim: 'Remaining balance',
};
