import { FunctionComponent } from 'react';
import styled from '../../core/styled';
import View from '../View';
import Text from '../Text';
import Link from '../Link';
import { webOnlyStyle } from '../../core/styleHelpers';
import { COLORS } from '../../constants/commonStyles';

interface HyperLinkProps {
  text: string;
  textColor?: string;
  linkColor?: string;
  isSystem?: boolean;
  textID: string;
  underline?: boolean;
}

const BodyText = styled(Text)<{ color: string; isSystem: boolean }>(({ color, isSystem }) => {
  return {
    color,
    textAlign: isSystem ? 'center' : 'left',
    fontSize: 16,
    WebkitTouchCallout: 'none !important',
    ...webOnlyStyle({
      lineHeight: '22px',
      whiteSpace: 'pre-line',
    }),
  };
});

const linkRegex =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%_+~#=]{2,256}\.[a-z]{2,64}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
const parseHyperlinksToComponents = (
  msgText: string,
  linkColor: string,
  underline?: boolean
): (string | JSX.Element)[] => {
  let prevPosition = 0;
  let rest = '';
  let match;
  const componentArr: (string | JSX.Element)[] = [];
  do {
    match = linkRegex.exec(msgText);
    if (!match) break;
    const textBeforeLink = msgText.slice(prevPosition, match.index);
    const linkText = msgText.slice(match.index, linkRegex.lastIndex);
    const httpPattern = /^((http|https|ftp):\/\/)/gi;
    const emailPattern = /^\w{1,10}([.-]?\w{0,10})?@\w{1,15}(\.\w{2,3})+$/gi;
    let link;
    const isEmailLink = emailPattern.test(linkText);
    if (isEmailLink) {
      link = `mailto:${linkText}`;
    } else {
      link = httpPattern.test(linkText) ? linkText : `http://${linkText}`;
    }
    if (textBeforeLink) {
      componentArr.push(textBeforeLink);
    }
    componentArr.push(
      <Link
        target="_blank"
        href={link}
        key={`link-${match.index}`}
        text={linkText}
        style={{ color: linkColor, textDecoration: underline ? 'underline' : 'none' }}
        stopPropagation
      />
    );
    prevPosition = linkRegex.lastIndex;
    rest = msgText.slice(linkRegex.lastIndex, msgText.length);
  } while (match);
  if (rest.length) {
    componentArr.push(rest);
  }
  return componentArr.length ? componentArr : [msgText];
};

const HyperLink: FunctionComponent<HyperLinkProps> = ({
  text,
  textColor = COLORS.white,
  linkColor = '#005C54',
  isSystem = false,
  textID,
  underline,
}) => (
  <View row style={{ flexWrap: 'wrap' }}>
    <BodyText id={textID} isSystem={isSystem} color={textColor}>
      {parseHyperlinksToComponents(text, linkColor, underline)}
    </BodyText>
  </View>
);

export default HyperLink;
