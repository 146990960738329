import { FunctionComponent } from 'react';
import Svg, { Path, G, Defs, ClipPath, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

const ListSearch: FunctionComponent<Props> = ({ width = 24, height = 24, color }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'List search icon';
  const iconColor = color || colors.permaEden;
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <G clipPath="url(#clip0_94_2425)">
        <Path
          d="M15 18C17.2091 18 19 16.2091 19 14C19 11.7909 17.2091 10 15 10C12.7909 10 11 11.7909 11 14C11 16.2091 12.7909 18 15 18Z"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M18.5 17.5L21 20"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4 5H20"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4 11H7"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4 17H7"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_94_2425">
          <Rect width={width} height={height} fill={colors.white} />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ListSearch;
