import { View, TextDS, Button, StickyDrawer } from '@talkspace/react-toolkit';
import { PencilPaperCheck } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import styled from '@/core/styled';
import { useA11y } from './WelcomeScreen.a11y';

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    alignSelf: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'left .2s',
    width: window.isMobile ? 375 : 420,
    alignItems: 'center',
    ':focus': {
      outline: 'none',
    },
  };
});

interface Props {
  handleStartButton: () => void;
  title?: string;
  prompt: string;
}

const WelcomeScreen = ({
  handleStartButton,
  title = 'Answer with the following in mind',
  prompt,
}: Props) => {
  const { titleID, promptID, wrapperRef } = useA11y();

  return (
    <Wrapper as="main" tabIndex={-1} aria-labelledby={titleID} ref={wrapperRef}>
      <View align="center" style={{ gap: 24 }}>
        <PencilPaperCheck />

        <View style={{ gap: 12, padding: '0px 16px' }}>
          <TextDS id={titleID} variant="heading2xl" style={{ textAlign: 'center' }}>
            {title}
          </TextDS>

          <TextDS id={promptID} colorRole="textSubtle" style={{ textAlign: 'center' }}>
            {prompt}
          </TextDS>
        </View>
      </View>

      <StickyDrawer noBorder>
        <Button
          aria-describedby={promptID}
          text="Continue"
          onPress={handleStartButton}
          dataQa="surveyWizardContinueButton"
          fixedWidth
          sizeDS="large"
        />
      </StickyDrawer>
    </Wrapper>
  );
};

export default WelcomeScreen;
