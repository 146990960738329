import { useEffect, useRef } from 'react';
import { View, TextDS, Spinner, useEmotionTheme } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import styled from '@/core/styled';
import { State } from '../reducers/surveyWizardState';
import SurveyWizardApiHelper from '../utils/surveyWizardApiHelper';

const SPINNER_DURATION = 2000;

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    width: window.isMobile ? 343 : 420,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 166,
    gap: 24,
  };
});

const CompleteSurvey = () => {
  const { colorRoles } = useEmotionTheme();

  const { appSource, userRoomSurveyID, responses, surveySections, isSubmittingLastAnswer } =
    useWizardState<State>();

  const apiRef = useRef(new SurveyWizardApiHelper(appSource));

  const hasCompletedSurveyRef = useRef(false);

  const { closeWizard } = useWizardActions();

  useEffect(() => {
    const completeSurvey = async () => {
      const responsesCount = responses.steps.length;
      const answerableSections = surveySections.length - 1; // Remove welcome screen, not considered a step
      if (
        !hasCompletedSurveyRef.current &&
        !isSubmittingLastAnswer &&
        responsesCount === answerableSections
      ) {
        hasCompletedSurveyRef.current = true;
        await apiRef.current.putCompleteSurvey(true, userRoomSurveyID);
        setTimeout(() => {
          closeWizard();
        }, SPINNER_DURATION);
      }
    };

    completeSurvey();
  }, [closeWizard, isSubmittingLastAnswer, responses.steps, surveySections, userRoomSurveyID]);

  return (
    <Wrapper>
      <Spinner
        isLoading
        primaryColor={colorRoles.icons.iconSubtleDisabled}
        secondaryColor={colorRoles.icons.iconSubtleDefault}
        style={{ width: 32, height: 32 }}
      />

      <TextDS variant="body" colorRole="textSubtle" style={{ textAlign: 'center' }}>
        Please hang on while we analyze your answers
      </TextDS>
    </Wrapper>
  );
};

export default CompleteSurvey;
