import { FunctionComponent, useEffect } from 'react';
import {
  useUniqueID,
  View,
  PanelManager,
  usePanelManagerState,
  usePanelManagerActions,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { Route, Switch, RouteComponentProps, Redirect } from '../../core/routerLib';
import RoomDetails from '../../containers/RoomDetails';
import TherapistDetails from '../../containers/TherapistDetails';
import StarredContainer from '../../containers/StarredContainer';
import CustomerSupport from '../../containers/CustomerSupport';
import SharedFilesContainer from '../../containers/SharedFilesContainer';
import styled from '../../core/styled';
import DashboardContainer from '../../containers/DashboardContainer';
import AccountDetailsContainer from '../../myAccount/containers/AccountDetails';
import PersonalInformationContainer from '../../myAccount/containers/PersonalInformation';
import ReviewTherapistContainer from '../../therapistReview';
import NotificationsContainer from '../../myAccount/containers/Notifications';
import ManageSubscriptionContainer from '../../myAccount/containers/ManageSubscription';
import { RoomInvitesContainer } from '../../roomInvites';
import { useMainState, useMainActions } from '../../hooks/mainContext';
import ClinicalProgress from '../../clinicalProgress';
import { useA11y } from './ClientWebPanelManager.a11y';
import { ID_MAIN_PANEL, ID_CHAT_PANEL } from '../../utils/IDConstants';
import sessionStorage from '../../core/storage/sessionStorage';
import ChatContainer from '@/containers/ChatContainer';

const leftRightPanels = [
  '/starred-messages',
  '/my-account',
  '/review-therapist',
  '/clinical-progress',
  '/shared-files',
  '/invite-partner',
];

const Wrapper = styled(View)<{ isNewNav: boolean }>(({ isNewNav }) => {
  return {
    flex: 1,
    flexDirection: 'row',
    position: isNewNav ? 'relative' : undefined,
    overflowY: 'hidden',
  };
});

type ClientRouteComponentProps = RouteComponentProps<{ roomID?: string }>;

const redirectQuerystring = (routeProps: ClientRouteComponentProps) => {
  const { match, location } = routeProps;
  const params = new URLSearchParams(location.search);
  if (match.params.roomID) {
    params.set('roomID', match.params.roomID);
  }
  return `?${params.toString()}`;
};

const ClientWebPanelManager: FunctionComponent<ClientRouteComponentProps> = (props) => {
  const {
    history,
    location,
    match: {
      params: { roomID },
    },
  } = props;
  const { search } = location;
  const { isMobile, isTablet, isDesktop } = useWindowWidthState();
  const { roomsByID, therapistsByID } = useMainState();
  const { setDropdownMenuVisible } = useMainActions();
  const { setIsRightPanelOpen, setIsLeftPanelOnTop, setIsLargeRightPanel } =
    usePanelManagerActions();
  const useNewNav = useNewMemberNav();
  const { isLeftPanelOnTop, isRightPanelOpen, isLargeRightPanel } = usePanelManagerState();
  const roomIDs = Object.keys(roomsByID);
  const firstRoomID = roomIDs[0];
  const roomStatus =
    (roomID && roomsByID && roomsByID[roomID] && roomsByID[roomID].status) || undefined;
  const provider = (roomID && therapistsByID[roomsByID[roomID]?.roomID]) || undefined;
  const isCompactView = useNewNav ? isMobile || isTablet : isMobile;
  const isChatHidden =
    (isCompactView && isLeftPanelOnTop) || (!isDesktop && isRightPanelOpen) || isLargeRightPanel;

  useEffect(() => {
    setIsLargeRightPanel(leftRightPanels.some((path) => location.pathname.includes(path)));
  }, [location.pathname, setIsLargeRightPanel]);

  useEffect(() => {
    if (location.pathname.startsWith('/rooms')) {
      history.replace(`/room/${firstRoomID}${location.pathname.replace('/rooms', '')}${search}`);
      setIsLeftPanelOnTop(isCompactView);
    }
  }, [
    firstRoomID,
    isCompactView,
    history,
    history.length,
    search,
    location.pathname,
    setIsLeftPanelOnTop,
  ]);

  useEffect(() => {
    if (!location.pathname.includes('/modal')) {
      // if the route is more than the base /room/:roomID we are displaying the right panel
      // TODO: @tricard-talkspace This is causing the right panel to open
      if (location.pathname.split('/').length > 3) {
        setIsRightPanelOpen(true);
        // leftPanel is not on top if we are not on a room route
        setIsLeftPanelOnTop(false);
      } else {
        setIsRightPanelOpen(false);
      }
    }
  }, [location.pathname, setIsLeftPanelOnTop, setIsRightPanelOpen]);

  useEffect(() => {
    if (
      location.pathname.includes('/therapist-details') &&
      !roomHasRealProvider({ room: { status: roomStatus }, provider })
    ) {
      history.replace(`/room/${firstRoomID}/room-details`);
    }
  }, [history, firstRoomID, roomStatus, location.pathname, provider]);

  const handleCloseButtonPress = () => {
    // eslint-disable-next-line react/destructuring-assignment
    history.push(`/room/${props.match.params.roomID}`);
    // if we are responsive then the X closes to the dash
    if (isCompactView) {
      setIsLeftPanelOnTop(true);
    }
  };

  const handleBackButtonPress = () => {
    setDropdownMenuVisible(true);
    history.goBack();
  };

  const dashID = useUniqueID('dashID');
  const chatID = `${ID_CHAT_PANEL}-${roomID}`;

  const { rightPanelId } = useA11y(
    dashID,
    chatID,
    ID_MAIN_PANEL,
    isLeftPanelOnTop,
    isCompactView,
    isChatHidden,
    isRightPanelOpen,
    location.pathname,
    useNewNav
  );

  let middlePanelStyle = {};
  const videoSessionInfo = sessionStorage.getItem('videoSessionInfo');
  if (videoSessionInfo) {
    const { isInSession } = JSON.parse(videoSessionInfo || '{}');
    middlePanelStyle = isInSession ? { zIndex: 'initial' } : {};
  }
  const hideLeftPanel = useNewNav && !!roomsByID && Object.keys(roomsByID).length === 1;
  return (
    <Wrapper isNewNav={useNewNav}>
      <PanelManager.LeftPanel
        id={dashID}
        role="navigation"
        aria-label="dashboard panel"
        hideLeftPanel={hideLeftPanel}
      >
        <DashboardContainer roomID={roomID} />
      </PanelManager.LeftPanel>
      <PanelManager.MiddlePanel
        id={chatID}
        style={isChatHidden ? { visibility: 'hidden' } : undefined}
        aria-label="therapy chatroom"
        middlePanelStyle={middlePanelStyle}
      >
        <Switch>
          <Route
            path="/room/:roomID"
            render={(routeProps) => (
              <ChatContainer
                {...routeProps}
                hideBackButton={hideLeftPanel}
                isChatHidden={isChatHidden}
              />
            )}
          />
        </Switch>
      </PanelManager.MiddlePanel>
      <PanelManager.RightPanel id={rightPanelId}>
        <Route
          exact
          path="/room/:roomID/my-account"
          render={(routeProps) =>
            useNewNav ? (
              <Redirect to={`/home/account/account-details${redirectQuerystring(routeProps)}`} />
            ) : (
              // eslint-disable-next-line react/destructuring-assignment
              <Redirect to={`/room/${props.match.params.roomID}/my-account/account-details`} />
            )
          }
        />
        <Route
          exact
          path="/room/:roomID/review-therapist"
          render={(routeProps) => <ReviewTherapistContainer {...routeProps} />}
        />
        <Route
          exact
          path="/room/:roomID/invite-partner"
          render={(routeProps) => <RoomInvitesContainer {...routeProps} />}
        />
        <Switch>
          <Route
            exact
            path="/room/:roomID/my-account/personal-information"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect
                  to={`/home/account/personal-information${redirectQuerystring(routeProps)}`}
                />
              ) : (
                <PersonalInformationContainer
                  onCloseButtonPress={handleCloseButtonPress}
                  onBackButtonPress={handleBackButtonPress}
                />
              )
            }
          />
          <Route
            exact
            path="/room/:roomID/my-account/account-details"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`/home/account/account-details${redirectQuerystring(routeProps)}`} />
              ) : (
                <AccountDetailsContainer
                  onCloseButtonPress={handleCloseButtonPress}
                  onBackButtonPress={handleBackButtonPress}
                />
              )
            }
          />
          <Route
            path="/room/:roomID/my-account/manage-subscription"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect
                  to={`/home/account/manage-subscription${redirectQuerystring(routeProps)}`}
                />
              ) : (
                <ManageSubscriptionContainer
                  onCloseButtonPress={handleCloseButtonPress}
                  onBackButtonPress={handleBackButtonPress}
                />
              )
            }
          />
          <Route
            path="/room/:roomID/my-account/notifications"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`/home/account/notifications${redirectQuerystring(routeProps)}`} />
              ) : (
                <NotificationsContainer
                  onCloseButtonPress={handleCloseButtonPress}
                  onBackButtonPress={handleBackButtonPress}
                />
              )
            }
          />
          <Route
            path="/room/:roomID/room-details"
            render={() => <RoomDetails isChatHidden={isChatHidden} isMobile={isCompactView} />}
          />
          <Route
            path={[
              '/room/:roomID/starred-messages/:starredRoomID',
              '/room/:roomID/starred-messages',
            ]}
            component={StarredContainer}
          />
          <Route
            path="/room/:roomID/therapist-details"
            render={() => <TherapistDetails isChatHidden={isChatHidden} isMobile={isCompactView} />}
          />
          <Route
            path="/room/:roomID/customer-support"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`home/account/customer-support${redirectQuerystring(routeProps)}`} />
              ) : (
                <CustomerSupport isChatHidden={isChatHidden} isMobile={isCompactView} />
              )
            }
          />
          <Route path="/room/:roomID/clinical-progress" component={ClinicalProgress} />
          <Route path="/room/:roomID/shared-files" component={SharedFilesContainer} />
        </Switch>
      </PanelManager.RightPanel>
    </Wrapper>
  );
};

export default ClientWebPanelManager;
