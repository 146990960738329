import styled from '../../../core/styled';
import TouchableView from '../../../components/TouchableView';
import View from '../../../components/View';

interface SwitchV1Props {
  isOn: boolean;
  isDisabled?: boolean;
  handleOnPress: () => void;
  containerWidth?: number;
  containerHeight?: number;
  circleSize?: number;
  dataQa?: string;
}
const Container = styled(TouchableView)<{
  isOn: boolean;
  isDisabled: boolean;
  containerWidth?: number;
  containerHeight?: number;
}>(({ theme: { colorRoles }, isDisabled, isOn, containerWidth = 56, containerHeight = 32 }) => {
  const getBackgroundStyle = () => {
    if (isDisabled) return colorRoles.surfaces.surfaceInteractiveDisabled;
    return isOn
      ? colorRoles.surfaces.surfaceInteractiveSelectedBold
      : colorRoles.surfaces.surfaceInteractiveDisabledBold;
  };

  return {
    width: containerWidth,
    height: containerHeight,
    borderRadius: containerHeight / 2,
    background: getBackgroundStyle(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: isOn && 'flex-end',
    paddingLeft: 2,
    paddingRight: 2,
  };
});

const Circle = styled(View)<{ circleSize?: number }>(
  ({ theme: { colorRoles }, circleSize = 28 }) => {
    return {
      width: circleSize,
      height: circleSize,
      borderRadius: '50%',
      background: colorRoles.surfaces.surfaceInteractiveDefault,
    };
  }
);

const SwitchV1 = ({
  isOn,
  isDisabled = false,
  handleOnPress,
  containerWidth,
  containerHeight,
  circleSize,
  dataQa,
}: SwitchV1Props) => {
  const onPress = () => {
    if (!isDisabled) handleOnPress();
  };

  return (
    <Container
      isOn={isOn}
      onPress={onPress}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      isDisabled={isDisabled}
      dataQa={dataQa}
    >
      <Circle circleSize={circleSize} />
    </Container>
  );
};

export default SwitchV1;
