import { forwardRef } from 'react';
import { TextDS, View, spacing, useEmotionTheme, EmotionStyle } from '../../../index';
import { Check } from '../../icons';
import { getTextColorForSurface } from '../../styles/textColor';
import { getIconColorForSurface } from '../../styles/iconColor';
import { ColorRolesVersion } from '../../types';

const { space025, space050, space100 } = spacing;

export type TagColorName = keyof ColorRolesVersion['surfaces'];
export interface TagProps {
  color: TagColorName;
  text: string;
  style?: EmotionStyle;
  inline?: boolean;
  icon?: boolean;
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ color, text, style, inline, icon }: TagProps, ref) => {
    const { colorRoles } = useEmotionTheme();
    const textColorRole = getTextColorForSurface(color);
    const iconColorRole = getIconColorForSurface(color);
    return (
      <View
        as={inline ? 'span' : undefined}
        style={{
          width: 'fit-content',
          borderRadius: 12,
          padding: `${space025}px ${space100}px`,
          backgroundColor: colorRoles.surfaces[color],
          display: inline ? 'inline' : 'flex',
          ...style,
        }}
        ref={ref}
      >
        {icon && (
          <View
            as={inline ? 'span' : undefined}
            style={{
              display: inline ? 'inline' : 'flex',
              verticalAlign: 'middle',
              marginRight: space050,
            }}
          >
            <Check colorType={iconColorRole} size="small" />
          </View>
        )}
        <TextDS variant="bodyXs" colorRole={textColorRole} as={inline ? 'span' : undefined}>
          {text}
        </TextDS>
      </View>
    );
  }
);

export default Tag;
