import { useCallback } from 'react';
import {
  Button,
  ChatIconOutline,
  ExtraBig,
  Small,
  View,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName, VideoCreditOffer } from 'ts-frontend/types';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { useChatMessageActions } from '../../../chat/hooks/chatMessageContext';
import NoAvailabilitiesIcon from '../icons/NoAvailabilitiesIcon';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import { useHistory } from '@/core/routerLib';

interface Props {
  roomID: number;
  therapist: { firstName: string };
  selectedCredit: VideoCreditOffer | undefined;
  dismissOnboarding?: () => void;
}
const SelectTimeslotNoAvailability = ({
  roomID,
  therapist,
  selectedCredit,
  dismissOnboarding = () => {},
}: Props) => {
  const history = useHistory();
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const { data: isNoAvailabilityFFOn } = useTSAdminConfig(AdminConfigName.NO_AVAILABILITY_PROMPT);
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const { therapistInfo, room } = useInRoomSchedulingState();

  const { setPrefilledChatMessage } = useChatMessageActions();

  const onPressAskForAvailability = useCallback(() => {
    if (isOnboarding) {
      dismissOnboarding();
    }
    if (selectedCredit) {
      const message = `Hi ${therapist.firstName}, I’d like to book my ${selectedCredit.displayName}. What days and times work for you? Here are some times that work for me:`;
      setPrefilledChatMessage(message);
      closeModal({
        navigateTo: 'room',
        metadata: { roomID, additionalMetaData: { actionType: 'prefillChatMessage', message } },
      });
    } else {
      closeModal({ navigateTo: 'room', metadata: { roomID } });
    }
    trackEvent(
      'Asked About Availability',
      {
        planID: selectedCredit?.planID,
        therapistID: therapistInfo?.id,
        roomID: room?.roomID,
        sessionLength: selectedCredit?.creditMinutes,
        creditType: selectedCredit?.type,
        creditID: selectedCredit?.id,
      },
      ['mixpanel']
    );
  }, [
    isOnboarding,
    dismissOnboarding,
    selectedCredit,
    therapistInfo?.id,
    room?.roomID,
    therapist.firstName,
    setPrefilledChatMessage,
    closeModal,
    roomID,
  ]);

  const handleClose = () => {
    if (isOnboarding) {
      dismissOnboarding();
    }
    closeModal({ navigateTo: 'room', metadata: { roomID } });
  };

  return (
    <View
      justify="space-between"
      align="center"
      style={{ height: isMobile ? '100%' : undefined, paddingTop: 70 }}
    >
      <View align="center">
        <View align="center" style={{ maxWidth: isMobile ? 200 : undefined }}>
          <NoAvailabilitiesIcon size={130} />
          <ExtraBig style={{ marginTop: 20, textAlign: 'center' }}>
            {therapist.firstName} hasn’t updated their calendar yet
          </ExtraBig>
        </View>
        <Small variant="smallDarkGrey" style={{ marginTop: 28, textAlign: 'center' }}>
          Let {therapist.firstName} know you’d like to book a session! Feel free to suggest
          available times that work for you and they will work directly with you to schedule a
          session.
        </Small>
      </View>
      <View align="center" style={{ width: '100%', marginTop: 28 }}>
        {isNoAvailabilityFFOn && (
          <Button onPress={onPressAskForAvailability} stretch dataQa="askAboutAvailability">
            <View align="center" justify="center" row>
              <ChatIconOutline style={{ marginRight: 8 }} />
              Ask about availability
            </View>
          </Button>
        )}
        <Button
          text="Close"
          onPress={handleClose}
          style={{ color: colors.green, backgroundColor: colors.white }}
          stretch
          dataQa="selectTimeslotNoAvailabilityCloseButton"
        />
      </View>
    </View>
  );
};

export default SelectTimeslotNoAvailability;
