import { useMutation, useQueryClient } from 'react-query';

import { roomDetailsQueryKey } from 'ts-frontend/hooks/queryKeys';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

type FeatureType = 'talktracks';

export interface OptInVariables {
  userID: number;
  feature: FeatureType;
  roomID: string;
}

const experimentalFeatureOptIn = async ({ userID, feature }: OptInVariables): Promise<void> => {
  await apiWrapper.post(
    `${
      apiHelper().authAPIEndpoint
    }/v2/users/${userID}/experimental-features/featureNames/${feature}/opt-in`,
    {}
  );
};

const useMutationExperimentalFeatures = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, OptInVariables>(experimentalFeatureOptIn, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(roomDetailsQueryKey(roomID));
    },
  });
};

export default useMutationExperimentalFeatures;
