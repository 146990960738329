import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import useQueryQuestionnairesByClient from 'ts-frontend/hooks/questionnaires/useQueryQuestionnairesByClient';
import ForYouCard from './ForYouCard';
import { useHistory } from '../../core/routerLib';
import Note from '../../components/icons/Note';
import { getUserData } from '../../auth/helpers/token';

const SafetyPlanCard = () => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const history = useHistory();
  const { riskAssessments } = useFlags();
  const { id: clientID } = getUserData();

  const { data: questionnaires, isLoading: isLoadingQuestionnaires } =
    useQueryQuestionnairesByClient({
      clientID,
      questionnaireTypes: ['safety_plan'],
    });

  return (
    <>
      {riskAssessments && !isLoadingQuestionnaires && questionnaires && questionnaires.length > 0 && (
        <ForYouCard
          dataQa="safetyPlanCard"
          onPress={() => {
            history.push('/home/journey');
          }}
          disabled={false}
          Icon={<Note />}
          titleText={tnewMemberNav('home.safetyPlan', 'Safety plan', undefined)}
          bodyText={tnewMemberNav(
            'home.safetyPlanBody',
            'Your safety plan is designed to guide you through a crisis.',
            undefined
          )}
        />
      )}
    </>
  );
};

export default SafetyPlanCard;
