import { useWizardState, WizardScheme } from 'stepWizard';
import { InputType, WizardStep } from 'stepWizard/types';
import { QuestionnaireQuestion, QuestionnaireType } from '../types';

const useQuestionnaireWizardScheme = ({
  questionnaireType,
  questionnaireQuestions,
}: {
  questionnaireType: QuestionnaireType;
  questionnaireQuestions: Array<QuestionnaireQuestion>;
}) => {
  const state = useWizardState<{ currentStepNumber: number }>();
  const { currentStepNumber } = state;

  const toCamelCase = (str) =>
    str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');

  const CapitalizeFirsWordWithSpaces = (str) =>
    str
      .split('-')
      .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
      .join(' ');

  const getInputType = (question: QuestionnaireQuestion): InputType => {
    let inputType: InputType = 'select'; // default to select
    switch (question.type) {
      case 'boolean':
        inputType = 'buttonsOnly';
        break;
      case 'multi_select':
        inputType = 'creatableSearchableMultiSelect';
        break;
    }

    return inputType;
  };

  const getSubtitle = (question: QuestionnaireQuestion) => {
    switch (questionnaireType) {
      case 'cssrs-screener':
        return question.order <= 2 ? 'over the past month...' : undefined;
      default:
        return question.description;
    }
  };

  const getActionDispatch = () => {
    return {
      actionDispatch:
        currentStepNumber + 1 === questionnaireQuestions.length + 1
          ? '/questionnaire-wizard/complete'
          : 'next',
    };
  };

  const getButtonsByQuestionType = (question) => {
    if (question.type === 'boolean') {
      return {
        nextButton: {
          displayText: 'Yes',
          ...getActionDispatch(),
          whenVisible: 'always',
          displayStyle: 'secondaryFooter',
          defaultValue: true,
        },
        secondaryButton: {
          displayText: 'No',
          ...getActionDispatch(),
          whenVisible: 'always',
          displayStyle: 'primary',
          defaultValue: false,
        },
      };
    }
    return {
      nextButton: {
        displayText: 'Next',
        ...getActionDispatch(),

        whenVisible: 'onSelect',
        displayStyle: 'secondaryFooter',
      },
      secondaryButton: {
        displayText: 'Skip',
        ...getActionDispatch(),

        whenVisible: 'hideOnSelect',
        displayStyle: 'secondaryFooter',
      },
      inputOptions: `questionnaireQuestion${question.order}Options`,
    };
  };

  const getDisplayCondition = (question: any) => {
    if (questionnaireType === 'cssrs-screener') {
      if (question.order >= 3 && question.order <= 6) {
        return { displayCondition: ['question1', 'question2'], conditionType: 'or' };
      }

      if (question.order === 7) {
        return { displayCondition: 'question6' };
      }
    }
    return {};
  };

  return {
    getQuestionnaireWizardScheme: (): WizardScheme => {
      return {
        wizardVersion: 1,
        wizardType: toCamelCase(questionnaireType),
        wizardModalTitle: CapitalizeFirsWordWithSpaces(questionnaireType),
        steps: [
          ...questionnaireQuestions
            ?.sort((a, b) => a.order - b.order)
            .map(
              (question) =>
                ({
                  inputTypeDescription: ' ', // we dont wanna show the input type description in questionnaireWizards by default
                  subtitle: getSubtitle(question),
                  additionalDataObject: question,
                  title: question.text,
                  inputType: getInputType(question),
                  inputState: `question${question.order}`,
                  backOption: 'back',
                  name: `question${question.order}`,
                  ...getButtonsByQuestionType(question),
                  ...getDisplayCondition(question),
                } as WizardStep)
            ),
          {
            title: 'To complete the questionnaire, please click the button below.',
            inputType: 'buttonsOnly',
            backOption: 'back',
            nextButton: {
              displayText: 'Complete',
              actionDispatch: '/questionnaire-wizard/complete',
              whenVisible: 'always',
              displayStyle: 'primary',
            },
            name: 'complete',
          },
        ],
      };
    },
  };
};

export default useQuestionnaireWizardScheme;
