/* eslint-disable import/prefer-default-export */
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

export const isZipValid = (zip: string, countryCode = 'US'): boolean => {
  if (!postcodeValidatorExistsForCountry(countryCode)) return true;
  try {
    return postcodeValidator(zip, countryCode);
  } catch (e) {
    return false;
  }
};
