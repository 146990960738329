import Button from '../Button';
import BaseButton from '../BaseButton';
import TextDS from '../../designSystems/components/typography/TextDS';
import { ChatSquareOutlineIcon, GreenCircleCheckMarkV2 } from '../icons';
import { Huge } from '../Typography';
import View from '../View';

import { useEmotionTheme } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';

import EligibilityWarningInfoItem from './EligibilityWarningInfoItem';
import EligibilityWarningFAQLink from './EligibilityWarningFAQLink';
import TherapistInfo from './TherapistInfo';
import SessionInfo from './SessionInfo';

const NewRoom = () => (
  <EligibilityWarningInfoItem
    icon={<ChatSquareOutlineIcon />}
    title="New room"
    text="You have a new room but you can still access your old messages in your previous room."
  />
);

interface Props {
  sessionsTransferred: boolean;
  sessionsCanceled: boolean;
  isNewProvider: boolean;
  hasPreviousProvider: boolean;
  isPsych?: boolean;
  isSameRoom?: boolean;
  showFaq?: boolean;
  ctaText: string;
  dataQa: string;
  onPressCTA: () => void;
  secondaryCtaText?: string;
  secondaryDataQa?: string;
  createRoomReturnProps?: {
    therapistID?: number;
    therapistFirstName?: string;
    therapistLastName?: string;
    appointmentID?: string;
  };
  onPressSecondaryCTA?: () => void;
}

const EligibilityWarningSuccess = ({
  sessionsTransferred,
  sessionsCanceled,
  isNewProvider,
  isSameRoom,
  hasPreviousProvider,
  isPsych,
  ctaText,
  dataQa, // `${camelCase(ctaText)}Button`
  showFaq,
  secondaryCtaText,
  secondaryDataQa,
  onPressSecondaryCTA,
  createRoomReturnProps,
  onPressCTA,
}: Props) => {
  const therapistName = `${createRoomReturnProps?.therapistFirstName} ${createRoomReturnProps?.therapistLastName}`;
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  return (
    <View>
      <View align="center">
        <View align="center">
          <GreenCircleCheckMarkV2 />
          <Huge
            style={{
              marginTop: 16,
              marginBottom: isMobile ? 16 : undefined,
              fontSize: 26,
              lineHeight: '34px',
            }}
          >
            Your account is updated!
          </Huge>
        </View>
        <TherapistInfo
          hasPreviousProvider={hasPreviousProvider}
          isNewProvider={isNewProvider}
          isPsych={isPsych}
          therapistName={therapistName}
        />
        {!isSameRoom && <NewRoom />}
        <SessionInfo
          sessionsCanceled={sessionsCanceled}
          sessionsTransferred={sessionsTransferred}
          isSameRoom={!!isSameRoom}
          hasAppointment={!!createRoomReturnProps?.appointmentID}
          therapistName={therapistName}
        />
      </View>
      <View align="center" style={{ width: '100%' }}>
        <Button
          dataQa={dataQa}
          text={ctaText}
          onPress={onPressCTA}
          style={{
            marginTop: isMobile ? 16 : 64,
            width: '100%',
            backgroundColor: colors.permaTalkspaceDarkGreen,
          }}
        />
        {onPressSecondaryCTA && secondaryCtaText && (
          <BaseButton
            dataQa={secondaryDataQa}
            onPress={onPressSecondaryCTA}
            style={{
              marginTop: 8,
              padding: 18,
              width: '100%',
              color: colors.permaTalkspaceDarkGreen,
            }}
          >
            <TextDS
              variant="headingMd"
              colorRole="buttonBrandSecondary"
              style={{ color: colors.permaTalkspaceDarkGreen }}
            >
              {secondaryCtaText}
            </TextDS>
          </BaseButton>
        )}
        {showFaq && <EligibilityWarningFAQLink />}
      </View>
    </View>
  );
};

export default EligibilityWarningSuccess;
