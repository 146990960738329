import TextBox from './TextBox';

import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof TextBox>> = {
  DefaultComponent: TextBox,
};

export default withVersioning(versionMap);
