import { useEffect } from 'react';
import {
  ConnectionState,
  IAgoraRTCClient,
  ConnectionDisconnectedReason,
  UID,
  RemoteStreamType,
  ChannelMediaRelayEvent,
  IAgoraRTCError,
} from 'agora-rtc-sdk-ng';
import { trackEvent } from '@/utils/analytics/eventTracker';

const errorCodes = {
  1001: 'FRAMERATE_INPUT_TOO_LOW',
  1002: 'FRAMERATE_SENT_TOO_LOW',
  1003: 'SEND_VIDEO_BITRATE_TOO_LOW',
  1005: 'RECV_VIDEO_DECODE_FAILED',
  2001: 'AUDIO_INPUT_LEVEL_TOO_LOW',
  2002: 'AUDIO_OUTPUT_LEVEL_TOO_LOW',
  2003: 'SEND_AUDIO_BITRATE_TOO_LOW',
  2005: 'RECV_AUDIO_DECODE_FAILED',
} as const;

interface ExceptionEvent {
  code: keyof typeof errorCodes;
  msg: typeof errorCodes[keyof typeof errorCodes];
  uid: UID;
}

const AGORA_EVENT_NAME = 'Agora stream event';

interface UseMediaStreamErrorHandlersProps {
  client?: IAgoraRTCClient;
  roomID: number | string;
  therapistID?: number | string;
  userID?: number | string;
  videoCallID?: number;
  isJoined: boolean;
  onMediaSteamError?: () => void;
}

const useMediaStreamErrorHandlers = ({
  client,
  roomID,
  therapistID,
  userID,
  videoCallID,
  isJoined,
  onMediaSteamError,
}: UseMediaStreamErrorHandlersProps) => {
  useEffect(() => {
    if (!client) {
      return () => {};
    }
    const commonParams = {
      roomID,
      userID,
      therapistID,
      videoCallID,
    };
    const eventHandlers = {
      'connection-state-change': (
        curState: ConnectionState,
        revState: ConnectionState,
        reason?: ConnectionDisconnectedReason
      ): void => {
        if (curState === 'RECONNECTING' && onMediaSteamError && isJoined) {
          onMediaSteamError();
        }
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'connection-state-change',
          message: curState,
          curState,
          revState,
          reason,
          ...commonParams,
        });
      },
      'media-reconnect-start': (uid: UID): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'media-reconnect-start',
          uid,
          ...commonParams,
        });
      },
      'stream-type-changed': (uid: UID, streamType: RemoteStreamType): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'stream-type-changed',
          uid,
          streamType,
          ...commonParams,
        });
      },
      'stream-fallback': (uid: UID, isFallbackOrRecover: 'fallback' | 'recover'): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'stream-fallback',
          uid,
          isFallbackOrRecover,
          ...commonParams,
        });
      },
      'channel-media-relay-event': (event: ChannelMediaRelayEvent): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'channel-media-relay-event',
          message: event,
          ...commonParams,
        });
      },
      'live-streaming-error': (url: string, err: IAgoraRTCError): void => {
        if (onMediaSteamError && isJoined) {
          onMediaSteamError();
        }
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'live-streaming-error',
          url,
          errCode: err.code,
          errMessage: err.message,
          errName: err.name,
          ...commonParams,
        });
      },
      exception: (event: ExceptionEvent): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'exception',
          uid: event.uid,
          errCode: event.code,
          errMessage: event.msg,
          ...commonParams,
        });
      },
      'is-using-cloud-proxy': (isUsingProxy: boolean): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'is-using-cloud-proxy',
          isUsingProxy: isUsingProxy ? 'true' : 'false',
          ...commonParams,
        });
      },
      'join-fallback-to-proxy': (proxyServer: string): void => {
        trackEvent(AGORA_EVENT_NAME, {
          agoraEventType: 'join-fallback-to-proxy',
          proxyServer,
          ...commonParams,
        });
      },
    };

    Object.keys(eventHandlers).forEach((eventName) => {
      client.on(eventName, eventHandlers[eventName]);
    });

    return () => {
      Object.keys(eventHandlers).forEach((eventName) => {
        client.off(eventName, eventHandlers[eventName]);
      });
    };
  }, [client, roomID, therapistID, userID, videoCallID, onMediaSteamError, isJoined]);
};

export default useMediaStreamErrorHandlers;
