import {
  FunctionComponent,
  useRef,
  useEffect,
  useState,
  useCallback,
  ReactEventHandler,
} from 'react';
import { AnimatedSpinner } from '@talkspace/react-toolkit';
import { PropsOf } from '@emotion/styled-base/types/helper';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled, { EmotionStyle } from '@/core/styled';
import { getIonicPlatform } from '../../../ts-ionic/plugins/capacitor';

const Video = styled.video({
  maxWidth: 480,
  maxHeight: 480,
  ...webOnlyStyle({
    '@media(max-width: 1125px)': {
      maxWidth: 340,
      maxHeight: 340,
    },
    '@media(max-width: 750px)': {
      maxWidth: 315,
      maxHeight: 315,
    },
  }),
});

export interface VideoPlayerViewProps extends PropsOf<typeof Video> {
  source?: string;
  playing: boolean;
  style?: EmotionStyle;
  captionsUrl?: string;
  thumbnailURL?: string;
  loading?: boolean;
  primaryColor?: string;
  videoMimeType?: string;
  previewFrame?: number;
  initialHeight?: number;
  initialWidth?: number;
  fallbackBlob?: Blob;
}

const VideoPlayerView: FunctionComponent<VideoPlayerViewProps> = ({
  style,
  onPlay,
  source,
  onPause,
  playing,
  loading,
  preload,
  captionsUrl,
  thumbnailURL,
  primaryColor,
  videoMimeType = 'video/mp4',
  previewFrame = !thumbnailURL ? 0.1 : undefined,
  initialHeight = 270,
  initialWidth = 200,
  fallbackBlob,
  ...otherProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoSource, setVideoSource] = useState<string | undefined>(source);

  useEffect(() => {
    setVideoSource(source);
  }, [source]);

  const changeSourceToBlob = useCallback(() => {
    if (!source?.includes('blob:') && fallbackBlob) {
      const blobUrl = URL.createObjectURL(fallbackBlob);
      setVideoSource(blobUrl);
    }
  }, [source, fallbackBlob]);

  // Make sure to revoke the URL when the component is unmounted
  useEffect(
    () => () => {
      if (videoSource?.includes('blob:')) {
        // Revoke URL to prevent memory leaks
        URL.revokeObjectURL(videoSource);
      }
    },
    [videoSource]
  );

  const handleVideoError: ReactEventHandler<HTMLVideoElement> = (error) => {
    // Fallback to video source, use blob
    changeSourceToBlob();
  };

  // Workaround for Android to show preview.
  useEffect(() => {
    if (getIonicPlatform() === 'android') {
      changeSourceToBlob();
    }
  }, [changeSourceToBlob]);

  useEffect(() => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [playing]);

  if (loading) {
    return (
      <AnimatedSpinner
        width={35}
        height={30}
        style={{ minWidth: initialWidth, minHeight: initialHeight }}
      />
    );
  }

  const preloadProp = preload || (previewFrame && 'metadata') || undefined;

  return (
    <Video
      aria-label="video message"
      controls
      src={previewFrame ? undefined : videoSource}
      ref={videoRef}
      preload={preloadProp}
      onPlay={(e) => {
        // Note: We need the play here to prevent the "Permission denied" error
        // on browsers that prevent programmatic media playing
        // even though it's already on a useEffect
        if (videoRef.current) videoRef.current.play();
        if (onPlay) onPlay(e);
      }}
      onPause={(e) => {
        if (videoRef.current) videoRef.current.pause();
        if (onPause) onPause(e);
      }}
      onError={handleVideoError}
      poster={thumbnailURL}
      style={{
        ...style,
      }}
      {...otherProps}
    >
      {captionsUrl && <track kind="captions" src={captionsUrl} />}
      {!!previewFrame && <source src={`${videoSource}#t=${previewFrame}`} type={videoMimeType} />}
    </Video>
  );
};

export default VideoPlayerView;
