import { FunctionComponent, useEffect, useState } from 'react';
import { View } from '@talkspace/react-toolkit';

import { useStripeTs } from 'stripe/stripeContext';
import { PaymentDetails as PaymentDetailsView } from 'checkout';
import { useTranslation } from '@talkspace/i18n';
import { TFBookingScreen } from 'i18n/types';
import { RouteComponentProps, useHistory, withRouter } from '@/core/routerLib';
import useAccountDetails from '@/myAccount/hooks/useAccountDetails';
import { deleteLastElementInPath } from '../../utils/url';
import { ConfirmBookingOptions } from '../../types';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../hooks/inRoomSchedulingContext';
import useRepeatingBooking from '../../hooks/useRepeatingBooking';
import InRoomSchedulingError from '../inRoomSchedulingError';
import AddPaymentMethodForm from '../../../checkout/components/AddPaymentMethodForm';
import { addPaymentMethodSources } from '../../utils/constants';

const getSubmitText = ({
  isBH,
  isBHAdHoc,
  tBookingScreen,
}: {
  isBH: boolean;
  isBHAdHoc: boolean;
  tBookingScreen: TFBookingScreen;
}): string => {
  let submitText = tBookingScreen('payment.complete', 'Complete purchase', undefined);

  if (isBH) {
    submitText = 'Book session';
  }

  if (isBHAdHoc) {
    submitText = 'Join session';
  }

  return submitText;
};

interface OwnProps {
  handleConfirmAppointment: (options: ConfirmBookingOptions) => void;
  onClosePress: () => void;
  useStripeLink: boolean;
  onLoadStripeLink: () => void;
  onStripeLinkError: (error: any) => void;
  bookingSuccessRoute: 'confirm-booking' | 'booking-success';
}

type Props = OwnProps & RouteComponentProps;

const PaymentDetails: FunctionComponent<Props> = ({
  match,
  history,
  handleConfirmAppointment,
  onClosePress,
  useStripeLink,
  onStripeLinkError,
  onLoadStripeLink,
  bookingSuccessRoute,
}) => {
  const {
    location: { search },
  } = useHistory();
  const [shouldShowAddPaymentMethodForm, setShouldShowUpdatePaymentMethodForm] = useState(false);

  const {
    selectedCreditOption,
    planInfo,
    savings,
    shouldShowBookingSuccess,
    isLoading,
    isError,
    errorMessage,
    modality,
    total,
    room,
    isBHAdHoc,
    selectedConfirmBooking,
  } = useInRoomSchedulingState();

  const { dispatchResetError } = useInRoomSchedulingActions();

  const { isRecurringBooking } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });

  const [{ paymentDetails }] = useAccountDetails();

  const stripe = useStripeTs();

  const isBH = Boolean(room?.isSessionBased);

  const { t: tBookingScreen } = useTranslation('bookingScreen');

  useEffect(() => {
    if (shouldShowBookingSuccess) {
      history.push(`${deleteLastElementInPath(match.url)}/${bookingSuccessRoute}`, {
        roomID: room?.roomID,
      });
    }
  }, [bookingSuccessRoute, history, match.url, shouldShowBookingSuccess, room?.roomID]);

  useEffect(() => {
    if (search) {
      const source = new URLSearchParams(search).get('source');
      setShouldShowUpdatePaymentMethodForm(
        !!source &&
          Object.values(addPaymentMethodSources).includes(
            source as typeof addPaymentMethodSources[keyof typeof addPaymentMethodSources]
          ) &&
          isBH
      );
    }
  }, [search, isBH]);

  if (!selectedCreditOption) {
    history.push(deleteLastElementInPath(match.url));
    return null;
  }

  const handleSubmit = (token: string) => {
    handleConfirmAppointment({
      isPurchase: true,
      isReschedule: false,
      shouldRedeemCredit: false,
      creditCardToken: token,
      modality,
      isRecurringBooking,
    });
  };

  if (isError && !errorMessage) {
    return <InRoomSchedulingError onClosePress={onClosePress} />;
  }

  return (
    <View align="center" style={{ marginTop: 30, paddingLeft: 16, paddingRight: 16 }}>
      {shouldShowAddPaymentMethodForm ? (
        <AddPaymentMethodForm
          onSubmit={handleSubmit}
          submitText={getSubmitText({ isBH, isBHAdHoc, tBookingScreen })}
          isProcessing={isLoading}
          resetError={dispatchResetError}
          errorMessage={errorMessage}
          onLoadStripeLink={onLoadStripeLink}
          onStripeLinkError={onStripeLinkError}
          title="Add a payment method to book your session"
          subtitle="We collect your card for any balances you may owe"
        />
      ) : (
        <PaymentDetailsView
          onSubmit={handleSubmit}
          resetError={dispatchResetError}
          provider={paymentDetails?.provider || 'Stripe'}
          currency={planInfo?.billingPrice.currency || 'USD'}
          submitText={getSubmitText({ isBH, isBHAdHoc, tBookingScreen })}
          total={total}
          savings={savings}
          isProcessing={isLoading}
          totalsVariant={isBH ? 'none' : 'standard'}
          errorMessage={errorMessage}
          stripe={stripe}
          useStripeLink={useStripeLink}
          onStripeLinkError={onStripeLinkError}
          onLoadStripeLink={onLoadStripeLink}
          showSavings={!isBH}
          totalDueText={isBH ? 'Co-pay due today' : undefined}
        />
      )}
    </View>
  );
};

export default withRouter(PaymentDetails);
