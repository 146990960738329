import { View } from '@talkspace/react-toolkit';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useWizardState, WizardActionStatus } from 'stepWizard';
import { RouteComponentProps } from '../../core/routerLib';
import useQuestionnaireWizard from '../hooks/useQuestionnaireWizard';
import { useCloseModal } from '../../utils/ModalsContextProvider';

const QuestionnaireWizardCompletion: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const { responses, clientUserID, roomID, questionnaireType } = useWizardState() as any;

  const closeModal = useCloseModal();

  const [state, { exitAction, createQuestionnaire }] = useQuestionnaireWizard();
  const { error, isSuccess } = state;

  useEffect(() => {
    if (responses || clientUserID || roomID) {
      const answers = responses.steps
        .filter((step, i) => i !== responses.steps.length - 1)
        .map((it) => {
          const { additionalDataObject: question } = it;
          return {
            questionID: question.id,
            booleanAnswer:
              !it.skip && (it.value || it.value === false) && question.type === 'boolean'
                ? it.value === true
                : undefined,
            options:
              !it.skip && it.value && question.type === 'multi_select'
                ? it.value.filter(
                    (multiSelectOption: number | string) => typeof multiSelectOption === 'number'
                  )
                : [],
            textOptions:
              !it.skip && it.value && question.type === 'multi_select'
                ? it.value.filter(
                    (multiSelectOption: number | string) => typeof multiSelectOption === 'string'
                  )
                : [],
          };
        });

      createQuestionnaire({
        roomID,
        createdBy: clientUserID,
        answers,
        type: questionnaireType.replace('-', '_'),
      });
    }
  }, [clientUserID, createQuestionnaire, questionnaireType, responses, roomID]);

  const getTitle = useCallback(() => {
    switch (questionnaireType) {
      case 'safety-plan':
        return 'Your safety plan was saved';
      case 'cssrs-screener':
        return 'You’re all set!';
      default:
        return '';
    }
  }, [questionnaireType]);

  const getSubtitle = useCallback(() => {
    switch (questionnaireType) {
      case 'safety-plan':
        return 'You can view it in your Journey tab';
      case 'cssrs-screener':
        return 'Your therapist will review your responses';
      default:
        return '';
    }
  }, [questionnaireType]);

  const getButtonText = useCallback(() => {
    switch (questionnaireType) {
      case 'safety-plan':
        return 'View safety plan';
      case 'cssrs-screener':
        return 'Close';
      default:
        return '';
    }
  }, [questionnaireType]);

  const closeCompletionModal = useCallback(() => {
    switch (questionnaireType) {
      case 'safety-plan':
        closeModal({ navigateTo: 'journey' });
        break;
      case 'cssrs-screener':
        closeModal();
        break;
      default:
        break;
    }
  }, [closeModal, questionnaireType]);

  return (
    <>
      {isSuccess && (
        <WizardActionStatus
          successTitle={getTitle()}
          successSubTitle={getSubtitle()}
          successButtonText={getButtonText()}
          handleAction={() => {
            exitAction();
            closeCompletionModal();
          }}
          actionHookState={state}
          successButtonDataQa={`questionnaireCompletionSuccessButton-${questionnaireType}`}
        />
      )}
      {error && (
        <View style={{ alignSelf: 'center', alignItems: 'center', paddingTop: 20, gap: 20 }}>
          FAILURE
        </View>
      )}
    </>
  );
};

export default QuestionnaireWizardCompletion;
