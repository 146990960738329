import { useCallback } from 'react';
import { Modal, useEmotionTheme, View, TSLogoCollapsed } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import EnterPasscode from '../components/EnterPasscode';
import usePasscode from '../hooks/usePasscode';
import { updateWebVersion } from '../../../plugins/liveUpdates';
import { IonicPasscodeProps } from '../types';

const Wrapper = styled(Modal)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: '100dvh',
    position: 'absolute',
    background: colors.white,
    zIndex: 99999,
  };
});

const IonicPasscode = ({ getTokens, isClient }: IonicPasscodeProps) => {
  const { colors } = useEmotionTheme();
  const onBeforeLockoutCheck = useCallback(
    () => updateWebVersion({ showSplash: true, splashDuration: 2000 }),
    []
  );
  const { showPasscodeView, isScreenBlocked, onAppResumed } = usePasscode({
    getTokens,
    isClient,
    onBeforeLockoutCheck,
  });

  if (showPasscodeView) {
    return (
      <Wrapper isVisible underlayStyle={{ background: colors.white, display: 'block' }}>
        <EnterPasscode isClient={isClient} handleAppResumed={onAppResumed} />
      </Wrapper>
    );
  }
  // This is used to prevent the content being visible while the app is in the background
  if (isScreenBlocked) {
    return (
      <Wrapper
        isVisible
        underlayStyle={{ backgroundColor: '#E1F0EE', display: 'block' }}
        dialogStyle={{ height: '100dvh', alignItems: 'center' }}
      >
        <View>
          <TSLogoCollapsed variant="2024" />
        </View>
      </Wrapper>
    );
  }

  return null;
};

export default IonicPasscode;
