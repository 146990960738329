import { useWizardState, WizardScheme } from 'stepWizard';
import { View, DischargeSuccessIcon } from '@talkspace/react-toolkit';
import { Survey } from 'ts-frontend/types';
import { PencilPaperCheck } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { SurveyWizardData } from './types';
import WelcomeScreen from './components/WelcomeScreen';
import WelcomeScreenCitation from './components/WelcomeScreenCitation';
import { State } from './reducers/surveyWizardState';

const surveyWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'surveyWizard',
  wizardModalTitle: 'survey',
  steps: [],
};

export function extractSurveyData(rawSurveyData: Survey): SurveyWizardData {
  return {
    surveyCitation: rawSurveyData.citation,
    surveySections: rawSurveyData.sections,
  };
}

const WelcomeScreenContainer = ({ prompt, onClick }: { prompt: string; onClick: () => void }) => {
  const { onSurveyProgress } = useWizardState<State>();

  return (
    <WelcomeScreen
      handleStartButton={() => {
        if (onSurveyProgress) onSurveyProgress(prompt, undefined, 0, false);
        onClick();
      }}
      prompt={prompt}
    />
  );
};

export function getWelcomeScreen(rawSurveyData: Survey): WizardScheme['welcomeScreen'] | null {
  return rawSurveyData.sections[0]
    ? (onClick) => (
        <WelcomeScreenContainer onClick={onClick} prompt={rawSurveyData.sections[0].prompt} />
      )
    : null;
}

export function getSurveySteps(rawSurveyData: Survey): WizardScheme['steps'] {
  return rawSurveyData.sections.slice(1).map((section, index, sections) => {
    if (section.sectionType === 'INSTRUCTIONS') {
      const nextQuestion = sections[index + 1];
      const isLastQuestion = !nextQuestion;

      return {
        inputState: '',
        backOption: 'back',
        hideProgressIndicator: true,
        IconComponent: () => (
          // There's too much space between the top of the screen and the step title,
          // reduce it with negative margin
          <View style={{ marginTop: -24, marginBottom: 24 }}>
            {isLastQuestion ? <DischargeSuccessIcon /> : <PencilPaperCheck />}
          </View>
        ),
        // Combined surveys have the completed survey prompt twice in a single survey
        name: section.prompt + index,
        title: isLastQuestion
          ? 'You’re all set'
          : 'For your remaining responses, please keep the following in mind',
        subtitle: isLastQuestion
          ? 'Thank you for taking the time to complete this assessment'
          : section.prompt,
        postAction: 'instructionsButtonPostAction',
        indexInputByCounter: true,
        inputRestoredAnswer: 'surveyRestoredAnswers',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: isLastQuestion ? '/survey/complete' : 'next',
          actionDispatchWithLocationState: true,
          whenVisible: 'always',
        },
        footer: isLastQuestion
          ? () => <WelcomeScreenCitation citation={rawSurveyData.citation} />
          : undefined,
      };
    }

    return {
      inputType: 'question',
      backOption: 'back',
      indexInputByCounter: true,
      inputOptions: 'surveyQuestions',
      inputRestoredAnswer: 'surveyRestoredAnswers',
      postAction: 'submitSurveyAnswerPostAction',
      inputState: `surveyResponse_${section.prompt}`,
      title: section.prompt,
      name: section.prompt,
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
        autoAdvanceDelay: 150,
      },
    };
  });
}

export default surveyWizardScheme;
