import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { debug } from 'ts-frontend/utils/dev-console';
import { View, Spinner } from '@talkspace/react-toolkit';
import appConfig from '@/utils/configs';
import { getUserData, getTokens } from '@/auth/helpers/token';
import API from './LaunchDarklyApiHelper';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

const LDClientIdentifier: FunctionComponent<{}> = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any | null | undefined>(undefined);
  const getUserAndIdentify = useCallback(async () => {
    const userData = getUserData();
    const tokens = getTokens();
    const isInFrame = ReactFrameService.instance().isInFrame();
    if (!userData || !userData.id || !(tokens.refreshToken || (isInFrame && tokens.accessToken))) {
      setUser(null);
      return;
    }
    try {
      const ldUser = await API.getClientUserForLD(userData.id);
      debug('Client Web: Identifying with LaunchDarkly', ldUser);
      setUser(ldUser);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setUser(null);
    }
  }, [setUser]);

  useEffect(() => {
    getUserAndIdentify();
  }, [getUserAndIdentify]);

  if (user === undefined) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }

  return (
    <LDProvider
      key={`ld-user-${user?.key}`}
      context={{
        ...user,
        kind: 'user',
        key: user?.key || 'anonymous-client-user',
      }}
      clientSideID={appConfig.launchDarkly.sdkKey}
      options={{
        bootstrap: 'localStorage',
      }}
    >
      {children}
    </LDProvider>
  );
};

export default LDClientIdentifier;
