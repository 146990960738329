import { History } from 'history';
import { Route, Switch } from 'react-router';
import { useWizardActions, useWizardState, WizardScreen } from 'stepWizard';
import {
  AnimatedSwipeDownModal,
  Button,
  EmotionThemeProvider,
  Screen,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';

import QuestionnaireWizardCompletion from './containers/QuestionnaireWizardCompletion';
import useQuestionnaireWizardScheme from './hooks/useQuestionnaireWizardScheme';
import { QuestionnaireType } from './types';
import { useCloseModal } from '../utils/ModalsContextProvider';

const Routes = ({
  history,
  roomID,
  questionnaireType,
}: {
  history: History;
  roomID: number;
  questionnaireType: QuestionnaireType;
}) => {
  const { isMobile } = useWindowWidthState();
  const { setState, onExitClick } = useWizardActions();

  const questionnaireWizardState = useWizardState();
  const { questionnaireQuestions, showLeaveWarning, responses } = questionnaireWizardState as any;
  const { getQuestionnaireWizardScheme } = useQuestionnaireWizardScheme({
    questionnaireType,
    questionnaireQuestions,
  });

  const isCompletionPage = history.location.pathname.includes('/complete');

  const closeModal = useCloseModal();

  return (
    <Switch>
      <Screen
        onCloseButtonClick={() => {
          isCompletionPage || responses?.steps?.length === 0
            ? (() => {
                onExitClick();
                closeModal({ navigateTo: 'room', metadata: { roomID } });
              })()
            : setState({ showLeaveWarning: true });
        }}
        title={isMobile ? ' ' : ''}
      >
        <Route
          exact
          path="/questionnaire-wizard/complete"
          component={QuestionnaireWizardCompletion}
        />
        <Route
          exact
          path="/questionnaire-wizard/type/:type/room/:roomID/source/:source"
          render={() =>
            questionnaireQuestions.length && (
              <>
                <WizardScreen wizardScheme={getQuestionnaireWizardScheme()} />
              </>
            )
          }
        />
        <EmotionThemeProvider version="2.0.0">
          <AnimatedSwipeDownModal
            isOpen={showLeaveWarning}
            onClose={() => setState({ showLeaveWarning: false })}
          >
            <View style={{ fontSize: 20, fontWeight: 600, marginBottom: 8 }}>
              Are you sure you want to leave?
            </View>
            <View style={{ marginBottom: 38 }}>Any unsaved changes will be lost</View>

            <Button
              onClick={() => {
                onExitClick();
                closeModal({ navigateTo: 'room', metadata: { roomID } });
              }}
              text="Leave"
            />
            <Button
              text="Stay"
              variant="tertiary"
              onClick={() => setState({ showLeaveWarning: false })}
            />
          </AnimatedSwipeDownModal>
        </EmotionThemeProvider>
      </Screen>
    </Switch>
  );
};

export default Routes;
