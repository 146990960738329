import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import styled, { EmotionTheme, useEmotionTheme, EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import Input from '../Input';
import View from '../View';
import RHFSelect from '../RHFSelect';
import { useShareForwardedRef } from '../../hooks/a11yHelper';

// TODO: @Eric add a prop isReactHookForm to conditionally use this component or AddressInputAutocomplete
// Note: this component assumes that it will be used in conjunction with react-hook-form
export type AddressComponentTypes =
  | 'street_number'
  | 'route'
  | 'locality'
  | 'sublocality'
  | 'administrative_area_level_1'
  | 'postal_code'
  | 'country'
  | 'postal_town';

export interface AddressComponent {
  fullAddress: string;
  state?: string;
  zipcode?: string;
  city?: string;
  address?: string;
  // eslint-disable-next-line camelcase
  long_name?: string;
  // eslint-disable-next-line camelcase
  short_name?: string;
  types: AddressComponentTypes[];
}

interface StateComponentProps<
  StateName extends string = string,
  CountryName extends string = string,
  TFieldValues = Record<StateName | CountryName, string>,
  QAAttributeUniqueNaming extends string = string
> {
  stateName: StateName;
  countryName: CountryName;
  states: Array<{ label: string; value: string }>;
  /** Inferred prop used solely for type validation */
  fields?: TFieldValues;
  qaAttributeUniqueNaming?: QAAttributeUniqueNaming;
}

interface A11yFullAddressProps<
  AddressLine1Name extends string = string,
  AddressLine2Name extends string = string,
  CityName extends string = string,
  StateName extends string = string,
  ZipcodeName extends string = string,
  CountryName extends string = string,
  TFieldValues = Record<
    AddressLine1Name | AddressLine2Name | CityName | StateName | ZipcodeName | CountryName,
    string
  >,
  QAAttributeUniqueNaming extends string = string
> {
  addressLine1Name?: AddressLine1Name;
  addressLine2Name?: AddressLine2Name;
  cityName?: CityName;
  stateName?: StateName;
  zipcodeName?: ZipcodeName;
  countryName?: CountryName;
  states: Array<{ label: string; value: string }>;
  countries: Array<{ label: string; value: string }>;
  /** Inferred prop used solely for type validation */
  fields?: TFieldValues;
  qaAttributeUniqueNaming?: QAAttributeUniqueNaming;
  address1Label?: string;
  address1Hint?: string;
  address2Label?: string;
  address2Placeholder?: string;
  onlyShowAddress?: boolean;
  containerStyles?: EmotionStyle;
  onSelectAddress?: (parsedAddress: TFieldValues) => void;
}

export const isZipValid = (zip: string, countryCode = 'US'): boolean => {
  if (!postcodeValidatorExistsForCountry(countryCode)) return true;
  try {
    return postcodeValidator(zip, countryCode);
  } catch (e) {
    return false;
  }
};

const OptionsContainerView = styled(View)({
  position: 'absolute',
  top: '100%',
  width: '100%',
  borderRadius: 9,
  overflow: 'hidden',
  boxShadow: '2px 10px 20px rgba(0, 0, 0, .15)',
});

const OptionsView = styled(View)({
  cursor: 'pointer',
  justifyContent: 'center',
  display: 'flex',
  height: 50,
  padding: 10,
  textAlign: 'center',
  alignItems: 'center',
});

const OptionsText = styled(View)({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
});

// media queries can't be applied inline so these trivial wrappers are used to stack the state and zipcode
// inputs on top of each other when the window shrinks
const StateZipWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 335,
  ...webOnlyStyle({
    '@media(max-width: 350px)': {
      display: 'inline-block',
    },
  }),
});

const SmallInputWrapper = styled(View)({
  width: '100%',
  maxWidth: 165,
  ...webOnlyStyle({
    '@media(max-width: 350px)': {
      maxWidth: 335,
    },
  }),
});

const parseAddress = <
  TFieldValues extends Record<string, string>,
  AddressLine1Name extends string,
  CityName extends string,
  StateName extends string,
  ZipcodeName extends string,
  CountryName extends string
>(
  addressComponents: AddressComponent[],
  addressLine1Name: AddressLine1Name,
  cityName: CityName,
  stateName: StateName,
  zipcodeName: ZipcodeName,
  countryName: CountryName
): TFieldValues => {
  let address: string | undefined;
  let addressStreet: string | undefined;
  let result: Partial<TFieldValues> = {};

  addressComponents.forEach(({ long_name: longName, short_name: shortName, types }) => {
    // this is the street number portion of the address i.e. 70
    if (types.includes('street_number')) {
      address = longName;
    }

    // street name portion of the address
    if (types.includes('route')) {
      addressStreet = longName;
    }

    // city portion of the address
    if (types.includes('locality')) {
      result = { ...result, [cityName]: longName };
    }

    // city sometimes appears in 'sublocality', like when the address is in Brooklyn, NY (go BK!)
    if ((types.includes('sublocality') || types.includes('postal_town')) && !result.city) {
      result = { ...result, [cityName]: longName };
    }

    // state
    if (types.includes('administrative_area_level_1')) {
      result = { ...result, [stateName]: shortName };
    }

    // zipcode
    if (types.includes('postal_code')) {
      result = { ...result, [zipcodeName]: longName };
    }

    // country
    if (types.includes('country')) {
      result = { ...result, [countryName]: shortName };
    }
  });
  // addressLine1 needed so the address can be presented like people are used to seeing
  result[addressLine1Name] = `${address ?? ''} ${
    addressStreet ?? ''
  }`.trim() as TFieldValues[AddressLine1Name];
  return result as TFieldValues;
};

const a11yInputStyles = (colors: EmotionTheme['colors'], isError: boolean) => {
  return {
    inputStyle: {
      width: '100%',
      '::placeholder': { color: colors.placeholderGrey },
      color: colors.black,
      borderColor: isError ? colors.torchRed : colors.permaLividBlueNew,
    },
    wrapperStyle: {
      marginBottom: 12,
    },
    containerStyle: { width: '100%', maxWidth: 335 },
    labelStyle: { marginBottom: 4, padding: 0 },
    errorStyle: { marginTop: -8, marginBottom: 8, paddingBottom: 8 }, // Make up for the top margin with bottom margin
  };
};

const smallInputStyles = (colors: EmotionTheme['colors']) => {
  return {
    containerStyle: {
      width: '100%',
    },
    inputStyle: {
      width: '100%',
      '::placeholder': { color: colors.placeholderGrey },
      color: colors.black,
    },
  };
};

const StateComponent = (props: StateComponentProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<NonNullable<typeof props.fields>>();
  const { stateName, countryName, states, qaAttributeUniqueNaming } = props;
  const isError = !!errors[stateName];

  const { colors } = useEmotionTheme();

  const [shouldUseDropdown, setShouldUseDropdown] = useState(false);
  const countryValue = useWatch({ name: countryName });
  const state = shouldUseDropdown ? undefined : register(stateName);

  // if selected country is US then render the state input as a dropdown with fixed state options
  useEffect(() => {
    if (countryValue === 'US') {
      setShouldUseDropdown(true);
    } else {
      setShouldUseDropdown(false);
    }
  }, [countryValue]);

  const findOption = (options: Array<{ label: string; value: string }>, value: string) =>
    options.find((stateOption) => stateOption.label === value || stateOption.value === value);

  return shouldUseDropdown ? (
    <SmallInputWrapper>
      <RHFSelect
        fieldName={stateName}
        label="State"
        options={states}
        findOption={findOption}
        allowMobileSearch
        registerOptionValue
      />
    </SmallInputWrapper>
  ) : (
    <SmallInputWrapper>
      <Input
        dataQa={`state${qaAttributeUniqueNaming}Input`}
        wrappedInputProps={{
          label: 'State',
          ...smallInputStyles(colors),
          ...a11yInputStyles(colors, isError),
          isError: !!errors[stateName],
          errorMessage: errors[stateName]?.message,
        }}
        placeholder="State"
        {...state}
        onChange={(e) => {
          state?.onChange(e);
        }}
      />
    </SmallInputWrapper>
  );
};

const A11yFullAddress = (props: A11yFullAddressProps) => {
  const {
    addressLine1Name = 'addressLine1',
    addressLine2Name = 'addressLine2',
    cityName = 'city',
    stateName = 'state',
    zipcodeName = 'zipcode',
    countryName = 'country',
    address1Label = 'Home address line 1',
    address1Hint = '',
    address2Label = 'Home address line 2',
    address2Placeholder = 'Apartment, suite, unit, floor',
    countries,
    states,
    fields,
    qaAttributeUniqueNaming = '',
    onlyShowAddress = false,
    containerStyles,
    onSelectAddress,
  } = props;
  type FieldValues = typeof fields;
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<NonNullable<FieldValues>>();
  const { colors } = useEmotionTheme();

  const addressLine1 = register(addressLine1Name);
  const addressLine2 = register(addressLine2Name);
  const city = register(cityName);
  const zipcode = register(zipcodeName);

  const addressLine2Ref = useShareForwardedRef(addressLine2.ref);

  const savedSearchValue = useWatch({ name: addressLine1Name });

  const handleAddressLine1Change = (address: string) => {
    setValue(addressLine1Name, address);
  };

  const handleAddressSelect = (firstAddress: string) => {
    geocodeByAddress(firstAddress).then((results) => {
      const [{ address_components: addressComponents = [] } = {}] = results;
      const parsedAddress: FieldValues = parseAddress(
        addressComponents,
        addressLine1Name,
        cityName,
        stateName,
        zipcodeName,
        countryName
      );
      addressLine2Ref.current?.focus();
      Object.keys(parsedAddress).forEach((key) => {
        setValue(key, parsedAddress[key]);
      });
      if (onSelectAddress) {
        onSelectAddress(parsedAddress);
      }
    });
  };

  const labelNames = [
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    zipcodeName,
    countryName,
  ];

  // Show errors in hidden fields in the address field if onlyShowAddress
  const addressInputError = onlyShowAddress
    ? errors[addressLine1Name] || errors[labelNames.find((label) => !!errors[label]) || '']
    : errors[addressLine1Name];

  return (
    <View>
      <PlacesAutocomplete
        value={savedSearchValue}
        onChange={handleAddressLine1Change}
        onSelect={handleAddressSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <View
            style={{
              position: 'relative',
              ...containerStyles,
            }}
          >
            <Input
              dataQa={`addressLine1${qaAttributeUniqueNaming}Input`}
              wrappedInputProps={{
                label: address1Label,
                ...a11yInputStyles(colors, !!addressInputError),
                hint: address1Hint,
                isError: !!addressInputError,
                errorMessage: addressInputError?.message,
              }}
              {...getInputProps({
                placeholder: 'Search places...',
              })}
              {...addressLine1}
              // combine the event handlers
              onChange={(e) => {
                addressLine1.onChange(e);
                getInputProps().onChange(e);
              }}
              onBlur={(e) => {
                addressLine1.onBlur(e);
                getInputProps().onBlur(e);
              }}
            />
            {suggestions && suggestions.length > 0 && (
              <OptionsContainerView
                style={{
                  border: `1px solid ${colors.edgewater}`,
                  backgroundColor: colors.white,
                  zIndex: 10,
                }}
              >
                {suggestions.map((suggestion) => (
                  <OptionsView
                    style={{
                      backgroundColor: suggestion.active ? colors.a11yRoyalBlue : colors.white,
                      color: suggestion.active ? colors.white : colors.black,
                    }}
                    {...getSuggestionItemProps(suggestion)}
                    // getSuggestionItemProp outputs a key so place key prop below the spread to avoid console warning
                    key={suggestion.placeId}
                  >
                    <OptionsText>{suggestion.description}</OptionsText>
                  </OptionsView>
                ))}
              </OptionsContainerView>
            )}
          </View>
        )}
      </PlacesAutocomplete>
      {!onlyShowAddress && (
        <>
          <Input
            dataQa={`addressLine2${qaAttributeUniqueNaming}Input`}
            wrappedInputProps={{
              label: address2Label,
              isError: !!errors[addressLine2Name],
              errorMessage: errors[addressLine2Name]?.message,
              ...a11yInputStyles(colors, !!errors[addressLine2Name]),
            }}
            maxLength={64}
            placeholder={address2Placeholder}
            {...addressLine2}
            onChange={(e) => {
              addressLine2.onChange(e);
            }}
            ref={addressLine2Ref}
          />
          <Input
            dataQa={`city${qaAttributeUniqueNaming}Input`}
            wrappedInputProps={{
              label: 'City',
              isError: !!errors[cityName],
              errorMessage: errors[cityName]?.message,
              ...a11yInputStyles(colors, !!errors[cityName]),
            }}
            placeholder="City"
            {...city}
            onChange={(e) => {
              city.onChange(e);
            }}
          />
          <StateZipWrapper>
            <StateComponent
              states={states}
              stateName={stateName}
              countryName={countryName}
              qaAttributeUniqueNaming={qaAttributeUniqueNaming}
            />
            <SmallInputWrapper>
              <Input
                dataQa={`zipCode${qaAttributeUniqueNaming}Input`}
                wrappedInputProps={{
                  label: 'Zip code',
                  ...smallInputStyles(colors),
                  ...a11yInputStyles(colors, !!errors[zipcodeName]),
                  isError: !!errors[zipcodeName],
                  errorMessage: errors[zipcodeName]?.message,
                }}
                placeHolderStyle={{ color: colors.permaWaikawaGreyNew }}
                placeholder="Zip code"
                {...zipcode}
                onChange={(e) => {
                  zipcode.onChange(e);
                }}
              />
            </SmallInputWrapper>
          </StateZipWrapper>
          <RHFSelect
            fieldName={countryName}
            label="Country"
            options={countries}
            allowMobileSearch
            registerOptionValue
          />
        </>
      )}
    </View>
  );
};

export default A11yFullAddress;
