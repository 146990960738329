// Media has a dependency with Dialog
// Depends on: Capacitor, Dialog, Crashlytics
import {
  MediaCapture,
  CaptureImageOptions,
  CaptureVideoOptions,
} from '@awesome-cordova-plugins/media-capture';
import { Camera } from '@awesome-cordova-plugins/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { sleep } from 'ts-frontend/helpers';
import { safeIonicWrapper } from '../../ionicUtils';
import { mediaLogger } from '../../loggers';
import { convertFileSrc, getIonicPlatform, isPluginAvailable } from '../capacitor';
import { dialogAlert } from '../dialog';
import { mediaLoggerWithCrashlytics, reportError } from './loggingUtils';

const VIDEO_MESSAGE_MIN_DURATION = 1; // 1 second
const VIDEO_MESSAGE_MAX_DURATION = 6 * 60; // 6 minutes
const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100 MB

type FileType = 'photo' | 'video' | 'unknown';

export type MediaType<TMediaType extends 'video' | 'photo' = 'photo'> = {
  path: string;
  blob: Blob;
  fileType: string;
  mediaType: TMediaType;
};

interface CaughtError {
  error: string;
}

interface CapturedVideo {
  blob: Blob;
  duration: number;
  fileType: string;
  localSrc: string;
  type: 'video';
  name: string;
  size: number;
  lastModified: Date;
}

interface PickedMedia {
  blob: Blob;
  duration: number | null;
  fileType: string;
  localSrc: string;
  type: FileType;
  name: string;
  size: number;
  lastModified?: Date;
}

const photoExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'];
const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'octet-stream'];

const getFileTypeFromExtension = (extension: string): FileType => {
  if (photoExtensions.includes(extension.toLowerCase())) {
    return 'photo';
  }
  if (videoExtensions.includes(extension.toLowerCase())) {
    return 'video';
  }
  return 'unknown';
};

const getVideoDuration = (src: Blob | string): Promise<number | null> =>
  new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      if (typeof src !== 'string') URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = (event, source, lineno, colno, error) => {
      mediaLogger.error('#getVideoDuration - Error loading video metadata', video.error, {
        event,
        source,
        lineno,
        colno,
        error,
      });
      resolve(null);
    };
    video.src = typeof src === 'string' ? src : URL.createObjectURL(src);
  });

const isValidVideoDuration = (duration: number | null): duration is number => {
  if (duration) {
    const isLongVideo = duration > VIDEO_MESSAGE_MAX_DURATION;
    if (duration < VIDEO_MESSAGE_MIN_DURATION || isLongVideo) {
      const errorMessage = isLongVideo
        ? `The video maximum duration is ${VIDEO_MESSAGE_MAX_DURATION / 60} minutes.`
        : 'The video is too short.';
      mediaLogger.warn(
        '#isValidVideoDuration - Video duration is invalid',
        `Duration: ${duration}, isLongVideo: ${isLongVideo}`
      );
      dialogAlert({
        message: errorMessage,
      });
      return false;
    }
    return true;
  }
  mediaLogger.debug('#isValidVideoDuration - Video duration is null');
  return false;
};

const isValidVideoSize = (blob: Blob): boolean => {
  if (blob.size > MAX_FILE_SIZE) {
    dialogAlert({
      message: 'The file is too large, maximum size reached.',
    });
    mediaLoggerWithCrashlytics('error', '#isValidVideoSize - Video size is invalid', {
      size: blob.size,
      maxSize: MAX_FILE_SIZE,
    });
    return false;
  }
  return true;
};

const tryFetchBlob = async (directory: Directory, fullPath: string) => {
  const { uri } = await Filesystem.getUri({
    path: fullPath,
    directory,
  });
  let newSrc = uri;
  let newBlob = await fetch(newSrc)
    .then((data) => {
      if (data.ok) return data.blob();
      return null;
    })
    .catch((err) => {
      mediaLoggerWithCrashlytics('warn', '#tryFetchBlob - Error fetching video blob', err);
      return null;
    });

  if (!newBlob) {
    newSrc = convertFileSrc(uri);
    newBlob = await fetch(newSrc)
      .then((data) => {
        if (data.ok) return data.blob();
        return null;
      })
      .catch((err) => {
        mediaLoggerWithCrashlytics(
          'warn',
          '#tryFetchBlob - Error fetching video blob 2nd attempt',
          err
        );
        return null;
      });
  }
  return { newSrc, newBlob };
};

/**
 * Attempts multiple methods to retrieve a playable video source and a Blob object.
 * @param fullPath Path of the video file in the user's device
 */
const getVideoSourceAndBlob = async (
  fullPath: string
): Promise<{ src: string; blob: Blob | null }> => {
  let src = convertFileSrc(fullPath);
  let blob = await fetch(src)
    .then((response) => response.blob())
    .then((data) => {
      if (data.size > 0) return data;
      return null;
    })
    .catch((err) => {
      mediaLoggerWithCrashlytics('warn', '#getVideoSourceAndBlob - Error fetching video blob', err);
      return null;
    });

  if (!blob) {
    mediaLoggerWithCrashlytics('warn', '#getVideoSourceAndBlob - Error fetching video blob', {
      src,
      fullPath,
    });

    let result = await tryFetchBlob(
      fullPath.includes('cache') ? Directory.Cache : Directory.ExternalStorage,
      fullPath
    );
    if (!result.newBlob) {
      result = await tryFetchBlob(Directory.External, fullPath);
    }

    if (result.newBlob) {
      src = result.newSrc;
      blob = result.newBlob;
    }
  }

  mediaLogger.debug('#getVideoSourceAndBlob - Data', { src, fullPath, blob });
  return { src, blob };
};

export const captureImage = safeIonicWrapper(async (options?: Partial<CaptureImageOptions>) => {
  const mediaFiles = await MediaCapture.captureImage({
    limit: 1,
    ...options,
  });
  if (Array.isArray(mediaFiles) && mediaFiles.length) {
    const { fullPath, name, type, lastModifiedDate } = mediaFiles[0];
    const src = convertFileSrc(fullPath);
    mediaLogger.debug('#captureImage - Data', { src, fullPath });
    const blob = await fetch(src).then((data) => data.blob());
    mediaLogger.debug('#captureImage - Converted to blob ', blob);
    return {
      blob,
      size: blob.size,
      name,
      localSrc: URL.createObjectURL(blob),
      type: 'photo',
      fileType: type,
      lastModified: lastModifiedDate,
    };
  }
  mediaLogger.warn(
    'Error in #captureImage',
    'code' in mediaFiles ? mediaFiles.code : 'Empty array'
  );
  if ('code' in mediaFiles) {
    reportError(`Error capturing photo #captureImage - errorCode ${mediaFiles.code}`);
  }
  return null;
}, Promise.resolve(null));

export const captureVideo = safeIonicWrapper(
  async (options?: Partial<CaptureVideoOptions>): Promise<CaughtError | CapturedVideo | null> => {
    try {
      const mediaFiles = await MediaCapture.captureVideo({
        limit: 1,
        duration: 300, // 5 minutes max
        quality: 0.5,
        ...options,
      });
      if (Array.isArray(mediaFiles) && mediaFiles.length) {
        const { fullPath, name, type, lastModifiedDate } = mediaFiles[0];
        mediaLoggerWithCrashlytics('debug', '#captureVideo - Video Data', mediaFiles[0]);

        const durationPromise = await new Promise<number | null>((resolve) => {
          mediaFiles[0].getFormatData(
            // Handle 0 duration (https://issues.apache.org/jira/browse/CB-7117)
            ({ duration }) => resolve(duration || null),
            (err) => {
              mediaLoggerWithCrashlytics('error', '#captureVideo - Error fetching video data', err);
              resolve(null);
            }
          );
        });
        const timeoutPromise = sleep(1000, null);
        let duration = await Promise.race([durationPromise, timeoutPromise]);
        const { src, blob } = await getVideoSourceAndBlob(fullPath);
        mediaLoggerWithCrashlytics('debug', '#captureVideo - Converted to blob', blob);
        if (!blob) {
          throw new Error('Could not fetch blob');
        }
        if (!duration) {
          mediaLoggerWithCrashlytics('warn', '#captureVideo - getFormatData returned 0 duration', {
            src,
            fullPath,
          });
          duration = await getVideoDuration(blob);
          if (!isValidVideoDuration(duration)) {
            mediaLoggerWithCrashlytics('warn', '#captureVideo - Invalid video duration', duration);
            throw new Error('Invalid video duration');
          }
        }
        mediaLoggerWithCrashlytics('debug', '#captureVideo - Data', { src, fullPath, duration });

        if (!isValidVideoSize(blob)) {
          return null;
        }

        return {
          blob,
          size: blob.size,
          duration,
          name,
          localSrc: src,
          type: 'video',
          fileType: type,
          lastModified: lastModifiedDate,
        };
      }
      return null;
    } catch (error) {
      mediaLogger.warn(
        'Error in #captureVideo',
        error,
        'code' in error ? error.code : 'Unknown error'
      );
      // User exited camera application
      if (error.code === 3) {
        return null;
      }
      reportError(error.message || 'Error capturing video #captureVideo', error);
      return { error: error.message };
    }
  },
  Promise.resolve(null)
);

/**
 * Does not work for Android. Uses Cordova Camera plugin.
 * The user is capable of selecting media, but the data returned is not enough to
 * create a Blob object on Android, as the file name sometimes does not have the file extension.
 * Allows compressing videos, as the default `quality` is set to 50.
 */
export const pickPhoto = safeIonicWrapper(async (): Promise<PickedMedia | null | CaughtError> => {
  try {
    const photoPath = (await Camera.getPicture({
      sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
      mediaType: Camera.MediaType.ALLMEDIA,
      destinationType: Camera.DestinationType.FILE_URI,
    })) as string | null;

    if (photoPath) {
      const sections = photoPath.split('/');
      const name = sections[sections.length - 1];
      const fileNameSections = name.split('.');
      const fileType = fileNameSections[fileNameSections.length - 1] || '';
      let type = getFileTypeFromExtension(fileType);
      const src = convertFileSrc(photoPath);
      let duration = type === 'video' ? await getVideoDuration(src) : null;
      if (type === 'video' && !isValidVideoDuration(duration)) {
        throw new Error('Invalid video duration');
      }
      mediaLogger.debug('#pickPhoto - Data', { src, photoPath, fileType, type, duration });
      const blob = await fetch(src).then((data) => data.blob());
      // Try getting the file type from the blob
      if (type === 'unknown') {
        mediaLogger.debug('#pickPhoto - Unknown file type', blob);
        type = getFileTypeFromExtension(blob.type.split('/')[1]);
      }

      let localSrc = type === 'video' ? src : URL.createObjectURL(blob);

      // Prefer using the blob URL if src failed (duration === null)
      if (duration === null && type === 'video' && blob.size > 0) {
        localSrc = URL.createObjectURL(blob);
        mediaLogger.debug('#pickPhoto - Using blob URL', localSrc, duration, src);
        duration = await getVideoDuration(localSrc);
        if (!isValidVideoDuration(duration)) {
          throw new Error('Invalid video duration');
        }
      }
      mediaLogger.debug('#pickPhoto - Converted to blob', blob);

      if (!isValidVideoSize(blob)) {
        return null;
      }

      return {
        blob,
        size: blob.size,
        name,
        localSrc,
        type,
        fileType,
        duration,
      };
    }
    throw new Error('No photo path');
  } catch (error) {
    mediaLogger.error('Error in #pickPhoto', error);
    // User exited camera application
    if (error.message === 'No Image Selected') {
      return null;
    }
    reportError(error.message || 'Error capturing media #pickPhoto', error);
    return { error: error.message };
  }
}, Promise.resolve(null));

const filePickerMediaFunc = () => {
  // For Android, pick files with specific mime types has a better user experience.
  if (getIonicPlatform() === 'android') {
    return FilePicker.pickFiles({
      types: ['image/*', 'video/*'],
      limit: 1,
    });
  }

  return FilePicker.pickMedia({
    limit: 1,
  });
};

export const pickMedia = safeIonicWrapper(async (): Promise<PickedMedia | null | CaughtError> => {
  // pickPhoto allows compressing videos. FIle Picker does not, but it has a better UX for android
  if (!isPluginAvailable('FilePicker') || getIonicPlatform() !== 'android') {
    mediaLogger.warn(
      'Warning in #pickMedia',
      'FilePicker plugin is not available - Using fallback'
    );
    return pickPhoto();
  }
  try {
    const mediaChosen = await filePickerMediaFunc();

    if (!mediaChosen.files?.length) {
      mediaLogger.warn('Error in #pickMedia', 'Empty selection', mediaChosen);
      throw new Error('Empty selection');
    }

    const file = mediaChosen.files[0];
    const { path, name, mimeType, size, modifiedAt, width, height, duration } = file;
    mediaLogger.debug('#pickMedia - File', {
      path,
      name,
      mimeType,
      size,
      modifiedAt,
      width,
      height,
      duration,
    });
    const fileType = name.split('.').pop() || '';
    const type = getFileTypeFromExtension(fileType);

    if (!path) {
      mediaLogger.warn('#pickMedia - Invalid path', path);
      throw new Error('Invalid path');
    }

    const localSrc = convertFileSrc(path);
    const blob = await fetch(localSrc).then((data) => data.blob());

    if (!isValidVideoSize(blob)) {
      return null;
    }

    return {
      blob,
      size,
      name,
      localSrc,
      type,
      fileType,
      duration,
      lastModified: modifiedAt,
    } as PickedMedia;
  } catch (err) {
    mediaLogger.error('#pickMedia - Error selecting media', err);
    if (err.message?.toLowerCase?.().includes('canceled')) {
      return null;
    }
    reportError(err.message || 'Error selecting media #pickMedia', err);
    return { error: err.message };
  }
}, Promise.resolve(null));

export const mediaIsError = <T>(media: T | null | CaughtError): media is CaughtError =>
  !!media && 'error' in media;

export { mediaLogger };
