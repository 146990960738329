import {
  FunctionComponent,
  useMemo,
  useState,
  useCallback,
  ComponentProps,
  useEffect,
} from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import {
  TouchableView,
  Standard,
  A11yMenuWrapper,
  FloatingMenuHeaderLeftArrow,
  FloatingMenuHeaderWrapper,
  FloatingMenuHeaderText,
  Spinner,
  Small,
  View,
  COLORS,
  Button,
  Link,
  TextDS,
} from '@talkspace/react-toolkit';
import { AllowedSessionModalities, VideoCreditOffer } from 'ts-frontend/types';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { captureImage, captureVideo, mediaIsError, pickMedia } from 'ts-ionic/plugins/media';
import BottomSheet from '@talkspace/react-toolkit/src/designSystems/components/BottomSheet';
import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { EActiveSession } from '../../entities/ActiveSession';
import { useVideoCallState } from '../../hooks/videoCallContext';
import { HandleShareFile } from '../../types/inputTypes';
import { preventNextSplashScreenShow } from '../../../ts-ionic/plugins/splashScreen';

const MAXIMUM_VIDEO_DURATION_IN_SECONDS = 60 * 5;

const AddMenuItem = styled(TouchableView)({
  padding: 10,
  borderRadius: 5,
  ...webOnlyStyle({
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#EFF3FA',
    },
  }),
});

const ResponsiveMenuItem = ({
  useBottomSheet,
  children,
  ...otherProps
}: { useBottomSheet?: boolean } & ComponentProps<typeof AddMenuItem>) => {
  if (useBottomSheet)
    return <BottomSheet.MenuOption {...otherProps}>{children}</BottomSheet.MenuOption>;

  return <AddMenuItem {...otherProps}>{children}</AddMenuItem>;
};

const Label = styled.label({
  cursor: 'pointer',
});

const Container = styled(View)(() => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 16,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 270,
    height: 220,
  };
});

export const SubmitButton = styled(Button)(({ theme: { colors } }) => {
  return {
    minHeight: 30,
    height: 30,
    borderRadius: 5,
    margin: 0,
    background: colors.permaEden,
    fontWeight: 700,
    fontSize: 15,
  };
});

const FreeCallLinkText = styled(View)(() => {
  return {
    color: COLORS.accessibilityGreenDark,
    fontWeight: 500,
    fontSize: 15,
    textAlign: 'center',
  };
});

interface FreeCallInfoTextProps {
  menuId?: string;
  ariaLabelledBy?: string;
  handleOnFreeCallInfoPress: () => void;
  onBackArrowPress: () => void;
}

const FreeCallInfoText: FunctionComponent<FreeCallInfoTextProps> = ({
  menuId,
  ariaLabelledBy,
  handleOnFreeCallInfoPress,
  onBackArrowPress,
}) => (
  <A11yMenuWrapper id={menuId} ariaLabelledBy={ariaLabelledBy}>
    <FloatingMenuHeaderWrapper>
      <FloatingMenuHeaderLeftArrow goBack={onBackArrowPress} />
      <FloatingMenuHeaderText>Free live session</FloatingMenuHeaderText>
    </FloatingMenuHeaderWrapper>
    <Container>
      <Small style={{ color: COLORS.permaRiverBed }}>
        You can use free calls at your discretion to provide emergency services to clients. Keep in
        mind that in most cases these calls are not billable.
      </Small>
      <Small style={{ color: COLORS.permaRiverBed }}>
        Please use paid live sessions wherever possible.
      </Small>
      <View row align="center" justify="end">
        <SubmitButton onPress={handleOnFreeCallInfoPress} text="Start session" />
      </View>
      <Link
        target="_blank"
        href="https://providers-help.talkspace.com/hc/en-us/articles/360050017471-How-do-I-join-or-initiate-a-Live-Session-#h_01GAHTDSDGT392MBHCVD9M3CMM"
      >
        <FreeCallLinkText>Learn more about free sessions</FreeCallLinkText>
      </Link>
    </Container>
  </A11yMenuWrapper>
);

interface Props {
  handleShareFile: HandleShareFile;
  onLiveModalitySessionPress: (type: string) => void;
  onRecordVoiceMessagePress: () => void;
  shouldShowModalityOptions: boolean;
  isPDFUploadEnabled?: boolean;
  isTherapistChat: boolean;
  allowedSessionModalities: AllowedSessionModalities;
  handleLiveModalitySessionSelect: (
    roomID: number,
    creditMinutes: number,
    type: string,
    modality: string
  ) => void;
  onBackArrowPress: () => void;
  roomID: number;
  toggleShowAddMedia: () => void;
  isPsychInPsychRoom: boolean;
  isNonPsychInPsychRoom: boolean;
  menuId?: string;
  ariaLabelledBy?: string;
  modality?: string;
  isLoadingActiveSessionInfo?: boolean;
  activeSession: EActiveSession | null;
  handleJoinActiveSession: () => void;
  isVideoCreditOffersLoading: boolean;
  videoCreditOffers?: VideoCreditOffer[];
  showPhotoVideoUpload?: boolean;
  useBottomSheet?: boolean;
  hideRecordVoiceMessage?: boolean;
  handleShareFilePress?: () => void;
  showLoadingSpinner?: boolean;
}

const AddMediaMenu: FunctionComponent<Props> = ({
  handleShareFile,
  onRecordVoiceMessagePress,
  onLiveModalitySessionPress,
  isPDFUploadEnabled,
  isTherapistChat,
  handleLiveModalitySessionSelect,
  shouldShowModalityOptions,
  onBackArrowPress,
  roomID,
  toggleShowAddMedia,
  isPsychInPsychRoom,
  isNonPsychInPsychRoom,
  menuId,
  ariaLabelledBy,
  modality = 'video',
  allowedSessionModalities,
  isLoadingActiveSessionInfo,
  activeSession,
  handleJoinActiveSession,
  isVideoCreditOffersLoading,
  videoCreditOffers,
  showPhotoVideoUpload,
  useBottomSheet,
  hideRecordVoiceMessage,
  handleShareFilePress,
  showLoadingSpinner,
}) => {
  const { psychBh15MinutesOffer } = useFlags();
  const [showSpinner, setShowSpinner] = useState(false);
  const [mediaUploadError, setMediaUploadError] = useState<string | null>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (mediaUploadError) {
      timeout = setTimeout(() => {
        setMediaUploadError(null);
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [mediaUploadError]);

  const { isMinimized } = useVideoCallState();

  const [showFreeCallInfoText, setShowFreeCallInfoText] = useState(false);

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);
  const { isBH } = businessLine || {};

  const sessionIsExpired =
    activeSession &&
    moment().isSameOrAfter(
      moment(activeSession?.callStartedAt).add(activeSession?.booking.creditMinutes || 30, 'minute')
    );

  const handleOnFreePress = () => {
    setShowFreeCallInfoText(true);
  };

  const handleOnFreeCallInfoPress = () => {
    toggleShowAddMedia();
    setShowFreeCallInfoText(false);
    handleLiveModalitySessionSelect(roomID, 0, 'free', modality);
  };

  const handleOnFreeCallInfoBackArrowPress = () => {
    setShowFreeCallInfoText(false);
  };

  const handleOnIntroPress = () => {
    toggleShowAddMedia();
    handleLiveModalitySessionSelect(roomID, 10, 'introduction', modality);
  };
  const handleOnBHPsychOfferPress = (minutes: number) => () => {
    toggleShowAddMedia();
    handleLiveModalitySessionSelect(roomID, minutes, 'psychiatry', modality);
  };
  const handleOnFirstPaidPress = () => {
    toggleShowAddMedia();
    handleLiveModalitySessionSelect(
      roomID,
      30,
      isPsychInPsychRoom ? 'psychiatry' : 'therapy',
      modality
    );
  };
  const handleOnSecondPaidPress = () => {
    toggleShowAddMedia();
    handleLiveModalitySessionSelect(
      roomID,
      isPsychInPsychRoom ? 60 : 45,
      isPsychInPsychRoom ? 'psychiatry' : 'therapy',
      modality
    );
  };

  const handleOn60MinTherapyPress = () => {
    toggleShowAddMedia();
    handleLiveModalitySessionSelect(roomID, 60, 'therapy', modality);
  };

  const cameraPress = useCallback(async () => {
    preventNextSplashScreenShow(15);
    const image = await captureImage();

    if (image)
      handleShareFile({
        file: image.blob,
        name: image.name,
        type: 'photo',
        localSrc: image.localSrc,
      });
  }, [handleShareFile]);
  const videoRecordPress = useCallback(async () => {
    preventNextSplashScreenShow(60);
    if (showLoadingSpinner) {
      setShowSpinner(true);
    }
    const video = await captureVideo({ duration: MAXIMUM_VIDEO_DURATION_IN_SECONDS });

    setShowSpinner(false);
    if (mediaIsError(video)) {
      setMediaUploadError(
        'Video recording is not supported for your device. Please try messaging your provider or sending a voice recording instead'
      );
    } else if (video) {
      handleShareFile({
        file: video.blob,
        name: video.name,
        type: 'video',
        durationSeconds: video.duration || undefined,
        localSrc: video.localSrc,
      });
    }
  }, [handleShareFile, showLoadingSpinner]);
  const videoPhotoLibraryPress = useCallback(async () => {
    if (showLoadingSpinner) {
      setShowSpinner(true);
    }
    preventNextSplashScreenShow(15);
    const file = await pickMedia();

    setShowSpinner(false);

    if (mediaIsError(file)) {
      setMediaUploadError(
        'There was an error processing your media. Please try again or contact support.'
      );
    } else if (file) {
      handleShareFile({
        file: file.blob,
        name: file.name,
        type: file.type,
        durationSeconds: file.duration || undefined,
        localSrc: file.localSrc,
      });
    }
  }, [showLoadingSpinner, handleShareFile]);

  const firstOption = useMemo(() => {
    if (isTherapistChat && !isNonPsychInPsychRoom) {
      if (isLoadingActiveSessionInfo) {
        return <Spinner />;
      }
      if (activeSession === null || sessionIsExpired || activeSession?.modality === 'chat') {
        return isVideoCreditOffersLoading ? (
          <Spinner />
        ) : (
          <>
            <AddMenuItem role="menuitem" onPress={() => onLiveModalitySessionPress('video')}>
              <Label>
                <Standard>Video session</Standard>
              </Label>
            </AddMenuItem>
            <AddMenuItem role="menuitem" onPress={() => onLiveModalitySessionPress('audio')}>
              <Label>
                <Standard>Audio session</Standard>
              </Label>
            </AddMenuItem>
            <AddMenuItem role="menuitem" onPress={() => onLiveModalitySessionPress('chat')}>
              <Label>
                <Standard>Chat session</Standard>
              </Label>
            </AddMenuItem>
          </>
        );
      }
      return (
        <AddMenuItem role="menuitem" onPress={handleJoinActiveSession}>
          <Label>
            <Standard>Join active session</Standard>
          </Label>
        </AddMenuItem>
      );
    }
    return null;
  }, [
    activeSession,
    handleJoinActiveSession,
    isLoadingActiveSessionInfo,
    isNonPsychInPsychRoom,
    isTherapistChat,
    onLiveModalitySessionPress,
    sessionIsExpired,
    isVideoCreditOffersLoading,
  ]);

  if (showFreeCallInfoText) {
    return (
      <FreeCallInfoText
        ariaLabelledBy={ariaLabelledBy}
        menuId={menuId}
        handleOnFreeCallInfoPress={handleOnFreeCallInfoPress}
        onBackArrowPress={handleOnFreeCallInfoBackArrowPress}
      />
    );
  }

  if (shouldShowModalityOptions) {
    let bhOffers: JSX.Element[] | undefined;
    if (isPsychInPsychRoom && isBH && psychBh15MinutesOffer && videoCreditOffers?.length) {
      if (!(videoCreditOffers.length === 1 && videoCreditOffers[0].creditMinutes === 60)) {
        bhOffers = videoCreditOffers.map((offer) => (
          <AddMenuItem onPress={handleOnBHPsychOfferPress(offer.creditMinutes)}>
            <Standard>
              Paid live {modality} psych session ({offer.creditMinutes} min)
            </Standard>
          </AddMenuItem>
        ));
      }
    }
    return (
      <A11yMenuWrapper id={menuId} ariaLabelledBy={ariaLabelledBy}>
        <FloatingMenuHeaderWrapper>
          <FloatingMenuHeaderLeftArrow goBack={onBackArrowPress} />
          <FloatingMenuHeaderText>Live {modality} session</FloatingMenuHeaderText>
        </FloatingMenuHeaderWrapper>
        <AddMenuItem onPress={handleOnFreePress}>
          <Standard>
            Free live {modality} {isPsychInPsychRoom && 'psych'} session
          </Standard>
        </AddMenuItem>
        {bhOffers ||
          (allowedSessionModalities[modality] && (
            <>
              {!isPsychInPsychRoom && modality !== 'chat' && (
                <AddMenuItem onPress={handleOnIntroPress}>
                  <Standard>Intro live {modality} session (10 min)</Standard>
                </AddMenuItem>
              )}
              <AddMenuItem onPress={handleOnFirstPaidPress}>
                <Standard>
                  Paid live {modality} {isPsychInPsychRoom && 'psych '}session (30 min)
                </Standard>
              </AddMenuItem>
              <AddMenuItem onPress={handleOnSecondPaidPress}>
                <Standard>
                  Paid live {modality} {isPsychInPsychRoom && 'psych '}session (
                  {isPsychInPsychRoom ? '60' : '45'} min)
                </Standard>
              </AddMenuItem>
              {!isPsychInPsychRoom && (
                <AddMenuItem>
                  <Standard onPress={handleOn60MinTherapyPress}>
                    Paid live {modality} session (60 min)
                  </Standard>
                </AddMenuItem>
              )}
            </>
          ))}
      </A11yMenuWrapper>
    );
  }

  const photoVideoUploadMenuItems = showPhotoVideoUpload && (
    <>
      <ResponsiveMenuItem
        useBottomSheet={useBottomSheet}
        role="menuitem"
        onPress={videoRecordPress}
        dataQa="videoRecordMenuItem"
      >
        <Label>
          <Standard>Record a video</Standard>
        </Label>
      </ResponsiveMenuItem>
      <ResponsiveMenuItem
        useBottomSheet={useBottomSheet}
        role="menuitem"
        onPress={cameraPress}
        dataQa="cameraMenuItem"
      >
        <Label>
          <Standard>Take a photo</Standard>
        </Label>
      </ResponsiveMenuItem>
      <ResponsiveMenuItem
        useBottomSheet={useBottomSheet}
        role="menuitem"
        onPress={videoPhotoLibraryPress}
        dataQa="videoPhotoLibraryMenuItem"
      >
        <Label>
          <Standard>Choose photo or video</Standard>
        </Label>
      </ResponsiveMenuItem>
    </>
  );

  if (showSpinner || mediaUploadError) {
    return (
      <A11yMenuWrapper id={menuId} ariaLabelledBy={ariaLabelledBy}>
        {showSpinner && <Spinner />}
        {mediaUploadError && (
          <ResponsiveMenuItem>
            <TextDS>{mediaUploadError}</TextDS>
          </ResponsiveMenuItem>
        )}
      </A11yMenuWrapper>
    );
  }

  return (
    <A11yMenuWrapper id={menuId} ariaLabelledBy={ariaLabelledBy}>
      {firstOption}
      {isPDFUploadEnabled && (
        <ResponsiveMenuItem
          useBottomSheet={useBottomSheet}
          role="menuitem"
          onPress={handleShareFilePress}
          dataQa="sharePDFPress"
        >
          <Label>
            <Standard>Upload PDF</Standard>
          </Label>
        </ResponsiveMenuItem>
      )}
      {!isMinimized && (
        <>
          {photoVideoUploadMenuItems}
          {!hideRecordVoiceMessage && (
            <ResponsiveMenuItem
              useBottomSheet={useBottomSheet}
              role="menuitem"
              onPress={onRecordVoiceMessagePress}
              dataQa="recordVoiceMessagePress"
            >
              <Label>
                <Standard>Record voice message</Standard>
              </Label>
            </ResponsiveMenuItem>
          )}
        </>
      )}
    </A11yMenuWrapper>
  );
};
export default AddMediaMenu;
