import { useReducer, useCallback, useRef } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import QuestionnaireWizardApiHelper, {
  CreateQuestionnaireParams,
} from '../utils/QuestionnaireWizardApiHelper';

interface QuestionnaireWizardState extends WizardActionState {}

export default function useQuestionnaireWizard(): [
  QuestionnaireWizardState,
  {
    createQuestionnaire: (params: CreateQuestionnaireParams) => void;
    exitAction: () => void;
  }
] {
  const additionalStateProps = {};

  const [state, dispatch] = useReducer(
    wizardActionReducer,
    wizardActionInitialState(additionalStateProps)
  );
  const apiRefQuestionnaireWizard = useRef(QuestionnaireWizardApiHelper);

  function createQuestionnaire(params: CreateQuestionnaireParams) {
    dispatch({ type: 'requestAction' });
    apiRefQuestionnaireWizard.current
      .createQuestionnaire(params)
      .then((res) => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function exitAction() {
    dispatch({ type: 'exitAction' });
  }

  return [
    state as QuestionnaireWizardState,
    {
      createQuestionnaire: useCallback(createQuestionnaire, []),
      exitAction: useCallback(exitAction, []),
    },
  ];
}
