import { useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Redirect } from '@/core/routerLib';
import WhatToExpect from '../components/WhatToExpect';
import { trackEvent } from '@/utils/analytics/eventTracker';

const WhatToExpectContainer = () => {
  const { updateCoveragePart2, addCoverageKeepProvider: flagACKP } = useFlags();

  useEffect(() => {
    if (updateCoveragePart2?.experimentActive) {
      trackEvent('TS Experiment Session', {
        experimentName: updateCoveragePart2.experimentName,
        variantName: updateCoveragePart2.variant,
      });
    }
  }, [updateCoveragePart2]);

  if (!flagACKP) {
    return <Redirect to="/eligibility-widget" />;
  }
  return <WhatToExpect />;
};

export default WhatToExpectContainer;
