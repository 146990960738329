import {
  CalendarXOutlineIcon,
  ShieldOutlineIcon,
  EligibilityWarningInfoItem,
} from '@talkspace/react-toolkit';

const EligibilityWarningInfoOutOfGracePeriod = () => (
  <>
    <EligibilityWarningInfoItem
      icon={<ShieldOutlineIcon />}
      title="Update coverage or payment"
      text="Your insurance coverage expired and your room is now closed. To continue care, update your coverage or payment method."
    />
    <EligibilityWarningInfoItem
      icon={<CalendarXOutlineIcon />}
      title="Sessions canceled"
      text="If you had any upcoming scheduled sessions, they have been canceled to protect you from unexpected fees."
    />
  </>
);

export default EligibilityWarningInfoOutOfGracePeriod;
