import { RoomType } from 'ts-frontend/entities/Room';
import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { roomDetailsQueryKey } from './queryKeys';

export interface RoomDetailsResponse {
  therapistDisplayName: string;
  expirationDate: string;
  paymentType: number;
  roomType: RoomType;
  roomStatus: number;
  isRoomDischarged?: boolean;
  shouldActivateDueToDischarge?: boolean;
  isChatDisabled?: boolean;
  isClientDischargeV3OnForPlan?: boolean;
  customerName: string;
  therapistID: number;
  planName: string;
  clientUserID: number;
  bookingsCount: number;
  hasHadFirstSession: boolean;
  isSmartInsightsActive: boolean;
  experimentalFeaturesClientOn?: string[];
  lastTalktrack?: { talktrackID: number; talktrackReviewed: boolean };
}

const fetchRoomDetails = (roomID?: number | string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/room-details`
  );

  return data.data;
};

const useQueryRoomDetails = (roomID?: number | string, isTransferred: boolean = false) =>
  useQuery<RoomDetailsResponse, Error>({
    enabled: !!roomID && !isTransferred,
    queryKey: roomDetailsQueryKey(roomID),
    queryFn: fetchRoomDetails(roomID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryRoomDetails;
