import { FunctionComponent } from 'react';
import { View, ExtraHuge, Small, TouchableView, Standard } from '@talkspace/react-toolkit';

import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import NoSharedFilesIcon from '../Icons/NoSharedFiles';

const Container = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  paddingTop: 133,
  ...webOnlyStyle({
    '@media (max-width: 750px)': {
      paddingTop: 60,
    },
  }),
});

interface Props {
  onClosePress: () => void;
}

const NoSharedFilesBanner: FunctionComponent<Props> = ({ onClosePress }) => (
  <Container>
    <NoSharedFilesIcon />
    <ExtraHuge>You haven't shared any files yet</ExtraHuge>
    <Small style={{ padding: 10 }}>Sharing a file will save it here for easy access.</Small>
    <TouchableView
      style={{ padding: 30 }}
      onPress={onClosePress}
      dataQa="noSharedFilesBannerCloseButton"
    >
      <Standard variant="standardDarkGrey">Close</Standard>
    </TouchableView>
  </Container>
);

export default NoSharedFilesBanner;
