import { HumanCheckmarkOutlineIcon } from '../icons';
import EligibilityWarningInfoItem from './EligibilityWarningInfoItem';

const TherapistChangedInfo = () => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="New therapist"
    text="Unfortunately, due to this update, your previous therapist is unavailable. We’ll match you to a new one shortly."
  />
);

const TherapistAssignedInfo = ({ therapistName }: { therapistName: string }) => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="New therapist"
    text={`You are all set up with your new therapist, ${therapistName}.`}
  />
);

const NewPysch = () => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="New psychiatry provider"
    text="Unfortunately, due to this update, your previous psychiatry provider is unavailable. We’ll match you to a new one shortly."
  />
);

const SameTherapist = () => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="Same therapist"
    text="You’ll be able to continue making progress with your same therapist."
  />
);

const SamePsych = () => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="Same psychiatry provider"
    text="You’ll be able to continue making progress with your same psychiatry provider."
  />
);

type TherapistInfoProps = {
  hasPreviousProvider: boolean;
  isNewProvider: boolean;
  isPsych?: boolean;
  therapistName: string;
};

const TherapistInfo = ({
  hasPreviousProvider,
  isNewProvider,
  isPsych,
  therapistName,
}: TherapistInfoProps) => {
  if (!hasPreviousProvider) {
    return <TherapistAssignedInfo therapistName={therapistName} />;
  }

  if (isPsych) {
    if (isNewProvider) {
      return <NewPysch />;
    }
    return <SamePsych />;
  }

  if (isNewProvider) {
    return <TherapistChangedInfo />;
  }
  return <SameTherapist />;
};

export default TherapistInfo;
