import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';

import Tooltip from './Tooltip';
import Tooltipv2 from './Tooltipv2';

export type { TooltipProps } from './Tooltip';

const versionMap: VersionMap<React.ComponentProps<typeof Tooltip>> = {
  '2.0.0': Tooltipv2,
  '1.0.0': Tooltipv2,
  DefaultComponent: Tooltip,
};

export default withVersioning(versionMap) as typeof Tooltip;
