import unionBy from 'lodash/unionBy';
import { LiveSessionModality } from 'ts-frontend/types';
import apiHelper from '@/core/api/apiHelper';
import ChatApiHelpers, { MESSAGES_PER_REQUEST } from '../helpers/ChatApiHelpersClass';
import {
  REQUEST_GET_MESSAGES,
  REQUEST_GET_MESSAGES_ERROR,
  RECEIVE_GET_MESSAGES,
  REQUEST_GET_ROOM_INFO,
  REQUEST_GET_ROOM_INFO_ERROR,
  RECEIVE_GET_ROOM_INFO,
  REQUEST_ACTIVE_SESSION_INFO,
  REQUEST_ACTIVE_SESSION_INFO_ERROR,
  RECEIVE_ACTIVE_SESSION_INFO,
  RECEIVE_ACKNOWLEDGEMENTS,
  RECEIVE_USER_TYPING,
  REQUEST_GET_STARRED_MESSAGES,
  REQUEST_GET_STARRED_MESSAGES_ERROR,
  RECEIVE_GET_STARRED_MESSAGES,
  RESET_CHAT,
  REQUEST_GET_SHARED_FILES,
  RECEIVE_GET_SHARED_FILES,
  ERROR_GET_SHARED_FILES,
  REQUEST_GET_HISTORICAL_SHARED_FILES,
  CLEAR_SHARED_FILES,
  REQUEST_TOGGLE_STAR_MESSAGE,
  ERROR_TOGGLE_STAR_MESSAGE,
  POST_LIVE_SESSION_EVENT,
  RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS,
  RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED,
  POST_LIVE_SESSION_EVENT_ERROR,
  RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED,
  POST_ACTIVE_SESSION_INFO,
  GetRoomInfoPayload,
  ChatActionTypes,
  MessageAcks,
  GetStarredMessagesPayload,
  GetSharedFilesPayload,
  RequestToggleStarMessagePayload,
  REQUEST_SESSION_STATUS,
  REQUEST_SESSION_STATUS_ERROR,
  RECEIVE_SESSION_STATUS,
  SessionStatus,
} from '../constants/chatTypes';
import { EMessage } from '../../entities/EMessage';
import { StarredMessage } from '../../entities/StarredMessage';
import { Status } from '../../entities/EMedia';
import { VideoCallEvent } from '../../types/videoCallTypes';

function requestMessages(payload?): ChatActionTypes {
  return { type: REQUEST_GET_MESSAGES, payload };
}
function requestMessagesError(error): ChatActionTypes {
  return { type: REQUEST_GET_MESSAGES_ERROR, error };
}
function receiveMessages(payload): ChatActionTypes {
  return { type: RECEIVE_GET_MESSAGES, payload };
}
function receiveAcks(payload): ChatActionTypes {
  return { type: RECEIVE_ACKNOWLEDGEMENTS, payload };
}
function receiveUserTyping(payload): ChatActionTypes {
  return { type: RECEIVE_USER_TYPING, payload };
}
function requestRoomInfo(): ChatActionTypes {
  return { type: REQUEST_GET_ROOM_INFO };
}
function requestRoomInfoError(error: Error): ChatActionTypes {
  return { type: REQUEST_GET_ROOM_INFO_ERROR, error };
}
function receiveRoomInfo(payload: GetRoomInfoPayload): ChatActionTypes {
  return { type: RECEIVE_GET_ROOM_INFO, payload };
}
function requestActiveSessionInfo(): ChatActionTypes {
  return { type: REQUEST_ACTIVE_SESSION_INFO };
}
function requestActiveSessionInfoError(error: Error): ChatActionTypes {
  return { type: REQUEST_ACTIVE_SESSION_INFO_ERROR, error };
}
function receiveActiveSessionInfo(payload): ChatActionTypes {
  return { type: RECEIVE_ACTIVE_SESSION_INFO, payload };
}
function postActiveSessionInfo(): ChatActionTypes {
  return { type: POST_ACTIVE_SESSION_INFO };
}
function postLiveSessionEvent(): ChatActionTypes {
  return { type: POST_LIVE_SESSION_EVENT };
}
function receivePostLiveSessionEventSuccess(): ChatActionTypes {
  return { type: RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS };
}

function receivePostLiveSessionEventAlreadyJoined(): ChatActionTypes {
  return { type: RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED };
}
function receivePostLiveSessionEventAlreadyEnded(): ChatActionTypes {
  return { type: RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED };
}
function postLiveSessionEventError(error: Error): ChatActionTypes {
  return { type: POST_LIVE_SESSION_EVENT_ERROR, error };
}
function requestSessionStatus(): ChatActionTypes {
  return { type: REQUEST_SESSION_STATUS };
}
function requestSessionStatusError(error: Error): ChatActionTypes {
  return { type: REQUEST_SESSION_STATUS_ERROR, error };
}
function receiveSessionStatus(payload: {
  sessionStatus: SessionStatus | undefined;
}): ChatActionTypes {
  return { type: RECEIVE_SESSION_STATUS, payload };
}

/**
 * This function merges two messages arrays, handle duplicates and sorts
 * the reason that message might not in order is when a client enable share
 * of messages to a new therapist.
 * @param  {} currentMessages
 * @param  {} newMessages
 */
function mergeMessages(original: EMessage[], newMessages: EMessage[]) {
  newMessages.forEach((msg) => {
    const foundIndex = original.findIndex((ori) => ori.id === msg.id);
    if (foundIndex < 0) original.push(msg);
  });

  original.sort((a, b) => {
    if (a.createdAt.getTime() === b.createdAt.getTime()) {
      if (a.id > b.id) return 1;
      return -1;
    }

    if (a.createdAt > b.createdAt) return 1;
    return -1;
  });

  return original;
}

const dispatchRequestMessages =
  (roomID: number, data: any) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestMessages());
    return api
      .getMessages({
        roomID,
        isTherapistChat: getState().chat.isTherapistChat,
        lastMessageID: getState().chat.lastMessageID,
      })
      .then((messages) => {
        const current: EMessage[] = getState().chat.messages;
        const currentRoomID = getState().chat.roomID;

        if (currentRoomID !== roomID) {
          dispatch(requestMessagesError(new Error('Room ID changed form last request')));
          return; // the chat store roomID is different from the request
        }

        if (Array.isArray(messages) && messages.length) {
          dispatch(
            receiveMessages({
              messages: mergeMessages(current, messages),
              lastMessageID: Math.max(...messages.map((m) => m.id), 0),
              wasLoadingHistorical: false,
            })
          );
        } else {
          dispatch(
            receiveMessages({
              wasLoadingHistorical: false,
            })
          );
        }
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch(requestMessagesError(error));
      });
  };

const dispatchRequestHistoricalMessages =
  (roomID: number, data: any) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestMessages({ isLoadingHistorical: true }));
    const current: EMessage[] = getState().chat.messages;
    const maxMessageId = Math.min(...current.map((m) => m.id), Number.MAX_SAFE_INTEGER);

    return api
      .getMessages({
        roomID,
        isTherapistChat: getState().chat.isTherapistChat,
        maxMessageID: maxMessageId,
      })
      .then((messages) => {
        const currentRoomID = getState().chat.roomID;

        if (currentRoomID !== roomID) {
          dispatch(requestMessagesError(new Error('Room ID changed form last request')));
          return; // the chat store roomID is different from the request
        }

        if (Array.isArray(messages) && messages.length) {
          dispatch(
            receiveMessages({
              messages: data?.replace ? messages : mergeMessages(current, messages),
              lastMessageId: Math.max(...messages.map((m) => m.id), 0),
              isLoadingHistorical: false,
              wasLoadingHistorical: true,
            })
          );
        } else {
          dispatch(
            receiveMessages({
              hasReceivedAllMessages: true,
              isLoadingHistorical: false,
            })
          );
        }
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch(requestMessagesError(error));
      });
  };

const dispatchAcknowledgements = (acks: MessageAcks) => (dispatch) =>
  dispatch(receiveAcks({ acks }));

const dispatchUserTyping = (show: boolean, displayName?: string) => (dispatch) =>
  dispatch(receiveUserTyping({ show, displayName }));

const dispatchResetChat =
  (roomID = 0) =>
  (dispatch, getState, api: ChatApiHelpers) => {
    if (!roomID) api.reset();
    dispatch({ type: RESET_CHAT, payload: roomID });
  };

const dispatchGetRoomInfo = (roomID: number) => (dispatch, getState, api: ChatApiHelpers) => {
  dispatch(requestRoomInfo());
  return api
    .getRoomData(roomID)
    .then((roomInfo) => dispatch(receiveRoomInfo({ roomInfo })))
    .catch(api.dismissIfCancelled)
    .catch((error) => dispatch(requestRoomInfoError(error)));
};

const dispatchGetActiveSessionInfo =
  (roomID: number, modality: LiveSessionModality) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestActiveSessionInfo());
    return api
      .getActiveSession(roomID, modality)
      .then((activeSession) => dispatch(receiveActiveSessionInfo({ activeSession })))
      .catch(api.dismissIfCancelled)
      .catch((error) => dispatch(requestActiveSessionInfoError(error)));
  };

const dispatchPostActiveSessionInfo =
  (roomID: number) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(postActiveSessionInfo());
    return api
      .postActiveSession(roomID)
      .catch(api.dismissIfCancelled)
      .catch(() => null);
  };

function requestGetStarredMessages(): ChatActionTypes {
  return { type: REQUEST_GET_STARRED_MESSAGES };
}

function requestGetStarredMessagesError(error: Error): ChatActionTypes {
  return { type: REQUEST_GET_STARRED_MESSAGES_ERROR, error };
}

function receiveGetStarredMessages(payload: GetStarredMessagesPayload): ChatActionTypes {
  return { type: RECEIVE_GET_STARRED_MESSAGES, payload };
}

const dispatchRequestStarredMessagesByUser =
  (userID: number, lastMessageID?: number) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestGetStarredMessages());

    return api
      .getStarredMessagesByUser(userID, lastMessageID)
      .then((res) => {
        const starredMessages = getState().chat.starredMessages.filter((msg) => msg.isStarred);
        const updatedMessages = !lastMessageID
          ? res.data
          : unionBy(starredMessages, res.data, 'messageID');
        dispatch(
          receiveGetStarredMessages({
            starredMessages: updatedMessages,
            starredHasReceivedAll: res.data.length < 20,
          })
        );
      })
      .catch((error) => {
        dispatch(requestGetStarredMessagesError(error));
      });
  };

const dispatchRequestStarredMessagesInRoom =
  (roomID: number, lastMessageID?: number) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestGetStarredMessages());

    return api
      .getStarredMessagesInRoom(roomID, lastMessageID)
      .then((res) => {
        const starredMessages = getState().chat.starredMessages.filter((msg) => msg.isStarred);

        const updatedMessages = !lastMessageID
          ? res.data
          : unionBy(starredMessages, res.data, 'messageID');
        dispatch(
          receiveGetStarredMessages({
            starredMessages: updatedMessages,
            starredHasReceivedAll: res.data.length < 20,
          })
        );
      })
      .catch((error) => {
        dispatch(requestGetStarredMessagesError(error.message));
      });
  };

const dispatchReceiveStarredMessages =
  (starredMessages: StarredMessage[]) => (dispatch, getState) => {
    const { starredHasReceivedAll, messages } = getState().chat;
    dispatch(
      receiveGetStarredMessages({
        starredMessages,
        starredHasReceivedAll,
      })
    );

    const updatedMessages = messages.map((msg: EMessage) => {
      const star = starredMessages.find((sm) => sm.messageID === msg.id);
      const isStarred = !!star;
      return {
        ...msg,
        isStarred,
      };
    });

    dispatch(
      receiveMessages({
        messages: updatedMessages,
      })
    );
  };

const dispatchUpdateMedia =
  (messageID: number, status: Status, url?: string, thumbnail?: string) => (dispatch, getState) => {
    const { messages } = getState().chat;

    const updatedMessages = messages.map((msg: EMessage) =>
      msg.id !== messageID
        ? msg
        : {
            ...msg,
            media: {
              ...msg.media,
              status,
              url: url || (msg.media && msg.media.url),
              thumbnail: thumbnail || (msg.media && msg.media.thumbnail),
            },
          }
    );

    dispatch(
      receiveMessages({
        messages: updatedMessages,
      })
    );
  };

function requestGetSharedFiles(): ChatActionTypes {
  return { type: REQUEST_GET_SHARED_FILES };
}

function receiveGetSharedFiles(payload: GetSharedFilesPayload): ChatActionTypes {
  return { type: RECEIVE_GET_SHARED_FILES, payload };
}

function errorGetSharedFiles(error: Error): ChatActionTypes {
  return { type: ERROR_GET_SHARED_FILES, error };
}

const sortSharedFiles = (unsorted: EMessage[]) => unsorted.sort((a, b) => b.id - a.id);

const createGetSharedFilesPayload = (
  currentMessages: EMessage[],
  newMessages: EMessage[]
): GetSharedFilesPayload => {
  return {
    sharedFiles: unionBy(currentMessages, newMessages, 'id'),
    receivedAllSharedFiles: newMessages.length < MESSAGES_PER_REQUEST,
  };
};

const getAvatarImage = (message: EMessage) =>
  message.user.userType === 3
    ? `${apiHelper().cdnEndpoint}/images/application/therapist/220/${message.user.id}.jpg`
    : message.avatarImage;

const adaptSharedFiles = (mediaMessages: EMessage[]) => {
  const notRejected = mediaMessages.filter((it) => it.media && it.media.status !== 'rejected');
  const withTherapistImage = notRejected.map((it) => {
    return {
      ...it,
      avatarImage: getAvatarImage(it),
    };
  });
  const sorted = sortSharedFiles(withTherapistImage);

  return sorted;
};

const dispatchGetSharedFiles = (roomID: number) => (dispatch, getState, api: ChatApiHelpers) => {
  dispatch(requestGetSharedFiles());

  const currentMessages = getState().chat.sharedFiles;

  return api
    .getMessages({
      roomID,
      isTherapistChat: getState().chat.isTherapistChat,
      mediaOnly: true,
    })
    .then((mediaMessages) => {
      if (mediaMessages.length > 0) {
        const adapted = adaptSharedFiles(mediaMessages);
        const payload = createGetSharedFilesPayload(currentMessages, adapted);
        dispatch(receiveGetSharedFiles(payload));
      } else {
        dispatch(
          receiveGetSharedFiles({
            receivedAllSharedFiles: true,
          })
        );
      }
    })
    .catch(api.dismissIfCancelled)
    .catch((error) => {
      dispatch(errorGetSharedFiles(error));
    });
};

function requestGetHistoricalSharedFiles(): ChatActionTypes {
  return { type: REQUEST_GET_HISTORICAL_SHARED_FILES };
}

const dispatchGetHistoricalSharedFiles =
  (roomID: number) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestGetHistoricalSharedFiles());

    const currentMessages: EMessage[] = getState().chat.sharedFiles;
    const maxMessageID = Math.min(...currentMessages.map((m) => m.id), Number.MAX_SAFE_INTEGER);

    return api
      .getMessages({
        roomID,
        isTherapistChat: getState().chat.isTherapistChat,
        maxMessageID,
        mediaOnly: true,
      })
      .then((mediaMessages) => {
        if (mediaMessages.length > 0) {
          const adapted = adaptSharedFiles(mediaMessages);
          const payload = createGetSharedFilesPayload(currentMessages, adapted);
          dispatch(receiveGetSharedFiles(payload));
        } else {
          dispatch(
            receiveGetSharedFiles({
              receivedAllSharedFiles: true,
            })
          );
        }
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch(errorGetSharedFiles(error));
      });
  };

const dispatchClearSharedFiles = () => (dispatch) => {
  dispatch({ type: CLEAR_SHARED_FILES });
};

const toggleStarMessageInMessagesList = (messageID: number, list: EMessage[]) =>
  list.map((it) =>
    it.id !== messageID
      ? it
      : {
          ...it,
          isStarred: !it.isStarred,
        }
  );

const toggleStarMessageInStarredMessagesList = (messageID: number, list: StarredMessage[]) =>
  list.map((it) =>
    it.messageID !== messageID
      ? it
      : {
          ...it,
          isStarred: !it.isStarred,
        }
  );

const dispatchPostLiveSessionEvent =
  (roomID: number, videoCallID: number, eventType: VideoCallEvent) =>
  (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(postLiveSessionEvent());
    return api
      .postLiveSessionEvent(roomID, videoCallID, eventType)
      .then((res) => {
        if (res.status === 204) {
          dispatch(receivePostLiveSessionEventAlreadyJoined());
        } else {
          dispatch(receivePostLiveSessionEventSuccess());
        }
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        if (error.message === '410') {
          dispatch(receivePostLiveSessionEventAlreadyEnded());
        } else {
          dispatch(postLiveSessionEventError(error));
        }
      });
  };

function requestToggleStarMessage(payload: RequestToggleStarMessagePayload): ChatActionTypes {
  return { type: REQUEST_TOGGLE_STAR_MESSAGE, payload };
}

function errorToggleStarMessage(payload: RequestToggleStarMessagePayload): ChatActionTypes {
  return { type: ERROR_TOGGLE_STAR_MESSAGE, payload };
}

const dispatchToggleStarMessage =
  (messageID: number, isStarred: boolean) => (dispatch, getState, api: ChatApiHelpers) => {
    const currentMessages = getState().chat.messages;
    const currentSharedFiles = getState().chat.sharedFiles;
    const currentStarredMessages = getState().chat.starredMessages;

    const updatedMessages = toggleStarMessageInMessagesList(messageID, currentMessages);

    const updatedSharedFiles = toggleStarMessageInMessagesList(messageID, currentSharedFiles);

    const updatedStarredMessages = toggleStarMessageInStarredMessagesList(
      messageID,
      currentStarredMessages
    );

    dispatch(
      requestToggleStarMessage({
        sharedFiles: updatedSharedFiles,
        messages: updatedMessages,
        starredMessages: updatedStarredMessages,
      })
    );

    const apiAction = isStarred ? api.unstarMessage : api.starMessage;

    return apiAction(messageID).catch(() => {
      dispatch(
        errorToggleStarMessage({
          sharedFiles: currentSharedFiles,
          messages: currentMessages,
          starredMessages: currentStarredMessages,
        })
      );
    });
  };

const dispatchGetSessionStatus = (roomID: number) => (dispatch, getState, api: ChatApiHelpers) => {
  dispatch(requestSessionStatus());
  return api
    .getSessionStatus(roomID)
    .then((data) => dispatch(receiveSessionStatus({ sessionStatus: data?.data })))
    .catch(api.dismissIfCancelled)
    .catch((error) => dispatch(requestSessionStatusError(error)));
};

export {
  dispatchRequestMessages,
  dispatchRequestHistoricalMessages,
  dispatchAcknowledgements,
  dispatchUserTyping,
  dispatchResetChat,
  dispatchGetRoomInfo,
  dispatchGetActiveSessionInfo,
  dispatchRequestStarredMessagesByUser,
  dispatchRequestStarredMessagesInRoom,
  dispatchReceiveStarredMessages,
  dispatchUpdateMedia,
  dispatchGetSharedFiles,
  dispatchGetHistoricalSharedFiles,
  dispatchClearSharedFiles,
  dispatchToggleStarMessage,
  dispatchPostLiveSessionEvent,
  dispatchPostActiveSessionInfo,
  dispatchGetSessionStatus,
  getAvatarImage,
};
