import { FunctionComponent } from 'react';
import {
  View,
  ClinicalProgressGaugeChart,
  Big,
  EmotionStyle,
  TextDS,
} from '@talkspace/react-toolkit';
import { SurveyResult } from 'ts-frontend/types';

interface SurveyResultViewProps {
  surveyResult: SurveyResult;
  prevSurveyResult?: SurveyResult;
  style?: EmotionStyle;
}

const SurveyResultView: FunctionComponent<SurveyResultViewProps> = ({
  surveyResult: { score, scoreRanges, maximum, surveyLabel, progressSummary, isScoreReversed },
  prevSurveyResult,
  style,
}) => (
  <View style={{ width: 340, ...style }}>
    <ClinicalProgressGaugeChart
      ariaHidden
      score={score}
      prevScore={prevSurveyResult && prevSurveyResult.score}
      scoreRanges={scoreRanges}
      max={maximum}
      isScoreReversed={isScoreReversed}
      surveyLabel={surveyLabel}
    />
    <TextDS variant="headingLg" as="h1" style={{ marginBottom: 12, marginTop: 24 }}>
      Your {surveyLabel} score is {score}
      <Big inline aria-label="out of">
        /
      </Big>
      {maximum}
    </TextDS>

    <TextDS variant="body" as="h2" colorRole="textSubtle">
      {progressSummary}
    </TextDS>
  </View>
);

export default SurveyResultView;
