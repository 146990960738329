import { useCallback, useState } from 'react';
import SurveyWizard from 'surveyWizard';
import { AppSource } from 'ts-frontend/types';
import { Screen } from '@talkspace/react-toolkit';
import { ClosePopupOpenLiveCallAction } from '@/auth/reactFrame/ReactFrameTypes';
import { useLocation, useHistory } from '@/core/routerLib';
import { trackEvent, tsAnalyticsTracker } from '@/utils/analytics/eventTracker';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ErrorModal from '../ErrorModal';
import PreSessionSurvey from '../PreSessionSurvey';

interface LocationState {
  roomID: number;
  userRoomSurveyID: number;
  surveySource?: string;
  videoCallID?: number;
}

const SurveyWizardAction = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();

  const { userRoomSurveyID, roomID, surveySource, videoCallID } = location.state || {};

  const [error, setError] = useState('');
  const [showPreSessionPage, setShowPreSessionPage] = useState(surveySource === 'pre-live-session');

  const closeModal = useCloseModal();

  const trackAssessmentDone = useCallback(() => {
    trackEvent(
      'Clinical Assessment Done',
      {
        actionName: 'clinicalAssessmentDone',
        roomID,
        userRoomSurveyID,
        isAssessmentDone: true,
        targets: [tsAnalyticsTracker.targetsEnum.BRAZE],
      },
      ['tsAnalytics']
    );
  }, [roomID, userRoomSurveyID]);

  const openResults = useCallback(() => {
    // we are routing to the results screen using the modal router
    history.push(`/room/${roomID}/survey-results/${userRoomSurveyID}`);
    trackAssessmentDone();
  }, [history, roomID, trackAssessmentDone, userRoomSurveyID]);

  const goToSession = useCallback(() => {
    document.dispatchEvent(new CustomEvent('refetchLiveCall', { detail: { videoCallID } }));
    closeModal({
      navigateTo: 'liveCall',
      metadata: { roomID, videoCallID } as ClosePopupOpenLiveCallAction['metadata'],
    });
    trackAssessmentDone();
  }, [closeModal, roomID, trackAssessmentDone, videoCallID]);

  return (
    <Screen showCloseButton={surveySource !== 'pre-live-session'} onCloseButtonClick={closeModal}>
      {(() => {
        if (error) {
          return (
            <ErrorModal
              // Remove error title
              error={' '}
              errorMessage={error}
              onButtonPress={() => closeModal()}
            />
          );
        }

        if (showPreSessionPage) {
          return (
            <PreSessionSurvey
              roomID={`${roomID}`}
              onContinuePress={() => setShowPreSessionPage(false)}
            />
          );
        }

        return (
          <SurveyWizard
            handleSurveysError={setError}
            closeWizardAction={surveySource === 'pre-live-session' ? goToSession : openResults}
            surveyID={userRoomSurveyID}
            source={AppSource.client}
          />
        );
      })()}
    </Screen>
  );
};

export default SurveyWizardAction;
