import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const majorPath =
  'M19.7778 5.71429C20.0833 5.71429 20.3333 5.97143 20.3333 6.28571V17.7143C20.3333 18.0286 20.0833 18.2857 19.7778 18.2857H4.22222C3.91667 18.2857 3.66667 18.0286 3.66667 17.7143V6.28571C3.66667 5.97143 3.91667 5.71429 4.22222 5.71429H19.7778ZM4.22222 4C2.99653 4 2 5.025 2 6.28571V17.7143C2 18.975 2.99653 20 4.22222 20H19.7778C21.0035 20 22 18.975 22 17.7143V6.28571C22 5.025 21.0035 4 19.7778 4H4.22222ZM9.22222 12C9.81159 12 10.3768 11.7592 10.7936 11.3305C11.2103 10.9019 11.4444 10.3205 11.4444 9.71429C11.4444 9.10808 11.2103 8.5267 10.7936 8.09804C10.3768 7.66939 9.81159 7.42857 9.22222 7.42857C8.63285 7.42857 8.06762 7.66939 7.65087 8.09804C7.23413 8.5267 7 9.10808 7 9.71429C7 10.3205 7.23413 10.9019 7.65087 11.3305C8.06762 11.7592 8.63285 12 9.22222 12ZM8.11111 13.1429C6.57639 13.1429 5.33333 14.4214 5.33333 16C5.33333 16.3143 5.58333 16.5714 5.88889 16.5714H12.5556C12.8611 16.5714 13.1111 16.3143 13.1111 16C13.1111 14.4214 11.8681 13.1429 10.3333 13.1429H8.11111ZM15.0556 8C14.5938 8 14.2222 8.38214 14.2222 8.85714C14.2222 9.33214 14.5938 9.71429 15.0556 9.71429H17.8333C18.2951 9.71429 18.6667 9.33214 18.6667 8.85714C18.6667 8.38214 18.2951 8 17.8333 8H15.0556ZM15.0556 11.4286C14.5938 11.4286 14.2222 11.8107 14.2222 12.2857C14.2222 12.7607 14.5938 13.1429 15.0556 13.1429H17.8333C18.2951 13.1429 18.6667 12.7607 18.6667 12.2857C18.6667 11.8107 18.2951 11.4286 17.8333 11.4286H15.0556Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: '' },
  },
  major: {
    default: { path: majorPath },
  },
};

const AddressCard = withDSIconMaker(pathConfig, 'address card');

export default AddressCard;
