import { useCallback, useState } from 'react';
import { addIonicAppListener } from '../../../plugins/capacitor';
import { getIonicPasscode, getIonicPasscodeEnabled } from '../../../plugins/secureStorage';
import { useHistory } from '@/core/routerLib';
import { getUserData } from '@/auth/helpers/token';
import { useIonicEffect } from '../../../hooks/genericHooks';
import {
  STORAGE_KEY_CHECK_PASSCODE,
  STORAGE_KEY_APP_LOADED,
  STORAGE_KEY_TIME_USER_LEFT_APP,
  PASSCODE_TIMEOUT,
} from '../constants';

import { shouldShowSplashScreen } from '../../../plugins/splashScreen';
import { IonicPasscodeProps } from '../types';

const routePathStartBlacklist = ['/two-factor', '/forgot-password', '/login', '/login-success'];

const setTimeUserLeftApp = () => {
  sessionStorage.setItem(STORAGE_KEY_TIME_USER_LEFT_APP, `${new Date().valueOf()}`);
};

const getShouldSkipPasscode = () => {
  const timeUserLeftApp = sessionStorage.getItem(STORAGE_KEY_TIME_USER_LEFT_APP);
  if (!timeUserLeftApp) {
    // If we don't know when they left, check passcode
    return false;
  }
  const now = new Date().valueOf();
  // if they left the app less than 10 seconds ago, skip passcode
  return now - parseInt(timeUserLeftApp, 10) < PASSCODE_TIMEOUT;
};

interface UsePasscodeProps extends IonicPasscodeProps {
  onBeforeLockoutCheck: () => Promise<void>;
}

const usePasscode = ({ getTokens, isClient, onBeforeLockoutCheck }: UsePasscodeProps) => {
  const history = useHistory();
  const { id: userID } = getUserData();
  const keySuffix = isClient && userID ? `${userID}` : undefined;
  const isLoggedIn = !routePathStartBlacklist.includes(history.location.pathname);

  const [showPasscodeView, setShowPasscodeView] = useState<boolean>(false);
  const [isScreenBlocked, setIsScreenBlocked] = useState<boolean>(false);
  const [hasCheckedAfterResume, setHasCheckedAfterResume] = useState<boolean>(true);

  // After a correct passcode entry the app is resumed.
  const onAppResumed = useCallback(() => {
    sessionStorage.removeItem(STORAGE_KEY_CHECK_PASSCODE);
    setShowPasscodeView(false);
    setIsScreenBlocked(false);
  }, []);

  // On app unfocus, block the screen
  useIonicEffect(() => {
    const listener = addIonicAppListener('appStateChange', (change) => {
      const { isActive } = change;
      if (!isActive) {
        setTimeUserLeftApp();
      }
      setIsScreenBlocked(!hasCheckedAfterResume || !isActive);
    });
    return () => {
      listener.remove();
    };
  }, [hasCheckedAfterResume]);

  // During app init the auth container may redirect and unmount this component
  // before the screen is blocked. In that case we can check the storage to block the screen on remount
  useIonicEffect(() => {
    if (sessionStorage.getItem(STORAGE_KEY_CHECK_PASSCODE) === 'true') {
      setShowPasscodeView(true);
      setIsScreenBlocked(true);
    }
  }, [history.location.pathname]);

  const checkForLockout = useCallback(async () => {
    setHasCheckedAfterResume(true);
    const { refreshToken } = getTokens();
    if (!isLoggedIn || !refreshToken) {
      setShowPasscodeView(false);
      setIsScreenBlocked(false);
      return;
    }
    sessionStorage.setItem(STORAGE_KEY_APP_LOADED, 'true');

    const { value } = await getIonicPasscode(keySuffix);
    const { value: isEnabledValue } = isClient
      ? await getIonicPasscodeEnabled(keySuffix)
      : { value: 'true' };

    const shouldSkipPasscode = getShouldSkipPasscode();

    if (!value && isEnabledValue === 'true') {
      setShowPasscodeView(false);
      setIsScreenBlocked(false);
      const createPasscodeRoute = '/passcode';
      if (!history.location.pathname.includes(createPasscodeRoute)) {
        history.push(createPasscodeRoute);
      }
    } else if (shouldSkipPasscode) {
      setShowPasscodeView(false);
      setIsScreenBlocked(false);
    } else if (isEnabledValue === 'true') {
      // in some cases this container will mount, but be redirected again before the EnterPasscode mounts
      // save a session variable so we can redirect to EnterPasscode even if STORAGE_KEY_APP_LOADED is true
      sessionStorage.setItem(STORAGE_KEY_CHECK_PASSCODE, 'true');
      setShowPasscodeView(true);
      setIsScreenBlocked(true);
    } else if (isEnabledValue !== 'true') {
      setIsScreenBlocked(false);
    }
  }, [history, isLoggedIn, getTokens, isClient, keySuffix]);

  // On first load, check to lock the screen
  useIonicEffect(() => {
    if (!sessionStorage.getItem(STORAGE_KEY_APP_LOADED)) {
      checkForLockout();
    }
  }, [checkForLockout]);

  // On app focus, check to lock the screen
  useIonicEffect(() => {
    const listener = addIonicAppListener('resume', () => {
      if (shouldShowSplashScreen() === false) return;
      setHasCheckedAfterResume(false);
      setIsScreenBlocked(true);
      onBeforeLockoutCheck().then(() => checkForLockout());
    });

    return () => {
      listener.remove();
    };
  }, [checkForLockout, onBeforeLockoutCheck]);

  return {
    onAppResumed,
    showPasscodeView,
    isScreenBlocked,
  };
};

export default usePasscode;
