import { FunctionComponent, Dispatch, SetStateAction, MouseEvent } from 'react';
import { spacing, TextDS, TouchableView, useEmotionTheme } from '@talkspace/react-toolkit';

import { EmotionStyle } from '@/core/styled/styled';
import styled from '@/core/styled';

interface InputSendViewProps {
  text: string;
  hide?: boolean;
  onPress(): void;
  disabled?: boolean;
  style?: EmotionStyle;
  setIsSendClicked: Dispatch<SetStateAction<boolean>>;
}

const Wrapper = styled(TouchableView)<{ hide?: boolean; disabled?: boolean }>(
  ({
    hide,
    disabled,
    theme: {
      window: { isMobile },
      colorRoles,
    },
  }) => {
    const borderColor = disabled
      ? colorRoles.button.brandSecondaryTextDisabled
      : colorRoles.button.brandSecondaryBorderDefault;
    const largeButtonStyle = {
      padding: '6px 16px 6px 16px',
      gap: 10,
      borderRadius: spacing.space100,
      border: `1px solid ${borderColor}`,
      background: colorRoles.button.brandSecondarySurfaceDefault,
      '&:hover:focus': {
        border: `1px solid ${borderColor}`,
      },
      minWidth: 100,
    };
    return {
      alignItems: 'center',
      justifyContent: 'center',
      display: hide ? 'none' : 'flex',
      ...(!isMobile ? largeButtonStyle : {}),
    };
  }
);

const StyledText = styled(TextDS)<Partial<InputSendViewProps>>(({ disabled }) => {
  return {
    opacity: disabled ? 0.4 : 1,
    transition: 'all 0.15s ease-in-out',
  };
});

const InputSendView: FunctionComponent<InputSendViewProps> = ({
  text,
  hide,
  onPress,
  disabled,
  style,
  setIsSendClicked,
}) => {
  const { colorRoles } = useEmotionTheme();
  const onPressHandler = (e: MouseEvent<HTMLDivElement>) => {
    // e.detail is the number of clicks that caused the event to fire
    // this prevents a focus outline from rendering on the input after a user clicks the message send button
    if (e?.detail > 0) {
      setIsSendClicked(true);
    }
    onPress();
  };
  return (
    <Wrapper
      disabled={disabled}
      onPress={onPressHandler}
      hide={hide}
      style={style}
      dataQa="chatInputSendButton"
    >
      <StyledText
        variant="headingMd"
        style={{
          color: disabled
            ? colorRoles.button.brandSecondaryTextDisabled
            : colorRoles.button.brandSecondaryTextDefault,
        }}
      >
        {text}
      </StyledText>
    </Wrapper>
  );
};

export default InputSendView;
