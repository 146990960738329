import { Dispatch, FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactTooltip, { Place, Effect, Offset } from 'react-tooltip';
import View from '../../../components/View';
import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import { useUniqueID } from '../../../hooks/a11yHelper';
import commonStyles from '../../../constants/commonStyles';
import { CircleInfo } from '../../icons';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

const TOOLTIP_MAX_WIDTH = 256;
const TOOLTIP_GAP = 8;
const TOOLTIP_EDGE_OFFSET = 24;

const TOOLTIP_ARROW_WIDTH = 8;
const TOOLTIP_ARROW_HEIGHT = 8;

type Toggle = {
  left: number;
  top: number;
  bottom: number;
  centerLeft: number;
};

type TooltipSize = {
  height: number;
  width: number;
};

const getBaseArrowOverrideStyles = () => {
  return {
    '&::after': {
      boxSizing: 'border-box',
      height: `${TOOLTIP_ARROW_HEIGHT}px !important`,
      width: `${TOOLTIP_ARROW_WIDTH}px !important`,
      position: 'fixed !important',
    },
  };
};

const getUpperArrowPositionOverrideStyles = (toggle: Toggle): EmotionStyle => {
  return {
    left: `${toggle.centerLeft - TOOLTIP_ARROW_WIDTH / 2}px !important`,
    top: `${toggle.top - TOOLTIP_GAP}px !important`,
  };
};

const getLowerArrowPositionOverrideStyles = (toggle: Toggle): EmotionStyle => {
  return {
    left: `${toggle.centerLeft - TOOLTIP_ARROW_WIDTH / 2}px !important`,
    top: `${toggle.bottom + TOOLTIP_GAP}px !important`,
    marginTop: '-6px !important',
  };
};

const getUpperRightOverrideStyles = (
  toggle: Toggle,
  toolTip: TooltipSize
): [EmotionStyle, { top: number; left: number }] => [
  {
    ...getBaseArrowOverrideStyles(),
    '&.place-left::after': getUpperArrowPositionOverrideStyles(toggle),
  },
  {
    left: toggle.centerLeft - toolTip.width + TOOLTIP_EDGE_OFFSET,
    top: toggle.top - TOOLTIP_GAP - toolTip.height,
  },
];

const getUpperLeftOverrideStyles = (
  toggle: Toggle,
  toolTip: TooltipSize
): [EmotionStyle, { top: number; left: number }] => [
  {
    ...getBaseArrowOverrideStyles(),
    '&.place-right::after': getUpperArrowPositionOverrideStyles(toggle),
  },
  { left: toggle.centerLeft - TOOLTIP_EDGE_OFFSET, top: toggle.top - TOOLTIP_GAP - toolTip.height },
];

const getLowerLeftOverrideStyles = (
  toggle: Toggle
): [EmotionStyle, { top: number; left: number }] => [
  {
    ...getBaseArrowOverrideStyles(),
    '&.place-right::after': getLowerArrowPositionOverrideStyles(toggle),
  },
  {
    left: toggle.centerLeft - TOOLTIP_EDGE_OFFSET,
    top: toggle.bottom + TOOLTIP_GAP,
  },
];

const getLowerRightOverrideStyles = (
  toggle: Toggle,
  toolTip: TooltipSize
): [EmotionStyle, { top: number; left: number }] => [
  {
    ...getBaseArrowOverrideStyles(),
    '&.place-left::after': getLowerArrowPositionOverrideStyles(toggle),
  },
  {
    left: toggle.centerLeft - toolTip.width + TOOLTIP_EDGE_OFFSET,
    top: toggle.bottom + TOOLTIP_GAP,
  },
];

const overridePosition =
  (setOverrideArrowStyles: Dispatch<React.SetStateAction<EmotionStyle>>) =>
  (
    { left, top }: { left: number; top: number },
    currentEvent: Event,
    currentTarget: HTMLElement,
    refNode: HTMLDivElement | HTMLSpanElement,
    place: Place
  ) => {
    const shiftDirection = place;

    const toggleRec = currentTarget?.getBoundingClientRect();
    const toolTipRec = refNode?.getBoundingClientRect();

    const toggle = {
      left: toggleRec.x,
      top: toggleRec.y,
      bottom: toggleRec.y + toggleRec.height,
      centerLeft: Math.round(toggleRec.x + toggleRec.width / 2),
    };

    const toolTip = {
      height: toolTipRec.height,
      width: toolTipRec.width,
    };

    const hasSpaceAbove = toggle.top - TOOLTIP_GAP - toolTip.height > 0;

    const setUpperLeft = shiftDirection === 'right' && hasSpaceAbove;
    const setUpperRight = shiftDirection === 'left' && hasSpaceAbove;
    const setLowerLeft = shiftDirection === 'right' && !hasSpaceAbove;
    const setLowerRight = shiftDirection === 'left' && !hasSpaceAbove;

    if (setUpperLeft) {
      const [arrowStyles, toolTipPosition] = getUpperLeftOverrideStyles(toggle, toolTip);

      setOverrideArrowStyles(arrowStyles);
      return toolTipPosition;
    }

    if (setUpperRight) {
      const [arrowStyles, toolTipPosition] = getUpperRightOverrideStyles(toggle, toolTip);

      setOverrideArrowStyles(arrowStyles);
      return toolTipPosition;
    }

    if (setLowerLeft) {
      const [arrowStyles, toolTipPosition] = getLowerLeftOverrideStyles(toggle);

      setOverrideArrowStyles(arrowStyles);
      return toolTipPosition;
    }

    if (setLowerRight) {
      const [arrowStyles, toolTipPosition] = getLowerRightOverrideStyles(toggle, toolTip);

      setOverrideArrowStyles(arrowStyles);
      return toolTipPosition;
    }

    return { left, top };
  };

interface CustomTooltipProps {
  hide?: boolean;
  tip?: string;
  messageComponent?: JSX.Element;
  buttonStyle?: EmotionStyle;
  messageStyle?: EmotionStyle;
  toggleSize?: number;
  place?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  ariaDescribedBy?: string;
  label?: string;
  dataQa?: string;
  roundedFocusStyle?: boolean;
  arrowColor?: string;
  textColor?: string;
  backgroundColor?: string;
  border?: boolean;
  borderColor?: string;
  effect?: 'float' | 'solid';
  arrowPosition?: 'left' | 'center' | 'right';
  overridePosition?: (
    position: { left: number; top: number },
    currentEvent: Event,
    currentTarget: EventTarget,
    refNode: null | HTMLDivElement | HTMLSpanElement,
    place: Place,
    desiredPlace: Place,
    effect: Effect,
    offset: Offset
  ) => { top: number; left: number };
  tooltipIcon?: React.ReactElement;
}

const StyledTooltip = styled(ReactTooltip)<
  Partial<CustomTooltipProps> & { overrideArrowStyles: EmotionStyle }
>(({ messageStyle, overrideArrowStyles }) => {
  return {
    maxWidth: TOOLTIP_MAX_WIDTH,
    textAlign: 'left',
    borderRadius: '8px !important',
    lineHeight: '19px',
    fontSize: 13,
    fontFamily: 'Roboto',
    padding: 12,
    opacity: 0.85,
    ...messageStyle,
    ...overrideArrowStyles,
  };
});

export type TooltipProps = CustomTooltipProps &
  Omit<React.ComponentProps<'button'>, 'type' | 'ref'>;

const TooltipToggle = styled.div<{
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  shouldShowFocusOutline?: boolean;
}>(({ primaryColor, roundedFocusStyle, shouldShowFocusOutline }) => {
  // TODO: this if statement can be removed once all the tooltips within client-web have been given the green light to show the new rounded focus styling
  // display: 'flex' should stay
  if (shouldShowFocusOutline) {
    let keyboardFocusStyles = {};
    if (primaryColor && roundedFocusStyle) {
      keyboardFocusStyles = roundedFocusRing(primaryColor, shouldShowFocusOutline);
    } else {
      keyboardFocusStyles = crossBrowserFocusRing;
    }
    return {
      ...keyboardFocusStyles,
      // ensures that the circled question mark is centered in the focus styling
      display: 'flex',
    };
  }
  return {
    outline: 'none',
  };
});

const Tooltip: FunctionComponent<TooltipProps> = ({
  tip,
  messageComponent,
  buttonStyle,
  toggleSize,
  place = 'top',
  className,
  label,
  ariaDescribedBy,
  dataQa,
  roundedFocusStyle,
  children,
  arrowColor,
  arrowPosition,
  hide,
  tooltipIcon,
  effect = 'solid',
  ...otherProps
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const tooltipID = useUniqueID('tooltipID');
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [overrideArrowStyles, setOverrideArrowStyles] = useState<EmotionStyle>({});

  useEffect(() => {
    if (hide) {
      ReactTooltip.hide(tooltipRef.current || undefined);
    }
  }, [hide]);

  const onClick = (e) => {
    // necessary to prevent form from submitting when the tooltip is located inside of a form
    e.preventDefault();
    if ((e.clientX && e.clientY) || (e.pageX && e.pageY)) {
      setIsClicked(true);
    }
  };

  // provides a way for keyboard users to close the tooltip
  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      ReactTooltip.hide(tooltipRef.current || undefined);
    }
  };

  const onFocus = () => {
    setIsFocused(true);
    const tooltip = tooltipRef.current || undefined;
    if (tooltip && !hide) {
      ReactTooltip.show(tooltip);
    }
  };

  const onBlur = () => {
    ReactTooltip.hide(tooltipRef.current || undefined);
    setIsFocused(false);
    setIsClicked(false);
  };

  const { colors } = useEmotionTheme();

  return (
    <>
      <TooltipToggle
        data-qa={dataQa}
        shouldShowFocusOutline={isFocused && !isClicked}
        aria-label={label || 'Tooltip'}
        aria-describedby={ariaDescribedBy || tooltipID}
        className={className}
        tabIndex={0}
        style={{
          width: 20,
          height: 20,
          justifyContent: 'center',
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 4,
          marginTop: 0,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
          ...buttonStyle,
        }}
        ref={tooltipRef}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        data-tip
        data-for={tooltipID}
        // renders new rounded focus style if roundedFocusStyleProp provided
        // assumes that tooltip color is permaWildBlueYonder
        primaryColor={colors.permaWildBlueYonder}
        roundedFocusStyle={roundedFocusStyle}
      >
        {children || tooltipIcon || <CircleInfo colorType="subtlest" />}
      </TooltipToggle>
      {!hide && (
        <StyledTooltip
          id={tooltipID}
          place={place}
          multiline
          effect={effect}
          {...otherProps}
          arrowColor={arrowColor}
          arrowPosition={arrowPosition}
          overridePosition={overridePosition(setOverrideArrowStyles)}
          overrideArrowStyles={overrideArrowStyles}
        >
          {tip && <View role="tooltip">{tip}</View>}
          {messageComponent && <View role="tooltip">{messageComponent}</View>}
        </StyledTooltip>
      )}
    </>
  );
};

export default Tooltip;
