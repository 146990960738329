import { IconConfig } from './types/types';
import { withDSIconMaker } from './utils/IconMaker';

const standardPath =
  'M3.25 3.875C2.73047 3.875 2.3125 4.29297 2.3125 4.8125V6.6875C2.3125 7.20703 2.73047 7.625 3.25 7.625H5.125C5.64453 7.625 6.0625 7.20703 6.0625 6.6875V4.8125C6.0625 4.29297 5.64453 3.875 5.125 3.875H3.25ZM8.875 4.8125C8.35547 4.8125 7.9375 5.23047 7.9375 5.75C7.9375 6.26953 8.35547 6.6875 8.875 6.6875H20.75C21.2695 6.6875 21.6875 6.26953 21.6875 5.75C21.6875 5.23047 21.2695 4.8125 20.75 4.8125H8.875ZM8.875 11.0625C8.35547 11.0625 7.9375 11.4805 7.9375 12C7.9375 12.5195 8.35547 12.9375 8.875 12.9375H20.75C21.2695 12.9375 21.6875 12.5195 21.6875 12C21.6875 11.4805 21.2695 11.0625 20.75 11.0625H8.875ZM8.875 17.3125C8.35547 17.3125 7.9375 17.7305 7.9375 18.25C7.9375 18.7695 8.35547 19.1875 8.875 19.1875H20.75C21.2695 19.1875 21.6875 18.7695 21.6875 18.25C21.6875 17.7305 21.2695 17.3125 20.75 17.3125H8.875ZM2.3125 11.0625V12.9375C2.3125 13.457 2.73047 13.875 3.25 13.875H5.125C5.64453 13.875 6.0625 13.457 6.0625 12.9375V11.0625C6.0625 10.543 5.64453 10.125 5.125 10.125H3.25C2.73047 10.125 2.3125 10.543 2.3125 11.0625ZM3.25 16.375C2.73047 16.375 2.3125 16.793 2.3125 17.3125V19.1875C2.3125 19.707 2.73047 20.125 3.25 20.125H5.125C5.64453 20.125 6.0625 19.707 6.0625 19.1875V17.3125C6.0625 16.793 5.64453 16.375 5.125 16.375H3.25Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const BulletLines = withDSIconMaker(pathConfig, 'bullet lines');

export default BulletLines;
