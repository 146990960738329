import { useQuery, UseQueryResult } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { QuestionnaireQuestion, QuestionnaireQuestionOption, QuestionnaireType } from './types';
import { questionnairesQueryKey } from './queryKeys';

export interface FetchQuestionnaireParams {
  roomID: number;
  questionnaireTypes?: Array<QuestionnaireType>;
}

export interface Questionnaire {
  createdAt: Date;
  createdBy: number;
  id: number;
  questionnaireAnswers: Array<QuestionnaireAnswer>;
  roomID: number;
  type: string;
  firstInstanceCreatedAt?: Date;
}

export type FetchQuestionnairePesponse = Array<Questionnaire>;

interface QuestionnaireAnswer {
  createdAt: Date;
  id: number;
  questionnaireID: number;
  questionnaireQuestionID: number;
  questionnaireQuestion: QuestionnaireQuestion;
  questionnaireQuestionOptions?: Array<QuestionnaireQuestionOption>;
  textOptions?: Array<string>;
  booleanAnswer?: boolean;
}

const fetchQuestionnaires =
  (params: FetchQuestionnaireParams) => async (): Promise<FetchQuestionnairePesponse> => {
    const { roomID, questionnaireTypes } = params;
    const data = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/questionnaires${
        questionnaireTypes?.length ? '?questionnaireTypes[]=' : ''
      }${questionnaireTypes || ''}`
    );
    return data?.data || [];
  };

const useQueryQuestionnaires = (
  params: FetchQuestionnaireParams
): UseQueryResult<FetchQuestionnairePesponse, Error> =>
  useQuery<FetchQuestionnairePesponse, Error>({
    queryKey: [questionnairesQueryKey(params.questionnaireTypes || [], params.roomID)],
    queryFn: fetchQuestionnaires(params),
  });

export default useQueryQuestionnaires;
