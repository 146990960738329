import { FunctionComponent, useEffect, useState } from 'react';
import { StepWizardProvider } from 'stepWizard';
import { ThemedFlagsProvider } from 'launchDarkly';
import { RouteComponentProps } from '@/core/routerLib';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import { useMainState, useMainActions } from '@/hooks/mainContext';
import ApiHelper from '@/utils/ApiHelper';
import Routes from './Routes';
import { getInitialState, State } from './reducers/matchingIntakeWizardState';
import matchingIntakeActions from './hooks/matchingIntakeActions';
import useShowOnboardingImprovements from '../hooks/useBHOnboardingImprovements';

interface MatchingIntakeRouteParams {
  roomID?: string;
  source?: string;
  contextID?: string;
}

const MatchingIntakeWizard: FunctionComponent<RouteComponentProps<MatchingIntakeRouteParams>> = ({
  match: {
    params: { roomID = '-1', source = '', contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const { roomsByID } = useMainState();
  const { getAllRooms } = useMainActions();

  const [initialState, setInitialState] = useState<State | undefined>();
  const genericMatchingIntakeActions = useGenericWizardActions(matchingIntakeActions);

  const shouldSeeBHOnboardingImprovements = useShowOnboardingImprovements(roomID, id);

  useEffect(() => {
    getAllRooms(id);
  }, [getAllRooms, id]);

  useEffect(() => {
    const room = roomsByID && roomsByID[roomID];
    if (room) {
      const { roomType } = room;
      // TODO: Eventually we will need to fix these eslint-disable-lines... Once we do, we should also be able to remove these non-null assertions.
      setInitialState(getInitialState(id, parseInt(roomID, 10), source, contextID, roomType));
    }
    // eslint-disable-next-line
  }, [roomsByID]);
  return (
    <>
      {initialState ? (
        <ThemedFlagsProvider
          versionKey={shouldSeeBHOnboardingImprovements ? 'quickmatchOrIntakeBHFlow' : 'default'}
        >
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericMatchingIntakeActions}
            ApiHelperOverride={ApiHelper}
          >
            <Routes />
          </StepWizardProvider>
        </ThemedFlagsProvider>
      ) : null}
    </>
  );
};

export default MatchingIntakeWizard;
