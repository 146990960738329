import { forwardRef, ComponentPropsWithoutRef } from 'react';
import View from '../../../components/View';
import TouchableView from '../../../components/TouchableView';
import styled, { EmotionStyle, EmotionTheme } from '../../../core/styled';
import Check from '../../../components/Svgs/Check';
import { webOnlyStyle } from '../../../core/styleHelpers';
import Input from '../../../components/Input';
import Tiny from '../../../components/Typography/Tiny';
import { useUniqueID, useShareForwardedRef } from '../../../hooks/a11yHelper';
import withHover from '../../../hoc/withHover';
import { WithHoverProps } from '../../../hoc';

export interface CheckboxProps extends WithHoverProps {
  label: string | JSX.Element;
  /**
   * Stretch checkbox container to occupy all available space horizontally
   * This also expands the touchable area of the checkbox
   */
  stretch?: boolean;
  /**
   * Easily override the margin bottom of the checkbox
   */
  marginBottom?: number;
  className?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  setIsChecked: (editing: boolean) => void;
  containerStyle?: EmotionStyle;
  labelStyle?: EmotionStyle;
  isLabelOnRight?: boolean;
  shouldDisplayError?: boolean;
  errorStyle?: EmotionStyle;
  errorMessage?: string | JSX.Element;
  shouldFocus?: boolean;
  borderColor?: string;
  checkedColor?: string;
  dataQa?: string;
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  alignCenter?: boolean;
  checkboxStyle?: EmotionStyle;
  hoverColors?: { borderColor: string; backgroundColor: string };
  hasHoverStyles?: boolean;
  checkComponent?: JSX.Element;
}

const getBorderColor = (
  colors: EmotionTheme['colors'],
  shouldDisplayError = false,
  isChecked = false,
  borderColor?: string
) => {
  if (!isChecked) {
    if (shouldDisplayError) {
      return colors.torchRed;
    }
    return borderColor;
  }
  return colors.white;
};

const CheckboxBox = styled(View)<Partial<CheckboxProps>>(
  ({
    isChecked,
    isDisabled,
    isLabelOnRight,
    shouldDisplayError,
    borderColor,
    checkedColor,
    checkboxStyle,
    isHovering,
    hoverColors,
    theme: { colors },
  }) => {
    const disabledStyles = {
      backgroundColor: colors.softGray,
      borderColor: colors.softGray,
    };
    return {
      position: 'relative',
      backgroundColor: isChecked ? checkedColor || colors.accessibilityGreenDark : colors.white,
      borderColor: getBorderColor(
        colors,
        shouldDisplayError,
        isChecked,
        borderColor || colors.permaPigeonBlue
      ),
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 5,
      height: 19,
      width: 19,
      marginLeft: isLabelOnRight ? 0 : 7,
      marginRight: isLabelOnRight ? 7 : 0,
      justifyContent: 'center',
      alignItems: 'center',
      ...(isDisabled ? disabledStyles : {}),
      ...checkboxStyle,
      ...(isHovering && !isChecked ? hoverColors : {}),
    };
  }
);

const Label = styled(Tiny)<Partial<CheckboxProps>>(
  ({ isLabelOnRight, isChecked, shouldDisplayError, theme: { colors }, labelStyle }) => {
    return {
      alignSelf: isLabelOnRight ? 'flex-start' : 'flex-end',
      marginRight: isLabelOnRight ? 0 : 7,
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 300,
      marginBottom: isChecked && shouldDisplayError ? 20 : 1,
      color: colors.black,
      ...labelStyle,
    };
  }
);

const ErrorMessage = styled(Tiny)<{
  hide: boolean;
  errorStyle: EmotionStyle;
}>(({ hide, errorStyle, theme: { colors } }) => {
  return {
    color: colors.torchRed,
    height: hide ? 0 : 20,
    opacity: hide ? 0 : 1,
    pointerEvents: hide ? 'none' : 'initial',
    ...webOnlyStyle({ transition: 'all .25s' }),
    ...errorStyle,
  };
});

const CheckboxContainer = styled(View)<{ marginBottom: number }>(({ marginBottom }) => {
  return {
    marginBottom,
  };
});

const TouchableContainer = styled(TouchableView)<{
  reverse?: boolean;
  stretch?: boolean;
  alignCenter?: boolean;
}>(({ stretch, reverse, alignCenter }) => {
  return {
    flexDirection: reverse ? 'row-reverse' : 'row',
    ...(alignCenter === true && { alignItems: 'center' }),
    ...(stretch === false && { alignSelf: 'start' }),
  };
});

const HiddenInput = styled(Input)({
  position: 'absolute',
  height: '100%',
  right: 0,
  opacity: 0,
  zIndex: -1,
});

const Checkbox = forwardRef<
  HTMLDivElement,
  CheckboxProps & ComponentPropsWithoutRef<typeof TouchableContainer>
>((props, ref) => {
  const {
    label,
    className,
    isChecked,
    isDisabled,
    isRequired,
    setIsChecked,
    errorMessage,
    shouldDisplayError,
    stretch = true,
    errorStyle = {},
    labelStyle = {},
    checkboxStyle = {},
    isLabelOnRight = false,
    containerStyle = {},
    marginBottom = 7,
    shouldFocus,
    borderColor,
    checkedColor,
    dataQa,
    primaryColor,
    roundedFocusStyle,
    alignCenter = true,
    hasHoverStyles = false,
    hoverColors,
    isHovering,
    checkComponent,
  } = props;
  const errorID = useUniqueID('checkbox-error');
  const touchableRef = useShareForwardedRef(ref);

  return (
    <CheckboxContainer marginBottom={marginBottom} style={containerStyle} className={className}>
      <TouchableContainer
        dataQa={dataQa}
        aria-describedby={shouldDisplayError && !isChecked ? errorID : undefined}
        tabIndex={shouldFocus ? 0 : -1}
        role="checkbox"
        aria-checked={isChecked}
        stretch={stretch}
        reverse={isLabelOnRight}
        onPress={() => !isDisabled && setIsChecked(!isChecked)}
        primaryColor={primaryColor}
        roundedFocusStyle={roundedFocusStyle}
        style={{ borderRadius: roundedFocusStyle ? 5 : 0 }}
        alignCenter={alignCenter}
        ref={touchableRef}
      >
        {typeof label === 'string' ? (
          <Label style={labelStyle} isLabelOnRight={isLabelOnRight} isChecked={isChecked}>
            {label}
          </Label>
        ) : (
          label
        )}
        <CheckboxBox
          isChecked={isChecked}
          isDisabled={isDisabled}
          isLabelOnRight={isLabelOnRight}
          shouldDisplayError={shouldDisplayError}
          borderColor={isHovering && hasHoverStyles ? hoverColors?.borderColor : borderColor}
          checkedColor={checkedColor}
          checkboxStyle={checkboxStyle}
          isHovering={isHovering}
          hoverColors={hoverColors}
        >
          <HiddenInput
            tabIndex={-1}
            type="checkbox"
            checked={isChecked}
            aria-required={isRequired}
          />
          {isChecked && <>{checkComponent || <Check />} </>}
        </CheckboxBox>
      </TouchableContainer>
      <ErrorMessage id={errorID} hide={!shouldDisplayError || isChecked} errorStyle={errorStyle}>
        {errorMessage}
      </ErrorMessage>
    </CheckboxContainer>
  );
});

export default withHover(Checkbox);
