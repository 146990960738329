import { COLORS as commonStyleColors, A11Y_COLORS } from '../../constants/commonStyles';

// This set of colors is approved by the design team and is what we are ultimately migrating to
// Will be updated to full list eventually. Source: https://www.figma.com/file/GeIvUthzJ56aEWCbire8kK/Design-System-2.0?type=design&node-id=62-11297&mode=design&t=kvZNGtPj6543OcND-0
const colorPaletteDesignSystemV2 = {
  neutralTransparent: 'rgba(0, 0, 0, 0)',
  neutral800A: 'rgba(104, 118, 141, 0.5)',
  neutral1000A: 'rgba(43, 42, 46, 0.9)',
  neutral0: '#FFFFFF',
  neutral100: '#FBFCFF',
  neutral200: '#F4F6FA',
  neutral300: '#F0F2F7',
  neutral400: '#E9ECF5',
  neutral500: '#D1D6E1',
  neutral600: '#B8C1D1',
  neutral700: '#A1ACBF',
  neutral800: '#68768D',
  neutral900: '#434F64',
  neutral1000: '#2B2A2E',
  teal100: '#E9F5F3',
  teal200: '#DDF0ED',
  teal400: '#CAE5E1',
  teal500: '#007A85',
  teal600: '#006B75',
  teal700: '#005C65',
  teal800: '#004654',
  teal900: '#173F4B',
  green200: '#E4F7D5',
  green400: '#C9EDAF',
  green600: '#3C8703',
  blue400: '#C1ECF7',
  blue500: '#2684FF',
  yellow200: '#FFF5D6',
  yellow400: '#FFE9AD',
  yellow600: '#E08D00',
  red100: '#FCE6E1',
  red200: '#FCDDD6',
  red600: '#C84E33',
  red700: '#B84228',
  red800: '#004654',
  blue600: '#007697',
  decorativeCouples: '#E1E5F0',
  decorativePsychRed: '#F1DDDD',
  tealExtralite: '#E1F0EE',
  decorativeLiftedLemon: '#EAF475',
  decorativeBalanceBlue: '#B9DDFF',
  decorativeBalanceBlueTint: '#E7F3FF',
  decorativeOptimistOrange: '#F2734A',
  decorativeGratitudeGreen: '#9DEAB2',
  decorativePresentPink: '#FFB8B2',
  decorativePresentPinkTint: '#FFE7E4',
  surfaceCriticalDefault: '#FFC9BD',
  decorativeLiftedLemonTint: '#FDFFDD',
  decorativeGratitudeGreenTint: '#D7F9E1',
};

export const COLORS = { ...commonStyleColors, ...colorPaletteDesignSystemV2 };

export const getColorsV0 = (isHighContrast: boolean) =>
  isHighContrast
    ? {
        ...A11Y_COLORS,
        ...colorPaletteDesignSystemV2,
      }
    : COLORS;

const colorsOverridesV1 = {
  green: COLORS.permaTalkspaceDarkGreen,
  darkGreen: COLORS.permaTalkspaceDarkGreen,
  greenText: COLORS.permaTalkspaceDarkGreen,
  therapistLink: COLORS.permaTalkspaceDarkGreen,
  gossamerGreen: COLORS.permaTalkspaceDarkGreen,
  surfieGreen: COLORS.permaTalkspaceDarkGreen,
  mosque: COLORS.permaTalkspaceDarkGreen,
  permaTropicalRainForest: COLORS.permaTalkspaceDarkGreen,
  permaViridianGreen: COLORS.permaTalkspaceDarkGreen,
  jadeGreen: COLORS.permaTalkspaceDarkGreen,
  permaTeal: COLORS.permaTalkspaceDarkGreen,
  permaDarkCyan: COLORS.permaTalkspaceDarkGreen,
  permaDarkForest: COLORS.permaTalkspaceDarkGreen,
  permaEden: COLORS.permaTalkspaceDarkGreen,
  permaVeryDarkCyan: COLORS.permaTalkspaceDarkGreen,
  permaBlueStoneNew: COLORS.permaTalkspaceDarkGreen,
  permaPineGreen: COLORS.permaTalkspaceDarkGreen,
  accessibilityGreenDark: COLORS.accessibilityGreenDark,
  permaPersianGreen: COLORS.permaTalkspaceDarkGreen,
  permaBlueStoneApprox: COLORS.permaTalkspaceDarkGreen,
  permaPersianGreenApprox: COLORS.permaTalkspaceDarkGreen,
  clientLink: COLORS.permaTalkspaceDarkGreen,
  rainforestGreen: COLORS.permaTalkspaceDarkGreen,
  permaBlueStone: COLORS.permaTalkspaceDarkGreen,
};

export const COLORSV1 = {
  ...COLORS,
  ...colorsOverridesV1,
  ...colorPaletteDesignSystemV2,
};

export const getColorsV1 = (isHighContrast: boolean) =>
  isHighContrast
    ? {
        ...getColorsV0(isHighContrast),
        ...colorsOverridesV1,
        ...colorPaletteDesignSystemV2,
      }
    : COLORSV1;

const colorsOverridesV2 = {
  ...colorsOverridesV1,
  green: COLORS.accessibilityGreenDark,
  darkGreen: COLORS.accessibilityGreenDark,
  greenText: COLORS.accessibilityGreenDark,
  therapistLink: COLORS.accessibilityGreenDark,
  gossamerGreen: COLORS.accessibilityGreenDark,
  surfieGreen: COLORS.accessibilityGreenDark,
  mosque: COLORS.accessibilityGreenDark,
  permaTropicalRainForest: COLORS.accessibilityGreenDark,
  permaViridianGreen: COLORS.accessibilityGreenDark,
  jadeGreen: COLORS.accessibilityGreenDark,
  permaTeal: COLORS.accessibilityGreenDark,
  permaDarkCyan: COLORS.accessibilityGreenDark,
  permaDarkForest: COLORS.accessibilityGreenDark,
  permaEden: COLORS.accessibilityGreenDark,
  permaVeryDarkCyan: COLORS.accessibilityGreenDark,
  permaBlueStoneNew: COLORS.accessibilityGreenDark,
  permaPineGreen: COLORS.accessibilityGreenDark,
  accessibilityGreenDark: COLORS.accessibilityGreenDark,
  permaPersianGreen: COLORS.accessibilityGreenDark,
  permaBlueStoneApprox: COLORS.accessibilityGreenDark,
  permaPersianGreenApprox: COLORS.accessibilityGreenDark,
  clientLink: COLORS.accessibilityGreenDark,
  rainforestGreen: COLORS.accessibilityGreenDark,
  permaBlueStone: COLORS.accessibilityGreenDark,
  permaTalkspaceDarkGreen: COLORS.accessibilityGreenDark,
};

export const COLORSV2 = {
  ...COLORS,
  ...colorsOverridesV2,
  ...colorPaletteDesignSystemV2,
};

export const getColorsV2 = (isHighContrast: boolean) =>
  isHighContrast
    ? {
        ...getColorsV0(isHighContrast),
        ...colorsOverridesV2,
        ...colorPaletteDesignSystemV2,
      }
    : COLORSV2;

export const COLORSV1_1 = {
  ...COLORS,
  ...colorsOverridesV2,
  ...colorPaletteDesignSystemV2,
};

export const getColorsV1p1 = (isHighContrast: boolean) =>
  isHighContrast
    ? { ...getColorsV0(isHighContrast), ...colorsOverridesV2, ...colorPaletteDesignSystemV2 }
    : COLORSV1_1;
