import { useEffect } from 'react';
import { History } from 'history';

import { useWizardActions, useWizardState, WizardScreen, WizardStepWrapper } from 'stepWizard';
import { CheckInSource } from 'chat/screens/VideoCallScreen';
import { trackEvent } from '@/utils/analytics/eventTracker';
import CheckInDone from './containers/CheckInDoneContainer';
import CheckInDoneV3 from './containers/CheckInDoneV3';
import { Route, Switch, useParams } from '../core/routerLib';
import CheckInWizardV2Scheme from './CheckInWizardV2Scheme';
import CheckInWizardV3Scheme from './CheckInWizardV3Scheme';
import CheckInBookingFirst from './containers/CheckInBookingFirst';
import ChangeProvider from './containers/ChangeProvider';

// Updated experiment name to v2 (MEMBER-3438)
const EXPERIMENT_NAME = 'post-lvs-check-in-no-bookings-fresh-start-1';

const Routes = ({ history }: { history: History }) => {
  const { isLoading, periodicCheckIn, nextBookingStartTime, checkInSource } = useWizardState<{
    isLoading: boolean;
    periodicCheckIn: boolean;
    nextBookingStartTime: Date;
    checkInSource: CheckInSource;
  }>();
  const { roomID } = useParams<{ roomID: string }>();
  const { onExitClick } = useWizardActions();
  const showBackButton = history.location.pathname.includes('/pause-therapy');

  const hasNoBookingsScheduled = !nextBookingStartTime;
  const bookingCompleted = new URLSearchParams(history.location.search).get('bookingCompleted');
  const shouldUseCheckInV3 =
    !periodicCheckIn &&
    checkInSource !== 'psychiatry-lvs' &&
    ((!isLoading && hasNoBookingsScheduled) || bookingCompleted); // NOTE: V3 only applies to people with no bookings or when they just booked via V2 flow

  useEffect(() => {
    if (!isLoading && !bookingCompleted) {
      // track user is in the treatment group
      trackEvent('TS Experiment Session', {
        experimentName: EXPERIMENT_NAME,
        variantName: shouldUseCheckInV3 ? 'treatment' : 'control',
      });

      if (shouldUseCheckInV3) {
        history.replace(`/v3/check-in/room/${roomID}`);
      }
    }
  }, [bookingCompleted, history, isLoading, roomID, shouldUseCheckInV3]);

  return (
    <Switch>
      <WizardStepWrapper showBackButton={showBackButton} onCloseButtonClick={onExitClick} title=" ">
        <Route exact path="/v3/check-in/room/:roomID" component={CheckInBookingFirst} />
        <Route exact path="/v3/check-in/done/:source" component={CheckInDoneV3} />
        <Route exact path="/v3/check-in/change-provider" component={ChangeProvider} />
        <Route
          exact
          path={[
            '/check-in/room/:roomID/source/:source/check-in-source/:checkInSource/video-call/:videoCallID',
            '/check-in/room/:roomID/source/:source/check-in-source/:checkInSource/user-prompt/:userPromptID',
          ]}
          render={() => (
            <WizardScreen
              wizardScheme={shouldUseCheckInV3 ? CheckInWizardV3Scheme : CheckInWizardV2Scheme}
            />
          )}
        />
        <Route exact path="/check-in/done/:source" component={CheckInDone} />
      </WizardStepWrapper>
    </Switch>
  );
};

export default Routes;
