import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { TFKeysQuickmatchCommon } from '@talkspace/i18n/types';
import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import View from '../../../components/View';
import BaseButton, { BaseButtonProps } from '../../../components/BaseButton';
import TabRadioGroup from '../../../components/TabRadioGroup';
import { withHover, WithHoverProps } from '../../../hoc';
import { getButtonStyles } from '../../../utils';
import CheckV2 from '../../../components/icons/CheckV2';
import Big from '../../../components/Typography/Big';
import Small from '../../../components/Typography/Small';

interface OptionType {
  value: string | JSX.Element;
  label: string;
  description?: string;
  labelTranslationKey?: TFKeysQuickmatchCommon;
}

export interface SimpleQuestionsV2Props {
  variant?: 'default' | 'checkbox' | 'withSlot';
  isMultiSelect?: boolean;
  onSubmitCallback?: (selectedOptions: OptionType[]) => void;
  optionsList?: OptionType[];
  isDisabled?: boolean;
  selectedOptions?: OptionType[];
  currentValue?: string;
  legendText?: string;
  dataQa?: string;
  wrapperStyle?: EmotionStyle;
  fieldSetStyle?: EmotionStyle;
  fieldStyle?: EmotionStyle;
}

type MultiButtonProps = {
  isMulti?: boolean;
  selected?: boolean;
} & BaseButtonProps;

const MultiButton = ({ isMulti, selected, children, ...props }: MultiButtonProps) => {
  const { colors } = useEmotionTheme();
  if (isMulti) {
    return (
      <BaseButton {...props}>
        <View row align="center" justify="space-between">
          {children}
          {selected && (
            <CheckV2 width={15} height={13} color={colors.green} style={{ marginRight: 5 }} />
          )}
        </View>
      </BaseButton>
    );
  }
  return <BaseButton {...props}>{children}</BaseButton>;
};

const Question = withHover(
  styled(MultiButton)<
    {
      selected: boolean;
      removeTransition?: boolean;
      isMulti?: boolean;
      isClicked?: boolean;
    } & WithHoverProps
  >(
    ({
      selected,
      isHovering,
      removeTransition,
      isMulti,
      hoverType,
      theme: { colors, colorRoles, isHighContrast },
    }) => {
      let bgColor = colors.white;
      let txtColor = colors.black;
      if (selected && !isMulti) bgColor = colors.green;
      if (selected && isHighContrast && !isMulti) txtColor = colors.white;
      if (selected && isMulti) bgColor = colors.lightGreen;
      return {
        fontSize: '1.4rem',
        textAlign: 'left',
        padding: '14px 20px',
        width: '100%', // for some Android browsers where flex does not properly fill width
        border: `1px solid ${colorRoles.borders.borderDefault}`,
        borderRadius: '1rem',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        color: txtColor,
        backgroundColor: bgColor,
        '&:not(:last-child)': {
          borderBottom: '0',
        },
        '&:first-of-type': {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },
        '&:last-child': {
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
        },
        '&:not(:first-of-type):not(:last-child)': {
          borderRadius: '0',
        },
        ...getButtonStyles(
          {
            activeColors: {
              color: isMulti ? colors.black : colors.white,
              backgroundColor: isMulti ? colors.lightGreen : colors.green,
            },
            hoverColors: {
              color: colors.black,
              backgroundColor: colors.lightGreen,
            },
          },
          { isSelected: selected, isHovering, isClicked: hoverType === 'mouse', removeTransition }
        ),
      };
    }
  )
);

const SimpleQuestionsV2: React.FC<SimpleQuestionsV2Props> = ({
  isMultiSelect = false,
  onSubmitCallback,
  optionsList = [],
  selectedOptions,
  currentValue,
  legendText = 'Select one of the following answers',
  dataQa,
  wrapperStyle,
  fieldSetStyle,
  fieldStyle,
  ...otherProps
}) => {
  const [selected, setSelected] = useState<OptionType[]>([]);
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  useEffect(() => {
    if (selectedOptions) {
      setSelected(selectedOptions);
    }
  }, [selectedOptions]);

  const { colorRoles } = useEmotionTheme();
  const onOptionSelect = useCallback(
    (option: OptionType) => {
      let newSelected;
      if (isMultiSelect) {
        const isSelected = selected.some((item) => item.label === option.label);
        newSelected = isSelected
          ? selected.filter((item) => item.label !== option.label)
          : [...selected, option];
      } else {
        newSelected = [option];
      }
      setSelected(newSelected);
      onSubmitCallback && onSubmitCallback(newSelected);
    },
    [isMultiSelect, onSubmitCallback, selected]
  );

  const renderedOptions = useMemo(
    () =>
      (Array.isArray(optionsList) ? optionsList : [optionsList]).map((option, index) => {
        const isSelected = selected.some((item) => item.label === option.label);
        return (
          <Question
            data-qa={dataQa ? `${dataQa}RadioButton${index}` : `radioButton${index}`}
            role="radio"
            removeWrapper
            removeTransition
            key={String(option.label + currentValue + index)}
            aria-checked={isSelected}
            tabIndex={isSelected ? 0 : -1}
            selected={isSelected}
            onPress={() => onOptionSelect(option)}
            isMulti={isMultiSelect}
            forwardHoverType
            style={{ ...fieldStyle }}
            {...otherProps}
          >
            {option.description && (
              <>
                <Big style={{ color: colorRoles.typography.textDefault }}>{option.label}</Big>
                <Small style={{ marginTop: 4, color: colorRoles.typography.textSubtle }}>
                  {option.description}
                </Small>
              </>
            )}
            {!option.description && option.labelTranslationKey && (
              <Trans
                t={tQuickmatchCommon}
                i18nKey={option.labelTranslationKey as TFKeysQuickmatchCommon}
                tOptions={undefined}
              >
                {option.label}
              </Trans>
            )}
            {!option.description && !option.labelTranslationKey && option.label}
          </Question>
        );
      }),
    [
      optionsList,
      selected,
      dataQa,
      currentValue,
      onOptionSelect,
      isMultiSelect,
      fieldStyle,
      otherProps,
      colorRoles,
      tQuickmatchCommon,
    ]
  );

  return (
    <View style={{ ...wrapperStyle }}>
      <TabRadioGroup
        legendText={legendText}
        initialSelection={!!currentValue}
        style={{ ...fieldSetStyle }}
      >
        {renderedOptions}
      </TabRadioGroup>
    </View>
  );
};

export default SimpleQuestionsV2;
