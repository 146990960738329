import { services } from '@talkspace/react-toolkit';
import { ROOM_TYPE_TO_SERVICE_TYPE, RoomType } from 'chat/constants';
import { AggregatedRoomDataByID } from '@/hooks/useNavShellData';

export const getServiceTypeByRoomType = (roomType: RoomType) => ROOM_TYPE_TO_SERVICE_TYPE[roomType];

export const getServiceNameByRoomType = (roomType: RoomType, isTeen: boolean) => {
  const serviceType = getServiceTypeByRoomType(roomType);
  const service = roomType === 'privateRoom' && isTeen ? 'therapyTeen' : serviceType;
  return services.find((s) => s.value === service)?.name || ROOM_TYPE_TO_SERVICE_TYPE.privateRoom;
};

export const getEAPRoomWithNoSessions = (dataByRoomID?: AggregatedRoomDataByID) =>
  Object.keys(dataByRoomID || {})
    .map((roomID: string | number) => dataByRoomID?.[roomID])
    .find((room) => room?.subscription?.subscription?.noEapSessionsLeft);

export const checkAndMarkEAPNoSessionLeft = ({
  userID,
  featureViewsLoading,
  featureViewsData,
  eapRoomWithNoCredits,
  markFeatureViewed,
}) => {
  const noEapSessionsLeftNotMarked =
    !featureViewsLoading && !!featureViewsData && !featureViewsData.noEapSessionsLeft;
  if (eapRoomWithNoCredits && noEapSessionsLeftNotMarked) {
    markFeatureViewed({ featureName: 'noEapSessionsLeft', userID: `${userID}` });
  }
};

export const checkAndMarkEAPUpdateCoverageCompleted = ({
  userID,
  featureViewsLoading,
  featureViewsData,
  eapRoomWithNoCredits,
  markFeatureViewed,
}) => {
  const completedUpdateCoverageNotMarked =
    !featureViewsLoading && !!featureViewsData && !featureViewsData.eapUpdateCoverageCompleted;
  if (eapRoomWithNoCredits && completedUpdateCoverageNotMarked) {
    markFeatureViewed({ featureName: 'eapUpdateCoverageCompleted', userID: `${userID}` });
  }
};

export const onUpdateCoverageCompleted = ({
  userID,
  dataByRoomID,
  featureViewsLoading,
  featureViewsData,
  markFeatureViewed,
}) => {
  const eapRoomWithNoCredits = getEAPRoomWithNoSessions(dataByRoomID);

  checkAndMarkEAPNoSessionLeft({
    userID,
    featureViewsLoading,
    featureViewsData,
    eapRoomWithNoCredits,
    markFeatureViewed,
  });

  checkAndMarkEAPUpdateCoverageCompleted({
    userID,
    featureViewsLoading,
    featureViewsData,
    eapRoomWithNoCredits,
    markFeatureViewed,
  });
};
