import { useEffect } from 'react';
import {
  View,
  Button,
  ScrollView,
  Screen,
  EmotionThemeProvider,
  StickyDrawer,
} from '@talkspace/react-toolkit';
import { SurveyResult } from 'ts-frontend/types';
import { useSurveysState, useSurveysActions } from '../hooks/surveysContext';
import { useParams } from '../../core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import SurveyResults from '../components/SurveyResults';
import extractRoomEntities from '../../utils/extractRoomEntitiesFromState';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import extractRoomSurveyResultsByLabel from '../utils/extractRoomSurveyResultsByLabel';
import { getUserData } from '../../auth/helpers/token';

// we don't want all the label results, just the labels of the current survey
function filterForCurrentLabelsOnly(
  surveyResultsByLabel: { [key: string]: SurveyResult[] },
  userRoomSurveyID?: number
): { [key: string]: SurveyResult[] } {
  return Object.entries(surveyResultsByLabel).reduce((acc, [label, surveys]) => {
    const shouldIncludeLabel = surveys.some((s) => s.userRoomSurveyID === userRoomSurveyID);
    return shouldIncludeLabel
      ? {
          ...acc,
          [label]: surveys,
        }
      : acc;
  }, {});
}

const SurveyResultsContainer = () => {
  const { roomID, userRoomSurveyID } = useParams<{ roomID: string; userRoomSurveyID: string }>();
  const { surveyResultsByRoomID } = useSurveysState();
  const { dispatchGetSurveys } = useSurveysActions();
  const closeModal = useCloseModal();
  const { getAllRooms } = useMainActions();
  const { therapist } = extractRoomEntities(useMainState(), Number(roomID));

  useEffect(() => {
    const { id } = getUserData();
    if (!therapist) {
      getAllRooms(id);
    }
  }, [getAllRooms, therapist]);

  useEffect(() => {
    dispatchGetSurveys();
  }, [dispatchGetSurveys]);

  const surveyResultsByLabel = Object.keys(surveyResultsByRoomID).length
    ? extractRoomSurveyResultsByLabel(surveyResultsByRoomID, Number(roomID))
    : undefined;

  const currentSurveyResultsByLabel = surveyResultsByLabel
    ? filterForCurrentLabelsOnly(surveyResultsByLabel, +userRoomSurveyID)
    : undefined;

  const hasResults =
    currentSurveyResultsByLabel && Object.keys(currentSurveyResultsByLabel).length > 0;

  return (
    <EmotionThemeProvider version="2.0.0">
      <Screen showCloseButton onCloseButtonClick={closeModal}>
        <View align="center" justify="center" style={{ paddingBottom: 38, overflowX: 'hidden' }}>
          <ScrollView style={{ minHeight: hasResults ? undefined : 300 }}>
            {hasResults && <SurveyResults surveyResultsByLabel={currentSurveyResultsByLabel} />}
          </ScrollView>

          <StickyDrawer>
            <Button
              fixedWidth
              sizeDS="large"
              text={`Discuss with ${therapist?.firstName || 'your provider'}`}
              onPress={() =>
                closeModal({
                  navigateTo: 'room',
                  metadata: { roomID: Number(roomID) },
                })
              }
            />

            <Button
              fixedWidth
              onPress={() => closeModal()}
              text="Done"
              variant="tertiary"
              sizeDS="large"
            />
          </StickyDrawer>
        </View>
      </Screen>
    </EmotionThemeProvider>
  );
};

export default SurveyResultsContainer;
