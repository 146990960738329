import { connect } from 'react-redux';
import { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import * as React from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import usePrevious from '@talkspace/react-toolkit/src/hooks/usePrevious';
import useAccordion from '@talkspace/react-toolkit/src/hooks/useAccordion';
import { HiddenText } from '@talkspace/react-toolkit';
import { ROOM_TYPE_TO_SERVICE_TYPE } from 'chat/constants';

import {
  trackJoiningAdHocSession,
  trackConfirmOrDeclineRecurringSessionInteraction,
} from 'ts-analytics/mixpanel/events';
import { useIonicKeyboardListener } from 'ts-ionic/hooks';
import {
  SubMessageType,
  EMessage,
  MessageBodyHasButton,
  messageTypeToBodyType,
} from '../entities/EMessage';
import configs from '@/utils/configs';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import { withRouter, RouteComponentProps } from '@/core/routerLib/routerLib';
import { getUserData } from '@/auth/helpers/token';
import { position, squareCornersType, AckLevel } from '../types';
import { useVideoCallActions, useVideoCallState } from '../hooks/videoCallContext';
import UserTypingSocketService from '../redux/helpers/UserTypingSocketService';
import MessagesSocketService from '../redux/helpers/MessagesSocketService';
import MediaSocketService from '../redux/helpers/MediaSocketService';
import AcksSocketService from '../redux/helpers/AcksSocketService';
import { UserTyping, SessionStatus, ChatBannerType } from '../redux/constants/chatTypes';
import { AppState } from '../redux/chatStore';
import {
  dispatchAcknowledgements,
  dispatchRequestHistoricalMessages,
  dispatchRequestMessages,
  dispatchGetActiveSessionInfo,
  dispatchGetSessionStatus,
  dispatchToggleStarMessage,
  dispatchUserTyping,
  dispatchResetChat,
  dispatchUpdateMedia,
  dispatchPostLiveSessionEvent,
} from '../redux/actions/chatActions';
import { useSharedChatState, useSharedChatActions } from '../hooks/sharedChatContext';
import { isSameTimeRange, isSameUser } from '../helpers';
import { EActiveSession } from '../entities/ActiveSession';
import ScrollView from '../components/ScrollView';
import Message from '../components/Message';
import * as newChatDing from '../assets/new-chat-message.mp3';
import { didClientJoin } from '../components/ScrollView/ScrollView';

const MINIMAL_CALL_DURATION_IN_SECONDS = 240;

interface MessagesContainerProps {
  activeSession: EActiveSession | null;
  sessionStatus?: SessionStatus;
  primaryClientID?: number;
  sharedBanners: ChatBannerType[];
  messages: EMessage[];
  isFirstGetMessagesCall: boolean;
  requestMessages: Function;
  requestHistoricalMessages: Function;
  requestGetActiveSessionInfo: Function;
  requestGetSessionStatus: Function;
  toggleStarMessage: (messageID: number, isStarred: boolean) => void;
  isChatSessionAllowed: boolean;
  isUpdating?: boolean;
  isLoadingHistorical: boolean;
  wasLoadingHistorical: boolean;
  hasReceivedAllMessages?: boolean;
  updateAcks: Function;
  maxAckReadId: number;
  maxAckReceivedId: number;
  userTyping: UserTyping;
  userTypingHandler: Function;
  roomID: number;
  isTherapistChat: boolean;
  therapistUserID: number;
  isAcking: boolean;
  lastMessageID: number;
  resetChat: Function;
  updateMedia: Function;
  shouldPlaySoundNotifications?: boolean;
  containerWidth: number;
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  postEvent: Function;
  isOffline?: boolean;
}

let messagesSocket: MessagesSocketService;
let acksSocket: AcksSocketService;
let userTypingSocket: UserTypingSocketService;
let mediaSocket: MediaSocketService;

type Props = MessagesContainerProps & RouteComponentProps;

const MessagesContainer: React.FunctionComponent<Props> = ({
  messages,
  isFirstGetMessagesCall,
  requestMessages,
  requestHistoricalMessages,
  requestGetActiveSessionInfo,
  requestGetSessionStatus,
  isChatSessionAllowed,
  toggleStarMessage,
  isLoadingHistorical,
  wasLoadingHistorical,
  lastMessageID,
  updateAcks,
  maxAckReadId,
  maxAckReceivedId,
  userTyping,
  userTypingHandler,
  roomID,
  isTherapistChat,
  therapistUserID,
  isAcking,
  resetChat,
  updateMedia,
  history,
  location,
  shouldPlaySoundNotifications,
  banners,
  priorityBanner,
  containerWidth,
  activeSession,
  sessionStatus,
  primaryClientID,
  sharedBanners,
  postEvent,
  isOffline,
  ...props
}) => {
  const [a11yNotification, setA11yNotification] = useState('');
  const [lastSeenMessageID, setLastSeenMessageID] = useState(0);
  const [socketReady, setSocketReady] = useState(false);
  const [shouldRenderNewItemAlert, setShouldRenderNewItemAlert] = useState(false);
  const currentUserIDRef = useRef(-1);
  const messagesLength = useRef(0);
  const requestedInitialActiveSessionRef = useRef(false);
  const scrollViewRef = useRef<HTMLDivElement>(null);

  const { talktracks } = useFlags();

  const { therapistFirstName, isChatHidden, isLiveChatBannerOpen, isMobileApp } =
    useSharedChatState();
  const { updateState } = useSharedChatActions();

  const { isMinimized } = useVideoCallState();
  const { toggleMinimizedAction } = useVideoCallActions();

  const { open: isAccordionOpen, toggleAccordion } = useAccordion({ initialState: false });

  const chatScrollHeight = scrollViewRef.current ? scrollViewRef.current.scrollHeight : 0;

  useEffect(() => {
    resetChat(roomID);
    currentUserIDRef.current = getUserData().id;
    messagesSocket = new MessagesSocketService(requestMessages, roomID, resetChat);
    userTypingSocket = new UserTypingSocketService(roomID, userTypingHandler);
    mediaSocket = new MediaSocketService(roomID, updateMedia);

    setSocketReady(true);
    return () => {
      messagesSocket.unmount(isTherapistChat);
      userTypingSocket.unmount();
      mediaSocket.unmount();
      resetChat();
    };
  }, [
    roomID,
    requestMessages,
    resetChat,
    updateAcks,
    userTypingHandler,
    isTherapistChat,
    updateMedia,
    requestHistoricalMessages,
  ]);

  useEffect(() => {
    // used primary for getting the state of the live chat banner

    if (!requestedInitialActiveSessionRef.current && isChatSessionAllowed) {
      // does initial call to initialize the activeSessionInfo
      requestGetActiveSessionInfo(roomID, 'chat');
      requestedInitialActiveSessionRef.current = true;
    }

    const liveChatMessageTypes = [46, 47, 51];

    if (
      requestedInitialActiveSessionRef.current &&
      isChatSessionAllowed &&
      messages &&
      messages.length &&
      liveChatMessageTypes.includes(messages[messages.length - 1]?.messageType)
    ) {
      requestGetActiveSessionInfo(roomID, 'chat');
    }
  }, [isChatSessionAllowed, requestGetActiveSessionInfo, roomID, messages, messages.length]);

  useEffect(() => {
    const handleRefetchSessionStatus = () => {
      requestGetSessionStatus(roomID);
    };

    window.addEventListener('refetchSessionStatus', handleRefetchSessionStatus);
    return () => {
      window.removeEventListener('refetchSessionStatus', handleRefetchSessionStatus);
    };
  }, [requestGetSessionStatus, roomID]);

  useEffect(() => {
    requestGetSessionStatus(roomID);
  }, [requestGetSessionStatus, roomID]);

  // be careful, using these values as a dependency could possibly cause this useEffect to run multiple times
  // keept this in mind when debugging any issues
  const previousCallStartedAt: string | undefined = usePrevious(activeSession?.callStartedAt);
  const previousVideoCallID: number | undefined = usePrevious(activeSession?.videoCallID);
  const previousVideoCreditType: string | undefined = usePrevious(activeSession?.booking.type);
  const previousActiveSession: boolean | undefined = usePrevious(!!activeSession);

  useEffect(() => {
    if (activeSession?.modality === 'chat' && !isLiveChatBannerOpen) {
      updateState({ isLiveChatBannerOpen: true });
    }
    if ((activeSession?.modality === 'chat' && activeSession?.callEndedAt) || !activeSession) {
      updateState({ isLiveChatBannerOpen: false });
      if (
        isTherapistChat &&
        previousCallStartedAt &&
        previousVideoCallID &&
        previousVideoCreditType !== 'introduction'
      ) {
        const callDurationInSeconds =
          (new Date().getTime() - new Date(previousCallStartedAt).getTime()) / 1000;
        if (callDurationInSeconds > MINIMAL_CALL_DURATION_IN_SECONDS) {
          const videoCallEndedEvent = new CustomEvent('videoCallEnded', {
            detail: {
              shouldOpenTalkTrack: talktracks,
              videoCallID: previousVideoCallID,
              shouldOpenPostLVSPrompt:
                !location.pathname.includes('/progress-notes') &&
                !location.pathname.includes('/collateral-notes') &&
                !location.pathname.includes('/case-consultation-notes') &&
                !location.pathname.includes('/discharge-notes') &&
                !location.pathname.includes('/psychotherapy-notes') &&
                !location.pathname.includes('/notes-tab'),
            },
          });
          document.dispatchEvent(videoCallEndedEvent);
        }
      }
      // handle client side for showing a modal to rate the session
      if (
        !isTherapistChat &&
        previousActiveSession &&
        previousCallStartedAt &&
        previousVideoCallID &&
        !activeSession
      ) {
        const callDurationInSeconds =
          (new Date().getTime() - new Date(previousCallStartedAt).getTime()) / 1000;
        const minimalCallDurationReached = callDurationInSeconds > MINIMAL_CALL_DURATION_IN_SECONDS;
        if (minimalCallDurationReached) {
          history.replace(
            `/room/${roomID}?action=check-in&source=post-lvs-web&check-in-source=live-chat&video-call=${previousVideoCallID}`
          );
        }
      }
    }
  }, [
    activeSession,
    isLiveChatBannerOpen,
    updateState,
    isTherapistChat,
    previousCallStartedAt,
    previousVideoCallID,
    previousVideoCreditType,
    location.pathname,
    previousActiveSession,
    history,
    roomID,
    talktracks,
  ]);

  useEffect(() => {
    if (activeSession?.callStartedAt) {
      document.dispatchEvent(new CustomEvent('liveSessionStarted'));
    }
  }, [activeSession, roomID]);

  useEffect(() => {
    updateState({ sessionStatus });
  }, [sessionStatus, updateState]);

  useEffect(() => {
    updateState({ primaryClientID });
  }, [primaryClientID, updateState]);

  useEffect(() => {
    if (sharedBanners && sharedBanners.length > 0 && !isLiveChatBannerOpen) {
      updateState({ sharedBanners });
    } else {
      updateState({ sharedBanners: [] });
    }
  }, [sharedBanners, isLiveChatBannerOpen, updateState]);

  useEffect(() => {
    currentUserIDRef.current = getUserData().id;
    if (isAcking && socketReady) {
      acksSocket = new AcksSocketService(
        updateAcks,
        roomID,
        currentUserIDRef.current,
        isTherapistChat
      );
    }
    return () => {
      if (acksSocket) acksSocket.unmount();
    };
  }, [roomID, updateAcks, isTherapistChat, isAcking, socketReady]);

  useEffect(() => {
    if (acksSocket) acksSocket.setChatVisible(!isChatHidden);
  }, [isChatHidden]);

  const scrollToBottom = useCallback((smooth?: boolean) => {
    if (scrollViewRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: scrollViewRef.current.scrollHeight,
        left: 0,
        behavior: smooth ? 'smooth' : 'auto',
      };
      if (scrollViewRef.current.scrollTo) scrollViewRef.current.scrollTo(scrollOptions);
    }
  }, []);

  // In Ionic, listen for cordova-plugin-ionic-keyboard keyboardDidShow event to scroll MessagesContainer view to latest message
  useIonicKeyboardListener('keyboardDidShow', () => scrollToBottom(), [scrollToBottom]);

  function playNewMessageDing() {
    try {
      new Audio(newChatDing).play();
    } catch {
      // Useful warning
      // eslint-disable-next-line no-console
      console.warn('audio support not enabled for this browser');
    }
    setTimeout(() => {
      setA11yNotification('');
    }, 1000);
  }

  useEffect(() => {
    if (acksSocket && isAcking) acksSocket.updatedMessages(messages);

    const isNewMessage =
      messagesLength.current !== 0 &&
      !wasLoadingHistorical &&
      messages.length > messagesLength.current;

    const lastMessageIsMe =
      messages.length && messages[messages.length - 1].user.id === currentUserIDRef.current;

    const isInitialLoad = messagesLength.current === 0 && messages.length > 0;

    if (isInitialLoad) {
      setTimeout(() => {
        scrollToBottom();
        setLastSeenMessageID(lastMessageID);
      }, 0);
    }

    if (isNewMessage) {
      if (lastMessageIsMe) scrollToBottom();
      else if (!isTherapistChat && shouldPlaySoundNotifications) {
        setA11yNotification('new message');
        playNewMessageDing();
      }
    }
    messagesLength.current = messages.length;
  }, [
    messages,
    messages.length,
    wasLoadingHistorical,
    lastMessageID,
    lastSeenMessageID,
    isTherapistChat,
    shouldPlaySoundNotifications,
    scrollToBottom,
    isAcking,
  ]);

  useEffect(() => {
    // chatScrollHeight is used in the dependencies because chat is open in the background of other panels,
    // while its in the background any changes (like new message received) causes its scroll height to change
    // this causes New Message alert to be displayed, when chat is back to the front its height changes and
    // this effect recalculate if needed to be displayed.
    if (!lastSeenMessageID) return;

    if (scrollViewRef.current) {
      const scrollThreshold = 150;
      const { clientHeight, scrollTop } = scrollViewRef.current;

      const isNewMessage =
        !!messages.length &&
        messages[messages.length - 1].user.id !== currentUserIDRef.current &&
        lastSeenMessageID !== lastMessageID;

      if (isNewMessage) {
        if (scrollTop + clientHeight > chatScrollHeight - scrollThreshold) {
          scrollToBottom(true);
        } else {
          setShouldRenderNewItemAlert(true);
        }
      } else {
        setShouldRenderNewItemAlert(false);
      }
    }
  }, [lastMessageID, lastSeenMessageID, messages, chatScrollHeight, scrollToBottom]);

  useEffect(() => {
    if (userTyping.show && scrollViewRef.current) {
      const scrollThreshold = 150;
      const { scrollHeight, clientHeight, scrollTop } = scrollViewRef.current;
      // we scroll to the bottom only if the user is in the last "scrollThreshold" pixels
      if (scrollTop + clientHeight > scrollHeight - scrollThreshold) {
        scrollToBottom(true);
      }
    }
  }, [chatScrollHeight, scrollToBottom, userTyping.displayName, userTyping.show]);

  const openModal = useOpenModal();

  const renderRow = (item: EMessage, index: number) => {
    if (!item.id && item.id !== 0) {
      // eslint-disable-next-line no-console
      console.warn('missing id for message', JSON.stringify(item));
    }

    const previousMessage = messages[index - 1] || {};
    const nextMessage = messages[index + 1] || {};

    const isAroundSameTime = isSameTimeRange(item, previousMessage, 1800);
    const isPrevRecent = isSameTimeRange(item, previousMessage, 60);
    const isNextRecent = isSameTimeRange(nextMessage, item, 60);
    const isPrevSameUser = isSameUser(item, previousMessage);
    const isNextSameUser = isSameUser(item, nextMessage);
    const isPrevSystem = previousMessage.isSystem;
    const isNextSystem = nextMessage.isSystem;

    let squareCorners: squareCornersType = 'NONE';
    if (isPrevRecent && isPrevSameUser && !isPrevSystem) squareCorners = 'TOP';
    if (isNextRecent && isNextSameUser && !isNextSystem) {
      squareCorners = squareCorners === 'TOP' ? 'BOTH' : 'BOTTOM';
    }

    let messagePosition: position = item.user.id === currentUserIDRef.current ? 'right' : 'left';

    if (item.isSystem) messagePosition = 'center';

    const isPeer = messagePosition === 'left' && !isTherapistChat && item.user.userType !== 3;

    let ackLevel = 0;
    if (!item.isSystem && isAcking && messagePosition === 'right') {
      if (item.id <= maxAckReadId) {
        ackLevel = 3;
      } else if (item.id <= maxAckReceivedId) {
        ackLevel = 2;
      } else {
        ackLevel = 1;
      }
    }

    let isLastReceivedMessage = false;
    if (messagePosition === 'left') {
      const allReceivedMessages = messages.filter(
        (message) => message.user.id !== currentUserIDRef.current
      );
      isLastReceivedMessage = allReceivedMessages[allReceivedMessages.length - 1].id === item.id;
    }

    const messageProps = {
      key: item.id,
      currentMessage: item,
      isLast: item.id === messages[messages.length - 1].id,
      isPeer,
      squareCorners: squareCorners as squareCornersType,
      ackLevel: ackLevel as AckLevel,
      position: messagePosition,
      isAroundSameTime,
      isPrevSameUser,
      isPrevSystem,
      isLastReceivedMessage,
      isMedia: ['VIDEO', 'AUDIO', 'IMAGE', 'PDF'].includes(item.bodyType),
      roomID,
    };
    let onActionPress = () => undefined;
    let onSecondaryActionPress = () => undefined;

    if ((item.messageBody as MessageBodyHasButton).buttonParams) {
      onActionPress = () => {
        const messageBody = item.messageBody as MessageBodyHasButton;

        const modalSettings = messageBody.buttonActionModal;
        const state = {
          ...messageBody.buttonParams,
          therapistUserID,
          roomID,
          therapistFirstName,
          messageSentBy: item.user.id,
        };

        if (
          messageBody.buttonParams &&
          messageBody.buttonDestinationPath &&
          messageTypeToBodyType(messageBody.buttonParams.messageType) === 'GENERIC'
        ) {
          if (
            !isTherapistChat &&
            messageBody.buttonParams.subMessageType ===
              SubMessageType.CONFIRM_OR_DECLINE_RECURRING_SESSIONS
          ) {
            const {
              buttonParams: { bookingID },
            } = messageBody;
            trackConfirmOrDeclineRecurringSessionInteraction({
              mode: 'click',
              bookingID: bookingID!,
              userID: currentUserIDRef.current,
            });
          }
          openModal(
            messageBody.buttonDestinationPath,
            state,
            modalSettings?.isFullscreen,
            modalSettings?.isTransparent
          );
        } else if (modalSettings) {
          openModal(
            `/${messageBody.buttonDestinationPath}`,
            state,
            modalSettings.isFullscreen,
            modalSettings.isTransparent
          );
        } else if (
          configs &&
          configs.featureFlags &&
          configs.featureFlags.treatmentIntake &&
          messageBody.buttonDestinationPath === 'intake'
        ) {
          openModal(`/treatment-intake-launcher/room/${roomID}/source/chat`, {}, true, true);
        } else if (messageBody.buttonDestinationPath === 'matching-intake') {
          openModal(`${messageBody.buttonDestinationPath}/room/${roomID}/source/chat`);
        } else if (messageBody.buttonDestinationPath === 'eligibility-widget') {
          const serviceType = messageBody?.buttonParams?.roomType
            ? ROOM_TYPE_TO_SERVICE_TYPE[messageBody.buttonParams.roomType]
            : '';
          openModal(
            `/what-to-expect?updateCoverageRoomID=${roomID}&source=update-coverage&serviceType=${serviceType}&previousTherapistId=${therapistUserID}`
          );
        } else if (
          messageBody.buttonDestinationPath === 'continue-to-booking' &&
          messageBody.buttonParams?.bookingID
        ) {
          openModal(
            `/in-room-scheduling/room/${roomID}/confirm-booking/booking/${messageBody.buttonParams.bookingID}?isJoin=true&videoCallID=${messageBody.buttonParams.videoCallID}&batch=true`
          );
        } else if (messageBody.buttonDestinationPath === 'parental-consent-details-resubmit') {
          openModal(`/parental-consent-resubmit`);
        } else if (
          messageBody.buttonDestinationPath === 'confirm-booking' &&
          messageBody.buttonParams?.bookingID
        ) {
          openModal(
            `/in-room-scheduling/room/${roomID}/confirm-booking/booking/${messageBody.buttonParams.bookingID}?batch=true`
          );
        } else if (
          messageBody.buttonDestinationPath === 'create-session-report' &&
          messageBody.buttonParams?.caseID
        ) {
          openModal(
            `/create-session-report/room/${roomID}?caseID=${messageBody.buttonParams.caseID}`,
            undefined,
            false,
            true
          );
        } else if (
          messageBody.buttonDestinationPath === 'send-live-session-event' &&
          messageBody.buttonParams &&
          messageBody.buttonParams.videoCallID &&
          (messageBody.buttonParams?.callType !== 'bh_ad_hoc' ||
            isTherapistChat ||
            (activeSession && didClientJoin(activeSession?.clients, currentUserIDRef.current)))
        ) {
          if (messageBody.buttonParams?.callType === 'bh_ad_hoc' && !isTherapistChat) {
            trackJoiningAdHocSession({
              userID: currentUserIDRef.current,
              roomID,
              providerID: therapistUserID,
            });
          }

          openModal(
            `/chat-session-start`,
            {
              videoCallID: messageBody.buttonParams.videoCallID,
              roomID,
              callType: messageBody.buttonParams.callType,
              userID: currentUserIDRef.current,
              providerID: therapistUserID,
            },
            false,
            true
          );
        } else if (
          messageBody.buttonDestinationPath === 'video-call' &&
          isMinimized &&
          isTherapistChat
        ) {
          toggleMinimizedAction();
        } else if (
          (messageBody.buttonDestinationPath === 'video-call' ||
            messageBody.buttonDestinationPath === 'send-live-session-event') &&
          messageBody.buttonParams?.callType === 'bh_ad_hoc' &&
          !isTherapistChat
        ) {
          trackJoiningAdHocSession({
            userID: currentUserIDRef.current,
            roomID,
            providerID: therapistUserID,
          });
          openModal(
            `/in-room-scheduling/room/${roomID}/confirm-booking?isJoin=true&videoCallID=${messageBody.buttonParams.videoCallID}&isBHAdHoc=true&adHocDuration=${messageBody.buttonParams.creditMinutes}&batch=true`
          );
        } else if (messageBody.buttonDestinationPath === 'video-call') {
          history.push(
            `${location.pathname}/modal/${messageBody.buttonDestinationPath}${location.search}`,
            state
          );
        } else {
          history.push(`${location.pathname}/modal/${messageBody.buttonDestinationPath}`, state);
        }
        return undefined;
      };
    }
    if ((item.messageBody as MessageBodyHasButton).secondaryButtonText) {
      onSecondaryActionPress = () => {
        const messageBody = item.messageBody as MessageBodyHasButton;
        const state = {
          ...messageBody.buttonParams,
          roomID,
        };

        if (
          messageBody.secondaryButtonDestinationPath === 'decline-booking' &&
          messageBody.buttonParams?.bookingID
        ) {
          openModal(
            `/in-room-scheduling/room/${roomID}/decline-booking/booking/${messageBody.buttonParams.bookingID}?batch=true`,
            state
          );
        } else {
          // adding this as a default action
          history.push(
            `${location.pathname}/modal/${messageBody.secondaryButtonDestinationPath}`,
            state
          );
        }
        return undefined;
      };
    }

    const onMessageStarPress = (message: EMessage) => {
      toggleStarMessage(message.id, message.isStarred);
    };

    return (
      <Fragment key={`message-container-${item.id}`}>
        <Message
          isOldestLoadedMessage={index === 0}
          {...messageProps}
          onActionPress={onActionPress}
          onSecondaryActionPress={onSecondaryActionPress}
          onStarPress={onMessageStarPress}
          containerWidth={containerWidth}
        />
        {a11yNotification && <HiddenText role="alert">{a11yNotification}</HiddenText>}
      </Fragment>
    );
  };

  const handleOnScrollTop = () => {
    // Limitation currently suspended, the reason for this is "isUpdating" being used
    // by other APIs on the reducer, any issues there is causing the chat not to scroll to
    // its history. TODO fix this.
    // if (!isUpdating && !hasReceivedAllMessages)
    requestHistoricalMessages(roomID);
  };

  const handleOnScrollBottom = () => setLastSeenMessageID(lastMessageID);

  const handleEndLiveChat = () => openModal('/end-live-chat', {}, false, true);

  return (
    <ScrollView
      items={messages}
      renderRow={renderRow}
      shouldRenderNewItemAlert={shouldRenderNewItemAlert}
      onScrollTop={handleOnScrollTop}
      onScrollBottom={handleOnScrollBottom}
      scrollViewRef={scrollViewRef}
      isLoadingHistorical={isLoadingHistorical || isFirstGetMessagesCall}
      banners={banners}
      priorityBanner={priorityBanner}
      isLiveChatBannerOpen={!!isLiveChatBannerOpen}
      isAccordionOpen={isAccordionOpen}
      isTherapistChat={isTherapistChat}
      handleAccordionToggle={toggleAccordion}
      handleEndLiveChat={handleEndLiveChat}
      activeSession={activeSession}
      userTyping={userTyping}
      style={{ ...(isMobileApp && { WebkitUserSelect: 'none', userSelect: 'none' }) }}
      isOffline={isOffline}
      {...props}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    activeSession: state.chat.activeSession,
    sessionStatus: state.chat.sessionStatus,
    primaryClientID: state.chat.roomInfo.primaryClientID,
    sharedBanners: state.chat.sharedBanners,
    isChatSessionAllowed: state.chat.roomInfo.allowedSessionModalities.chat,
    lastMessageID: state.chat.lastMessageID,
    messages: state.chat.messages,
    isFirstGetMessagesCall: state.chat.isFirstGetMessagesCall,
    isUpdating: state.chat.isUpdating,
    isLoadingHistorical: state.chat.isLoadingHistorical,
    wasLoadingHistorical: state.chat.wasLoadingHistorical,
    isError: state.chat.isError,
    hasReceivedAllMessages: state.chat.hasReceivedAllMessages,
    maxAckReadId: state.chat.acks.maxAckReadId,
    maxAckReceivedId: state.chat.acks.maxAckReceivedId,
    isTherapistChat: state.chat.isTherapistChat || false,
    therapistUserID: state.chat.roomInfo.therapistUserID,
    userTyping: state.chat.userTyping,
  };
};
const mapDispatchToProps = {
  requestMessages: dispatchRequestMessages,
  requestHistoricalMessages: dispatchRequestHistoricalMessages,
  requestGetActiveSessionInfo: dispatchGetActiveSessionInfo,
  requestGetSessionStatus: dispatchGetSessionStatus,
  toggleStarMessage: dispatchToggleStarMessage,
  updateAcks: dispatchAcknowledgements,
  userTypingHandler: dispatchUserTyping,
  resetChat: dispatchResetChat,
  updateMedia: dispatchUpdateMedia,
  postEvent: dispatchPostLiveSessionEvent,
};
const MessagesContainerWithRedux = connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);

export default withRouter(MessagesContainerWithRedux);
