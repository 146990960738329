import { useMemo } from 'react';

import { useEmotionTheme, View, Screen, useWindowWidthState } from '@talkspace/react-toolkit';
import { useWizardActions, WizardScreen } from 'stepWizard';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Route, Switch, useHistory, useParams } from '@/core/routerLib';
import SubmitTeenEmergencyContact from './containers/SubmitTeenEmergencyContact';
import emergencyContactScheme from './teenEmergencyContactScheme';
import useShowOnboardingImprovements from '@/hooks/useBHOnboardingImprovements';
import { getUserData } from '@/auth/helpers/token';

const Routes = () => {
  const { onExitClick } = useWizardActions();
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { disableAddressAutocomplete } = useFlags();

  const {
    location: { pathname },
  } = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const { id: userID } = getUserData();

  const isOnboarding = pathname.includes('/onboarding');
  const isBHOnboardingImprovementsEnabled = useShowOnboardingImprovements(roomID, userID);
  const showCloseButton = !isOnboarding;

  const wizardScheme = useMemo(() => {
    return {
      ...emergencyContactScheme(disableAddressAutocomplete),
      ...(isOnboarding ? { hideProgressIndicator: true } : {}),
      ...(isBHOnboardingImprovementsEnabled
        ? {
            showProgressBarConfig: {
              showProgressBar: true,
              sections: ['Check coverage', 'Your preferences', 'Get matched'],
              currentSection: 3,
            },
          }
        : {}),
    };
  }, [disableAddressAutocomplete, isOnboarding, isBHOnboardingImprovementsEnabled]);

  return (
    <Switch>
      <View style={{ background: colors.white }}>
        <Screen
          safeAreaVariant="setHeightAndAddTop"
          onCloseButtonClick={onExitClick}
          title={isMobile ? 'Personal information' : ''}
          showCloseButton={showCloseButton}
          scrollViewStyles={{ padding: !isMobile ? '0 22px 22px 22px' : 0 }}
        >
          <Route
            exact
            path={[
              '/teen-emergency-contact-wizard/submit',
              '/room/:roomID/onboarding/teen-emergency-contact/source/:source/submit',
            ]}
            component={SubmitTeenEmergencyContact}
          />
          <Route
            exact
            path={[
              '/teen-emergency-contact-wizard/room/:roomID/source/:source',
              '/room/:roomID/onboarding/teen-emergency-contact/source/:source',
            ]}
            render={() => <WizardScreen wizardScheme={wizardScheme} />}
          />
        </Screen>
      </View>
    </Switch>
  );
};

export default Routes;
