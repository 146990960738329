import { UseMutateFunction } from 'react-query';
import { VoidFunctionComponent, useState } from 'react';
import {
  Button,
  Large,
  RadioButton,
  Standard,
  TabRadioGroup,
  View,
} from '@talkspace/react-toolkit';

import { Trans, useTranslation } from '@talkspace/i18n';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import { ContentBodyWrapper, Headline, Content } from '../StyledComponents';
import { RenewVoucherVariables } from '../../hooks/useMutationRenewVoucher';

const RadioButtonWrapper = styled(View)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const StyledRadioButton = styled(RadioButton)({
  padding: 5,
});

const RadioButtonText = styled(Standard)({ textAlign: 'left', fontWeight: 'normal' });
interface Props {
  orgName: string;
  roomID: number;
  renewVoucher: UseMutateFunction<void, Error, RenewVoucherVariables>;
  setShouldClose: (value: boolean) => void;
}

const DTERenewalForm: VoidFunctionComponent<Props> = ({
  orgName,
  roomID,
  renewVoucher,
  setShouldClose,
}) => {
  const [shouldRenew, setShouldRenew] = useState<boolean | null>(null);
  const closeModal = useCloseModal();
  const { t: tDteRevalidation } = useTranslation('dte');

  const handleSubmit = () => {
    if (shouldRenew !== null) {
      renewVoucher(
        {
          roomID,
          shouldRenew,
        },
        {
          onSuccess: () => {
            setShouldClose(true);
            closeModal();
          },
        }
      );
    }
  };

  return (
    <Content height={500}>
      <Headline>{tDteRevalidation('dteRenewal.header', 'Benefit Renewal', undefined)}</Headline>
      <ContentBodyWrapper>
        <Trans t={tDteRevalidation} i18nKey="dteRenewal.continueReceivingCoverage">
          <Large style={{ marginBottom: 50, fontWeight: 'normal' }} variant="largeMediumTSBlack">
            To continue receiving this coverage, please attest that you are still an active member
            of {{ orgName }}, and therefore eligible for this benefit.
          </Large>
        </Trans>
        <TabRadioGroup
          style={{
            width: '100%',
            minInlineSize: 'fit-content',
          }}
        >
          <RadioButtonWrapper style={{ marginBottom: 15 }}>
            <StyledRadioButton
              tabFirstOption
              isActive={!!shouldRenew}
              onPress={() => setShouldRenew(true)}
              dataQa="dteRenewalFormYesRadioButton"
            />
            <View style={{ width: 350 }}>
              <RadioButtonText variant="standardDarkGrey">
                <Trans t={tDteRevalidation} i18nKey="dteRenewal.attest">
                  I attest that I am eligible for continued coverage through {{ orgName }}
                </Trans>
              </RadioButtonText>
            </View>
          </RadioButtonWrapper>
          <RadioButtonWrapper style={{ marginBottom: 30 }}>
            <StyledRadioButton
              tabFirstOption
              isActive={shouldRenew === false}
              onPress={() => setShouldRenew(false)}
              dataQa="dteRenewalFormNoRadioButton"
            />
            <View style={{ width: 350 }}>
              <RadioButtonText variant="standardDarkGrey">
                {tDteRevalidation(
                  'dteRenewal.noLongerEligible',
                  'I am no longer eligible for this benefit, but wish to view other coverage options',
                  undefined
                )}
              </RadioButtonText>
            </View>
          </RadioButtonWrapper>
        </TabRadioGroup>
        <Button onPress={handleSubmit} dataQa="dteRenewalFormSubmitButton">
          {tDteRevalidation('dteRenewal.submit', 'Submit', undefined)}
        </Button>
      </ContentBodyWrapper>
    </Content>
  );
};

export default DTERenewalForm;
