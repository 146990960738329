import { useState } from 'react';
import IconButton from '../components/IconButton';
import { XMarkLarge } from '../icons';
import styled, { useEmotionTheme } from '../../core/styled';

import {
  TextDS,
  Checkbox,
  View,
  TextAreaRounded,
  Chip,
  Button,
  Modal,
  CheckV2,
  TouchableView,
  AnimatedSwipeDownModal,
} from '../../components';

import ToggleSwitch from '../../components/ToggleSwitch';
import { getSpacing } from '../tokens';

export type FeedbackOption<T extends string = string> = {
  label: string;
  field: T;
};

export type FeedbackType<T extends string = string> = {
  label: string;
  value: T;
  dataQa: string;
};

export type TextAreaTitles<T extends string> = Record<T, string>;

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: -4,
  top: -4,
});

const MainViewDesktop = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    padding: 20,
    width: 407,
    gap: spacing('space200'),
    background: colorRoles.surfaces.surfaceInteractiveDefault,
    boxShadow: '0px 8px 32px rgba(43, 42, 46, 0.25)',
    borderRadius: spacing('space200'),
  };
});

const MainViewMobile = styled(View)(({ theme: { spacing } }) => {
  return {
    gap: spacing('space200'),
  };
});

const ButtonsViewMobile = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: spacing('space150'),
  };
});

const ButtonsViewDesktop = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: spacing('space150'),
  };
});

interface SubmitPayload {
  modalType: string | null;
  freeText?: string;
  feedbackPayload?: Record<string, boolean>;
}

interface Props<T extends string> {
  isOpen: boolean;
  onClose: (shouldSendData: boolean) => void;
  onSubmit: (payload: SubmitPayload) => void;
  title: string;
  subtitle?: string;
  feedbackTypes: FeedbackType<T>[];
  feedbackCheckboxes: Record<T, FeedbackOption[]>;
  textAreaTitles: TextAreaTitles<T>;
  feedbackToggles?: Record<T, FeedbackOption[]>;
  secondaryButtonLabel?: string;
  onSecondaryButtonPress?: () => void;
}

export default function FeedbackModal<T extends string>({
  isOpen,
  onClose,
  onSubmit,
  title,
  subtitle = '',
  feedbackTypes,
  feedbackCheckboxes,
  textAreaTitles,
  feedbackToggles,
  secondaryButtonLabel,
  onSecondaryButtonPress,
}: Props<T>) {
  const [modalType, setModalType] = useState<string | null>(null);
  const [feedbackPayload, setFeedbackPayload] = useState<Record<string, boolean>>({});

  const modalTypeLabel = feedbackTypes.find((chip) => chip.value === modalType)?.label;

  const feedbackTitle = modalType ? 'Provide additional feedback' : title;
  const feedbackSubTitle = modalType ? `Why did you choose “${modalTypeLabel}”?` : subtitle;

  const {
    window: { isMobile },
  } = useEmotionTheme();

  const [freeText, setFreeText] = useState('');

  const handleClose = (shouldSendData = true) => {
    setModalType(null);
    setFreeText('');
    setFeedbackPayload({});
    onClose(shouldSendData);
  };

  const handleCheckboxChange = (field: string) => {
    setFeedbackPayload((prev) => {
      return { ...prev, [field]: !prev[field] };
    });
  };

  const handleSubmit = () => {
    const payload = {
      modalType,
      freeText,
      feedbackPayload,
    };

    onSubmit(payload);
    handleClose(false);
  };

  const handleChipPress = (value: string) => {
    setModalType(value);
  };

  const renderCheckboxes = () => {
    if (!modalType) return null;
    const options = feedbackCheckboxes[modalType] || [];
    return (
      <View>
        {options.map(({ label, field }) => (
          <Checkbox
            key={field}
            stretch={false}
            isLabelOnRight
            label={label}
            isChecked={!!feedbackPayload[field]}
            setIsChecked={() => handleCheckboxChange(field)}
            checkComponent={<CheckV2 />}
          />
        ))}
      </View>
    );
  };

  const renderToggles = () => {
    if (!modalType || !feedbackToggles) return null;
    const options = feedbackToggles[modalType] || [];
    return options.map(({ label, field }) => (
      <TouchableView
        key={field}
        row
        align="center"
        style={{ gap: 8 }}
        onPress={() => handleCheckboxChange(field)}
      >
        <ToggleSwitch
          isOn={!!feedbackPayload[field]}
          handleOnPress={() => handleCheckboxChange(field)}
        />
        <TextDS variant="bodySm">{label}</TextDS>
      </TouchableView>
    ));
  };

  const content = (
    <>
      <View style={{ position: 'relative' }} row>
        <View>
          <TextDS variant="headingLg" style={{ maxWidth: 310, marginBottom: 8 }}>
            {feedbackTitle}
          </TextDS>
          <TextDS colorRole="textSubtle" variant="bodySm" style={{ marginBottom: 12 }}>
            {feedbackSubTitle}
          </TextDS>
        </View>

        <CloseButton
          dataQa="closeButton"
          Icon={<XMarkLarge size="major" />}
          onPress={() => handleClose(true)}
        />
      </View>

      {!modalType && (
        <>
          <View row style={{ gap: 12 }}>
            {feedbackTypes.map((chip) => (
              <Chip
                size="medium"
                variant="secondary"
                label={chip.label}
                onPress={() => handleChipPress(chip.value)}
                isSelected={modalType === chip.value}
                dataQa={chip.dataQa}
              />
            ))}
          </View>
          {secondaryButtonLabel && onSecondaryButtonPress && (
            <Button
              variant="tertiary"
              onPress={onSecondaryButtonPress}
              text={secondaryButtonLabel}
              style={{ alignSelf: 'center', marginTop: getSpacing('space200') }}
            />
          )}
        </>
      )}
      {renderCheckboxes()}

      {modalType && (
        <>
          <TextDS variant="bodySm">{textAreaTitles[modalType]}</TextDS>
          <TextAreaRounded
            value={freeText}
            onChangeText={(value) => setFreeText(value)}
            dataQa="additionalFeedbackTextArea"
          />
        </>
      )}

      {renderToggles()}
    </>
  );

  const buttons = modalType ? (
    <>
      <Button
        text="Dismiss"
        sizeDS={isMobile ? 'medium' : 'slim'}
        variant="tertiary"
        onPress={() => handleClose(true)}
        dataQa="dismissButton"
      />
      <Button
        text="Submit Feedback"
        sizeDS={isMobile ? 'medium' : 'slim'}
        onPress={handleSubmit}
        dataQa="submitFeedbackButton"
      />
    </>
  ) : null;

  if (isMobile) {
    return (
      <AnimatedSwipeDownModal
        shouldDisplayCloseButton={false}
        isOpen={isOpen}
        onClose={() => handleClose(true)}
        containerStyles={{ padding: 20 }}
      >
        <MainViewMobile>
          {content}
          <ButtonsViewMobile>{buttons}</ButtonsViewMobile>
        </MainViewMobile>
      </AnimatedSwipeDownModal>
    );
  }

  return (
    <Modal onBackdropPress={() => handleClose(true)} isVisible={isOpen}>
      <MainViewDesktop>
        {content}
        <ButtonsViewDesktop>{buttons}</ButtonsViewDesktop>
      </MainViewDesktop>
    </Modal>
  );
}
