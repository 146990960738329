import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

function GreenCircleCheckMarkV2({ width = 67, height = 68, color, ...otherProps }: Props) {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'success';

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 67 68"
      fill="none"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.147 14.14C18.115-.415 38.807-3.323 53.362 7.645c14.555 10.968 17.463 31.66 6.495 46.215-10.968 14.555-31.66 17.463-46.215 6.495C-.913 49.387-3.821 28.695 7.147 14.14zm43.808-3.3C38.163 1.2 19.98 3.755 10.342 16.546.702 29.338 3.258 47.522 16.049 57.16c12.791 9.64 30.974 7.084 40.613-5.707 9.64-12.792 7.084-30.975-5.707-40.614z"
        fill={color || colorRoles.icons.iconSuccessDefault}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.487 20.495A2.5 2.5 0 0151 23.993L30.643 51.331 16.718 37.258a2.5 2.5 0 013.554-3.517l9.835 9.938L46.99 21.007a2.5 2.5 0 013.498-.512z"
        fill={color || colorRoles.icons.iconSuccessDefault}
      />
    </Svg>
  );
}

export default GreenCircleCheckMarkV2;
