import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { View, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '../core/styled';
import { webOnlyStyle } from '../core/styled/styleHelpers';

interface ArrowLeftProps {
  color?: string;
  width?: number;
  height?: number;
  hoverColor?: string;
  hasHoverStyles?: boolean;
  style?: {};
}

const StyledSVG = styled(Svg, {
  shouldForwardProp: (prop) => !['hoverColor', 'hasHoverStyles'].includes(prop),
})<{ hoverColor: string; hasHoverStyles?: boolean }>(({ hoverColor, hasHoverStyles }) => {
  if (!hasHoverStyles) return {};
  return {
    ...webOnlyStyle({
      display: 'block',
      '&:hover': {
        fill: hoverColor,
      },
    }),
  };
});

const ArrowLeft: FunctionComponent<ArrowLeftProps> = ({
  hasHoverStyles,
  hoverColor,
  color,
  width = 10,
  height = 16,
  useNewNav,
  ...otherProps
}: {
  hasHoverStyles?: boolean;
  hoverColor?: string;
  color?: string;
  width?: number;
  height?: number;
  useNewNav?: boolean;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'left arrow';
  return (
    <View style={{ height, width }}>
      <StyledSVG
        height="100%"
        width="100%"
        viewBox="0 0 10 16"
        title={titleText}
        aria-label={titleText}
        fill={color || colors.extraLightGrey}
        hoverColor={hoverColor || colors.green}
        hasHoverStyles={hasHoverStyles}
        {...otherProps}
      >
        <Path
          d="M8.64 14.63a1.194 1.194 0 01-1.726.002L0 7.501 6.914.369A1.191 1.191 0 018.639.37a1.281 1.281 0 01-.002 1.785L3.456 7.501l5.18 5.344c.48.494.484 1.29.003 1.785z"
          fillRule="evenodd"
        />
      </StyledSVG>
    </View>
  );
};

export default ArrowLeft;
