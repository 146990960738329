import { useEffect } from 'react';
import { Button, StickyDrawer, TextDS, View } from '@talkspace/react-toolkit';
import { Sprout } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import useQueryTherapistInfo from '@/hooks/useQueryTherapistInfo';
import { trackEvent } from '@/utils/analytics/eventTracker';

interface Props {
  roomID: string;
  onContinuePress: () => void;
}

export default function PreSessionSurvey({ roomID, onContinuePress }: Props) {
  const { data: therapistInfo } = useQueryTherapistInfo({ roomID });

  useEffect(() => {
    trackEvent('View pre-session assessment screen', { roomID }, ['mixpanel']);
  }, [roomID]);

  return (
    <>
      <View align="center" style={{ padding: 44, paddingTop: 32 }}>
        <View align="center" style={{ maxWidth: 375, gap: 24 }}>
          <Sprout />

          <View align="center" style={{ gap: 12 }}>
            <TextDS variant="heading2xl" style={{ textAlign: 'center' }}>
              Welcome to your first session with {therapistInfo?.firstName}
            </TextDS>
            <TextDS colorRole="textSubtle" style={{ textAlign: 'center' }}>
              Before you get started, let’s check in on how you’re feeling. This will only take a
              few minutes and will help you track your progress over time.
            </TextDS>
          </View>
        </View>

        <StickyDrawer noBorder>
          <Button
            text="Continue"
            onPress={onContinuePress}
            dataQa="preSessionSurveyContinue"
            fixedWidth
            sizeDS="large"
          />
        </StickyDrawer>
      </View>
    </>
  );
}
