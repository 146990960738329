import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M12 2.39648C11.4469 2.39648 11 2.84336 11 3.39648C11 3.94961 11.4469 4.39648 12 4.39648H14.5844L8.29375 10.6902C7.90312 11.0809 7.90312 11.7152 8.29375 12.1059C8.68437 12.4965 9.31875 12.4965 9.70937 12.1059L16 5.81211V8.39648C16 8.94961 16.4469 9.39648 17 9.39648C17.5531 9.39648 18 8.94961 18 8.39648V3.39648C18 2.84336 17.5531 2.39648 17 2.39648H12ZM4.5 3.39648C3.11875 3.39648 2 4.51523 2 5.89648V15.8965C2 17.2777 3.11875 18.3965 4.5 18.3965H14.5C15.8813 18.3965 17 17.2777 17 15.8965V12.3965C17 11.8434 16.5531 11.3965 16 11.3965C15.4469 11.3965 15 11.8434 15 12.3965V15.8965C15 16.1715 14.775 16.3965 14.5 16.3965H4.5C4.225 16.3965 4 16.1715 4 15.8965V5.89648C4 5.62148 4.225 5.39648 4.5 5.39648H8C8.55312 5.39648 9 4.94961 9 4.39648C9 3.84336 8.55312 3.39648 8 3.39648H4.5Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const ArrowUpRightFromSquare = withDSIconMaker(pathConfig, 'arrow up right from square');

export default ArrowUpRightFromSquare;
