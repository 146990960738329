import { EmotionThemeProvider, FeedbackModal } from '@talkspace/react-toolkit';
import useTalktrackFeedbackReminder from 'ts-frontend/hooks/useTalktrackFeedbackReminder';

import { getUserData } from '../../auth/helpers/token';
import {
  feedbackTypes,
  feedbackCheckboxes,
  textAreaTitles,
  feedbackToggles,
} from './utils/feedbackConfig';

const FeedbackModalSection = () => {
  const userID = getUserData().id;

  const {
    showTalktrackReminder,
    dismissTalktrackReminder,
    handleSubmitTalktrackFeedback,
    handleDidntListen,
  } = useTalktrackFeedbackReminder('client', userID);

  return (
    <EmotionThemeProvider version="2.0.0">
      <FeedbackModal
        isOpen={showTalktrackReminder}
        onClose={dismissTalktrackReminder}
        onSubmit={handleSubmitTalktrackFeedback}
        title="What did you think of your Talkcast episode?"
        subtitle="Your feedback helps us improve"
        feedbackTypes={feedbackTypes}
        feedbackCheckboxes={feedbackCheckboxes}
        textAreaTitles={textAreaTitles}
        feedbackToggles={feedbackToggles}
        secondaryButtonLabel="I didn't listen to it"
        onSecondaryButtonPress={handleDidntListen}
      />
    </EmotionThemeProvider>
  );
};

export default FeedbackModalSection;
