import { FunctionComponent, useEffect, useRef } from 'react';
import { useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import ActionStatus from '@/components/ActionStatus';
import styled from '@/core/styled';
import useMentalHealth from '../hooks/useTeenMentalHealth';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';
import { TeenMentalHealthWizardState } from '../reducers/teenMentalHealthWizardState';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const SubmitMentalHealth: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const [{ error, isSuccess, isLoading }, { submitIntakeResults }] = useMentalHealth();
  const requestSent = useRef(false);
  const { colors } = useEmotionTheme();

  const {
    roomID,
    clientUserID,
    presentingProblems,
    sleepQuality,
    previousMentalHealthIssues,
    hospitalizationHistory,
    selectedTraumaticExperience,
    selectedTraumaticFlashbacks,
    selectedGuilt,
    selectedIsolation,
    suicideIdeation,
    suicidePlanning,
    homicidalIdeation,
    homicidalPlanning,
    selectedAngerAggression,
    experiencingSchoolCommunity,
    experiencingSocialMedia,
    controlledSubstances,
  } = useWizardState<TeenMentalHealthWizardState>();

  const { onExitClick } = useWizardActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const payload = {
    presentingProblems,
    sleepQuality,
    previousMentalHealthIssues: previousMentalHealthIssues || [],
    hospitalizationHistory,
    traumaticExperience: !!selectedTraumaticExperience,
    traumaticFlashbacks: selectedTraumaticFlashbacks,
    guilt: selectedGuilt,
    isolation: selectedIsolation,
    suicideIdeation,
    suicidePlanning,
    homicidalIdeation,
    homicidalPlanning,
    angerAggression: !!selectedAngerAggression,
    experiencingSchoolCommunity,
    experiencingSocialMedia,
    controlledSubstances: controlledSubstances || [],
    isOnboarding,
  };

  useEffect(() => {
    if (!requestSent.current && roomID && payload) {
      requestSent.current = true;
      submitIntakeResults(roomID, clientUserID, payload);
    }
  }, [clientUserID, payload, roomID, submitIntakeResults]);

  const { data: treatmentIntakeInOnboardingActive } = useTSAdminConfig(
    AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING
  );

  useEffect(() => {
    if (isSuccess) {
      if (treatmentIntakeInOnboardingActive && isOnboarding) {
        history.push(`/room/${roomID}/onboarding/next`);
      } else {
        history.push(`/treatment-intake-launcher/room/${roomID}/source/chat`, { initStep: 1 });
      }
    }
  }, [treatmentIntakeInOnboardingActive, history, isSuccess, roomID, isOnboarding]);

  return isLoading || error ? (
    <Wrapper>
      <ActionStatus
        isLoading={isLoading}
        isError={!!error}
        errorTitle="Something Went Wrong"
        errorSubTitle="Please check your internet connection and try again."
        errorButtonText="Close"
        errorButtonAction={onExitClick}
        titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
        subTitleStyles={{
          fontSize: 16,
          color: colors.darkGray,
          marginTop: 13,
          marginBottom: 42,
          width: 200,
          textAlign: 'center',
        }}
      />
    </Wrapper>
  ) : null;
};

export default SubmitMentalHealth;
