import { Route } from '../core/routerLib';
import SurveyWizardAction from './components/SurveyWizardAction';
import SchedulerAction from './components/SchedulerAction';
import SuggestSwitchTherapist from './components/SuggestSwitchTherapist';
import KeepMessagingModal from './components/KeepMessagingModal';
import InformedConsentAction from '../components/InformedConsentAction';
import TalktrackInfo from './components/Talktrack';

export default function ModalRoutes() {
  return (
    <>
      <Route path="/survey" component={SurveyWizardAction} />
      <Route path="/suggest-switch-therapist" component={SuggestSwitchTherapist} />
      <Route path="/scheduler" component={SchedulerAction} />
      <Route path="/keep-messaging" component={KeepMessagingModal} />
      <Route
        path={['/informed-consent/room/:roomID', '/informed-consent']}
        component={InformedConsentAction}
      />
      <Route path="/talktracks-info/room/:roomID" component={TalktrackInfo} />
    </>
  );
}
