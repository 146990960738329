import React from 'react';
import styled from '@emotion/styled';
import { View, TextDS } from '../../../components';
import { EmotionTheme } from '../../../hooks/EmotionThemeProvider';
import { useEmotionTheme } from '../../../index.next';
import { fontWeight } from '../../tokens';

/* 
  When isSectioned is true, numberOfSections should be between 2 and 4. 
  Redesign would be required should there ever be more than 4 sections.
*/

export type allowableNumberOfSections = 1 | 2 | 3 | 4;
export type colorsOverride = {
  unreachedStepsColor: string;
  completedAndLastStepColor: string;
  inProgressStepColor: string;
};
export interface ProgressBarV2Props {
  numberOfSections: allowableNumberOfSections;
  sectionLabels: string[];
  stepsPerSection: number[];
  currentStep: number;
  currentSection: number;
  isSectioned?: boolean;
  barHeight?: 'default' | 'tall';
  defaultColorsOverride?: colorsOverride;
}

const ProgressBarWrapper = styled(View)<{ maxWidth?: string; theme: EmotionTheme }>(
  ({ theme: { spacing, window } }) => {
    const { isMobile } = window;
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: isMobile ? '90vw' : 440, // maxWidth for large screens following ~440px maxWidth in QuickMatch Step Container, full width for small screens
      margin: isMobile
        ? `${spacing('space200')}px ${spacing('space200')}px 0 ${spacing('space200')}px`
        : `${spacing('space200')}px auto 0`,
    };
  }
);

const SectionsWrapper = styled(View)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
  };
});

const SectionWrapper = styled(View)<{
  numberOfSections?: allowableNumberOfSections;
  theme: EmotionTheme;
}>(({ numberOfSections, theme: { spacing } }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: numberOfSections ? `${100 / numberOfSections}%` : `33.33%`,
    maxWidth: numberOfSections
      ? `calc(${100 / numberOfSections}% - ${Number(spacing('space200')) / numberOfSections}px)`
      : `33.33%`,
    margin: spacing('space050'),
  };
});

const SimpleProgressBar = styled(View)<{
  isCompleted: boolean;
  barHeight: 'default' | 'tall';
  defaultColorsOverride?: colorsOverride;
  theme: EmotionTheme;
}>(({ isCompleted, barHeight, defaultColorsOverride, theme: { spacing, colorRoles } }) => {
  return {
    width: '100%',
    height: barHeight === 'tall' ? spacing('space150') : spacing('space100'),
    borderRadius: barHeight === 'tall' ? spacing('space150') : spacing('space050'),
    backgroundColor: isCompleted
      ? defaultColorsOverride?.completedAndLastStepColor || colorRoles.surfaces.brandBoldDefault
      : defaultColorsOverride?.unreachedStepsColor || colorRoles.surfaces.neutralPressed,
    overflow: 'hidden',
    position: 'relative',
    marginTop: spacing('space100'),
  };
});

const Progress = styled(View)<{
  progress: number;
  isLastStep?: boolean;
  barHeight: 'default' | 'tall';
  defaultColorsOverride?: colorsOverride;
  theme: EmotionTheme;
}>(({ progress, isLastStep, barHeight, defaultColorsOverride, theme: { spacing, colorRoles } }) => {
  return {
    width: `${progress}%`,
    height: '100%',
    borderRadius: barHeight === 'tall' ? spacing('space150') : spacing('space050'),
    backgroundColor: isLastStep
      ? defaultColorsOverride?.completedAndLastStepColor || colorRoles.surfaces.brandBoldDefault
      : defaultColorsOverride?.inProgressStepColor || colorRoles.surfaces.interactiveBoldDefault,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'width 0.5s ease-in-out',
  };
});

const ProgressBarV2: React.FC<ProgressBarV2Props> = ({
  numberOfSections,
  sectionLabels,
  stepsPerSection,
  currentStep,
  currentSection,
  isSectioned = false,
  barHeight = 'default',
  defaultColorsOverride = undefined,
}) => {
  const { colorRoles } = useEmotionTheme();

  if (!isSectioned) {
    const totalSteps = stepsPerSection[0];
    const progress = (currentStep / totalSteps) * 100;
    const isLastStep = currentStep === totalSteps;

    return (
      <ProgressBarWrapper tabIndex={0} aria-label="Progress bar">
        <SimpleProgressBar
          isCompleted={false}
          barHeight={barHeight}
          defaultColorsOverride={defaultColorsOverride}
        >
          <Progress
            progress={progress}
            barHeight={barHeight}
            isLastStep={isLastStep}
            defaultColorsOverride={defaultColorsOverride}
            aria-label={`Current progress: step ${currentStep} of ${totalSteps}`}
            tabIndex={0}
          />
        </SimpleProgressBar>
      </ProgressBarWrapper>
    );
  }

  return (
    <ProgressBarWrapper tabIndex={0} aria-label="Progress bar">
      <SectionsWrapper>
        {Array.from({ length: numberOfSections }).map((_, sectionIndex) => {
          const sectionSteps = stepsPerSection[sectionIndex];
          const sectionProgress =
            ((currentSection - 1 === sectionIndex ? currentStep : sectionSteps) / sectionSteps) *
            100;
          const isCompleted = sectionIndex < currentSection - 1;
          const isCurrent = sectionIndex === currentSection - 1;

          return (
            <SectionWrapper
              key={`section-${sectionLabels[sectionIndex]}`}
              numberOfSections={numberOfSections}
            >
              <TextDS
                variant="headingXs"
                aria-level={2}
                aria-label={`Section ${sectionIndex + 1}: ${sectionLabels[sectionIndex]}`}
                tabIndex={0}
                style={{
                  color: isCurrent
                    ? defaultColorsOverride?.inProgressStepColor ||
                      colorRoles.typography.textInteractiveDefault
                    : defaultColorsOverride?.unreachedStepsColor ||
                      colorRoles.typography.textDefaultDisabled,
                  fontWeight: isCurrent ? fontWeight.bold : fontWeight.regular,
                  alignSelf: 'flex-start',
                  letterSpacing: '0.0075em',
                }}
              >
                {sectionLabels[sectionIndex]}
              </TextDS>
              <SimpleProgressBar
                isCompleted={isCompleted}
                barHeight={barHeight}
                defaultColorsOverride={defaultColorsOverride}
              >
                {isCurrent && (
                  <Progress
                    progress={sectionProgress}
                    barHeight={barHeight}
                    defaultColorsOverride={defaultColorsOverride}
                    aria-valuenow={sectionProgress}
                    aria-valuemin={0}
                    aria-valuemax={sectionSteps}
                    aria-label={`Current step ${currentStep} of ${sectionSteps} in section ${
                      sectionIndex + 1
                    }`}
                    tabIndex={0}
                  />
                )}
              </SimpleProgressBar>
            </SectionWrapper>
          );
        })}
      </SectionsWrapper>
    </ProgressBarWrapper>
  );
};

export default ProgressBarV2;
