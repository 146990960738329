import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import ParentalConsent from '../assets/ParentalConsent';

const OnboardingPageTeenEmergencyContact = ({
  match: {
    params: { roomID },
  },
  totalSteps,
  currentStep,
  history,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => {
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<ParentalConsent />}
      title={tTeenOnboarding(
        'letsCompleteProfile.title',
        'Help your provider get to know you',
        undefined
      )}
      description={tTeenOnboarding(
        'letsCompleteProfile.description',
        'We’ll ask some required questions so you can meet your provider',
        undefined
      )}
      ctaText={tTeenOnboarding('letsCompleteProfile.ctaText', 'Get Started', undefined)}
      onPress={() => {
        history.push(`/room/${roomID}/onboarding/teen-emergency-contact/source/onboarding`);
      }}
    />
  );
};

export default withRouter(OnboardingPageTeenEmergencyContact);
