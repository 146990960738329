import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';
import OptionBlock from './OptionBlock';
import OptionBlockV1 from './OptionBlockV1';
import OptionBlockV2 from './OptionBlockV2';

export type { OptionBlockProps } from './OptionBlock';

const versionMap: VersionMap<React.ComponentProps<typeof OptionBlock>> = {
  '2.0.0': OptionBlockV2,
  '1.0.0': OptionBlockV1,
  DefaultComponent: OptionBlock,
};

// TODO: @LuisRizo - Make withVersioning's TypeScript support OptionBlock<SessionType>. Temporary solution: casting
export default withVersioning(versionMap) as typeof OptionBlock;
