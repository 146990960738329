import { EmotionStyle } from '../core/styled';
import { AlignType, DirectionType, JustifyType } from '../types';
import { isApple } from './deviceUtils';

export const getButtonStyles = (
  colors: {
    activeColors?: {
      color?: string;
      backgroundColor?: string;
    };
    hoverColors?: {
      color?: string;
      backgroundColor?: string;
    };
  },
  buttonState: {
    isSelected?: boolean;
    isHovering?: boolean;
    isClicked?: boolean;
    removeTransition?: boolean;
  }
) => {
  // Video of intended behavior:
  // https://app.vidgrid.com/view/89u1PYqKil6l

  // If `isSelected` is true, always show active styles,
  // otherwise use the CSS pseudo-class and let it happen natively
  const activeStyles = colors.activeColors && {
    ...(buttonState.isSelected
      ? {
          color: colors.activeColors?.color,
          backgroundColor: colors.activeColors?.backgroundColor,
          transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
        }
      : {
          '&:active': {
            color: colors.activeColors?.color,
            backgroundColor: colors.activeColors?.backgroundColor,
            transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
          },
        }),
  };

  const hoverStyles = !buttonState.isSelected &&
    !buttonState.isClicked && {
      color: colors.hoverColors?.color,
      backgroundColor: colors.hoverColors?.backgroundColor,
    };

  return {
    // Apple touch devices's `:active` triggers too early (on hover)
    // Put ':active' styles before ':focus' on Android touch devices only
    ...(isApple() && activeStyles),
    // Helps showing focus when the user focuses with keyboard
    '&:focus': {
      ...hoverStyles,
    },
    // Adds styles when user is hovering or clicking on the element
    ...(buttonState.isHovering &&
      colors.hoverColors && {
        '&:hover': {
          ...hoverStyles,
        },
      }),
    ...(!isApple() && activeStyles),
  };
};

export const prependFlex = (str: JustifyType | AlignType): string =>
  str === 'start' || str === 'end' ? `flex-${str}` : str;

export const getFlexDirection = (row?: boolean, reverse?: boolean): DirectionType => {
  if (row) {
    if (reverse) {
      return 'row-reverse';
    }
    return 'row';
  }
  if (reverse) {
    return 'column-reverse';
  }
  return 'column';
};

export const truncateTextMaxLinesStyle = (maxLines: number): EmotionStyle => {
  return {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: `${maxLines}`,
    WebkitBoxOrient: 'vertical',
  };
};

// Expands on getButtonStyles to include border color and disabled, focus styles for DS 2.0 SelectList
export const getSelectListStyles = (
  colorRoles,
  buttonState: {
    isSelected?: boolean;
    isHovering?: boolean;
    isDisabled?: boolean;
    removeTransition?: boolean;
  }
) => {
  // If `isSelected` is true, always show selected (:active) styles,
  // otherwise use the CSS pseudo-class and let it happen natively
  const selectedStyles = {
    ...(buttonState.isSelected
      ? {
          color: colorRoles.typography.textInteractiveDefault,
          backgroundColor: colorRoles.surfaces.surfaceInteractiveSelected,
          border: `2px solid ${colorRoles.borders.borderInteractiveSelectedBold}`,
          transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
        }
      : {
          '&:active': {
            color: colorRoles.typography.textInteractiveDefault,
            backgroundColor: colorRoles.surfaces.surfaceInteractiveSelected,
            border: `2px solid ${colorRoles.borders.borderInteractiveSelectedBold}`,
            transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
          },
        }),
  };

  const disabledStyles = {
    ...(buttonState.isDisabled
      ? {
          color: colorRoles.typography.textDefaultDisabled,
          backgroundColor: colorRoles.surfaces.surfaceInteractiveDisabledSubtle,
          border: `2px solid ${colorRoles.borders.borderSubtleDefault}`,
          transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
        }
      : {
          '&:disabled': {
            color: colorRoles.typography.textDefaultDisabled,
            backgroundColor: colorRoles.surfaces.surfaceInteractiveDisabledSubtle,
            border: `2px solid ${colorRoles.borders.borderSubtleDefault}`,
            transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
          },
        }),
  };

  const pressedStyles = {
    color: colorRoles.typography.textInteractivePressed,
    backgroundColor: colorRoles.surfaces.surfaceInteractivePressed,
    border: `1px solid ${colorRoles.borders.interactiveBoldPressed}`,
    transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
  };

  const hoverStyles = !buttonState.isSelected &&
    !buttonState.isDisabled && {
      color: colorRoles.typography.textInteractiveHovered,
      backgroundColor: colorRoles.surfaces.surfaceInteractiveHovered,
      border: `1px solid ${colorRoles.borders.interactiveBoldHovered}`,
    };

  const focusStyles = !buttonState.isSelected &&
    !buttonState.isDisabled && {
      color: colorRoles.typography.textSubtle,
      backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
      borderRadius: '12px',
      outline: `3px auto ${colorRoles.borders.borderFocusedOuterDefault}`,
      outlineOffset: 3,
      zIndex: 1,
    };

  return {
    ...(!buttonState.isDisabled && {
      // Apple touch devices's `:active` triggers too early (on hover)
      // Put ':active' styles before ':focus' on Android touch devices only
      ...(!isApple() && selectedStyles),
      // Helps showing focus when the user focuses with keyboard
      '&:focus': {
        ...focusStyles,
      },
      '&:active:hover': {
        ...pressedStyles,
      },
    }),
    ...(buttonState.isDisabled && {
      '&:disabled': {
        ...disabledStyles,
      },
      ...disabledStyles,
    }),
    // Adds styles when user is hovering or clicking on the element
    ...(buttonState.isHovering && {
      '&:hover': {
        ...hoverStyles,
      },
    }),
  };
};
