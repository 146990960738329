import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import SelectList, { SelectListProps, OptionType } from './SelectList';
import SimpleQuestionsV2, { SimpleQuestionsV2Props } from './SimpleQuestionsV2';

export type { SelectListProps, SimpleQuestionsV2Props, OptionType };

const versionMap: VersionMap<React.ComponentProps<typeof SelectList | typeof SimpleQuestionsV2>> = {
  '2.0.0': SelectList,
  '0.0.0': SimpleQuestionsV2,
  DefaultComponent: SelectList,
};

export default withVersioning(versionMap);
