import SocketService from '@/utils/socket/SocketService';

const POLLING_INTERVAL = 3000;

export default class MessagesSocketService {
  roomID: number;

  pollingInterval: number | undefined;

  registerRoomInitTimeout: number | undefined;

  requestMessages: Function;

  resetChat: Function;

  io: SocketService;

  isMounted = false;

  constructor(requestMessages: Function, roomID: number, resetChat: Function) {
    this.roomID = roomID;
    this.resetChat = resetChat;
    this.requestMessages = requestMessages;
    this.io = SocketService.instance();
    this.socketInit();
  }

  private socketInit = () => {
    this.isMounted = true;
    this.io.on('newMessage', this.getMessages);
    this.io.on('refreshChatMessages', this.refreshChatMessages);
    this.io.addStateListener(this.socketStateChange);
  };

  private startPolling = (): void => {
    if (this.pollingInterval) return;
    this.pollingInterval = window.setInterval(() => {
      this.getMessages();
    }, POLLING_INTERVAL);
  };

  private stopPolling = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = undefined;
    // make a last call incase a message was entered during the register room
    if (this.isMounted) this.getMessages();
  };

  private socketRegisterRoomInit = () => {
    if (this.registerRoomInitTimeout) {
      clearTimeout(this.registerRoomInitTimeout);
      this.registerRoomInitTimeout = undefined;
    }

    this.registerRoomEvents()
      .then(() => {
        this.stopPolling();
      })
      .catch(() => {
        if (this.isMounted)
          this.registerRoomInitTimeout = window.setTimeout(() => {
            this.socketRegisterRoomInit();
          }, 2000);
      });
  };

  private socketStateChange = (state: boolean) => {
    if (state) this.socketRegisterRoomInit();
    else this.startPolling();
  };

  private refreshChatMessages = (data?) => {
    if (!this.roomID) return;
    this.resetChat(this.roomID);
  };

  private getMessages = (data?) => {
    if (data && data.roomId !== this.roomID) return;
    this.requestMessages(this.roomID, data);
  };

  public unmount = (shouldUnregister = true) => {
    this.isMounted = false;
    this.stopPolling();
    if (shouldUnregister) this.unregisterRoomEvents().catch(() => undefined);
    this.io.off('newMessage', this.getMessages);
    this.io.off('refreshChatMessages', this.refreshChatMessages);

    SocketService.instance().removeStateListener(this.socketStateChange);
  };

  private registerRoomEvents = (): Promise<unknown> =>
    SocketService.instance().emit('registerRoom', {
      roomId: this.roomID,
    });

  private unregisterRoomEvents = (): Promise<unknown> =>
    SocketService.instance().emit('unregisterRoom', {
      roomId: this.roomID,
    });
}
