import { useRef, useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import {
  View,
  TouchableView,
  Big,
  Small,
  Large,
  Spinner,
  CalendarIcon,
  LiveSessionIcon,
  ThreeDots,
  ArrowRight,
  useEmotionTheme,
  useUniqueID,
  HiddenText,
  StackBubble,
} from '@talkspace/react-toolkit';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { RoomStatus } from 'ts-frontend/entities/Room';
import moment from 'moment-timezone';
import { Subscription, Booking, SessionModality } from 'ts-frontend/types';
import redirectToLiveSession from 'chat/utils/redirectToLiveSession';
import styled, { EmotionStyle } from '@/core/styled';
import useLVSContent from '../../hooks/useLVSContent';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../hooks/inRoomSchedulingContext';

type ActionIconType = 'arrow' | 'dots';

const getCardTitle = ({
  modality,
  defaultTitle,
}: {
  modality?: SessionModality;
  defaultTitle: string;
}): string => {
  switch (modality) {
    case 'video':
      return 'Live Video';
    case 'audio':
      return 'Live Audio';
    case 'chat':
      return 'Live Chat';
    case 'messaging':
      return 'Messaging Session';
    default:
      return defaultTitle;
  }
};

const Wrapper = styled(TouchableView)<{
  isDisabled?: boolean;
  isInDashboard?: boolean;
  hasNoBookings?: boolean;
}>(({ isDisabled, isInDashboard, hasNoBookings, theme: { colors } }) => {
  return {
    minHeight: 73,
    backgroundColor: isDisabled || hasNoBookings ? colors.permaLinkWaterGrey : colors.white,
    borderRadius: 10,
    paddingLeft: 17,
    paddingRight: 16,
    paddingTop: isDisabled || hasNoBookings ? 15 : 5,
    paddingBottom: isDisabled || hasNoBookings ? 15 : undefined,
    cursor: isInDashboard ? 'pointer' : 'default',
  };
});

const SchedulingDateOrMessage = ({
  modality,
  startDatetime,
  minuteDuration = 30,
  message,
  setAriaLabelledBy,
  onPress,
}: {
  modality?: SessionModality;
  startDatetime?: string;
  minuteDuration?: number;
  message: string | JSX.Element;
  setAriaLabelledBy: Dispatch<SetStateAction<string>>;
  onPress?: () => void;
}) => {
  const hiddenTextId = useUniqueID('hiddenTextId');
  const dayId = useUniqueID('dayId');
  const timeId = useUniqueID('timeId');
  const defaultTextId = useUniqueID('defaultTextId');

  useEffect(() => {
    setAriaLabelledBy(startDatetime ? `${hiddenTextId} ${dayId} ${timeId}` : defaultTextId);
  }, [dayId, defaultTextId, hiddenTextId, setAriaLabelledBy, startDatetime, timeId]);

  if (!startDatetime) {
    return (
      <TouchableView onPress={onPress} dataQa="inRoomSchedulingCardLiveSessions">
        <View row justify="space-between" align="center">
          <Big id={defaultTextId} variant="bigDarkGrey" style={{ marginBottom: 2 }}>
            Live sessions
          </Big>
        </View>
        {message}
      </TouchableView>
    );
  }
  const startAt = moment(startDatetime);
  const endAt = startAt.clone().add(minuteDuration, 'minutes');
  const day = startAt.format('dddd, MMM D');
  const startTime = startAt.format('h:mmA');
  const endTime = endAt.format('h:mmA');

  return (
    <TouchableView
      row
      justify="space-between"
      align="center"
      onPress={onPress}
      dataQa="inRoomSchedulingCardLiveSessionsWithTime"
    >
      <View>
        <HiddenText id={hiddenTextId}>Live session scheduled for</HiddenText>
        <Big style={{ marginBottom: 1 }} id={dayId}>
          {getCardTitle({ modality, defaultTitle: day })}
        </Big>
        <Large id={timeId} variant="largeDarkGrey">
          {modality === 'messaging'
            ? ` Started on ${moment(startDatetime).format('ddd, MMM D')}`
            : `${moment(startDatetime).format('ddd, MMM D')}, ${startTime} – ${endTime}`}
        </Large>
      </View>
    </TouchableView>
  );
};

const DisabledMessage = ({
  isError,
  LVSIconSize,
  lvsMessageID,
  message,
}: {
  isError?: boolean;
  LVSIconSize: number;
  lvsMessageID?: string;
  message: string | JSX.Element;
}) => (
  <View
    id={lvsMessageID}
    alignSelf="stretch"
    justify="center"
    style={{ width: 200, height: LVSIconSize - 10 }}
  >
    <Big variant="bigMedium">{isError ? 'Unable to load content' : message}</Big>
  </View>
);

const getStatusText = (therapistFirstName?: string, booking?: Booking): string => {
  if (!booking || !therapistFirstName) return '';
  let statusText;
  if (booking.modality === 'messaging' && booking.status === 'active') {
    statusText = 'Session in progress';
  } else if (booking.status === 'active') {
    switch (booking.timekitBookingState) {
      case 'tentative': {
        // pending
        statusText =
          booking.scheduledByUserType === 'client'
            ? 'Awaiting confirmation...'
            : 'Awaiting your confirmation';
        break;
      }
      case 'confirmed': {
        // confirmed
        statusText =
          booking.scheduledByUserType === 'client'
            ? `Confirmed by ${therapistFirstName}`
            : 'Confirmed';
        break;
      }
      default: {
        // declined
        statusText =
          booking.scheduledByUserType === 'client'
            ? `${therapistFirstName} has declined`
            : "You've declined this session";
      }
    }
  } else if (booking.timekitBookingState === 'declined') {
    // declined
    statusText =
      booking.scheduledByUserType === 'client'
        ? `${therapistFirstName} has declined`
        : "You've declined this session";
  } else {
    // Was never confirmed
    statusText = 'Session cancelled';
  }
  return statusText;
};

interface InRoomSchedulingCardProps {
  style?: EmotionStyle;
  isDisabled?: boolean;
  LVSIconSize?: number;
  booking?: Booking;
  actionIconType?: ActionIconType;
  isError?: boolean;
  isLoading?: boolean;
  therapist?: ETherapistInfo;
  hasAnyCredit?: boolean;
  onPress?: () => void;
  onBubblePress?: (roomID: number) => void;
  lvsMessageID?: string;
  id?: string;
  isEAP?: boolean;
  ariaExpanded?: boolean;
  ariaControls?: string;
  onKebabPress?: () => void;
  isInDashboard?: boolean;
  hasNoBookings?: boolean;
  subscriptions?: Subscription[];
  roomType?: string;
  currentRoomID?: number;
  roomStatus?: number;
}

const InRoomSchedulingCard = ({
  style = {},
  onPress,
  onBubblePress,
  booking,
  LVSIconSize = 58,
  isError,
  isDisabled = false,
  hasAnyCredit = false,
  therapist,
  actionIconType,
  lvsMessageID,
  isLoading,
  id,
  ariaExpanded,
  ariaControls,
  isEAP = false,
  onKebabPress,
  isInDashboard,
  hasNoBookings,
  subscriptions,
  roomType,
  currentRoomID,
  roomStatus,
}: InRoomSchedulingCardProps) => {
  const { dispatchClientJoinLiveChat, dispatchGetActiveSession } = useInRoomSchedulingActions();
  const { activeSession } = useInRoomSchedulingState();
  const { startTime, creditMinutes } = booking || {};
  const { firstName } = therapist || {};
  const shouldShowConfirmationButton =
    booking?.scheduledByUserType === 'provider' && booking?.timekitBookingState === 'tentative';
  const statusText = getStatusText(firstName, booking);
  const fiveMinutesInMilliseconds = 300000;
  const tenMinutesInMilliseconds = 600000;
  const millisecondsToStartTime: number =
    booking?.modality === 'chat' ? fiveMinutesInMilliseconds : tenMinutesInMilliseconds;
  const [shouldShowJoinButton, setShouldShowJoinButton] = useState<boolean>(false);

  const intervalIDRef = useRef<number>();

  useEffect(() => {
    if (
      booking?.startTime &&
      booking?.creditMinutes &&
      booking?.timekitBookingState === 'confirmed'
    ) {
      intervalIDRef.current = window.setInterval(() => {
        const timeUntilStartTime =
          (booking?.startTime &&
            Math.floor(
              (new Date(booking.startTime).getTime() -
                new Date().getTime() -
                millisecondsToStartTime) /
                1000
            )) ||
          1;

        const timeUntilEndTime = +timeUntilStartTime + (booking?.creditMinutes || 0) * 60;

        if (timeUntilStartTime && timeUntilStartTime < 0 && timeUntilEndTime > 0) {
          setShouldShowJoinButton(true);
          if (intervalIDRef.current) clearInterval(intervalIDRef.current);
        }
      }, 30000);
    }
    return () => {
      if (intervalIDRef.current) clearInterval(intervalIDRef.current);
    };
  }, [booking, millisecondsToStartTime]);

  // allows for pre-existing texts to provide context for thee dots button
  const [ariaLabelledBy, setAriaLabelledBy] = useState('');
  const statusTextId = useUniqueID('statusTextId');
  const confirmTextId = useUniqueID('confirmTextId');
  let finalAriaLabelledBy = ariaLabelledBy;
  if (statusText) {
    finalAriaLabelledBy += ` ${statusText}`;
  } else if (shouldShowConfirmationButton) {
    finalAriaLabelledBy += ` ${confirmTextId}`;
  }

  const { colors } = useEmotionTheme();

  const { generateMessaging } = useLVSContent({
    subscriptions,
    onBubblePress,
    isEAP,
    hasAnyCredit,
    currentRoomID,
    roomType,
  });

  const { message, reminders } = generateMessaging();

  const buildModalityIconType = useCallback(
    (modality: SessionModality = 'video') => {
      const { patensBlue, periwinkleGrey, purple, white } = colors;
      if (isInDashboard) {
        return (
          <CalendarIcon
            aria-hidden="true"
            width={LVSIconSize}
            height={LVSIconSize}
            color={isDisabled ? periwinkleGrey : purple}
            iconColor={isDisabled ? white : patensBlue}
          />
        );
      }

      return (
        <LiveSessionIcon
          modality={modality}
          width={LVSIconSize}
          height={LVSIconSize}
          color={purple}
          iconColor={patensBlue}
        />
      );
    },
    [LVSIconSize, colors, isDisabled, isInDashboard]
  );

  const joinSessionAction = useCallback(
    (potentialActiveBooking?: Booking) => {
      if (
        potentialActiveBooking?.roomID &&
        potentialActiveBooking?.modality &&
        potentialActiveBooking?.modality !== 'messaging'
      ) {
        dispatchGetActiveSession(potentialActiveBooking.roomID, potentialActiveBooking.modality);
      }
    },
    [dispatchGetActiveSession]
  );

  useEffect(() => {
    if (activeSession && booking?.roomID) {
      if (activeSession.modality === 'chat') {
        dispatchClientJoinLiveChat(booking.roomID, activeSession.videoCallID);
      } else {
        const videoCallState = {
          therapistUserID: activeSession.therapist.userID,
          roomID: booking?.roomID,
          therapistFirstName: activeSession.therapist.therapistFirstName,
          therapistLastName: activeSession.therapist.therapistLastName,
          startTime: activeSession.booking.startTime,
          creditMinutes: activeSession.booking.creditMinutes,
          videoCreditType: activeSession.booking.type,
          videoCallID: activeSession.videoCallID,
          modality: activeSession.modality,
          tokenExpiresAt: moment(activeSession.booking.startTime)
            .add(activeSession.booking.creditMinutes || 30, 'minute')
            .toISOString(),
        };
        redirectToLiveSession(booking.roomID, videoCallState);
      }
    }
  }, [booking, activeSession, dispatchClientJoinLiveChat]);

  const ActionIcon =
    actionIconType === 'arrow'
      ? ArrowRight
      : () => {
          if (isDisabled) return null;
          return (
            <TouchableView
              aria-labelledby={finalAriaLabelledBy}
              align="center"
              justify="center"
              style={{ position: 'relative', left: 15, width: 45, height: 45 }}
              onPress={onKebabPress}
              dataQa="inRoomSchedulingCardKebabButton"
            >
              <ThreeDots aria-hidden="true" width={5} height={21} color={colors.heatherGrey} />
            </TouchableView>
          );
        };
  if (isLoading) {
    return (
      <Wrapper id={id} align="stretch" isDisabled row style={style} tabIndex={-1}>
        <View align="center" justify="center" flex={1} style={{ height: 70 }}>
          <Spinner isLoading primaryColor={colors.chineseSilver} />
        </View>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      isInDashboard={isInDashboard}
      // necessary to override default role="button" for TouchableView
      hasNoBookings={hasNoBookings}
      role={!isInDashboard ? 'none' : undefined}
      tabIndex={isInDashboard ? 0 : -1}
      aria-label={isInDashboard ? 'live sessions' : undefined}
      id={id}
      align="stretch"
      row
      style={style}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
    >
      <View style={{ position: 'relative' }}>
        {buildModalityIconType(booking?.modality)}
        {/* TODO: implemented when mobile implements */}
        {/* <ActiveDotIcon isActive={sessionHasStarted} /> */}
      </View>
      <View flex={1} style={{ marginLeft: 14 }}>
        {isDisabled ? (
          <DisabledMessage
            isError={isError}
            LVSIconSize={LVSIconSize}
            lvsMessageID={lvsMessageID}
            message={message}
          />
        ) : (
          <SchedulingDateOrMessage
            modality={booking?.modality}
            setAriaLabelledBy={setAriaLabelledBy}
            startDatetime={startTime}
            minuteDuration={creditMinutes}
            message={message}
            onPress={isInDashboard ? onPress : undefined}
          />
        )}
        {statusText && (
          <View row>
            <View>
              <Small
                id={statusTextId}
                style={{ color: colors.slateGrey, marginTop: 7, marginBottom: 2 }}
              >
                {statusText}
              </Small>
            </View>
            {/* <TherapistAndDefaultAvatars imageURL={imageURL} /> */}
          </View>
        )}
        {shouldShowConfirmationButton && !isInDashboard && (
          <>
            <HiddenText id={confirmTextId}>Confirmation needed</HiddenText>
            <TouchableView onPress={onPress} dataQa="inRoomSchedulingCardConfirmSession">
              <Big style={{ color: colors.green, lineHeight: '20px' }}>Confirm session</Big>
            </TouchableView>
          </>
        )}
        {shouldShowJoinButton && isInDashboard && (
          <>
            <HiddenText id={confirmTextId}>Confirmation needed</HiddenText>
            <TouchableView
              disabled={isLoading}
              onPress={() => joinSessionAction(booking)}
              dataQa="inRoomSchedulingCardJoinSession"
            >
              <Big style={{ color: colors.green, lineHeight: '20px' }}>Join session</Big>
            </TouchableView>
          </>
        )}
        {isInDashboard &&
          roomStatus &&
          ![RoomStatus.CLOSED, RoomStatus.CANCELED].includes(roomStatus) && (
            <StackBubble bubbleContainerStyle={{ marginTop: 7, width: '235px' }} data={reminders} />
          )}
      </View>
      <TouchableView
        onPress={isInDashboard ? onPress : undefined}
        dataQa="inRoomSchedulingCardActionIcon"
      >
        {actionIconType && <ActionIcon style={{ marginTop: 4 }} color={colors.periwinkleGrey} />}
      </TouchableView>
    </Wrapper>
  );
};

export default InRoomSchedulingCard;
