import { useCallback } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';

import { Booking } from 'ts-frontend/types';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { UserFeatureViews } from 'ts-frontend/hooks/featureViews/useQueryFeatureViews';
import {
  getLastMessageTimeDisplayString,
  getTherapistNameDisplayString,
} from 'ts-frontend/utils/displayStrings';
import { MessagePreviewAPI } from 'ts-frontend/entities/MessagePreview';
import { View, useWindowWidthState } from '@talkspace/react-toolkit';
import { getUserData } from '@/auth/helpers/token';
import { useHistory } from '@/core/routerLib';

import YourSupportTeamCard from '../components/YourSupportTeamCard';
import { AggregatedRoomData } from '../../hooks/useNavShellData';
import YourSupportTeamCardCTA from '../components/YourSupportTeamCardCTA';
import YourSupportTeamLoader from '../components/YourSupportTeamLoader';

const MESSAGE_PREVIEW_MAX_LENGTH_MOBILE = 80;
const MESSAGE_PREVIEW_MAX_LENGTH_DESKTOP = 260;

const STATUS_LIST_ALLOW_CHANGE_PROVIDER = [
  RoomStatus.ACTIVE,
  RoomStatus.PAST_DUE,
  RoomStatus.FROZEN,
  RoomStatus.NOT_RENEWING,
  RoomStatus.FREE_TRIAL,
];

interface GetMessageSenderParams {
  lastMessage: MessagePreviewAPI;
  clientUserID: number | string;
  therapistFirstName: string;
}

const getMessageSender = ({
  lastMessage,
  clientUserID,
  therapistFirstName,
}: GetMessageSenderParams) => {
  if (lastMessage.userType === 2 && lastMessage.userId === clientUserID) return 'You:';
  if (lastMessage.messageType === 52) return 'Talkspace:';
  if (lastMessage.userType === 3) return `${therapistFirstName}:`;
  return '';
};

const getNextBooking = ({
  bookings,
  activeSession,
}: Pick<AggregatedRoomData, 'bookings' | 'activeSession'>): Booking | undefined => {
  if (!bookings) return undefined;

  const nonMessagingBookings = bookings.filter((booking) => booking.modality !== 'messaging');

  if (nonMessagingBookings.length === 0) return undefined;

  const [nextBooking] = nonMessagingBookings.sort(
    (b1, b2) => new Date(b1.startTime).valueOf() - new Date(b2.startTime).valueOf()
  );

  const firstBookingIsActive = nextBooking.startTime === activeSession?.booking.startTime;

  if (firstBookingIsActive) {
    // when next booking is active do not show it. There is only a Join session CTA on the card in this case
    return undefined;
  }

  return nextBooking;
};

interface YourSupportTeamCardControllerProps {
  aggregatedRoomData: AggregatedRoomData;
  roomID: number;
  featureViewsLoading: boolean;
  featureViewsData?: UserFeatureViews;
}
const YourSupportTeamCardController = ({
  aggregatedRoomData: {
    room,
    lastMessage,
    therapistInfo,
    bookings,
    activeSession,
    hasUnreadMessage,
    subscription,
  },
  roomID,
  featureViewsLoading,
  featureViewsData,
}: YourSupportTeamCardControllerProps) => {
  const { eapToBhActivationFf } = useFlags();
  const { isMobile } = useWindowWidthState();
  const { id: clientUserID } = getUserData();
  const history = useHistory();

  const onRoomPress = useCallback(() => {
    history.push(`/room/${roomID}`);
  }, [history, roomID]);

  const onBookingPress = useCallback(() => {
    history.push(`/home/schedule`);
  }, [history]);

  if (!room || !lastMessage || !therapistInfo || !subscription) {
    return <YourSupportTeamLoader />;
  }
  const { roomType, status } = room;
  const { firstName } = therapistInfo;

  const messagingSession = bookings && bookings.find((booking) => booking.modality === 'messaging');

  const nextBooking = getNextBooking({ bookings, activeSession });

  const shouldBookAsyncSession = !nextBooking && subscription?.subscription.canCreateBooking;

  const messagePreviewMaxLength = isMobile
    ? MESSAGE_PREVIEW_MAX_LENGTH_MOBILE
    : MESSAGE_PREVIEW_MAX_LENGTH_DESKTOP;
  const truncatedMessage = `${lastMessage.message?.slice(0, messagePreviewMaxLength)}${
    lastMessage.message?.length > messagePreviewMaxLength ? '...' : ''
  }`;
  const liveSessionStarting = !!activeSession;
  const isEapToBhFFOn = !!eapToBhActivationFf?.variant;
  const noEapSessionsLeft = isEapToBhFFOn && !!subscription?.subscription?.noEapSessionsLeft;
  const fewEapSessionsLeft = isEapToBhFFOn && !!subscription?.subscription?.fewEapSessionsLeft;
  const remainingSessions = (isEapToBhFFOn && subscription?.subscription?.remainingSessions) || 0;

  return (
    <YourSupportTeamCard
      hasAlert={hasUnreadMessage}
      roomID={room.roomID}
      roomType={roomType}
      onPress={onRoomPress}
      onBookingPress={onBookingPress}
      therapistName={getTherapistNameDisplayString({ therapistInfo })}
      therapistImage={therapistInfo.imageURL}
      messageSender={getMessageSender({
        lastMessage,
        clientUserID,
        therapistFirstName: firstName,
      })}
      messageText={lastMessage.previewText || truncatedMessage}
      messageTime={getLastMessageTimeDisplayString({ createdAt: new Date(lastMessage.createdAt) })}
      nextBooking={nextBooking}
      shouldBookAsyncSession={!!shouldBookAsyncSession}
      liveSessionStarting={liveSessionStarting}
      messagingSessionActive={!!messagingSession}
      BottomRowButton={
        <View style={isMobile ? { width: '100%' } : undefined}>
          <YourSupportTeamCardCTA
            subscription={subscription}
            activeSession={activeSession}
            bookings={bookings}
            roomID={roomID}
            nextBooking={nextBooking}
            therapistInfo={therapistInfo}
            noEapSessionsLeft={noEapSessionsLeft}
          />
        </View>
      }
      roomClosed={status === RoomStatus.CLOSED || status === RoomStatus.CANCELED}
      showMenu={STATUS_LIST_ALLOW_CHANGE_PROVIDER.includes(status)}
      noEapSessionsLeft={noEapSessionsLeft}
      fewEapSessionsLeft={fewEapSessionsLeft}
      remainingSessions={remainingSessions}
      featureViewsLoading={featureViewsLoading}
      featureViewsData={featureViewsData}
    />
  );
};

export default YourSupportTeamCardController;
