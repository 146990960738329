import { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  TouchableView,
  View,
  Modal,
  Spinner,
  CloseButton,
  getScreenSafeAreaInsets,
} from '@talkspace/react-toolkit';
import {
  postPromiseMessage,
  PromiseMessageEvent,
  PromiseMessageTypeNames,
  PROMISE_MESSAGE_TYPE,
  usePromiseMessageContextActions,
} from 'ts-promise-message';
import { useKeyboardStatus } from 'ts-ionic/plugins/keyboard';
import { useIonicEffect } from 'ts-ionic';
import styled from '../../core/styled';
import { COLORS } from '../../utils/design';
import { ClosePopupAction } from '../../auth/reactFrame/ReactFrameTypes';

const Content = styled(TouchableView)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: COLORS.white,
      height: '100%',
      width: '100%',
      alignItems: isMobile ? 'start' : 'center',
      justifyContent: isMobile ? 'start' : 'center',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      // In desktop, the modal container is not full screen
      position: isMobile ? undefined : 'absolute',
    };
  }
);

interface Props {
  isLoading: boolean;
  src: string;
  title: string;
  onClose: (data?: ClosePopupAction) => void;
  onTokenRequest?: (refresh: boolean) => Promise<{ token: string; userID: string }>;
  onFinishedLoading?: () => void;
  showCloseButton?: boolean;
  onPromiseMessage?: (data: PromiseMessageEvent) => void;
  onMessage: (eventData: TSFrameEvent) => void;
}

type eventTypeNames =
  | 'closePopup'
  | 'resizeFrameHeight'
  | 'finishLoading'
  | 'authToken'
  | 'frameHeaderChange'
  | 'generalMessage'
  | typeof PROMISE_MESSAGE_TYPE;

type reactFrameEventsNames = 'refreshToken' | 'showCloseButton' | typeof PROMISE_MESSAGE_TYPE;

export interface TSFrameEvent {
  type: eventTypeNames;
  data: any;
}

const IFrameModal: FunctionComponent<Props> = ({
  isLoading,
  src,
  title,
  showCloseButton,
  onClose,
  onTokenRequest,
  onFinishedLoading,
  onPromiseMessage,
  onMessage,
}) => {
  const iFrameURLRef = useRef(new URL(src));
  const iFrameContainerRef = useRef<HTMLIFrameElement>(null);
  const [shouldShowCloseButton, setShouldShowCloseButton] = useState(true);
  const { receivedMessage } = usePromiseMessageContextActions();
  const { isKeyboardOpen, keyboardHeight } = useKeyboardStatus();
  const postMessageRef = useRef<
    ((type: reactFrameEventsNames, data: unknown) => void) | undefined
  >();

  useEffect(() => {
    function postMessage(type: reactFrameEventsNames, data: unknown) {
      if (
        iFrameContainerRef.current &&
        iFrameContainerRef.current.contentWindow &&
        iFrameContainerRef.current.contentWindow.postMessage
      ) {
        iFrameContainerRef.current.contentWindow.postMessage(
          {
            type,
            data,
          },
          iFrameURLRef.current.origin
        );
      }
    }

    postMessageRef.current = postMessage;

    function receiveMessage(event) {
      if (onTokenRequest) {
        // check origin
        if (iFrameURLRef.current.origin !== event.origin) {
          return;
        }
        const { data: eventData } = event;

        if (!eventData || !eventData.type) return;

        const { type, data } = eventData as TSFrameEvent;
        switch (type) {
          case 'authToken':
            onTokenRequest(true)
              .then((tokenData) => {
                postMessage('refreshToken', tokenData);
              })
              // eslint-disable-next-line no-console
              .catch(console.error);
            break;
          case 'closePopup':
            onClose(data && data.return);
            break;
          case 'finishLoading':
            onTokenRequest(false)
              .then((tokenData) => {
                postMessage('refreshToken', tokenData);
                postMessage('showCloseButton', showCloseButton);
                if (!showCloseButton) setShouldShowCloseButton(false);
                if (onFinishedLoading) onFinishedLoading();
              })
              // eslint-disable-next-line no-console
              .catch(console.error);
            break;
          case PROMISE_MESSAGE_TYPE:
            receivedMessage(postMessage, event);
            break;
          case 'frameHeaderChange':
            if (data?.return?.hide === true) {
              setShouldShowCloseButton(false);
            } else if (data?.return?.hide === false) {
              setShouldShowCloseButton(true);
            }
            break;
          case 'generalMessage':
            onMessage(eventData);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(`event type unknown ${type}`);
            break;
        }
      }
    }

    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [
    onClose,
    onFinishedLoading,
    onTokenRequest,
    onPromiseMessage,
    receivedMessage,
    showCloseButton,
    src,
    onMessage,
  ]);
  const safeAreaInsets = getScreenSafeAreaInsets();

  useIonicEffect(() => {
    if (!isLoading && postMessageRef.current) {
      postPromiseMessage(
        postMessageRef.current,
        PromiseMessageTypeNames.ionicNotifyKeyboardStatus,
        {
          isKeyboardOpen,
          keyboardHeight,
        },
        false
      );
    }
  }, [isLoading, isKeyboardOpen, keyboardHeight]);

  return (
    <Modal isVisible isMobileFullscreen>
      <Content>
        {shouldShowCloseButton && (
          <CloseButton
            dataQa="iframeModalCloseButton"
            onPress={() => onClose()}
            style={{
              position: 'fixed',
              top: 16 + safeAreaInsets.top,
              right: 16,
            }}
          />
        )}
        {isLoading && (
          <View
            style={{
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
            }}
          >
            <Spinner />
          </View>
        )}
        <iframe
          src={src}
          frameBorder="0"
          title={title}
          width="100%"
          height="100%"
          ref={iFrameContainerRef}
          style={{
            visibility: isLoading ? 'hidden' : 'visible',
          }}
        />
      </Content>
    </Modal>
  );
};

export default IFrameModal;
