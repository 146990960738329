import EligibilityWarningInfoItem from './EligibilityWarningInfoItem';
import { CalendarVOutlineIcon, CalendarXOutlineIcon } from '../icons';

const BookNextSession = () => (
  <EligibilityWarningInfoItem
    icon={<CalendarVOutlineIcon />}
    title="Sessions"
    text="You can use any scheduled sessions, in progress sessions, or credits in your old room."
  />
);

const SessionsCanceled = () => (
  <EligibilityWarningInfoItem
    icon={<CalendarXOutlineIcon />}
    title="Sessions canceled"
    text="Unfortunately, due to this update, your existing sessions have been canceled. You can book new sessions with your provider."
  />
);

const ScheduledSessions = () => (
  <EligibilityWarningInfoItem
    icon={<CalendarVOutlineIcon />}
    title="Sessions"
    text="Any scheduled sessions have been transferred to your updated coverage."
  />
);

const ConfirmedSession = ({ therapistName }: { therapistName: string }) => (
  <EligibilityWarningInfoItem
    icon={<CalendarVOutlineIcon />}
    title="Sessions"
    text={`Your session with ${therapistName} is confirmed.`}
  />
);

type SessionInfoProps = {
  sessionsCanceled: boolean;
  sessionsTransferred: boolean;
  isSameRoom: boolean;
  hasAppointment: boolean;
  therapistName: string;
};
const SessionInfo = ({
  sessionsCanceled,
  sessionsTransferred,
  isSameRoom,
  hasAppointment,
  therapistName,
}: SessionInfoProps) => {
  if (hasAppointment) {
    return <ConfirmedSession therapistName={therapistName} />;
  }

  if (sessionsCanceled && !sessionsTransferred) {
    return <SessionsCanceled />;
  }

  if (sessionsTransferred || (isSameRoom && !sessionsCanceled)) {
    return <ScheduledSessions />;
  }

  if (!sessionsTransferred && !sessionsCanceled && !isSameRoom) {
    return <BookNextSession />;
  }

  return null;
};

export default SessionInfo;
