const NAV_SHELL_VISIBLE_PATHS = ['home', 'room', 'rooms', 'clinical-progress-rooms', 'surveys'];
const NAV_SHELL_EXCLUDE_PATHS = ['onboarding', 'session-details', 'after-login'];

// Use whitelist of base paths to allow nav shell to show

const navShellVisiblePath = (pathname: string) => {
  const basePath = pathname.split('/')[1];
  const basePathAllowed = NAV_SHELL_VISIBLE_PATHS.some((path) => basePath === path);
  const pathExcluded = NAV_SHELL_EXCLUDE_PATHS.some((path) => pathname.includes(path));

  return basePathAllowed && !pathExcluded;
};

export default navShellVisiblePath;
