import * as LiveUpdates from '@capacitor/live-updates';
import { safeIonicWrapper } from '../../ionicUtils';
import { liveUpdatesLogger } from '../../loggers';
import {
  STORAGE_KEY_APP_LOADED,
  STORAGE_KEY_CHECK_PASSCODE,
  UPDATE_TIMEOUT,
  STORAGE_KEY_TIME_USER_UPDATED,
  STORAGE_KEY_LAST_UPDATE_INFO,
} from '../../modules/passcode/constants';
import { SplashScreenHide, SplashScreenShow } from '../splashScreen';

const setTimeUserUpdated = () => {
  sessionStorage.setItem(STORAGE_KEY_TIME_USER_UPDATED, `${new Date().valueOf()}`);
};

const persistUpdateInfo = (updateInfo: LiveUpdates.SyncResult | null) => {
  const updateInfoString = updateInfo ? JSON.stringify(updateInfo) : '';
  sessionStorage.setItem(STORAGE_KEY_LAST_UPDATE_INFO, updateInfoString);
};

const getUpdateInfo = (): LiveUpdates.SyncResult | null => {
  const updateInfoString = sessionStorage.getItem(STORAGE_KEY_LAST_UPDATE_INFO);
  try {
    const updateInfo = updateInfoString
      ? (JSON.parse(updateInfoString) as LiveUpdates.SyncResult)
      : null;
    return updateInfo;
  } catch (error) {
    return null;
  }
};

const getShouldSkipUpdate = () => {
  const timeUserUpdated = sessionStorage.getItem(STORAGE_KEY_TIME_USER_UPDATED);
  if (!timeUserUpdated) {
    // If we don't know when they updated then update app
    return false;
  }
  const now = new Date().valueOf();
  // if they updated less than 1 hour, skip update
  return now - parseInt(timeUserUpdated, 10) < UPDATE_TIMEOUT;
};

let currentLiveUpdate: LiveUpdates.SyncResult | null = null;

type Config = {
  showSplash?: boolean;
  /**
   * Amount of milliseconds to show the splash screen for before hiding it.
   * This might take longer due to Live Updates
   */
  splashDuration?: number;
  /**
   * Function called right before the app reloads due to a Live Update
   * Useful for tracking or logging
   */
  onBeforeReload?: (liveUpdate: LiveUpdates.SyncResult) => void;
};

export const updateWebVersion = safeIonicWrapper(
  async ({ showSplash = false, splashDuration = 2000, onBeforeReload = () => {} }: Config = {}) => {
    const shouldSkipUpdate = getShouldSkipUpdate();
    const now = new Date();
    let liveUpdateResult: LiveUpdates.SyncResult | null = null;
    if (!shouldSkipUpdate) {
      if (showSplash)
        await SplashScreenShow({
          autoHide: true,
          showDuration: 5 * 1000, // Default timeout for app to never become unresponsive
        });
      liveUpdateResult = await LiveUpdates.sync().catch((error) => {
        liveUpdatesLogger.error('Live update failed', error);
        return null;
      });
      currentLiveUpdate = liveUpdateResult;
      persistUpdateInfo(liveUpdateResult);
    }

    if (liveUpdateResult?.activeApplicationPathChanged) {
      liveUpdatesLogger.info('Live update success');
      sessionStorage.removeItem(STORAGE_KEY_APP_LOADED);
      sessionStorage.removeItem(STORAGE_KEY_CHECK_PASSCODE);
      onBeforeReload?.(liveUpdateResult);
      await LiveUpdates.reload();
      liveUpdatesLogger.info('Live update reload');
      // No need to hide splash here. When the app reloads, it will run this function again and it will go to the `else` block
    } else {
      // If _splashDuration_ mil have passed, immediately hide, otherwise hide for the remaining duration
      const timePassed = new Date().getTime() - now.getTime();
      const fadeOutDuration = showSplash ? splashDuration - timePassed : 0;
      await SplashScreenHide({
        fadeOutDuration: fadeOutDuration <= 0 ? undefined : fadeOutDuration,
      });
      liveUpdatesLogger.info('Splash screen hidden');
      setTimeUserUpdated();
    }
  },
  Promise.resolve(undefined)
);

export const getLiveUpdateStatus = () => currentLiveUpdate || getUpdateInfo();

export { liveUpdatesLogger };
