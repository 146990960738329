import {
  Grid,
  Col,
  View,
  SpacingView,
  Card,
  TextDS,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import useQueryQuestionnairesByClient from 'ts-frontend/hooks/questionnaires/useQueryQuestionnairesByClient';
import { getCrisisSupportCards, getTechnicalSupportCards } from './helpCards';
import ssoHelper from '@/utils/sso';
import HelpCard from './HelpCard';
import useNavShellData from '../../../hooks/useNavShellData';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import { ClosePopupAction } from '../../../auth/reactFrame/ReactFrameTypes';
import { getUserData } from '../../../auth/helpers/token';

export interface UserSettings {
  sendMessagesReceipts: boolean;
}

const HelpScreen = () => {
  const { isMobile } = useWindowWidthState();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const { riskAssessments } = useFlags();
  const closeModal = useCloseModal();
  const { id: clientID } = getUserData();

  const { data: questionnaires, isLoading: isLoadingQuestionnaires } =
    useQueryQuestionnairesByClient({
      clientID,
      questionnaireTypes: ['safety_plan'],
    });

  const { subscriptions } = useNavShellData();
  return (
    <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
      <Col sm={8} lg={8}>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="heading2xl">{tnewMemberNav('help.help', '', undefined)}</TextDS>
          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <TextDS variant="headingLg">
                {tnewMemberNav('help.technical', 'Technical support', undefined)}
              </TextDS>
              {getTechnicalSupportCards(tnewMemberNav).map(
                ({ dataQa, zendeskPath, route, ...props }) => (
                  <Card
                    variant="noBorder"
                    padding="slim"
                    onPress={() => {
                      if (zendeskPath) {
                        ssoHelper.openZendesk(zendeskPath);
                      } else {
                        window.open(route, '_blank');
                      }
                    }}
                    dataQa={dataQa}
                  >
                    <HelpCard {...props} />
                  </Card>
                )
              )}
            </SpacingView>
          </View>
          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <View as="span" style={{ display: 'inline' }}>
                <TextDS variant="headingLg" as="span">
                  {tnewMemberNav('help.crisis', 'Crisis support', undefined)}
                </TextDS>
              </View>

              {getCrisisSupportCards({
                tnewMemberNav,
                isUS: Boolean(subscriptions?.some((s) => s.participantCountry === 'US')),
                showSafetyPlanCard:
                  riskAssessments &&
                  !isLoadingQuestionnaires &&
                  questionnaires &&
                  questionnaires.length > 0,
              }).map(({ dataQa, route, isInnerRoute, ...props }) => (
                <Card
                  variant="noBorder"
                  padding="slim"
                  onPress={() => {
                    isInnerRoute
                      ? closeModal({ navigateTo: route } as ClosePopupAction)
                      : window.open(route, '_blank');
                  }}
                  dataQa={dataQa}
                >
                  <HelpCard {...props} />
                </Card>
              ))}
            </SpacingView>
          </View>
        </SpacingView>
      </Col>
    </Grid>
  );
};

export default HelpScreen;
