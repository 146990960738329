import { forwardRef } from 'react';
import Button from './Button';
import { ColorRolesVersion } from '../../types';

import styled, { useEmotionTheme, EmotionStyle } from '../../../core/styled';
import { getSpacing } from '../../tokens';
import { ChevronDown, ChevronUp } from '../../icons';
import TextDS from '../typography/TextDS';
import {
  getActiveStyles,
  getDisabledStyles,
  getButtonTextColor,
  getLeftIconStyles,
  getRightIconStyles,
} from './utils';
import { ButtonV2Props } from './types';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';

const primaryButtonStyle = (colorRoles: ColorRolesVersion, disabled: boolean) => {
  return {
    backgroundColor: colorRoles.button.brandPrimarySurfaceDefault,
    ...(disabled
      ? getDisabledStyles('primary', colorRoles)
      : {
          '&:hover': {
            backgroundColor: colorRoles.button.brandPrimarySurfaceHovered,
          },
          '&:active': {
            backgroundColor: colorRoles.button.brandPrimarySurfacePressed,
          },
        }),
  };
};

const secondaryButtonStyle = (colorRoles: ColorRolesVersion, disabled: boolean) => {
  return {
    backgroundColor: colorRoles.button.brandSecondarySurfaceDefault,
    border: `1px solid ${colorRoles.button.brandSecondaryBorderDefault}`,
    ...(disabled
      ? getDisabledStyles('secondary', colorRoles)
      : {
          '&:hover': {
            backgroundColor: colorRoles.button.brandSecondarySurfaceHovered,
            border: `1px solid ${colorRoles.button.brandSecondaryBorderHovered}`,
          },
          '&:active': {
            backgroundColor: colorRoles.button.brandSecondarySurfacePressed,
            border: `1px solid ${colorRoles.button.brandSecondaryBorderPressed}`,
          },
        }),
  };
};

const tertiaryButtonStyle = (colorRoles: ColorRolesVersion, disabled: boolean) => {
  return {
    backgroundColor: colorRoles.button.brandTertiarySurfaceDefault,
    ...(disabled
      ? getDisabledStyles('tertiary', colorRoles)
      : {
          '&:hover': {
            backgroundColor: colorRoles.button.brandTertiarySurfaceHovered,
          },
          '&:active': {
            backgroundColor: colorRoles.button.brandTertiarySurfacePressed,
          },
        }),
  };
};

interface GetVariantStyleParams {
  variant: ButtonV2Props['variant'];
  colorRoles: ColorRolesVersion;
  disabled: boolean;
}

const getVariantStyle = ({
  variant,
  colorRoles,
  disabled,
}: GetVariantStyleParams): EmotionStyle => {
  switch (variant) {
    case 'primary':
      return primaryButtonStyle(colorRoles, disabled);
    case 'secondary':
      return secondaryButtonStyle(colorRoles, disabled);
    case 'tertiary':
      return tertiaryButtonStyle(colorRoles, disabled);
    default:
      return primaryButtonStyle(colorRoles, disabled);
  }
};

const getSizeStyle = (
  size: ButtonV2Props['sizeDS'],
  variant: ButtonV2Props['variant'],
  isMobile: boolean
): EmotionStyle => {
  const mediumPadding = `${getSpacing('space100') + getSpacing('space025')}px ${getSpacing(
    'space300',
    true
  )}`;
  const largePadding = `${getSpacing('space200') + getSpacing('space025')}px ${getSpacing(
    'space300',
    true
  )}`;

  switch (size) {
    case 'slim':
      if (variant === 'tertiary') {
        return {
          padding: `${getSpacing('space075', true)} ${getSpacing('space075', true)} ${getSpacing(
            'space075',
            true
          )} ${getSpacing('space100', true)}`,
        };
      }
      return { padding: `${getSpacing('space075', true)} ${getSpacing('space200', true)}` };
    case 'large':
      return { padding: largePadding };
    case 'medium':
    default:
      if (isMobile) {
        return { padding: largePadding };
      }
      return { padding: mediumPadding };
  }
};

const ButtonV2StyledWithIcon = styled(Button)<{
  variant?: ButtonV2Props['variant'];
  sizeDS?: ButtonV2Props['sizeDS'];
  // TODO: simplify how button icons are styled
  iconConfig?: ButtonV2Props['iconConfig'];
  isActive?: ButtonV2Props['isActive'];
  Icon?: ButtonV2Props['Icon'];
  hasCaretDown?: ButtonV2Props['hasCaretDown'];
  isMobile: boolean;
  disabled?: ButtonV2Props['disabled'];
  children?: ButtonV2Props['children'];
}>(
  ({
    variant,
    sizeDS,
    iconConfig,
    isActive,
    Icon,
    hasCaretDown,
    isMobile,
    theme: { colorRoles },
    disabled = false,
  }) => {
    const baseButtonStyles = {
      padding: `${getSpacing('space100', true)} ${getSpacing('space400', true)}`,
      marginTop: 0,
    };
    const variantStyles = getVariantStyle({ variant, colorRoles, disabled });
    const sizeStyles = getSizeStyle(sizeDS, variant, isMobile);
    const iconStyles =
      Icon && iconConfig ? getLeftIconStyles(variant, colorRoles, iconConfig, isActive) : {};
    const disclosureStyles = hasCaretDown
      ? getRightIconStyles(variant, colorRoles, { path: ['fill'] }, isActive)
      : {};
    const activeStyles = isActive ? getActiveStyles(colorRoles) : {};
    return {
      ...baseButtonStyles,
      ...variantStyles,
      ...iconStyles,
      ...disclosureStyles,
      ...activeStyles,
      ...sizeStyles,
    };
  }
);

const getWidth = ({ stretch, fixedWidth, sizeDS }) => {
  if (fixedWidth && sizeDS !== 'small') {
    return 300;
  }
  return stretch ? '100%' : 'fit-content';
};

const ButtonV2 = forwardRef<HTMLButtonElement, ButtonV2Props>(
  (
    { text, stretch: stretchProp, fixedWidth, sizeDS, IconRight, ...otherProps }: ButtonV2Props,
    ref
  ) => {
    const { isMobile } = useWindowWidthState();
    const stretch = stretchProp;
    const {
      variant = 'primary',
      Icon,
      hasCaretDown,
      isActive,
      disabled,
      style,
      children,
      isSecondary,
    } = otherProps;

    const { colorRoles } = useEmotionTheme();

    const iconColor =
      variant === 'primary'
        ? colorRoles.button.brandPrimaryIconDefault
        : colorRoles.button.brandSecondaryIconDefault;

    const textColor = getButtonTextColor({ colorRoles, variant, isActive, disabled });

    const chevronIconColorType = variant === 'primary' ? 'inverse' : 'brand';
    return (
      <ButtonV2StyledWithIcon
        stretch={stretch}
        isMobile={isMobile}
        sizeDS={sizeDS}
        // The variant tertiary of v2 corresponds to the secondary style of v0
        // map them to each other for compatibility
        isSecondary={variant === 'tertiary' ? true : isSecondary}
        variant={isSecondary ? 'tertiary' : variant}
        {...otherProps}
        style={{
          marginTop: 0,
          maxWidth: stretch ? 'none' : 335,
          width: getWidth({ stretch, fixedWidth, sizeDS }),
          height: 'unset',
          minHeight: 'unset',
          display: 'flex',
          gap: 6,
          ...style,
        }}
        ref={ref}
      >
        {Icon && <Icon color={iconColor} />}
        <TextDS
          variant={sizeDS === 'slim' ? 'headingSm' : 'headingMd'}
          style={{ color: textColor }}
        >
          {text || children}
        </TextDS>
        {!hasCaretDown && IconRight && <IconRight colorType={chevronIconColorType} />}
        {hasCaretDown &&
          (isActive ? (
            <ChevronUp colorType={chevronIconColorType} />
          ) : (
            <ChevronDown colorType={chevronIconColorType} />
          ))}
      </ButtonV2StyledWithIcon>
    );
  }
);

export default ButtonV2;
