import { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { promisify } from 'util';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  View,
  Button,
  ExtraHuge,
  Big,
  Large,
  Standard,
  RedX,
  GreenCircleCheckMarkV2,
  BookingIconWithX,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { Booking } from 'ts-frontend/types';
import { trackTrizettoErrorScreen } from 'ts-analytics/mixpanel/events';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import styled from '@/core/styled';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../../hooks/inRoomSchedulingContext';

import BookingSummaryList from '../BookingSummaryList';
import BookingSummaryDatetimes from '../BookingSummaryDatetimes';

export const GENERAL_SCHEDULER_ERROR =
  'The time slot may no longer be available or there may have been an issue with your internet connection. Please try again.';

const ErrorMessage = styled(Large)(({ theme: { colors } }) => {
  return {
    width: 327,
    textAlign: 'center',
    marginTop: 20,
    opacity: 0.7,
    color: colors.permaRiverBed,
  };
});

interface OwnProps {
  onClosePress: () => void;
  message?: string;
  title?: string;
  buttonText?: string;
  userID?: number | string;
  roomID?: number | string;
}

type Props = OwnProps & RouteComponentProps;

function getErrorIcon(booking?: Booking) {
  if (
    !booking ||
    (booking && !booking.status) ||
    booking.status === 'never-confirmed' ||
    booking.timekitBookingState === 'declined' ||
    booking.timekitBookingState === 'tentative'
  )
    return RedX;
  if (booking.status === 'active') return GreenCircleCheckMarkV2;
  if (booking.status === 'therapist-canceled' || booking.status === 'client-canceled')
    return BookingIconWithX;
  return RedX;
}

const InRoomSchedulingError: FunctionComponent<Props> = ({
  onClosePress,
  message,
  title,
  buttonText,
  userID,
  roomID,
}) => {
  const { repeatingSessionsFull2 } = useFlags();
  const {
    errorMessage,
    errorTitle,
    selectedConfirmBooking,
    selectedTimeslot,
    isClientConfirmError,
    isTrizettoError,
  } = useInRoomSchedulingState();
  const { dispatchResetError } = useInRoomSchedulingActions();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    if (userID && roomID && isTrizettoError && errorMessage) {
      trackTrizettoErrorScreen({
        userID,
        roomID,
        reason: errorMessage,
      });
    }
  }, [errorMessage, isTrizettoError, roomID, userID]);

  const handleOnClosePress = () => {
    setIsLoading(true);
    promisify(onClosePress)().then(() => dispatchResetError());
  };

  const { colors } = useEmotionTheme();

  const showBookingTime =
    isClientConfirmError &&
    selectedConfirmBooking &&
    selectedConfirmBooking.timekitBookingState === 'confirmed';

  const ErrorIcon = getErrorIcon(selectedConfirmBooking);

  const isRecurringBooking = !!selectedConfirmBooking?.repeatingBookingID;
  const recurringTimeslots =
    isRecurringBooking &&
    selectedConfirmBooking &&
    selectedConfirmBooking.repeatingBookings
      ?.filter(
        (booking) =>
          booking.status === 'active' &&
          (booking.timekitBookingState === 'confirmed' ||
            booking.timekitBookingState === 'tentative')
      )
      ?.map((booking) => {
        return {
          start: booking.startTime,
          end: moment(booking.startTime).add(booking.creditMinutes, 'minute').toISOString(),
        };
      });

  const TimeslotsSummary = repeatingSessionsFull2 ? BookingSummaryDatetimes : BookingSummaryList;

  return (
    <View style={{ marginTop: 65, paddingTop: 3 }} justify="center" align="center">
      <ErrorIcon />
      <ExtraHuge style={{ width: 327, marginTop: 20, textAlign: 'center' }}>
        {title || errorTitle || 'Sorry, something went wrong'}
      </ExtraHuge>
      {selectedTimeslot &&
        showBookingTime &&
        (isRecurringBooking && recurringTimeslots ? (
          <View style={{ marginTop: 20 }}>
            <TimeslotsSummary timeslots={recurringTimeslots} />
          </View>
        ) : (
          <>
            <Big style={{ marginTop: 20 }}>
              {moment(selectedTimeslot.start).format('dddd, MMM D')}
            </Big>
            <Standard variant="standardDarkGrey" style={{ marginBottom: 10 }}>
              {`${moment(selectedTimeslot.start).format('h:mmA')} – ${moment(
                selectedTimeslot.end
              ).format('h:mmA')}`}
            </Standard>
          </>
        ))}
      {repeatingSessionsFull2 && !isTrizettoError && (
        <ErrorMessage>{message || errorMessage || GENERAL_SCHEDULER_ERROR}</ErrorMessage>
      )}
      {!isTrizettoError && !repeatingSessionsFull2 && (
        <Large
          style={{
            width: 327,
            textAlign: 'center',
            marginTop: 20,
            opacity: 0.7,
            color: colors.permaRiverBed,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
          }}
        >
          {message || errorMessage || GENERAL_SCHEDULER_ERROR}
        </Large>
      )}
      <Button
        style={{ marginTop: 40, width: 327, backgroundColor: colors.permaTalkspaceDarkGreen }}
        text={buttonText || (repeatingSessionsFull2 ? 'Close' : 'Done')}
        onPress={handleOnClosePress}
        isLoading={isLoading}
        dataQa="inRoomSchedulingErrorCloseButton"
      />
    </View>
  );
};

export default withRouter(InRoomSchedulingError);
