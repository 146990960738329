import {
  EmotionThemeProvider,
  View,
  PanelHeader,
  BackNextButton,
  ResponsiveLayoutWithHeader,
  useEmotionTheme,
  TextDS,
  TouchableView,
  styled,
  Tag,
  getSpacing,
  Carousel,
  Button,
  Headphones,
  ColorRolesVersion,
  useWindowWidthState,
  ErrorScreen,
} from '@talkspace/react-toolkit';
import { ArrowRotateRight, XMarkLarge } from '@talkspace/react-toolkit/src/designSystems/icons';
import { Move } from '@talkspace/react-toolkit/src/components/Carousel';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  LockKey,
  ThumbsUp,
  WebPageError,
} from '@talkspace/react-toolkit/src/designSystems/illustrations';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import useMutationExperimentalFeatures from '../../../hooks/useMutationExperimentalFeatures';
import { getUserData } from '../../../auth/helpers/token';
import { useParams } from '@/core/routerLib';

const BackArrow = styled(BackNextButton)(({ theme: { spacing } }) => {
  return {
    display: 'inline-block',
    borderRadius: spacing('space050'),
    marginBottom: spacing('space025'),
  };
});

const Separator = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    height: '1px',
    background: colorRoles.borders.borderDefault,
    width: 'calc(100% + 40px)',
    marginBottom: spacing('space200'),
    marginLeft: -20,
  };
});

const ContentWrapper = styled(View)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    return {
      height: '100%',
      flexDirection: 'column',
      padding: isDesktop ? '64px 16px' : '32px 20px',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center',
      backgroundColor: colors.white,
    };
  }
);

const IconWrapper = styled(View)<{ color: any }>(({ color, theme: { spacing } }) => {
  return {
    background: color,
    height: 149,
    borderRadius: 12,
    paddingTop: spacing('space200'),
    paddingBottom: spacing('space200'),
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  };
});

const getFlowData = (colorRoles: ColorRolesVersion) => [
  {
    id: 1,
    title: 'A personalized mental health audio episode',
    description:
      'Go further in your mental health journey with your very own Talkcast episode, crafted with AI and reviewed by your provider. Each episode offers insights from your therapy journey to help you feel supported between sessions.',
    icon: <Headphones />,
    color: colorRoles.surfaces.decorativeLiftedLemonTint,
  },
  {
    id: 2,
    title: 'Secure, confidential, and private',
    description:
      'Your Talkcast episodes stay between you and your therapist. Talkspace uses secure, trusted systems to protect your privacy, maintain data security, and ensure the confidentiality of your Talkcast episodes.',
    icon: <LockKey />,
    color: colorRoles.surfaces.decorativeBalanceBlueTint,
  },
  {
    id: 3,
    title: 'You’re eligible for early access!',
    description:
      'We invite you to try an early-access version of Talkcast, which is only available to a select few people. You’ll have a chance to tell us what you think of your episodes.',
    icon: <ThumbsUp width={107} height={107} />,
    color: colorRoles.surfaces.decorativeGratitudeGreenTint,
  },
];

const carouselContainerStyles = {
  flex: 1,
  justifyContent: 'space-between',
  overflowX: 'hidden',
};

const TalktrackInfo: FunctionComponent = () => {
  const { colorRoles } = useEmotionTheme();
  const closeModal = useCloseModal();
  const { isMobile } = useWindowWidthState();

  const { roomID } = useParams<{ roomID: string }>();

  const { mutate: featureOptIn, isLoading: isOptInLoading } = useMutationExperimentalFeatures();

  const { data: roomDetailsData, isLoading: isRoomDetailsLoading } = useQueryRoomDetails(roomID);

  const [move, setMove] = useState<Move>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [optedIn, setOptedIn] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (roomDetailsData?.experimentalFeaturesClientOn?.includes('talktracks')) {
      setOptedIn(true);
    }
  }, [roomDetailsData?.experimentalFeaturesClientOn]);

  const fullFlowData = getFlowData(colorRoles);
  const flowData = optedIn ? fullFlowData.slice(0, -1) : fullFlowData;

  const handleStepChange = (changedStep: number) => {
    setCurrentStep(changedStep);
  };

  const handleSubmit = () => {
    featureOptIn(
      { feature: 'talktracks', userID: getUserData().id, roomID },
      {
        onError: () => {
          setIsError(true);
        },
        onSuccess: () => {
          closeModal();
        },
      }
    );
  };

  const showBackButton = currentStep !== 0;
  const isLastStep = currentStep === flowData.length - 1;

  const handleContinue = () => {
    if (isLastStep) {
      closeModal();
    } else {
      setMove('forward');
    }
  };

  if (isRoomDetailsLoading) {
    return null;
  }

  return (
    <EmotionThemeProvider version="2.0.0">
      <ResponsiveLayoutWithHeader
        dataQa="talktrackInfoHeader"
        renderHeader={() => (
          <PanelHeader
            useNewNavHeader
            renderLeft={() => (
              <>
                {showBackButton ? (
                  <BackArrow
                    onPress={() => setMove('backward')}
                    hidden
                    primaryColor={colorRoles.icons.iconBrandDefault}
                    caretColor={colorRoles.icons.iconBrandDefault}
                    dataQa="talktrackInfoHeaderBackButton"
                  />
                ) : (
                  <View />
                )}
                <View
                  flex={1}
                  align="center"
                  justify="center"
                  row
                  style={{ gap: getSpacing('space100') }}
                >
                  <TextDS colorRole="textBrandDefault" variant="decorative/headingLg">
                    Talkcast
                  </TextDS>
                  <Tag text="Beta" color="decorativeGratitudeGreen" />
                </View>
              </>
            )}
            renderRight={() => (
              <TouchableView
                align="end"
                onPress={() => closeModal()}
                data-qa="talktrackInfoHeaderCloseButton"
                flex={1}
                justify="end"
                style={{ padding: getSpacing('space050') }}
              >
                <XMarkLarge size="major" colorType="brand" />
              </TouchableView>
            )}
          />
        )}
      >
        <ContentWrapper>
          {isError ? (
            <ErrorScreen
              isButtonLoading={isOptInLoading}
              onPressButton={handleSubmit}
              contentWrapperStyle={{ height: 'unset' }}
              icon={() => <ArrowRotateRight colorType="inverse" />}
              hideHeader
              onClose={closeModal}
              buttonText="Try again"
              illustration={<WebPageError />}
              title="Something went wrong"
              description="We weren’t able to send the episode to you. Please refresh the screen to try again."
            />
          ) : (
            <>
              <Carousel
                resetMove={() => setMove(null)}
                hideArrows
                carouselIndicatorsStyles={{ display: !isMobile && 'none' }}
                containerStyle={{ ...carouselContainerStyles, maxWidth: isMobile ? 343 : 680 }}
                move={move}
                onStepChange={handleStepChange}
              >
                {flowData.map((item) => (
                  <View key={item.id}>
                    <IconWrapper color={item.color}>{item.icon}</IconWrapper>
                    <TextDS
                      style={{ marginBottom: getSpacing('space150') }}
                      variant="decorative/heading2Xl"
                    >
                      {item.title}
                    </TextDS>
                    <TextDS variant="body">{item.description}</TextDS>
                  </View>
                ))}
              </Carousel>
              <View style={{ width: '100%' }}>
                {isMobile && <Separator />}
                {isLastStep && !optedIn ? (
                  <View align="center" style={{ width: '100%' }}>
                    <Button
                      text="Try it out"
                      sizeDS="large"
                      onPress={handleSubmit}
                      style={{
                        width: isMobile ? '100%' : 384,
                        marginBottom: getSpacing('space050'),
                      }}
                    />
                    <Button text="No, thanks" variant="tertiary" onPress={() => closeModal()} />
                  </View>
                ) : (
                  <Button
                    text="Continue"
                    sizeDS="large"
                    onPress={handleContinue}
                    style={{ width: isMobile ? '100%' : 384, alignSelf: !isMobile && 'center' }}
                  />
                )}
              </View>
            </>
          )}
        </ContentWrapper>
      </ResponsiveLayoutWithHeader>
    </EmotionThemeProvider>
  );
};

export default TalktrackInfo;
