import { useEmotionTheme, View, Screen, useWindowWidthState } from '@talkspace/react-toolkit';
import { WizardScreen, useWizardActions } from 'stepWizard';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Route, Switch, useHistory, useParams } from '@/core/routerLib';
import emergencyContactScheme from './emergencyContactScheme';
import SubmitEmergencyContact from './containers/submitEmergencyContact';
import useQueryEmergencyContact from '@/hooks/useQueryEmergencyContact';
import { getUserData } from '@/auth/helpers/token';
import useShowOnboardingImprovements from '@/hooks/useBHOnboardingImprovements';

const Routes = () => {
  const { onExitClick } = useWizardActions();
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');
  const { id: userID } = getUserData();
  const {
    location: { pathname },
  } = useHistory();
  const { roomID } = useParams<{ roomID: string }>();

  const { data: emergencyContact } = useQueryEmergencyContact({ userID });
  const isOnboarding = pathname.includes('/onboarding');

  const isBHOnboardingImprovementsEnabled = useShowOnboardingImprovements(roomID, userID);
  const { disableAddressAutocomplete } = useFlags();
  const showCloseButton = !isOnboarding;
  const scheme = emergencyContactScheme(tAdultOnboarding, disableAddressAutocomplete);
  const wizardScheme = {
    ...scheme,
    ...(isOnboarding ? { hideProgressIndicator: true } : {}),
    ...(isBHOnboardingImprovementsEnabled
      ? {
          showProgressBarConfig: {
            showProgressBar: true,
            sections: ['Check coverage', 'Your preferences', 'Get matched'],
            currentSection: 3,
          },
        }
      : {}),
  };

  const showEmergencyContactTitle = !!(
    emergencyContact?.firstName &&
    emergencyContact?.lastName &&
    emergencyContact?.address &&
    emergencyContact?.phone
  );

  const title = showEmergencyContactTitle
    ? tAdultOnboarding('emergencyContact.navBarTitle', 'Emergency contact intake', undefined)
    : tAdultOnboarding('emergencyContact.navBarTitle2', 'Contact information', undefined);

  return (
    <Switch>
      <View style={{ background: colors.white }}>
        <Screen
          safeAreaVariant="setHeightAndAddTop"
          onCloseButtonClick={onExitClick}
          title={isMobile ? title : ''}
          showCloseButton={showCloseButton}
          scrollViewStyles={{ padding: !isMobile ? '0 22px 22px 22px' : 0 }}
        >
          <Route
            exact
            path={[
              '/emergency-contact-wizard/submit',
              '/room/:roomID/onboarding/emergency-contact/source/:source/submit',
            ]}
            component={SubmitEmergencyContact}
          />
          <Route
            exact
            path={[
              '/emergency-contact-wizard/room/:roomID/source/:source',
              '/room/:roomID/onboarding/emergency-contact/source/:source',
            ]}
            render={() => <WizardScreen wizardScheme={wizardScheme} />}
          />
        </Screen>
      </View>
    </Switch>
  );
};

export default Routes;
