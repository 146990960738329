import { SuccessCheckmarkV2, SuccessCheckmark } from '@talkspace/react-toolkit';
import WorkingTodayIcon from './WorkingTodayIcon';
import RightCaretIcon from './RightCaretIcon';
import TimeOffIcon from './TimeOffIcon';
import OffTodayIcon from './OffTodayIcon';
import PencilIcon from './PencilIcon';
import VideoIcon from './VideoIcon';
import PauseIcon from './PauseIcon';
import ChangeIcon from './ChangeIcon';
import RectangleIcon from './RectangleIcon';
import StarIcon from './StarIcon';
import CTIcon from './CTIcon';
import CircleCTIcon from './CircleCTIcon';
import ToggleDetailsIcon from './ToggleDetailsIcon';
import CheckmarkIcon from './CheckmarkIcon';
import RoundCheckmark from './RoundCheckmark';
import LinkIcon from './LinkIcon';
import TalkspaceVerifiedIcon from './TalkspaceVerifiedIcon';
import PasswordDots from './PasswordDots';
import Eye from './Eye';
import CrossedEye from './CrossedEye';
import DailyReminder from './DailyReminder';
import EmailEnvelope from './EmailEnvelope';
import PausedPlan from './PausedPlan';
import RoundedPausedPlan from './RoundedPausedPlan';
import CancelledPlan from './CancelledPlan';
import FreeConsultationPlan from './FreeConsultationPlan';
import PastDuePlan from './PastDuePlan';
import File from './File';
import CircleCheckmark from './CircleCheckmark';
import CircleCalendar from './CircleCalendar';
import CircleClock from './CircleClock';
import VerifyEmail from './VerifyEmail';
import ChangePlan from './ChangePlan';
import CellPhone from './CellPhone';
import SuperbillsIcon from './SuperbillsIcon';
import SuperbillsIconBig from './SuperbillsIconBig';
import PsychRx from './PsychRx';
import Lightbulb from './Lightbulb';
import OpenBook from './OpenBook';
import Notepad from './Notepad';

export {
  WorkingTodayIcon,
  RightCaretIcon,
  TimeOffIcon,
  OffTodayIcon,
  PencilIcon,
  VideoIcon,
  PauseIcon,
  ChangeIcon,
  LinkIcon,
  RectangleIcon,
  StarIcon,
  CTIcon,
  CircleCTIcon,
  ToggleDetailsIcon,
  CheckmarkIcon,
  RoundCheckmark,
  TalkspaceVerifiedIcon,
  SuccessCheckmark,
  SuccessCheckmarkV2,
  PasswordDots,
  Eye,
  CrossedEye,
  DailyReminder,
  EmailEnvelope,
  PausedPlan,
  RoundedPausedPlan,
  CancelledPlan,
  FreeConsultationPlan,
  PastDuePlan,
  File,
  CircleCheckmark,
  CircleCalendar,
  CircleClock,
  VerifyEmail,
  ChangePlan,
  CellPhone,
  SuperbillsIcon,
  SuperbillsIconBig,
  PsychRx,
  Lightbulb,
  OpenBook,
  Notepad,
};

const IconIndex = {
  WorkingTodayIcon,
  RightCaretIcon,
  TimeOffIcon,
  OffTodayIcon,
  PencilIcon,
  VideoIcon,
  PauseIcon,
  ChangeIcon,
  LinkIcon,
  RectangleIcon,
  StarIcon,
  CTIcon,
  CircleCTIcon,
  ToggleDetailsIcon,
  CheckmarkIcon,
  RoundCheckmark,
  TalkspaceVerifiedIcon,
  SuccessCheckmark,
  SuccessCheckmarkV2,
  PasswordDots,
  Eye,
  CrossedEye,
  DailyReminder,
  EmailEnvelope,
  PausedPlan,
  RoundedPausedPlan,
  CancelledPlan,
  FreeConsultationPlan,
  PastDuePlan,
  File,
  CircleCheckmark,
  CircleCalendar,
  CircleClock,
  VerifyEmail,
  ChangePlan,
  CellPhone,
  SuperbillsIcon,
  SuperbillsIconBig,
  PsychRx,
  Lightbulb,
  OpenBook,
  Notepad,
};

export default IconIndex;
