import * as React from 'react';
import View from '../View';
import TextDS from '../../designSystems/components/typography/TextDS';
import styled, { useEmotionTheme } from '../../core/styled';
import RoundFilledCheckmark from '../icons/RoundFilledCheckmark';

export interface IconListItem {
  title: string;
  description?: string;
  isCompleted?: boolean;
  isCurrent?: boolean;
}

export interface IconListProps {
  title: string;
  items: IconListItem[];
}

const IconListContainer = styled(View)(() => {
  return {
    textAlign: 'left',
  };
});
const ListItemContainer = styled(View)<{ isLast?: boolean }>(({ isLast, theme: { spacing } }) => {
  return {
    marginBottom: isLast ? 0 : spacing('space300'),
  };
});
const ListItemLeftColumn = styled(View)(({ theme: { spacing } }) => {
  return {
    marginRight: spacing('space200'),
  };
});

const TitleContainer = styled(View)(({ theme: { spacing } }) => {
  return {
    marginBottom: spacing('space200'),
  };
});

const CircleOutline = styled(View)(({ theme: { colorRoles } }) => {
  return {
    width: 19,
    height: 19,
    borderRadius: '50%',
    border: `1px solid ${colorRoles.surfaces.successBoldDefault}`,
    boxShadow: `0px 0px 0px 10px ${colorRoles.surfaces.successSubtleDefault}`,
    backgroundColor: 'white',
  };
});

const CircleDashed = styled(View)(({ theme: { colorRoles } }) => {
  return {
    width: 19,
    height: 19,
    borderRadius: '50%',
    border: `1px dashed ${colorRoles.surfaces.successBoldDefault}`,
    backgroundColor: 'white',
  };
});

const VerticalLine = styled(View)(({ theme: { colorRoles } }) => {
  return {
    position: 'absolute',
    left: 0,
    borderLeft: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
    width: 0,
    height: 'calc(100% - 20px)',
    marginLeft: 10,
    marginTop: 10,
    zIndex: 1,
  };
});

const IconList: React.FC<IconListProps> = ({ title, items }) => {
  const { colorRoles, spacing } = useEmotionTheme();
  return (
    <IconListContainer>
      <TitleContainer>
        <TextDS variant="headingXs">{title}</TextDS>
      </TitleContainer>
      <View style={{ position: 'relative' }}>
        <VerticalLine />
        <View style={{ zIndex: 2 }}>
          {items.map((item, index, array) => (
            <ListItemContainer key={item.title} isLast={index === array.length - 1} row>
              <ListItemLeftColumn>
                {item.isCompleted ? (
                  <RoundFilledCheckmark color={colorRoles.surfaces.successBoldDefault} />
                ) : null}
                {item.isCurrent ? <CircleOutline /> : null}
                {!item.isCompleted && !item.isCurrent ? <CircleDashed /> : null}
              </ListItemLeftColumn>
              <View flex={1}>
                <TextDS
                  variant="headingMd"
                  colorRole={item.isCompleted ? 'textDefaultDisabled' : 'textDefault'}
                >
                  {item.title}
                </TextDS>
                {item.description ? (
                  <TextDS style={{ marginTop: spacing('space075') }} variant="bodySm">
                    {item.description}
                  </TextDS>
                ) : null}
              </View>
            </ListItemContainer>
          ))}
        </View>
      </View>
    </IconListContainer>
  );
};

export default IconList;
