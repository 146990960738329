import { VoidFunctionComponent } from 'react';

import { View, TextDS, EmotionStyle, styled } from '@talkspace/react-toolkit';
import { PaymentForm } from 'checkout';

import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import { useStripeTs } from '../../../stripe/stripeContext';

interface Props {
  onLoadStripeLink: () => void;
  onStripeLinkError: (error: unknown) => void;
  onSubmit: (params: any) => any;
  resetError: () => void;
  errorMessage?: string;
  isProcessing: boolean;
  title?: string;
  subtitle?: string;
  submitText: string;
  style?: EmotionStyle;
}

const Title = styled(TextDS)({
  fontWeight: fontWeight.bold,
  marginBottom: 12,
  marginTop: 2,
  textAlign: 'center',
});

const Subtitle = styled(TextDS)({
  marginBottom: 32,
  textAlign: 'center',
});

const AddPaymentMethodForm: VoidFunctionComponent<Props> = ({
  onLoadStripeLink,
  onStripeLinkError,
  onSubmit,
  resetError,
  errorMessage,
  isProcessing,
  title,
  subtitle,
  submitText,
  style,
}) => {
  const stripe = useStripeTs();

  return (
    <View style={style}>
      <Title variant="headingXl">{title}</Title>
      <Subtitle variant="body">{subtitle}</Subtitle>
      <PaymentForm
        onSubmit={onSubmit}
        onLoadStripeLink={onLoadStripeLink}
        onStripeLinkError={onStripeLinkError}
        resetError={resetError}
        isEmailDisabled
        isEmailRequired={false}
        provider="Stripe"
        submitText={submitText}
        errorMessage={errorMessage}
        isProcessing={isProcessing}
        stripe={stripe}
        useStripeLink
        showTemporaryHoldBullet
      />
    </View>
  );
};

export default AddPaymentMethodForm;
