import { ServiceType } from 'ts-frontend/types';

export const MIN_COMPOSER_HEIGHT = 67;
export const MIN_COMPOSER_HEIGHT_V2 = 24;
export const MAX_COMPOSER_HEIGHT = 67;

export type RoomType = 'privateRoom' | 'couplesRoom' | 'psychiatryRoom';
export const ROOM_TYPE_TO_SERVICE_TYPE: Record<RoomType, ServiceType> = {
  privateRoom: 'psychotherapy',
  couplesRoom: 'therapyCouples',
  psychiatryRoom: 'psychiatry',
};

export const AGORA_PROXY_SERVER_FORCE_TLS = 5;
