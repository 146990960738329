import { View, TextDS } from '@talkspace/react-toolkit';

const WelcomeScreenCitation = ({ citation }: { citation: string }) => {
  if (!citation) return null;
  return (
    <View style={{ marginTop: 16, display: 'inline' }}>
      <TextDS variant="bodyXs" inline style={{ fontWeight: 'bold' }}>
        Source:{' '}
      </TextDS>
      <TextDS variant="bodyXs" inline colorRole="textSubtlest">
        {citation}
      </TextDS>
    </View>
  );
};

export default WelcomeScreenCitation;
