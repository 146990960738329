import { useFlags } from 'launchDarkly/FlagsProvider';
import { useEffect } from 'react';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import useNavShellData from '@/hooks/useNavShellData';
import { SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL } from '@/community/constants';
import { getEAPRoomWithNoSessions } from '../utils';

const CheckCoverageVerifier = ({ featureViewsLoading, featureViewsData }) => {
  const openModal = useOpenModal();
  const { eapToBhActivationFf } = useFlags();

  const { dataByRoomID } = useNavShellData();

  useEffect(() => {
    const eapRoomWithNoCredits = getEAPRoomWithNoSessions(dataByRoomID);

    const isFFOn = !!eapToBhActivationFf?.variant;
    const featureNotMarked =
      !featureViewsLoading && !!featureViewsData && !featureViewsData.noEapSessionsLeft;
    const ignoreEapToBHModal = sessionStorage.getItem(SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL);
    const shouldShowModal =
      !ignoreEapToBHModal && isFFOn && featureNotMarked && !!eapRoomWithNoCredits;

    if (shouldShowModal) {
      openModal(`/check-coverage`);
    }
  }, [eapToBhActivationFf, featureViewsLoading, featureViewsData, dataByRoomID, openModal]);

  return null;
};

export default CheckCoverageVerifier;
