import { useMutation } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

type TalktrackFeedbackType = 'dismissed' | 'didntListen' | 'notGood' | 'justOK' | 'helpful';

interface TherapistPayload {
  feedback: TalktrackFeedbackType;
  notSatisfiedWithContent?: boolean;
  episodeLength?: boolean;
  notFitWorkflow?: boolean;
  qualityContent?: boolean;
  fitsInWorkflow?: boolean;
  feedbackDescription?: string;
}

interface ClientPayload {
  feedback: TalktrackFeedbackType;
  topicNotRelevant?: boolean;
  dontEnjoyDoing?: boolean;
  feltRelevant?: boolean;
  episodeLength?: boolean;
  insightfulContent?: boolean;
  helpfulActivityBetweenSessions?: boolean;
  feedbackDescription?: string;
  optOut?: boolean;
}

export interface SubmitTalktrackFeedbackVariables {
  talktrackID: number;
  userID: number;
  provider?: TherapistPayload;
  client?: ClientPayload;
}

const submitTalktrackFeedback = async (params: SubmitTalktrackFeedbackVariables): Promise<void> => {
  const { talktrackID, userID, provider, client } = params;
  const entity = client ? 'clients' : 'therapists';
  const payload = client || provider;

  const data = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/${entity}/${userID}/talktracks/${talktrackID}/feedback`,
    payload
  );
  return data.data;
};

const useMutationTalktrackFeedback = () =>
  useMutation<void, Error, SubmitTalktrackFeedbackVariables>(submitTalktrackFeedback);

export default useMutationTalktrackFeedback;
