/* eslint-disable arrow-body-style */
import { Fragment, forwardRef } from 'react';

import { useHistory } from 'react-router';
import { ColorRolesVersion } from '../../types';
import ChevronRight from '../../icons/ChevronRight';
import View from '../../../components/View';
import BaseButton from '../../../components/BaseButton';
import TextDS from '../typography/TextDS';
import styled from '../../../core/styled';

const getInteractiveStyles = (colorRoles: ColorRolesVersion) => {
  return {
    color: colorRoles.typography.textSubtlest,
    '&:hover': {
      color: `${colorRoles.button.brandSecondaryTextHovered}`,
    },
    '&:active': {
      color: colorRoles.button.brandSecondaryTextPressed,
    },
  };
};

const StyledLink = styled(BaseButton)(({ theme: { colorRoles } }) =>
  getInteractiveStyles(colorRoles)
);

export interface BreadcrumbRoute {
  label: string;
  path: string;
}

export interface BreadcrumbProps {
  routes: BreadcrumbRoute[];
}

const Breadcrumb = forwardRef<HTMLDivElement, BreadcrumbProps>(({ routes }, ref) => {
  const history = useHistory();
  const lastIdx = routes.length - 1;

  return (
    <View
      ref={ref}
      as="nav"
      aria-label="Breadcrumb"
      row
      columnGap={8}
      align="center"
      style={{ marginBottom: 8 }}
    >
      {routes.map((route, i) =>
        i < lastIdx ? (
          <Fragment key={`${route.path}/breadcrumb`}>
            <StyledLink
              onPress={() => {
                history.push(route.path);
              }}
            >
              <TextDS variant="headingSm" inheritColor>
                {route.label}
              </TextDS>
            </StyledLink>
            <ChevronRight size="small" colorType="subtlest" />
          </Fragment>
        ) : (
          <Fragment key={route.label}>
            <TextDS
              variant="headingSm"
              colorRole="textSubtlest"
              aria-current={i === lastIdx && 'page'}
            >
              {route.label}
            </TextDS>
          </Fragment>
        )
      )}
    </View>
  );
});

export default Breadcrumb;
