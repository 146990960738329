export const getIsDebugMenuEnabled = () => sessionStorage.getItem('debugMenuEnabled');

export const goToDebugMenu = () => {
  sessionStorage.setItem('debugMenuEnabled', 'true');
  window.location.href = '/debug';
};

export const injectDebugFunctions = () => {
  window.openDebugMenu = goToDebugMenu;
};
