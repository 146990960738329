import { VoidFunctionComponent } from 'react';
import { Button, Large } from '@talkspace/react-toolkit';

import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import { IneligibleReason } from '@/DTERenewal/hooks/useQueryRenewalEligibility';
import { ContentBodyWrapper, Headline, Content } from '../StyledComponents';

interface Props {
  ineligibleReason: IneligibleReason;
}

const WarningText = styled(Large)({ fontWeight: 'normal' });

const AlreadySubmittedWarning: VoidFunctionComponent<Props> = (props) => {
  const { ineligibleReason } = props;
  const closeModal = useCloseModal();
  const headlineText =
    ineligibleReason === 'not_eligible_for_benefit' ? 'Benefit Not Available' : 'Benefit Renewal';
  const contentHeight = ineligibleReason === 'not_eligible_for_benefit' ? 400 : 300;

  return (
    <Content height={contentHeight}>
      <Headline>{headlineText}</Headline>
      <ContentBodyWrapper>
        {ineligibleReason === 'not_eligible_for_benefit' ? (
          <>
            <WarningText style={{ marginBottom: 20 }} variant="largeMediumTSBlack">
              You previously indicated that you are no longer eligible for this benefit through your
              company or organization. If this was submitted in error, please reach out to
              partners-support@talkspace.com.
            </WarningText>
            <WarningText style={{ marginBottom: 50 }} variant="largeMediumTSBlack">
              Tap <b>Check my coverage</b> in the account menu to view other coverage and payment
              options.
            </WarningText>
          </>
        ) : (
          <>
            <WarningText variant="largeMediumTSBlack">
              Your account has already been renewed.
            </WarningText>
            <WarningText style={{ marginBottom: 50 }} variant="largeMediumTSBlack">
              No need for further action.
            </WarningText>
          </>
        )}

        <Button dataQa="dteRenewalAlreadySubmittedWarningCloseButton" onPress={() => closeModal()}>
          Close
        </Button>
      </ContentBodyWrapper>
    </Content>
  );
};

export default AlreadySubmittedWarning;
