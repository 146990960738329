import { useCallback, useEffect } from 'react';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { FEEDBACK_REMINDER_FOR_TALKTRACK_ID } from 'ts-frontend/hooks/useTalktrackFeedbackReminder';
import { useParams } from '@/core/routerLib/routerLib';
import storage from '@/core/storage';

const useTalktrackFeedbackEvents = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const { data: roomDetailsData } = useQueryRoomDetails(roomID);

  const handleDisplayTalktrackFeedback = useCallback(() => {
    if (!roomDetailsData) {
      return;
    }

    const { lastTalktrack: { talktrackID, talktrackReviewed } = {}, experimentalFeaturesClientOn } =
      roomDetailsData;
    const isOptedIn = experimentalFeaturesClientOn?.includes('talktracks');

    if (isOptedIn && !talktrackReviewed && talktrackID) {
      setTimeout(() => {
        storage.setItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID, talktrackID.toString());
        const talktrackClosedEvent = new CustomEvent('talktrackClosed');
        document.dispatchEvent(talktrackClosedEvent);
      }, 2000);
    }
  }, [roomDetailsData]);

  useEffect(() => {
    document.addEventListener('openTalktrackFeedback', handleDisplayTalktrackFeedback);

    return () => {
      document.removeEventListener('openTalktrackFeedback', handleDisplayTalktrackFeedback);
    };
  }, [handleDisplayTalktrackFeedback]);

  return null;
};

export default useTalktrackFeedbackEvents;
