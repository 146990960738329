import { useEffect } from 'react';
import { styled, View, Screen, TextDS, useWindowWidthState } from '@talkspace/react-toolkit';
import ButtonV2 from '@talkspace/react-toolkit/src/designSystems/components/Button/ButtonV2';
import { capitalize } from 'lodash';
import { RoomType } from 'chat/constants';
import { useTranslation } from '@talkspace/i18n';
import useMutationFeatureViews from 'ts-frontend/hooks/featureViews/useMutationFeatureViews';
import useQueryFeatureViews from 'ts-frontend/hooks/featureViews/useQueryFeatureViews';
import { useHistory } from '@/core/routerLib';
import { useCloseModal, useIsModal } from '@/utils/ModalsContextProvider';
import CircledShieldPlus from '@/components/icons/CircledShieldPlus';
import useNavShellData from '@/hooks/useNavShellData';
import { getServiceNameByRoomType, getServiceTypeByRoomType } from '../utils';
import { getUserData } from '@/auth/helpers/token';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL } from '@/community/constants';

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    ...(isMobile && {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 32,
    }),
  };
});

const Footer = styled(View)(({ theme: { colors, window } }) => {
  const { isMobile } = window;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: isMobile ? '100%' : '100vw',
    paddingTop: 10,
    borderTopWidth: isMobile ? 0 : 1,
    borderTopColor: colors.permaLightGrey,
    borderTopStyle: 'solid',
  };
});

const CenteredContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 384,
});

const CheckCoverageContainer = () => {
  const userID = `${getUserData().id}`;
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const { isMobile } = useWindowWidthState();

  const closeModal = useCloseModal();
  const isModal = useIsModal();
  const history = useHistory();

  const {
    dataByRoomID,
    queries: { isLoadingRoomsList },
  } = useNavShellData();

  const { isLoading: featureViewsLoading, data: featureViewsData } = useQueryFeatureViews(userID);

  const { mutate: markFeatureViewed } = useMutationFeatureViews();

  useEffect(() => {
    if (!featureViewsLoading && !!featureViewsData && featureViewsData.noEapSessionsLeft) {
      closeModal();
    }
  }, [featureViewsLoading, featureViewsData, closeModal]);

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL, 'true');
    trackEvent('View EAP sessions completed prompt', {});
  }, []);

  const eapRoom =
    dataByRoomID &&
    Object.keys(dataByRoomID)
      .map((roomID) => dataByRoomID[roomID])
      .find((room) => room.subscription?.subscription?.noEapSessionsLeft);

  const closeContainer = () => {
    closeModal();
  };

  const markDoItLater = () => {
    markFeatureViewed({ featureName: 'noEapSessionsLeft', userID });
  };

  const updateCoverage = () => {
    const roomID = eapRoom?.room?.roomID;
    const serviceType = getServiceTypeByRoomType(eapRoom?.subscription?.roomType as RoomType);
    const queryString = new URLSearchParams({
      source: 'update-coverage',
      serviceType,
      previousTherapistId: `${eapRoom?.therapistInfo?.id}`,
      fromPage: 'check-coverage',
    }).toString();
    history.push(`/eligibility-widget/room/${roomID}?${queryString}`);
  };

  const isTeen = !!eapRoom?.subscription?.subscription.isTeen;
  const roomType = (eapRoom?.subscription?.roomType || 'privateRoom') as RoomType;
  const serviceName = capitalize(getServiceNameByRoomType(roomType, isTeen));

  const therapistName = eapRoom?.therapistInfo?.firstName || '';
  const title = isLoadingRoomsList
    ? ''
    : `${serviceName} ${tnewMemberNav('eapToBh.with', 'with', undefined)} ${therapistName}`;
  const checkInsuranceText = isLoadingRoomsList
    ? ''
    : `${tnewMemberNav(
        'eapToBh.continueWorking',
        'To continue working with',
        undefined
      )} ${therapistName}, ${tnewMemberNav(
        'eapToBh.checkInsurance',
        'check your insurance and see how much it will cover',
        undefined
      )}`;

  return (
    <Screen
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
      showCloseButton
      onCloseButtonClick={closeContainer}
      closeButtonDataQa="CheckCoverageCloseButton"
      title="Check coverage"
      titleAlign="center"
      scrollViewStyles={{ paddingTop: !isMobile ? 88 : 0 }}
    >
      <Wrapper align="center" style={{ height: '100%' }}>
        <CenteredContainer>
          <CircledShieldPlus />
          <TextDS variant="headingMd" style={{ margin: '24px 0 24px 0', textAlign: 'center' }}>
            {title}
          </TextDS>
          <TextDS variant="headingXl" style={{ marginBottom: 12, textAlign: 'center' }}>
            {tnewMemberNav('eapToBh.outOfSessions', 'You ran out of session credits', undefined)}
          </TextDS>
          <TextDS variant="body" style={{ textAlign: 'center' }}>
            {checkInsuranceText}
          </TextDS>
        </CenteredContainer>
        <Footer>
          <ButtonV2
            onPress={updateCoverage}
            text={tnewMemberNav('eapToBh.updateMyCoverage', 'Update my coverage', undefined)}
            sizeDS="large"
            variant="primary"
            isLoading={isLoadingRoomsList}
            disabled={isLoadingRoomsList}
            dataQa="updateMyCoverageButton"
          />
          <ButtonV2
            onPress={markDoItLater}
            text={tnewMemberNav('eapToBh.doLater', `I'll do this later`, undefined)}
            sizeDS="large"
            variant="tertiary"
            dataQa="illDoThisLaterButton"
          />
        </Footer>
      </Wrapper>
    </Screen>
  );
};

export default CheckCoverageContainer;
