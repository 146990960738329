import { PolicyLinkType } from './PolicyLink';

type SupportedStates = 'NY';

const SUPPORTED_STATES: SupportedStates[] = ['NY'];

type LinkCategory = 'policy' | 'consent' | 'rules';

type StateLinks = Record<LinkCategory, PolicyLinkType>;

const CLIENT_STATE_LINKS: Record<string, StateLinks> = {
  NY: {
    policy: 'teenPrivacyPolicyNYC',
    consent: 'telemedicineConsentNYC',
    rules: 'termsOfUseNYC',
  },
};

const isSupportedState = (state?: string): state is SupportedStates =>
  state !== undefined && SUPPORTED_STATES.includes(state as SupportedStates);

export const getTelemedicineConsentLinkByState = (clientState?: string): PolicyLinkType => {
  if (isSupportedState(clientState)) {
    return CLIENT_STATE_LINKS[clientState].consent;
  }
  return 'telemedicineConsent';
};

export const getTermsOfUseByState = (clientState?: string): PolicyLinkType => {
  if (isSupportedState(clientState)) {
    return CLIENT_STATE_LINKS[clientState].rules;
  }
  return 'termsOfUse';
};

export const getPolicyLinkByState = (clientState?: string): PolicyLinkType => {
  if (isSupportedState(clientState)) {
    return CLIENT_STATE_LINKS[clientState].policy;
  }
  return 'teenPrivacyPolicy';
};
