import { Standard } from '../Typography';
import View from '../View';
import Link from '../Link';
import { useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

const FAQ_LINK_ELIGIBILITY_WARNING = 'https://help.talkspace.com/hc/en-us/articles/15849467636123';

const EligibilityWarningFAQLink = () => {
  const { colors } = useEmotionTheme();
  const haveQuestionsTextID = useUniqueID('haveQuestionsTextID');
  return (
    <View row align="center" style={{ marginTop: 24 }}>
      <Standard
        id={haveQuestionsTextID}
        style={{ fontSize: 14, fontWeight: 400, lineHeight: 1.8, color: colors.purple600 }}
      >
        Have questions?
      </Standard>
      <Link
        ariaDescribedBy={haveQuestionsTextID}
        href={FAQ_LINK_ELIGIBILITY_WARNING}
        style={{ textDecoration: 'none' }}
        target="_blank"
        roundedFocusStyle
        primaryColor={colors.permaTalkspaceDarkGreen}
        dataQa="faqLink"
      >
        <Standard
          style={{
            paddingLeft: 3,
            color: colors.permaTalkspaceDarkGreen,
            fontWeight: 700,
          }}
        >
          Visit the FAQ
        </Standard>
      </Link>
    </View>
  );
};

export default EligibilityWarningFAQLink;
