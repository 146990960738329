import { useState, useEffect, useMemo } from 'react';
import AgoraRTC, { ConnectionState, IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { getUserData } from '@/auth/helpers/token';
import { RemoteUserTracks } from '../types/videoCallTypes';

export interface MediaStreamState {
  remoteStreamList: RemoteUserTracks[];
  mediaLists: {
    cameras: MediaDeviceInfo[] | undefined;
    audioOuts: MediaDeviceInfo[] | undefined;
    audioIns: MediaDeviceInfo[] | undefined;
  };
  isDisconnected: boolean;
  activeSpeakerUserID: number | undefined;
  audioMutedByUserID: { [userID: number]: boolean };
  videoMutedByUserID: { [userID: number]: boolean };
}

const useMediaStream = ({
  client,
  isJoined,
}: {
  client: IAgoraRTCClient | undefined;
  isJoined: boolean;
}): MediaStreamState => {
  const [remoteStreamList, setRemoteStreamList] = useState<RemoteUserTracks[]>([]);
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);
  const [activeSpeakerUserID, setActiveSpeakerUserID] = useState<number | undefined>(undefined);
  const [audioMutedByUserID, setAudioMutedByUserID] = useState<{
    [userID: number]: boolean;
  }>({});
  const [videoMutedByUserID, setVideoMutedByUserID] = useState<{
    [userID: number]: boolean;
  }>({});
  const [audioOuts, setAudioOuts] = useState<MediaDeviceInfo[] | undefined>(undefined);
  const [audioIns, setAudioIns] = useState<MediaDeviceInfo[] | undefined>(undefined);
  const [cameras, setCameras] = useState<MediaDeviceInfo[] | undefined>(undefined);

  useEffect(() => {
    const activeSpeakerGone = remoteStreamList.find(
      (x) => Number(x.userID) === activeSpeakerUserID
    );
    if (activeSpeakerGone) {
      return;
    }
    const localUserID = getUserData().id;
    const newActiveSpeaker = remoteStreamList.find((x) => Number(x.userID) !== localUserID);
    if (!newActiveSpeaker) {
      return;
    }
    setActiveSpeakerUserID(newActiveSpeaker?.userID);
  }, [activeSpeakerUserID, remoteStreamList]);

  useEffect(() => {
    let mounted = true;

    const handleCameraChanged = async () => {
      const updatedCameras = await AgoraRTC.getCameras();

      setCameras(updatedCameras);
    };

    const handleConnectionStateChange = (curState: ConnectionState, prevState: ConnectionState) => {
      if (prevState === 'CONNECTED' && curState === 'DISCONNECTED') {
        setIsDisconnected(true);
      }
    };

    const handleMicrophoneChanged = async () => {
      const updatedAudioIns = await AgoraRTC.getMicrophones();

      setAudioIns(updatedAudioIns);
    };

    const handlePlaybackDeviceChanged = async () => {
      const updatedAudioOuts = await AgoraRTC.getPlaybackDevices();

      setAudioOuts(updatedAudioOuts);
    };

    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      const userID = Number(user.uid);

      setRemoteStreamList((prevList) => prevList.filter((x) => x.userID !== userID));

      setAudioMutedByUserID((prevObj) => {
        delete prevObj[userID]; // eslint-disable-line no-param-reassign
        return prevObj;
      });

      setVideoMutedByUserID((prevObj) => {
        delete prevObj[userID]; // eslint-disable-line no-param-reassign
        return prevObj;
      });
    };

    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      if (!mounted || !client) {
        return;
      }

      const userID = Number(user.uid);

      setRemoteStreamList((prevList) => {
        const existingEntry = prevList.find((x) => x.userID === userID);

        if (existingEntry) {
          return prevList;
        }

        const newEntry: RemoteUserTracks = {
          userID,
        };

        return [...prevList, newEntry];
      });

      setActiveSpeakerUserID(userID);
    };

    const handleUserPublished = async (
      remoteUser: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video'
    ) => {
      if (!mounted || !client || !isJoined || client.connectionState !== 'CONNECTED') {
        return;
      }

      await client.subscribe(remoteUser, mediaType);

      const userID = Number(remoteUser.uid);

      setRemoteStreamList((prevList) => {
        const updates: RemoteUserTracks = {
          audioTrack: remoteUser.audioTrack,
          userID,
          videoTrack: remoteUser.videoTrack,
        };

        if (mediaType === 'audio') {
          delete updates.videoTrack;
        } else if (mediaType === 'video') {
          delete updates.audioTrack;
        }

        if (prevList.find((x) => x.userID === userID)) {
          return prevList.map((x) => {
            if (x.userID === userID) {
              return {
                ...x,
                ...updates,
              };
            }

            return x;
          });
        }
        return [...prevList, updates];
      });

      if (mediaType === 'video') {
        setVideoMutedByUserID((prevObj) => {
          return {
            ...prevObj,
            [userID]: false,
          };
        });
      } else if (mediaType === 'audio') {
        setAudioMutedByUserID((prevObj) => {
          return {
            ...prevObj,
            [userID]: false,
          };
        });
      }
    };

    const handleUserUnpublished = async (
      remoteUser: IAgoraRTCRemoteUser,
      mediaType: 'audio' | 'video'
    ) => {
      if (!mounted || !client) {
        return;
      }

      const userID = Number(remoteUser.uid);

      setRemoteStreamList((prevList) =>
        prevList.map((x) => {
          if (x.userID === userID) {
            const updates = { ...x };

            if (mediaType === 'audio') {
              delete updates.audioTrack;
            } else if (mediaType === 'video') {
              delete updates.videoTrack;
            }

            return updates;
          }

          return x;
        })
      );

      if (mediaType === 'video') {
        setVideoMutedByUserID((prevObj) => {
          return {
            ...prevObj,
            [userID]: true,
          };
        });
      } else if (mediaType === 'audio') {
        setAudioMutedByUserID((prevObj) => {
          return {
            ...prevObj,
            [userID]: true,
          };
        });
      }
    };

    const handleVolumeIndicator = (volumes: { level: number; uid: String }[]) => {
      const localUserID = getUserData().id;
      const activeSpeakers = volumes
        .filter((x) => x.level > 5)
        .map((x) => Number(x.uid))
        .filter((x) => x !== localUserID);

      setActiveSpeakerUserID((currentActiveSpeaker) => {
        if (activeSpeakers.length === 0) {
          return currentActiveSpeaker;
        }

        if (currentActiveSpeaker && activeSpeakers.includes(currentActiveSpeaker)) {
          return currentActiveSpeaker;
        }

        return activeSpeakers[0];
      });
    };

    if (client) {
      client.enableAudioVolumeIndicator();

      client.on('connection-state-change', handleConnectionStateChange);
      client.on('user-left', handleUserLeft);
      client.on('user-joined', handleUserJoined);
      client.on('user-published', handleUserPublished);
      client.on('user-unpublished', handleUserUnpublished);
      client.on('volume-indicator', handleVolumeIndicator);

      AgoraRTC.onCameraChanged = handleCameraChanged;
      AgoraRTC.onMicrophoneChanged = handleMicrophoneChanged;
      AgoraRTC.onPlaybackDeviceChanged = handlePlaybackDeviceChanged;
    }

    return () => {
      mounted = false;
      if (client) {
        client.off('connection-state-change', handleConnectionStateChange);
        client.off('user-left', handleUserLeft);
        client.off('user-joined', handleUserJoined);
        client.off('user-published', handleUserPublished);
        client.off('user-unpublished', handleUserUnpublished);
        client.off('volume-indicator', handleVolumeIndicator);

        delete AgoraRTC.onCameraChanged;
        delete AgoraRTC.onMicrophoneChanged;
        delete AgoraRTC.onPlaybackDeviceChanged;
      }
    };
  }, [client, remoteStreamList, isJoined]);

  const memoizedState = useMemo<MediaStreamState>(() => {
    return {
      mediaLists: {
        cameras,
        audioOuts,
        audioIns,
      },
      isDisconnected,
      remoteStreamList,
      activeSpeakerUserID,
      audioMutedByUserID,
      videoMutedByUserID,
    };
  }, [
    cameras,
    audioOuts,
    audioIns,
    isDisconnected,
    remoteStreamList,
    activeSpeakerUserID,
    audioMutedByUserID,
    videoMutedByUserID,
  ]);
  return memoizedState;
};

export default useMediaStream;
