import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M18.1184 10.8666C19.884 9.10094 19.884 6.24157 18.1184 4.47594C16.5559 2.91344 14.0934 2.71032 12.2965 3.99469L12.2465 4.02907C11.7965 4.35094 11.6934 4.97594 12.0152 5.42282C12.3371 5.86969 12.9621 5.97594 13.409 5.65407L13.459 5.61969C14.4621 4.90407 15.834 5.01657 16.7027 5.88844C17.6871 6.87282 17.6871 8.46657 16.7027 9.45094L13.1965 12.9634C12.2121 13.9478 10.6184 13.9478 9.63398 12.9634C8.76211 12.0916 8.64961 10.7197 9.36523 9.71969L9.39961 9.66969C9.72148 9.21969 9.61523 8.59469 9.16836 8.27594C8.72148 7.95719 8.09336 8.06032 7.77461 8.50719L7.74023 8.55719C6.45273 10.3509 6.65586 12.8134 8.21836 14.3759C9.98398 16.1416 12.8434 16.1416 14.609 14.3759L18.1184 10.8666ZM1.88086 10.1353C0.115234 11.9009 0.115234 14.7603 1.88086 16.5259C3.44336 18.0884 5.90586 18.2916 7.70273 17.0072L7.75273 16.9728C8.20273 16.6509 8.30586 16.0259 7.98398 15.5791C7.66211 15.1322 7.03711 15.0259 6.59023 15.3478L6.54023 15.3822C5.53711 16.0978 4.16523 15.9853 3.29648 15.1134C2.31211 14.1259 2.31211 12.5322 3.29648 11.5478L6.80273 8.03844C7.78711 7.05407 9.38086 7.05407 10.3652 8.03844C11.2371 8.91032 11.3496 10.2822 10.634 11.2853L10.5996 11.3353C10.2777 11.7853 10.384 12.4103 10.8309 12.7291C11.2777 13.0478 11.9059 12.9447 12.2246 12.4978L12.259 12.4478C13.5465 10.6509 13.3434 8.18844 11.7809 6.62594C10.0152 4.86032 7.15586 4.86032 5.39023 6.62594L1.88086 10.1353Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const Link = withDSIconMaker(pathConfig, 'link');

export default Link;
