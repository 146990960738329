import { Trans, useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { A11Y_COLORS, PolicyLink, TextDS } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const AcceptTermsWrapper = styled.div({
  lineHeight: '18px',
  marginLeft: 15,
  color: A11Y_COLORS.darkGray,
});

const AcceptTerms = ({ userFirstName }: { userFirstName: string }) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });
  return (
    <AcceptTermsWrapper>
      <TextDS variant="bodyXs">
        <Trans t={tParentalConsent} i18nKey="consentPage.termsBodyTeen" lang="en">
          I have read and understood Talkspace’s <PolicyLink type="informedConsent" />,
          <PolicyLink type="noticeOfPrivacyPractices" />,
          <PolicyLink type="teenPrivacyPolicy" />, and
          <PolicyLink type="termsOfUse" text="Rules for using the Talkspace App" />, and give my
          consent for Talkspace providers to provide treatment services to{' '}
          {{ first_name: userFirstName }}.
        </Trans>
      </TextDS>
    </AcceptTermsWrapper>
  );
};

export default AcceptTerms;
