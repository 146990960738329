// TODO - decommission this file once the new DS 2.0 ProgressBar component is used in quickmatch & client-web onboarding

import { FunctionComponent } from 'react';
import HiddenLabel from '../HiddenLabel';
import { useUniqueID } from '../../hooks/a11yHelper';
import styled from '../../core/styled';

interface ProgressBarProps {
  maxProgress: number;
  currentProgress: number;
  isHidden?: boolean;
}

const StyledProgress = styled.progress(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaTalkspaceDarkGreen,
    color: colors.green,
    // In Firefox, background color applies to the background of the bar, instead of the progress itself like in Chrome.
    '@-moz-document url-prefix()': {
      backgroundColor: 'unset',
      color: 'unset',
    },
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    display: 'block',
    width: '100%',
    height: 3,
    border: 'none',
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1000,
    '::-webkit-progress-value': {
      backgroundColor: colors.permaTalkspaceDarkGreen,
      WebkitTransition: 'width 0.5s ease',
      MozTransition: 'width 0.5s ease',
      OTransition: 'width 0.5s ease',
      transition: 'width 0.5s ease',
    },
    '::-webkit-progress-bar': {
      backgroundColor: colors.permaBotticelli,
    },
    '::-ms-fill': {
      border: 'none',
    },
    '::-moz-progress-bar': {
      backgroundColor: colors.permaTalkspaceDarkGreen,
    },
  };
});
const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  isHidden,
  currentProgress,
  maxProgress,
}) => {
  const progressBarId = useUniqueID('progressBarId');
  return isHidden ? null : (
    <>
      <HiddenLabel htmlFor={progressBarId}>Current progress:</HiddenLabel>
      <StyledProgress
        id={progressBarId}
        aria-valuenow={currentProgress}
        aria-valuemin={0}
        aria-valuemax={maxProgress}
        value={currentProgress * 1000}
        max={maxProgress * 1000}
      />
    </>
  );
};

export default ProgressBar;
