import { BaseWizardState, baseWizardInitialState } from 'stepWizard';
import { QuestionnaireQuestion, QuestionnaireType } from '../types';

export interface State extends BaseWizardState {
  showLeaveWarning: boolean;
  roomID: number;
  clientUserID: number;
  questionnaireType: QuestionnaireType;
  questionnaireQuestions: QuestionnaireQuestion[];
  [key: `questionnaireQuestion${number}Options`]: Array<{ label: string; value: string }>;
  [key: `question${number}`]: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string,
  questionnaireType: QuestionnaireType
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    questionnaireType,
    questionnaireQuestions: [],
    showLeaveWarning: false,
  };
};
