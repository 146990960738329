import { OptionType } from '@talkspace/react-toolkit/src/components/Select/types';

import { TFKeysSwitchWizard } from '@talkspace/i18n/types';
import { ClientMatchPresentingProblem, TeenPresentingProblem } from '../types/Matches';

export const presentingProblemsList: ClientMatchPresentingProblem[] = [
  {
    id: 1,
    name: 'Anger Control Problems',
    expertiseID: 23,
    expertiseName: 'Anger management',
  },
  {
    id: 3,
    name: 'Anxiety',
    expertiseID: 25,
    expertiseName: 'Anxiety',
  },
  {
    id: 4,
    name: 'ADD - Adult',
    expertiseID: 17,
    expertiseName: 'ADHD',
  },
  {
    id: 5,
    name: 'Bipolar Disorder - Depression',
    expertiseID: 29,
    expertiseName: 'Bipolar disorder',
  },
  {
    id: 6,
    name: 'Bipolar Disorder - Mania',
    expertiseID: 29,
    expertiseName: 'Bipolar disorder',
  },
  {
    id: 7,
    name: 'Borderline Personality Disorder',
    expertiseID: 30,
    expertiseName: 'Borderline personality',
  },
  {
    id: 8,
    name: 'Childhood Trauma',
    expertiseID: 73,
    expertiseName: 'Trauma and PTSD',
  },
  {
    id: 9,
    name: 'Chronic Pain',
    expertiseID: 34,
    expertiseName: 'Chronic pain',
  },
  {
    id: 11,
    name: 'Dependency',
    expertiseID: 35,
    expertiseName: 'Chronic Relapse',
  },
  {
    id: 13,
    name: 'Eating Disorders',
    expertiseID: 45,
    expertiseName: 'Eating disorders',
  },
  {
    id: 15,
    name: 'Family Conflict',
    expertiseID: 47,
    expertiseName: 'Family conflict',
  },
  {
    id: 16,
    name: 'Sex therapy',
    expertiseID: 77,
    expertiseName: "Women's issues",
  },
  {
    id: 18,
    name: 'Grief/Bereavement',
    expertiseID: 49,
    expertiseName: 'Grief',
  },
  {
    id: 19,
    name: 'Trouble Controlling my behavior or impulses',
    expertiseID: 33,
    expertiseName: 'Chronic impulsivity',
  },
  {
    id: 20,
    name: 'Difficulty in relationship',
    expertiseID: 55,
    expertiseName: 'Marital and premarital',
  },
  {
    id: 22,
    name: 'Low Self-Esteem',
    expertiseID: 63,
    expertiseName: 'Self esteem',
  },
  {
    id: 23,
    name: 'Male Sexual Dysfunction',
    expertiseID: 56,
    expertiseName: "Men's issues",
  },
  {
    id: 24,
    name: 'Medical/Physical problem',
    expertiseID: 34,
    expertiseName: 'Chronic pain',
  },
  {
    id: 25,
    name: 'OCD/Other compulsive disorder',
    expertiseID: 59,
    expertiseName: 'OCD',
  },
  {
    id: 26,
    name: 'Panic/Agoraphobia',
    expertiseID: 25,
    expertiseName: 'Anxiety',
  },
  {
    id: 28,
    name: 'Parenting',
    expertiseID: 61,
    expertiseName: 'Parenting',
  },
  {
    id: 29,
    name: 'Phase of Life Problems',
    expertiseID: 37,
    expertiseName: 'Coping skills',
  },
  {
    id: 30,
    name: 'Specific Phobia',
    expertiseID: 25,
    expertiseName: 'Anxiety',
  },
  {
    id: 31,
    name: 'Trauma, PTSD or Sexual abuse',
    expertiseID: 73,
    expertiseName: 'Trauma and PTSD',
  },
  {
    id: 33,
    name: 'Sexual Abuse Survivor',
    expertiseID: 66,
    expertiseName: 'Sexual abuse',
  },
  {
    id: 35,
    name: 'Difficulty with sleep',
    expertiseID: 67,
    expertiseName: 'Sleep or insomnia',
  },
  {
    id: 36,
    name: 'Social Anxiety',
    expertiseID: 25,
    expertiseName: 'Anxiety',
  },
  {
    id: 39,
    name: 'Substance Abuse',
    expertiseID: 69,
    expertiseName: 'Substance use disorder',
  },
  {
    id: 42,
    name: 'Feeling down or depressed',
    expertiseID: 38,
    expertiseName: 'Depression',
  },
  {
    id: 43,
    name: 'Stress at work or school',
    expertiseID: 37,
    expertiseName: 'Coping skills',
  },
  {
    id: 44,
    name: 'Gambling/Internet/Video Games and other non-substance(behavioral) addictions',
    expertiseID: 48,
    expertiseName: 'Gambling',
  },
  {
    id: 45,
    name: 'Bipolar Disorder',
    expertiseID: 29,
    expertiseName: 'Bipolar disorder',
  },
  {
    id: null,
    name: "I'm not sure",
  },
  {
    id: 47,
    name: 'Gender identity',
    expertiseID: 221,
    expertiseName: 'Gender dysphoria and gender-affirming approaches to treatment',
  },
  {
    id: 48,
    name: 'Becoming my best self',
    expertiseID: 63,
    expertiseName: 'Low Self-Esteem',
  },
  {
    id: 49,
    name: 'Social media use',
    expertiseID: 167,
    expertiseName: 'Social media',
  },
  {
    id: 50,
    name: 'Difficulties at school ',
    expertiseID: 187,
    expertiseName: 'School issues',
  },
  {
    id: 51,
    name: 'Concentration or focus',
    expertiseID: 17,
    expertiseName: 'ADHD',
  },
  {
    id: 52,
    name: 'Improving my relationships',
    expertiseID: 171,
    expertiseName: 'Relationships',
  },
  {
    id: 53,
    name: 'Bullying',
    expertiseID: 62,
    expertiseName: 'Peer Relationship',
  },
  {
    id: 54,
    name: 'Racial, ethnic, or cultural identity',
    expertiseID: 186,
    expertiseName: 'Racial, ethnic, or cultural identity',
  },
  {
    id: null,
    name: 'Adolescent/Teenager',
    expertiseID: 135,
    expertiseName: 'Adolescents/teenagers (13 to 17)',
  },
];

/**
 * Return the list with fields of expertise ids based on problems Ids
 *
 * @param problemsIds
 * @returns {number[]}
 */
export const getFieldsOfExpertise = (problemsIds: number[] = []) => {
  const list = problemsIds
    .filter((id) => !!id)
    .reduce<ClientMatchPresentingProblem[]>(
      (aList, problemId) =>
        aList.concat(presentingProblemsList.filter(({ id }) => problemId === id)),
      []
    )
    .map(({ expertiseID: id }) => id);
  return Array.from(new Set(list));
};

// simplified presenting problems for therapy
export const simplifiedPresentingProblems: ClientMatchPresentingProblem[] = [
  { id: 3 as const, name: `I'm feeling anxious or panicky` },
  { id: 20 as const, name: `I'm having difficulty in my relationship` },
  { id: 31 as const, name: `A traumatic experience [past or present]` },
  { id: 35 as const, name: `I've been having trouble sleeping` },
  { id: 39 as const, name: `I'm navigating addiction or difficulty with substance abuse` },
  { id: 42 as const, name: `I'm feeling down or depressed` },
  { id: 43 as const, name: `I'm dealing with stress at work or school` },
  { id: 46 as const, name: `Something else` },
].map((item) => {
  return {
    ...item,
    translationKey: `quickmatch.common:simplifiedPresentingProblems.${item.id}` as const,
  };
});

// presenting problems for Israel therapy
export const presentingProblemsIsraelFlow: ClientMatchPresentingProblem[] = [
  { id: 31, name: `טראומה` },
  { id: 3, name: `חרדה` },
  { id: 18, name: `התמודדות עם אובדן` },
  { id: 18, name: `שכול` },
  { id: 15, name: `טיפול בבני משפחה בטראומה` },
  { id: 28, name: `הדרכת הורים` },
  { id: 46, name: `אחר` },
];

// simplified presenting problems for psychiatry, with exact problem descriptions
export const simplifiedPsychiatryPresentingProblems: ClientMatchPresentingProblem[] = [
  { id: 3 as const, name: 'Anxiety' },
  { id: 42 as const, name: 'Depression' },
  { id: 35 as const, name: 'Insomnia' },
  { id: 45 as const, name: 'Bipolar Disorder' },
  { id: 7 as const, name: 'Borderline Personality Disorder' },
  { id: 31 as const, name: 'Posttraumatic Stress Disorder' },
  { id: 25 as const, name: 'Obsessive-Compulsive Disorder' },
  { id: 46 as const, name: 'Other' },
].map((item) => {
  return {
    ...item,
    translationKey: `quickmatch.common:simplifiedPsychiatry.${item.id}`,
  };
});

export const teenPresentingProblems: TeenPresentingProblem[] = [
  { id: 3 as const, name: 'Anxiety or worrying' },
  { id: 42 as const, name: 'Sadness or depression' },
  { id: 13 as const, name: 'Body image or eating habits' },
  { id: 54 as const, name: 'Racial, ethnic, or cultural identity' },
  { id: 47 as const, name: 'Gender identity' },
  { id: 48 as const, name: 'Becoming my best self' },
  { id: 31 as const, name: 'Traumatic experience' },
  { id: 39 as const, name: 'Drug or alcohol use' },
  { id: 49 as const, name: 'Social media use' },
  { id: 50 as const, name: 'Difficulties at school' },
  { id: 15 as const, name: 'Difficulties at home or with family' },
  { id: 51 as const, name: 'Concentration or focus' },
  { id: 52 as const, name: 'Improving my relationships' },
  { id: 18 as const, name: 'Grief, loss, or tough transitions' },
  { id: 53 as const, name: 'Bullying' },
  { id: 46 as const, name: 'Other' },
].map((item) => {
  return {
    ...item,
    translationKey: `quickmatch.common:teenPresentingProblems.${item.id}`,
  };
});

type MatchPreferencesSummaryPresentingProblems = Omit<
  ClientMatchPresentingProblem,
  'translationKey'
> & {
  translationKey?: TFKeysSwitchWizard;
};

export const matchPreferencesSummaryPresentingProblems: MatchPreferencesSummaryPresentingProblems[] =
  [
    { id: 0 as const, name: 'ADD - Adult' },
    { id: 1 as const, name: 'Anger Control Problems' },
    { id: 2 as const, name: 'Anxiety' },
    { id: 3 as const, name: 'Becoming my best self' },
    { id: 4 as const, name: 'Bipolar Disorder' },
    { id: 5 as const, name: 'Borderline Personality Disorder' },
    { id: 6 as const, name: 'Bullying' },
    { id: 7 as const, name: 'Childhood Trauma' },
    { id: 8 as const, name: 'Chronic Pain' },
    { id: 9 as const, name: 'Concentration or focus' },
    { id: 10 as const, name: 'Dependency' },
    { id: 11 as const, name: 'Difficulties at school' },
    { id: 12 as const, name: 'Difficulty in relationships' },
    { id: 13 as const, name: 'Difficulty with sleep (ISI)' },
    { id: 14 as const, name: 'Eating Disorders' },
    { id: 15 as const, name: 'Family Conflict' },
    { id: 16 as const, name: 'Feeling down or depressed' },
    {
      id: 17 as const,
      name: 'Gambling/Internet/Video Games and other non-substance(behavioral) addictions',
    },
    { id: 18 as const, name: 'Gender identity' },
    { id: 19 as const, name: 'Grief/Bereavement' },
    { id: 20 as const, name: 'Improving my relationships' },
    { id: 21 as const, name: 'Low Self-Esteem' },
    { id: 22 as const, name: 'Male Sexual Dysfunction' },
    { id: 23 as const, name: 'Medical/Physical problem' },
    { id: 24 as const, name: 'OCD/Other compulsive disorder' },
    { id: 25 as const, name: 'Panic/Agoraphobia' },
    { id: 26 as const, name: 'Parenting' },
    { id: 27 as const, name: 'Phase of Life Problems' },
    { id: 28 as const, name: 'Racial, ethnic, or cultural identity' },
    { id: 29 as const, name: 'Sex therapy' },
    { id: 30 as const, name: 'Sexual Abuse Survivor' },
    { id: 31 as const, name: 'Social Anxiety' },
    { id: 32 as const, name: 'Social media use' },
    { id: 33 as const, name: 'Specific Phobia' },
    { id: 34 as const, name: 'Stress at work or school' },
    { id: 35 as const, name: 'Substance Abuse' },
    { id: 36 as const, name: 'Trauma, PTSD, and Sexual Abuse' },
    { id: 37 as const, name: 'Trouble Controlling my behavior or impulses' },
  ].map((item) => {
    return {
      ...item,
      translationKey: `FocusArea.${item.id}` as const,
    };
  });

export const getPresentingProblemsByIds = (
  problemIds: Array<ClientMatchPresentingProblem['id']> = [],
  problemsListName?:
    | 'simplifiedTherapy'
    | 'simplifiedPsychiatry'
    | 'teenPresentingProblems'
    | 'israelPresentingProblems'
) => {
  let problemsList = presentingProblemsList;
  switch (problemsListName) {
    case 'simplifiedTherapy':
      problemsList = simplifiedPresentingProblems;
      break;
    case 'simplifiedPsychiatry':
      problemsList = simplifiedPsychiatryPresentingProblems;
      break;
    case 'teenPresentingProblems':
      problemsList = teenPresentingProblems;
      break;
    case 'israelPresentingProblems':
      problemsList = presentingProblemsIsraelFlow;
      break;
    default:
      // no default
      break;
  }
  return problemsList.filter((el) => problemIds.includes(el.id));
};

export const mapSimplifiedToOriginalPresentingProblems = (
  simplifiedProblems: ClientMatchPresentingProblem[]
) =>
  simplifiedProblems
    // ignore problems with id: null,
    // since there are more than one original problems with null id
    .filter((el) => el.id !== null)
    .map((el) => presentingProblemsList.find((p) => p.id === el.id) || el);

export const reducePresentingProblemsOptionsByValue = (presentingProblems: OptionType<number>[]) =>
  presentingProblemsList.reduce((acc, presentingProblem) => {
    const match = presentingProblems.find((p) => p.value === presentingProblem.expertiseID);
    if (match) {
      acc.push(presentingProblem);
    }
    return acc;
  }, [] as ClientMatchPresentingProblem[]);

/**
 * Returns an array of numbers ( presentingProblemIds ) based on a string array
 * of presentingProblem expertiseNames. This helper exists because therapist match results
 * gives expertise by string name only.
 *
 * @param expertiseNames ['ADHD', 'Anxiety']
 * @returns presentingProblemIds as an Array of numbers
 */

export const getPresentingProblemIdsByExpertiseNames = (expertiseNames: string[]) =>
  presentingProblemsList
    .filter((problem) => problem.expertiseName && expertiseNames.includes(problem.expertiseName))
    .map((filteredProblem) => filteredProblem.id);
