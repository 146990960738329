import styled from '../../../core/styled';
import Button from '../Button';

const BrickV1 = styled(Button)<{ isSelected: boolean }>(
  ({ isSelected = false, theme: { colorRoles } }) => {
    return {
      height: 49,
      color: isSelected
        ? colorRoles.typography.textInverse
        : colorRoles.typography.textInteractiveDefault,
      backgroundColor: isSelected
        ? colorRoles.surfaces.surfaceInteractiveSelectedBold
        : colorRoles.surfaces.surfaceInteractiveDefault,
      borderRadius: 7,
      borderColor: isSelected
        ? colorRoles.borders.borderInteractiveSelectedBold
        : colorRoles.borders.borderInteractiveDefault,
      borderStyle: 'solid',
      fontWeight: 700,
      letterSpacing: 0.3,
      fontSize: 16,
      borderWidth: 1,
      padding: 14,
      display: 'inline',
      width: 'fit-content',
      margin: 5,
    };
  }
);

export default BrickV1;
