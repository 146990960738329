import { createNamespace } from 'ts-frontend/utils/dev-console';

export const ionicLogger = createNamespace('Ionic', false);
export const pushNotificationLogger = createNamespace('Notifications', false);
export const nativeBiometricLogger = createNamespace('Biometrics', false);
export const mediaLogger = createNamespace('Media', false);
export const liveUpdatesLogger = createNamespace('LiveUpdates', false);
export const deepLinkLogger = createNamespace('DeepLink', false);
export const splashScreenLogger = createNamespace('SplashScreen', false);
export const crashlyticsLogger = createNamespace('Crashlytics', false);
