import { FunctionComponent, useState } from 'react';
import {
  View,
  Large,
  Button,
  TextAreaRounded,
  useEmotionTheme,
  spacing,
} from '@talkspace/react-toolkit';

import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { deleteLastElementInPath } from '../../utils/url';
import { TherapistInfo, CancelBookingParams } from '../../types';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../../hooks/inRoomSchedulingContext';

const { space200 } = spacing;

const CancelReason: FunctionComponent<
  {
    therapist?: TherapistInfo;
  } & RouteComponentProps<{ roomID: string }, {}, { isReschedule: boolean }>
> = ({ therapist, history, match, location }) => {
  const { colors } = useEmotionTheme();
  const queryParams = new URLSearchParams(location.search);
  const cancelBatch = queryParams.get('batch') === 'true';
  const { dispatchSetCancelReason, dispatchCancelBooking } = useInRoomSchedulingActions();
  const { selectedCancelBooking, cancelReason } = useInRoomSchedulingState();
  const [isReasonMissing, setIsReasonMissing] = useState<boolean>(false);
  if (!therapist) {
    return null;
  }
  const { isReschedule = false } = location.state ? location.state : {};
  const isCancel = !isReschedule;
  const handleOnChangeText = () => {
    setIsReasonMissing(false);
  };
  const handleOnBlur = (e) => {
    dispatchSetCancelReason(e.target.value);
  };
  const handleOnButtonPress = () => {
    if (!selectedCancelBooking) return;
    if (!cancelReason) {
      setIsReasonMissing(true);
      return;
    }
    const baseURL = deleteLastElementInPath(match.url);
    if (isCancel) {
      const payload: CancelBookingParams = {
        shouldRedeemCredit: true, // if cancelling here it must be less than 24 hrs
        reason: cancelReason,
      };
      dispatchCancelBooking({
        bookingID: selectedCancelBooking.id,
        data: payload,
        cancelBatch: cancelBatch && !!selectedCancelBooking.repeatingBookingID,
      });
      history.push(`${baseURL}/confirm-cancel`);
      return;
    }
    history.push(`${baseURL}/confirm-booking/booking/${selectedCancelBooking.id}`, {
      isReschedule,
    });
  };

  return (
    <View flex={1} align="center" justify="space-between">
      <View align="center">
        {isReschedule ? (
          <Large variant="largeDarkGrey" style={{ textAlign: 'center', width: 200 }}>
            Let {therapist.firstName} know why you need to reschedule.
          </Large>
        ) : (
          <Large variant="largeDarkGrey" style={{ textAlign: 'center' }}>
            Tell {therapist.firstName} why you need to cancel.
          </Large>
        )}
        <TextAreaRounded
          style={{ marginTop: 50, width: 320 }}
          multiline
          onChangeText={handleOnChangeText}
          onBlur={handleOnBlur}
        />
        {isReasonMissing && (
          <Large style={{ color: colors.red, width: '100%' }}>You must provide a reason</Large>
        )}
      </View>
      <Button
        style={{ marginTop: space200 }}
        onPress={handleOnButtonPress}
        stretch
        text={isCancel ? 'Cancel session' : 'Continue'}
        dataQa="cancelReasonButton"
      />
    </View>
  );
};

export default withRouter(CancelReason);
