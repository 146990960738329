import { useCallback, useState, forwardRef } from 'react';
import { CardProps, CardVariant } from './types';
import { spacing } from '../../tokens';
import { getSurfaceStylesByVariant } from '../../styles/interactiveStateStyles';
import { RoleVariantName, ColorRolesVersion } from '../../types';
import styled from '../../../core/styled';
import View from '../../../components/View';
import TouchableView from '../../../components/TouchableView';

const { space100, space150, space200, space300 } = spacing;

const CardContainer = styled(View)<{
  variant?: CardVariant;
  disabled?: boolean;
  forceIdleState?: boolean;
  removeTopBorder?: boolean;
  removeBottomBorder?: boolean;
  padding?: number | string | 'default' | 'slim';
  surfaceRoleVariantName?: RoleVariantName | keyof ColorRolesVersion['surfaces'];
}>(
  ({
    variant = 'full',
    padding = 'default',
    disabled,
    forceIdleState,
    surfaceRoleVariantName,
    removeTopBorder,
    removeBottomBorder,
    theme: { colorRoles },
  }) => {
    const hasBorder = ['full', 'topBottomBorder'].includes(variant);
    const hasSideBorders = variant === 'full';
    const isRounded = variant !== 'topBottomBorder';

    const topBorderWidth = removeTopBorder ? 0 : 1;
    const bottomBorderWidth = removeBottomBorder ? 0 : 1;

    const borderStyle = {
      borderColor: colorRoles.borders.borderDefault,
      borderStyle: hasBorder ? 'solid' : 'none',
      borderRadius: isRounded ? 10 : 0,
      borderTopWidth: hasBorder ? topBorderWidth : 0,
      borderBottomWidth: hasBorder ? bottomBorderWidth : 0,
      borderLeftWidth: hasSideBorders ? 1 : 0,
      borderRightWidth: hasSideBorders ? 1 : 0,
    };
    const getPadding = () => {
      if (padding === 'default') return `${space300}px ${space200}px`;
      if (padding === 'slim') return `${space150}px ${space100}px`;
      return padding;
    };

    const surfaceStates = forceIdleState
      ? {
          colorRoles,
          disabled: false,
          active: false,
          hovered: false,
          multiplyBackground: undefined,
        }
      : {
          colorRoles,
          disabled,
          active: undefined,
          hovered: undefined,
          multiplyBackground: undefined,
        };
    const surfaceStyles = getSurfaceStylesByVariant(surfaceRoleVariantName || 'subtle')(
      surfaceStates
    );

    return {
      flex: 1,
      position: 'relative',
      padding: getPadding(),
      ...surfaceStyles,
      ...borderStyle,
    };
  }
);

const CardWithContentController = forwardRef<HTMLDivElement, CardProps>((props: CardProps, ref) => {
  const {
    isDisabled,
    children,
    variant,
    padding,
    removeBottomBorder,
    removeTopBorder,
    dataQa,
    forceIdleState: forceIdleStateProp,
    surfaceRoleVariantName,
    rowGap,
  } = props;

  const [forceIdleState, setForceIdleState] = useState(!!isDisabled || !!forceIdleStateProp);
  const onChildMouseEnter = useCallback(() => {
    setForceIdleState(true);
  }, [setForceIdleState]);
  const onChildMouseLeave = useCallback(() => {
    if (!!isDisabled || !!forceIdleStateProp) {
      return;
    }
    setForceIdleState(false);
  }, [setForceIdleState, isDisabled, forceIdleStateProp]);

  return (
    <CardContainer
      surfaceRoleVariantName={surfaceRoleVariantName}
      disabled={isDisabled}
      forceIdleState={forceIdleState}
      variant={variant}
      padding={padding}
      removeTopBorder={removeTopBorder}
      removeBottomBorder={removeBottomBorder}
      data-qa={dataQa}
      rowGap={rowGap}
      ref={ref}
    >
      {typeof children === 'function'
        ? children({ onChildMouseEnter, onChildMouseLeave })
        : children}
    </CardContainer>
  );
});

const InteractiveCard = forwardRef<HTMLDivElement, CardProps>((props: CardProps, ref) => {
  const { dataQa, isDisabled, tabIndex, 'aria-label': ariaLabel, ...touchableViewProps } = props;
  const { onPress } = touchableViewProps;
  if (!isDisabled && onPress) {
    return (
      <TouchableView
        row
        flex={1}
        {...touchableViewProps}
        roundedFocusStyle
        dataQa={dataQa}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        allowOnKeyDownPropagation
        ref={ref}
      >
        <CardWithContentController row flex={1} {...props} />
      </TouchableView>
    );
  }
  return (
    <View data-qa={dataQa} flex={1} tabIndex={tabIndex} aria-label={ariaLabel}>
      <CardWithContentController
        row
        flex={1}
        {...props}
        dataQa={dataQa}
        isDisabled={isDisabled}
        ref={ref}
      />
    </View>
  );
});

export default InteractiveCard;
