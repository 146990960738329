import {
  SquarePlus,
  Phone,
  CircleQuestion,
  PenToSquare,
  Files,
  BulletLines,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { Tag, spacing } from '@talkspace/react-toolkit';
import {
  immediateCrisisSupportLink,
  helpNowLink,
  helpCenterLink,
  reportAnIssueLink,
  viewMyTicketsLink,
} from 'ts-frontend/constants';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import { AccountCard } from '../../types';

const { space025, space150 } = spacing;

export const getCrisisSupportCards = ({
  tnewMemberNav,
  isUS,
  showSafetyPlanCard,
}: {
  tnewMemberNav: TFNewMemberNav;
  isUS: boolean;
  showSafetyPlanCard;
}): AccountCard[] => [
  ...(isUS
    ? [
        {
          titleText: tnewMemberNav('help.hotlineCardTitle', 'Crisis support hotline', undefined),
          bodyText: tnewMemberNav(
            'help.hotlineCardBody',
            'Call +1(833) 929-1721 to talk to a trained crisis counselor for 24/7 support',
            undefined
          ),
          Icon: <Phone size="major" colorType="brand" />,
          Tag: (
            <Tag
              color="criticalBoldDefault"
              text={tnewMemberNav('help.sos', 'SOS', undefined)}
              style={{ padding: '1px 8px', marginLeft: space150, marginTop: -space025 }}
              inline
            />
          ),
          hideCaret: false,
          route: immediateCrisisSupportLink,
          dataQa: 'accountHelpCallCrisisSupport',
        },
      ]
    : []),
  {
    titleText: tnewMemberNav('help.emergency', 'Emergency resources', undefined),
    bodyText: tnewMemberNav('help.more', 'More hotlines and helpful websites', undefined),
    Icon: <SquarePlus size="major" colorType="brand" />,
    route: helpNowLink,
    hideCaret: false,
    dataQa: 'accountHelpEmergencyResources',
  },
  ...(showSafetyPlanCard
    ? [
        {
          titleText: tnewMemberNav('help.safetyPlan', 'Your safety plan', undefined),
          bodyText: tnewMemberNav(
            'help.safetyPlanBody',
            'Refer to this plan in case you have thoughts of hurting yourself',
            undefined
          ),
          Icon: <BulletLines size="standard" colorType="brand" />,
          route: 'journey',
          isInnerRoute: true,
          hideCaret: false,
          dataQa: 'accountHelpSafetyPlan',
        },
      ]
    : []),
];

export const getTechnicalSupportCards = (tnewMemberNav: TFNewMemberNav): AccountCard[] => [
  {
    titleText: tnewMemberNav('help.visit', 'Visit our help center', undefined),
    Icon: <CircleQuestion size="major" colorType="brand" />,
    hideCaret: false,
    route: helpCenterLink,
    dataQa: 'accountHelpVisitHelpCenter',
    zendeskPath: ssoHelper.ZendeskPath.KNOWLEDGE_BASE as ZendeskPath,
  },
  {
    titleText: tnewMemberNav('help.report', 'Report an issue', undefined),
    Icon: <PenToSquare size="major" colorType="brand" />,
    hideCaret: false,
    route: reportAnIssueLink,
    dataQa: 'accountHelpReportAnIssue',
    zendeskPath: ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath,
  },
  {
    titleText: tnewMemberNav('help.view', 'View my tickets', undefined),
    Icon: <Files size="major" colorType="brand" />,
    hideCaret: false,
    route: viewMyTicketsLink,
    dataQa: 'accountHelpViewTickets',
    zendeskPath: ssoHelper.ZendeskPath.MY_TICKETS as ZendeskPath,
  },
];
