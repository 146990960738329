import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { StepWizardProvider } from 'stepWizard';
import { RouteComponentProps } from '../core/routerLib';
import { useGenericWizardActions } from '../utils/genericWizardActions';
import { getInitialState, State } from './reducers/QuestionnaireWizardState';
import Routes from './Routes';
import { getUserData } from '../auth/helpers/token';
import QuestionnaireWizardActions from './hooks/QuestionnaireWizardActions';
import { MainContextProvider } from '../hooks/mainContext';
import { QuestionnaireType } from './types';

interface QuestionnaireWizardRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
  questionnaireType: QuestionnaireType;
}

const QuestionnaireWizard: FunctionComponent<
  RouteComponentProps<QuestionnaireWizardRouteParams>
> = ({
  history,
  match: {
    params: { roomID, source, contextID = '', questionnaireType },
  },
}) => {
  const { id } = getUserData();

  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);

  const [initialState, setInitialState] = useState<State | undefined>();
  const genericQuestionnaireWizardActions = useGenericWizardActions(QuestionnaireWizardActions);

  useEffect(() => {
    setInitialState(getFirstState(id, roomID, source, contextID, questionnaireType));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {initialState && (
        <MainContextProvider>
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericQuestionnaireWizardActions}
          >
            <Routes history={history} roomID={roomID} questionnaireType={questionnaireType} />
          </StepWizardProvider>
        </MainContextProvider>
      )}
    </>
  );
};

export default QuestionnaireWizard;
