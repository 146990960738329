import { FunctionComponent } from 'react';
import { ExtraHuge, View, Large, Button, EnvelopeX, Text } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { RoomInviteStatus } from '../hooks/useRoomInvites';

const Wrapper = styled(View)({
  maxWidth: 600,
  paddingLeft: 30,
  paddingRight: 30,
  alignSelf: 'center',
  alignItems: 'center',
  width: '100%',
});

const IconContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 50,
});

const MessageTitleContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 15,
});

const MessageContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  textAlign: 'center',
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  textAlign: 'center',
});

const RevokeButtonMargin = styled(View)({
  marginTop: 20,
});

const InvitationSentSuccess: FunctionComponent<{
  setInviteScreen: (screen: RoomInviteStatus) => void;
  handleClosePanel: () => void;
  inviteEmail: string;
}> = ({ handleClosePanel, setInviteScreen, inviteEmail }) => {
  const sendNewInvitation = () => {
    setInviteScreen(RoomInviteStatus.HAS_NO_INVITES);
  };
  return (
    <Wrapper>
      <IconContainer>
        <EnvelopeX width={83} />
      </IconContainer>
      <MessageTitleContainer>
        <ExtraHuge>Invitation revoked</ExtraHuge>
      </MessageTitleContainer>
      <MessageContainer>
        <Large variant="largeDarkGrey">
          The invitation link sent to{' '}
          <Text inline style={{ fontWeight: 'bold' }}>
            {inviteEmail}
          </Text>{' '}
          has been invalidated.
        </Large>
      </MessageContainer>
      <ButtonContainer>
        <Button onClick={handleClosePanel}>Done</Button>
        <RevokeButtonMargin>
          <Button onClick={sendNewInvitation} isSecondary size="small" removeBorder>
            Send a new invitation
          </Button>
        </RevokeButtonMargin>
      </ButtonContainer>
    </Wrapper>
  );
};
export default InvitationSentSuccess;
