import { FunctionComponent, useMemo } from 'react';
import {
  View,
  Text,
  useEmotionTheme,
  ComplianceBadges,
  useUniqueID,
  CheckList,
} from '@talkspace/react-toolkit';
import { Stripe } from '@stripe/stripe-js';
import { PaymentDetails, Provider } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import { StringOrJSXElement } from '@talkspace/react-toolkit/src/utils/getStringOrJSXElementKey';
import styled, { EmotionStyle } from '@/core/styled';
import NewCard from './NewCard';
import NewCardWithLink from './NewCardWithLink';

interface Props {
  onSubmit: (token: string, paymentDetails?: PaymentDetails, email?: string) => void;
  resetError: () => void;
  onLoadStripeLink: () => void;
  onStripeLinkError: (error: any) => void;
  email?: string;
  isEmailRequired: boolean;
  provider: Provider;
  style?: EmotionStyle;
  submitText: string;
  errorMessage?: string;
  isProcessing: boolean;
  stripe: Stripe;
  isEmailDisabled?: boolean;
  buttonDescribedBy?: string;
  additionalMessage?: JSX.Element | string;
  useStripeLink: boolean;
  maxAdditionalPayment?: number;
  isBH?: boolean;
  showTemporaryHoldBullet?: boolean;
}

const Styled = {
  ListWrapper: styled(View)({
    alignItems: 'baseline',
    flexDirection: 'row',
    textAlign: 'left',
  }),
  ButtonDisclaimerWrapper: styled(View)({
    marginTop: 16,
    marginBottom: 8,
    maxWidth: 345,
    width: 'max-content',
  }),
};

const TemporaryHoldBullet = () => (
  <>
    To verify your card, we'll place a temporary $10 hold and <b>remove</b> it within 7 days.
  </>
);

const useAboveButtonDisclaimer = ({
  maxAdditionalPayment,
  isBH,
  showTemporaryHoldBullet,
}: {
  maxAdditionalPayment?: number;
  isBH?: boolean;
  showTemporaryHoldBullet?: boolean;
}) => {
  const { colors } = useEmotionTheme();
  const items = useMemo(() => {
    const bullets: StringOrJSXElement[] = [];
    if (isBH) {
      bullets.push(
        `You won’t pay until your first session.`,
        `After your first session, we'll bill your insurance. Based on your plan and deductible, you may owe an additional payment up to $${maxAdditionalPayment}.`
      );
    }
    bullets.push(<TemporaryHoldBullet />);

    return bullets;
  }, [isBH, maxAdditionalPayment]);

  if (isBH || showTemporaryHoldBullet) {
    return (
      <Styled.ButtonDisclaimerWrapper>
        <CheckList items={items} bulletTextStyle={{ color: colors.darkGray }} />
      </Styled.ButtonDisclaimerWrapper>
    );
  }

  return undefined;
};

const PaymentForm: FunctionComponent<Props> = ({
  onSubmit,
  resetError,
  onLoadStripeLink,
  onStripeLinkError,
  email,
  isEmailRequired,
  provider,
  style,
  submitText,
  errorMessage,
  isProcessing,
  stripe,
  isEmailDisabled,
  buttonDescribedBy,
  additionalMessage,
  useStripeLink,
  maxAdditionalPayment,
  isBH,
  showTemporaryHoldBullet,
}) => {
  const { colors } = useEmotionTheme();
  const sslPaymentTextID = useUniqueID('sslPaymentTextID');
  const aboveButtonDisclaimer = useAboveButtonDisclaimer({
    maxAdditionalPayment,
    isBH,
    showTemporaryHoldBullet,
  });
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  return (
    <View align="stretch" style={style}>
      {useStripeLink ? (
        <NewCardWithLink
          email={email}
          isEmailRequired={isEmailRequired}
          isEmailDisabled={isEmailDisabled}
          onSubmit={onSubmit}
          resetError={resetError}
          onLoadStripeLink={onLoadStripeLink}
          onError={onStripeLinkError}
          provider={provider}
          submitText={submitText}
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          stripe={stripe}
          aboveButtonDisclaimer={aboveButtonDisclaimer}
        />
      ) : (
        <NewCard
          email={email}
          isEmailRequired={isEmailRequired}
          isEmailDisabled={isEmailDisabled}
          onSubmit={onSubmit}
          provider={provider}
          submitText={submitText}
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          stripe={stripe}
          buttonDescribedBy={`${buttonDescribedBy || ''}${sslPaymentTextID}`}
          aboveButtonDisclaimer={aboveButtonDisclaimer}
        />
      )}
      <View align="center">
        {additionalMessage || null}
        <Text
          id={sslPaymentTextID}
          style={{
            color: colors.osloGrey,
            fontSize: 12,
            marginLeft: 8,
            marginTop: 20,
          }}
        >
          {tBookingScreen('payment.all', 'All payments are secure SSL encrypted.', undefined)}
        </Text>
        <ComplianceBadges style={{ marginTop: 20 }} />
      </View>
    </View>
  );
};

export default PaymentForm;
