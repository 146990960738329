export enum AdminConfigName {
  NO_AVAILABILITY_PROMPT = 'no_availability_prompt',
  TREATMENT_INTAKE_IN_ONBOARDING = 'treatment_intake_in_onboarding',
  BI_MONTHLY_PAYOUT = 'bi_monthly_payout',
  CONSOLIDATED_ICP_RATES = 'consolidated_icp_rates',
}

export enum PublicAdminConfigName {
  INTEGRATIONS_GLOBAL_CONFIG = 'integrations_global_config',
}

export const DYNAMIC_INTEGRATIONS_CONFIG = 'dynamic_integrations_config';

export interface AdminConfigApiResponse {
  success: boolean;
  data: number;
}
