import { FunctionComponent } from 'react';
import {
  View,
  TouchableView,
  Tiny,
  Large,
  Big,
  ExtraHuge,
  ImplicitBusinessHoursChart,
  TimeOff,
  TimeZone,
  HiddenText,
  useUniqueID,
  Tooltip,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { TherapistInfo } from 'ts-frontend/entities/Therapist';
import { useTranslation, Trans } from '@talkspace/i18n';
import DetailsRow from '../DetailsRow';
import LinkIcon from '../icons/LinkIcon';
import TalkspaceVerifiedIcon from '../icons/TalkspaceVerifiedIcon';
import TherapistLicenseIcon from '../../clientChat/assets/TherapistLiceneseIcon';
import { ImplicitBusinessHoursByDay } from '../../clientChat/reducers/inPlatformMatchingReducer';

export interface TherapistDetailsViewPTProps {
  therapist: TherapistInfo;
  businessHoursByDay?: ImplicitBusinessHoursByDay;
  timeOffText: string;
  error: string | null;
}

const TherapistDetailsViewPT: FunctionComponent<TherapistDetailsViewPTProps> = ({
  therapist,
  businessHoursByDay,
  timeOffText,
  error,
}) => {
  const { brandRefreshChanges } = useFlags();
  const newTabInfoID = useUniqueID('newTabInfoID');
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <View style={{ alignSelf: 'stretch' }}>
      <View row align="center" style={{ marginBottom: 40, marginTop: 25 }}>
        <Big as="h2">
          {tnewMemberNav('providerProfile.availability', 'Availability', undefined)}
        </Big>
        <Tooltip
          label={tnewMemberNav(
            'providerProfile.more',
            'More info about provider availability bar graph',
            undefined
          )}
          tip={tnewMemberNav(
            'providerProfile.the',
            'The bars in this graph represent the level of typical activity for this provider on a given day. The data is sourced from the past several weeks of platform activity. It is intended to help you find a provider whose activity patterns best fit your schedule.',
            undefined
          )}
          toggleSize={24}
          buttonStyle={{ display: 'flex', marginLeft: 8 }}
        />
      </View>
      <View style={{ width: 335, marginBottom: 50, alignSelf: 'center' }}>
        <ImplicitBusinessHoursChart
          implicitBusinessHoursByDay={error ? undefined : businessHoursByDay}
          businessDays={therapist.businessDays}
        />
      </View>
      <DetailsRow
        title={tnewMemberNav('providerProfile.on', 'On time off', undefined)}
        text={timeOffText}
        renderLeft={() => <TimeOff width={24} height={25} />}
      />
      <DetailsRow
        title={tnewMemberNav('providerProfile.timezone', 'Timezone', undefined)}
        text={therapist.businessHoursTimezone}
        renderLeft={() => <TimeZone width={22} height={25} />}
      />
      <Big style={{ marginBottom: 6, marginTop: 18 }} as="h2">
        {tnewMemberNav('providerProfile.licenses', 'Licenses', undefined)}
      </Big>
      <DetailsRow
        textItems={[
          therapist.licenses[0],
          ...therapist.licenseDetails.map(
            ({ state, number, name }) => `${name} ${number} ${state}`
          ),
        ]}
        renderLeft={() => <TherapistLicenseIcon width={24} height={27} />}
      />
      <Big style={{ marginBottom: 6, marginTop: 18 }} as="h2">
        {tnewMemberNav('providerProfile.treatment', 'Treatment experience', undefined)}
      </Big>
      <DetailsRow
        title={tnewMemberNav('providerProfile.approach', 'Approach', undefined)}
        textItems={therapist.expertise.treatmentApproach}
        shouldRenderTextItemsInline
      />
      <DetailsRow
        title={tnewMemberNav('providerProfile.focus', 'Focus', undefined)}
        textItems={therapist.expertise.focus}
        shouldRenderTextItemsInline
      />
      <Tiny variant="tinyAllCaps" style={{ marginTop: 19 }}>
        {tnewMemberNav('providerProfile.years', 'Years in practice', undefined)}
      </Tiny>
      <ExtraHuge>{therapist.yearsInPractice}</ExtraHuge>
      <View row align="stretch" justify="space-between" style={{ marginTop: 20, maxWidth: 341 }}>
        <View justify="space-around">
          <TouchableView
            elementType="a"
            aria-describedby={newTabInfoID}
            onPress={() => {
              window.open(`https://therapists.psychologytoday.com/${therapist.id}`, '_blank');
            }}
            row
            align="center"
            justify="space-between"
          >
            <LinkIcon aria-hidden="true" />
            <Large variant="largeBoldWideGreen" style={{ marginLeft: 5 }}>
              {tnewMemberNav('providerProfile.psychology', 'Psychology Today profile', undefined)}
            </Large>
          </TouchableView>
          <HiddenText id={newTabInfoID}>
            {tnewMemberNav('providerProfile.opens', 'opens in new tab', undefined)}
          </HiddenText>
          <Large>
            <Trans>
              Joined Talkspace {therapist.joinedYearsAgo} year
              {therapist.joinedYearsAgo === 1 ? '' : 's'} ago.
            </Trans>
          </Large>
        </View>
        {!brandRefreshChanges && <TalkspaceVerifiedIcon />}
      </View>
      <View style={{ height: 50 }} />
    </View>
  );
};

export default TherapistDetailsViewPT;
