import { useMemo } from 'react';
import { useLocation } from '@/core/routerLib';

const useUpdateCoverageParams = (): Record<string, string> => {
  const location = useLocation();

  const updateCoverageParams = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const serviceType = queryParams.get('serviceType');
    const qmFlowID = queryParams.get('qmFlowID');
    const prevTherapistId = queryParams.get('previousTherapistId');
    const hasPaymentMethod = queryParams.get('hasPaymentMethod');
    const source = queryParams.get('source') || '';
    const keepProvider = queryParams.get('keepProvider');
    const skipCapacityCheck = queryParams.get('skipCapacityCheck');
    const fromPage = queryParams.get('fromPage');

    const params = {
      ...(qmFlowID && { 'qm-flow': qmFlowID }),
      source,
      ...(serviceType && { serviceType }),
      previousTherapistId: prevTherapistId || '0',
      ...(keepProvider && { keepProvider }),
      ...(hasPaymentMethod && { hasPaymentMethod }),
      ...(skipCapacityCheck && { skipCapacityCheck }),
      ...(fromPage && { fromPage }),
    };
    return params;
  }, [location]);
  return updateCoverageParams;
};
export default useUpdateCoverageParams;
