import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const CrossedEye = ({ width = 27, height = 24, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'eye with strike';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.white}
          stroke={colors.periwinkleGrey}
          d="M10 0h315c5.523 0 10 4.477 10 10v30c0 5.523-4.477 10-10 10H10C4.477 50 0 45.523 0 40V10C0 4.477 4.477 0 10 0z"
          transform="translate(-293 -13)"
        />
        <Path
          fill={colors.periwinkleGrey}
          d="M2.207.707a1.002 1.002 0 011.41-.005L24.84 21.925a.994.994 0 01-.005 1.41c-.391.39-1.027.387-1.41.005L2.202 2.116A.994.994 0 012.207.707zm1.59 5.127l3.691 3.69a6.5 6.5 0 008.487 8.489l2.882 2.88C17.2 21.596 15.384 22.02 13.5 22 6.27 21.925.016 15.621 0 12c-.008-1.752 1.46-4.154 3.798-6.165zM13.5 2c7.23.018 13.527 6.379 13.5 10-.013 1.747-1.474 4.154-3.795 6.17l-3.693-3.694a6.5 6.5 0 00-8.489-8.487L8.155 3.121C9.81 2.423 11.624 1.995 13.5 2zm-4.83 8.706l6.124 6.125a5 5 0 01-6.125-6.125zM13.5 7a5 5 0 014.83 6.294L12.206 7.17C12.618 7.06 13.052 7 13.5 7z"
        />
      </G>
    </Svg>
  );
};

export default CrossedEye;
