import { FeedbackOption, TextAreaTitles, FeedbackType } from '@talkspace/react-toolkit';

type TalktrackFeedbackType = 'helpful' | 'notGood' | 'justOK';

export const textAreaTitles: TextAreaTitles<TalktrackFeedbackType> = {
  helpful:
    'What did you like about the Talkcast? Please describe it without including any identifiable information about you.',
  notGood:
    'What was the issue with the Talkcast? Please describe it without including any identifiable information about you.',
  justOK:
    'What could’ve been better with the Talkcast. Please describe it without including any identifiable information about you.',
};

export const feedbackCheckboxes: Record<TalktrackFeedbackType, FeedbackOption[]> = {
  helpful: [
    { label: 'Felt relevant to me', field: 'feltRelevant' },
    { label: 'Insightful content', field: 'insightfulContent' },
    { label: 'Helpful activity between sessions', field: 'helpfulActivityBetweenSessions' },
  ],
  notGood: [
    { label: 'Topic wasn’t relevant', field: 'topicNotRelevant' },
    { label: 'It’s not something I enjoy doing', field: 'dontEnjoyDoing' },
    { label: 'Episode length', field: 'episodeLength' },
  ],
  justOK: [
    { label: 'Topic wasn’t relevant', field: 'topicNotRelevant' },
    { label: 'It’s not something I enjoy doing', field: 'dontEnjoyDoing' },
    { label: 'Episode length', field: 'episodeLength' },
  ],
};

export const feedbackTypes: FeedbackType<TalktrackFeedbackType>[] = [
  {
    label: 'Not good',
    value: 'notGood',
    dataQa: 'notGoodChip',
  },
  {
    label: 'Just okay',
    value: 'justOK',
    dataQa: 'justOkayChip',
  },
  {
    label: 'Helpful',
    value: 'helpful',
    dataQa: 'helpfulChip',
  },
];

export const feedbackToggles: Record<TalktrackFeedbackType, FeedbackOption[]> = {
  helpful: [],
  notGood: [{ label: 'Don’t send me any more Talkcasts', field: 'optOut' }],
  justOK: [{ label: 'Don’t send me any more Talkcasts', field: 'optOut' }],
};
