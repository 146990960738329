import Link from '../Link';
import TextDS, { TextDSLinkVariants } from '../../designSystems/components/typography/TextDS';
import { ColorRolesVersion } from '../../designSystems';

export type PolicyLinkType =
  | 'termsOfUse'
  | 'termsOfUseNYC'
  | 'informedConsent'
  | 'telemedicineConsentNYC'
  | 'telemedicineConsent'
  | 'noticeOfPrivacyPractices'
  | 'privacyPolicy'
  | 'teenPrivacyPolicy'
  | 'teenPrivacyPolicyNYC';

const linkMapping: { [key in PolicyLinkType]: { text: string; href: string; dataQa: string } } = {
  // Terms of Use
  termsOfUse: {
    text: 'Terms of use',
    href: 'https://www.talkspace.com/public/terms',
    dataQa: 'termsOfUseLink',
  },
  termsOfUseNYC: {
    text: 'Rules for Using NYC Teenspace', // NOTE: NYC wants to use "Rule" instead of "Terms"
    href: 'https://talkspace.com/public/nyc-teenspace-rules',
    dataQa: 'termsOfUseNYCLink',
  },

  // Informed Consent
  informedConsent: {
    text: 'Informed Consent',
    href: 'https://www.talkspace.com/public/informed-consent',
    dataQa: 'informedConsentLink',
  },

  // Telemedicine
  telemedicineConsent: {
    text: 'Telemedicine',
    href: 'https://www.talkspace.com/public/telemedicine',
    dataQa: 'telemedicineConsentLink',
  },
  telemedicineConsentNYC: {
    text: 'Telemedicine',
    href: 'https://talkspace.com/public/nyc-teenspace-informed-consent',
    dataQa: 'telemedicineConsentLinkNYC',
  },

  // Notice of Privacy Practices
  noticeOfPrivacyPractices: {
    text: 'Notice of Privacy Practices',
    href: 'https://www.talkspace.com/notice-of-privacy-practices',
    dataQa: 'noticeOfPrivacyPracticesLink',
  },

  // Privacy Policy
  privacyPolicy: {
    text: 'Privacy Policy',
    href: 'https://www.talkspace.com/public/privacy-policy',
    dataQa: 'privacyPolicyLink',
  },

  // Teen Privacy Policy
  // - default
  teenPrivacyPolicy: {
    text: 'Teenspace Privacy Policy',
    href: 'https://www.talkspace.com/public/teenspace-privacy-policy',
    dataQa: 'teenPrivacyPolicyLink',
  },
  // - NYC
  teenPrivacyPolicyNYC: {
    text: 'NYC Teenspace Privacy Policy',
    href: 'https://talkspace.com/public/nyc-teenspace-privacy-policy',
    dataQa: 'teenPrivacyPolicyNYCLink',
  },
};

type PunctuationMark = '.' | ',';

interface Props {
  type: PolicyLinkType;
  text?: string;
  textVariant?: typeof TextDSLinkVariants[number];
  punctuationMark?: PunctuationMark;
  punctuationColorRole?: keyof ColorRolesVersion['typography'];
}

const PolicyLink = ({ type, text, textVariant, punctuationMark, punctuationColorRole }: Props) => {
  const { text: defaultText, href, dataQa } = linkMapping[type];
  return (
    <>
      <Link dataQa={dataQa} href={href} target="_blank" roundedFocusStyle>
        <TextDS inline variant={textVariant || 'linkXs'} colorRole="textInteractiveDefault">
          {text || defaultText}
        </TextDS>
      </Link>
      {punctuationMark && (
        <TextDS
          inline
          variant="bodyXs"
          colorRole={punctuationColorRole || 'textInteractiveDefault'}
          style={{ marginLeft: '-3px' }}
        >
          {punctuationMark}
        </TextDS>
      )}
    </>
  );
};

export default PolicyLink;
