import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { View, useEmotionTheme, useWindowWidthState } from '@talkspace/react-toolkit';
import {
  APINotificationPreference,
  Preferences,
  NotificationType,
} from 'ts-frontend/types/Notifications';
import { useTranslation } from '@talkspace/i18n';

import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '../../../core/styled';
import AccountUpdatesAccordion from '../NotificationAccordions/AccountUpdates';
import RoomActivityAccordion from '../NotificationAccordions/RoomActivity';
import SessionRemindersAccordion from '../NotificationAccordions/SessionReminders';
import MarketingCommunicationsAccordion from '../NotificationAccordions/MarketingCommunications';
import TeenspaceNotificationsAccordion from '../NotificationAccordions/TeenspaceNotifications';
import SuperbillsAccordion from '../NotificationAccordions/Superbills';
import NotificationTitle from '../NotificationTitle';
import NotificationToggle from '../NotificationToggle';
import { NotificationPreferencesByName } from '../../reducers/notificationPreferences';
import useObjectState from '../../hooks/useObjectState';
import ReactFrameService from '../../../auth/reactFrame/ReactFrameService';
import useNotificationPreferences from '../../hooks/useNotificationPreferences';
import useNavShellData from '../../../hooks/useNavShellData';
import { hasTeenspaceCommunity } from '../../../community/utils/helpers';
import { trackNotificationsSettingChanged } from '../../../utils/analytics/events';

const NotificationTogglePanel = styled(View)<{ useNewNav: boolean }>(
  ({ theme: { window }, useNewNav }) => {
    const { isMobile } = window;
    const baseStyling = {
      minWidth: isMobile ? '100%' : '335',
      width: 'unset',
      border: '1px solid #D2D8E3',
    };
    const oldNavStyling = {
      ...baseStyling,
      borderRadius: isMobile ? 0 : 10,
      marginTop: 30,
      maxWidth: isMobile ? '100%' : 500,
    };
    const newNavStyling = {
      ...baseStyling,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      maxWidth: 'unset',
    };

    return useNewNav ? newNavStyling : oldNavStyling;
  }
);

const DesktopNotificationSoundPanel = styled(NotificationTogglePanel)<{ useNewNav: boolean }>({
  height: 75,
  marginBottom: 15,
});

const ContentArea = styled(View)<{ useNewNav: boolean }>(({ useNewNav, theme: { spacing } }) => {
  return {
    paddingTop: spacing('space200'),
    maxWidth: useNewNav ? 'unset' : 'calc(100% - 5px)',
    paddingRight: useNewNav ? 15 : 40,
    marginLeft: useNewNav ? 15 : 20,
    paddingBottom: spacing('space200'),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

export interface FormRef {
  getFormState: () => NotificationPreferencesByName;
}

interface Props {
  notificationPreferencesByName: NotificationPreferencesByName;
  isError: boolean;
  patchNotificationPreferences: (
    payload: APINotificationPreference[],
    formState: NotificationPreferencesByName
  ) => void;
}

const Notifications: FunctionComponent<Props> = ({
  notificationPreferencesByName,
  isError,
  patchNotificationPreferences,
}) => {
  const [isAccountUpdatesOpen, setIsAccountUpdatesOpen] = useState(false);
  const [isRoomActivityOpen, setIsRoomActivityOpen] = useState(false);
  const [isSessionRemindersOpen, setIsSessionRemindersOpen] = useState(false);
  const [isMarketingCommunicationsOpen, setIsMarketingCommunicationsOpen] = useState(false);
  const [isSuperbillsOpen, setIsSuperbillsOpen] = useState(false);
  const [isTeenspaceNotificationsOpen, setIsTeenspaceNotificationsOpen] = useState(false);
  const [{ superbillsFlag }] = useNotificationPreferences();

  const [form, setNotificationPreferences] = useObjectState<NotificationPreferencesByName>(
    notificationPreferencesByName
  );

  const formRef = useRef<FormRef | null>(null);

  formRef.current = { getFormState: () => form };

  useEffect(() => {
    if (isError) {
      setNotificationPreferences(notificationPreferencesByName);
    }
  }, [notificationPreferencesByName, isError, setNotificationPreferences]);

  const {
    accountUpdate,
    roomActivity,
    sessionReminder,
    marketingNews,
    marketingPromotions,
    marketingBlog,
    marketingEducation,
    soundNotification,
    superbills,
    communityComments,
    communityDailyPrompt,
  } = form;

  function saveChanges(updatedObject: Partial<NotificationPreferencesByName>) {
    if (formRef.current) {
      const formState = formRef.current.getFormState();
      const payload = Object.keys(formState)
        .map((name) => {
          if (updatedObject[name]) {
            return { name, ...updatedObject[name] };
          }
          return null;
        })
        .filter(Boolean) as APINotificationPreference[];
      patchNotificationPreferences(payload, formState);
    }
  }

  interface UpdatedObject {
    [key: string]: boolean;
  }

  function handleToggle(
    notificationName: Preferences,
    notificationType: NotificationType,
    valueInt: number
  ) {
    const value = Boolean(valueInt);
    const updatedObject: { [key: string]: UpdatedObject } =
      notificationType !== 'all'
        ? {
            [notificationName]: {
              ...form[notificationName],
              [notificationType]: value,
            },
          }
        : {
            [notificationName]: {
              ...form[notificationName],
              email: value,
              sms: value,
              push: value,
            },
          };

    if (notificationName === 'soundNotification' || notificationName === 'sendMessagesReceipts') {
      updatedObject[notificationName].sms = value;
      updatedObject[notificationName].push = value;
    }

    setNotificationPreferences(updatedObject);
    saveChanges(updatedObject);
    trackNotificationsSettingChanged({ notificationName, notificationType, value });
  }

  const { isMobile } = useWindowWidthState();
  const isInFrame = ReactFrameService.instance().isInFrame();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const isMobileNative = ReactFrameService.isMobile();
  const useNewNav = useNewMemberNav();
  const { colorRoles } = useEmotionTheme();
  const {
    queries: { isLoadingSubscriptions },
    subscriptions,
  } = useNavShellData();
  const shouldShowTeenspaceFeatures =
    !isLoadingSubscriptions &&
    !isMobileNative &&
    subscriptions &&
    hasTeenspaceCommunity(subscriptions);

  return (
    <>
      <AccountUpdatesAccordion
        isOpen={isAccountUpdatesOpen}
        handlePress={(value) => {
          setIsAccountUpdatesOpen(value);
        }}
        isMobile={isMobile}
        accountUpdate={accountUpdate}
        handlePushToggle={(value) => handleToggle('accountUpdate', 'push', value)}
        handleSMSToggle={(value) => handleToggle('accountUpdate', 'sms', value)}
      />
      <RoomActivityAccordion
        isOpen={isRoomActivityOpen}
        handlePress={(value) => {
          setIsRoomActivityOpen(value);
        }}
        isMobile={isMobile}
        roomActivity={roomActivity}
        handlePushToggle={(value) => handleToggle('roomActivity', 'push', value)}
        handleEmailToggle={(value) => handleToggle('roomActivity', 'email', value)}
      />
      <SessionRemindersAccordion
        isOpen={isSessionRemindersOpen}
        handlePress={(value) => {
          setIsSessionRemindersOpen(value);
        }}
        isMobile={isMobile}
        sessionReminder={sessionReminder}
        handlePushToggle={(value) => handleToggle('sessionReminder', 'push', value)}
        handleEmailToggle={(value) => handleToggle('sessionReminder', 'email', value)}
        handleSMSToggle={(value) => handleToggle('sessionReminder', 'sms', value)}
      />
      {shouldShowTeenspaceFeatures && (communityComments || communityDailyPrompt) && (
        <TeenspaceNotificationsAccordion
          isOpen={isTeenspaceNotificationsOpen}
          handlePress={(value) => {
            setIsTeenspaceNotificationsOpen(value);
          }}
          isMobile={isMobile}
          communityComments={communityComments}
          communityDailyPrompt={communityDailyPrompt}
          handleDailyQuestionPushToggle={(value) =>
            handleToggle('communityDailyPrompt', 'push', value)
          }
          handleCommentsPushToggle={(value) => handleToggle('communityComments', 'push', value)}
        />
      )}
      {superbillsFlag ? (
        <SuperbillsAccordion
          isOpen={isSuperbillsOpen}
          handlePress={(value) => {
            setIsSuperbillsOpen(value);
          }}
          isMobile={isMobile}
          superbills={superbills}
          handlePushToggle={(value) => handleToggle('superbills', 'push', value)}
          handleEmailToggle={(value) => handleToggle('superbills', 'email', value)}
        />
      ) : null}
      <MarketingCommunicationsAccordion
        isOpen={isMarketingCommunicationsOpen}
        handlePress={(value) => {
          setIsMarketingCommunicationsOpen(value);
        }}
        isMobile={isMobile}
        marketingPromotions={marketingPromotions}
        marketingNews={marketingNews}
        marketingEducation={marketingEducation}
        marketingBlog={marketingBlog}
        handleEmailToggle={(value) => handleToggle('marketingPromotions', 'email', value)}
        handleSMSToggle={(value) => handleToggle('marketingPromotions', 'sms', value)}
        handlePushToggle={(value) => handleToggle('marketingPromotions', 'push', value)}
        handlePromotionsEmailToggle={(value) => handleToggle('marketingPromotions', 'all', value)}
        handleNewsEmailToggle={(value) => handleToggle('marketingNews', 'all', value)}
        handleEducationEmailToggle={(value) => handleToggle('marketingEducation', 'all', value)}
        handleBlogEmailToggle={(value) => handleToggle('marketingBlog', 'all', value)}
      />
      {!isInFrame && (
        <>
          <DesktopNotificationSoundPanel useNewNav={useNewNav}>
            <ContentArea useNewNav={useNewNav}>
              <NotificationTitle
                title={tnewMemberNav(
                  'notifications.desktop',
                  'Desktop notification sound',
                  undefined
                )}
                isMobile={isMobile}
                subTitle={
                  soundNotification.email
                    ? tnewMemberNav('notifications.on', 'On', undefined)
                    : tnewMemberNav('notifications.off', 'Off', undefined)
                }
              />
              <NotificationToggle
                onBackgroundColor={
                  useNewNav
                    ? colorRoles.button.brandPrimarySurfaceDefault
                    : colorRoles.system.actionPrimaryDefault
                }
                isOn={soundNotification.email}
                onChange={(value) => handleToggle('soundNotification', 'email', Number(value))}
                name="soundNotification"
                dataQa="myAccountNotificationSoundToggle"
                label={tnewMemberNav(
                  'notifications.desktop',
                  'Desktop notification sound',
                  undefined
                )}
              />
            </ContentArea>
          </DesktopNotificationSoundPanel>
        </>
      )}
    </>
  );
};

export default Notifications;
