import { useEffect } from 'react';
import camelCase from 'lodash/camelCase';
import {
  EligibilityWarningSuccess,
  ResponsiveLayoutWithHeader,
  SimpleHeader,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { useLocation, useParams } from '@/core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import useEligibilityWarning from '../hooks/useEligibilityWarning';

const EligibilityWarningSuccessContainer = () => {
  const closeModal = useCloseModal();
  const { roomID } = useParams<{ roomID: string }>();
  const { refetchEligibilityWarning } = useEligibilityWarning({
    roomID: Number(roomID),
  });

  useEffect(() => {
    refetchEligibilityWarning();
  }, [refetchEligibilityWarning]);

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const isNewProvider = urlParams.get('isNewProvider') === 'true';
  const newRoomID = Number(urlParams.get('newRoomID'));
  const sessionsCanceled = urlParams.get('sessionsCanceled') === 'true';
  const sessionsTransferred = urlParams.get('sessionsTransferred') === 'true';

  const shouldBookSession = !isNewProvider && !sessionsTransferred;
  const ctaText = shouldBookSession ? 'Book a session' : 'Go to new room';
  const { isMobile } = useWindowWidthState();
  const onPressCTA = shouldBookSession
    ? () =>
        closeModal({
          navigateTo: 'bookSession',
          metadata: { roomID: newRoomID, forceNewRoomRedirect: true },
          forceWebRedirect: true,
        })
    : () => closeModal({ navigateTo: 'room', metadata: { roomID: newRoomID } });
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader onClose={() => closeModal()} />}
      noHeaderLine
      containerStyle={{ width: '100%', alignItems: 'center' }}
      contentWrapperStyle={{
        width: isMobile ? '100%' : 394,
        alignItems: 'center',
        padding: '0 24px 34px',
        justifyContent: isMobile ? 'space-between' : undefined,
      }}
    >
      <EligibilityWarningSuccess
        sessionsTransferred={sessionsTransferred}
        sessionsCanceled={sessionsCanceled}
        isNewProvider={isNewProvider}
        hasPreviousProvider
        isSameRoom={newRoomID !== Number(roomID)}
        ctaText={ctaText}
        onPressCTA={onPressCTA}
        dataQa={`${camelCase(ctaText)}Button`}
      />
    </ResponsiveLayoutWithHeader>
  );
};

export default EligibilityWarningSuccessContainer;
