import { FunctionComponent, useMemo } from 'react';
import { ThemeProvider } from 'emotion-theming';
import s, { CreateStyled as CS } from '@emotion/styled';
import { getColorNames, getColorRoles } from '../designSystems/utils';
import { ColorRolesVersion } from '../designSystems/types';
import { useA11yState, A11yState } from './a11yContextProvider';
import { WindowWidthValues } from './useWindowWidth';
import { useWindowWidthState, WindowWidthContextProvider } from './windowWidthContext';
import { SemanticVersion } from '../utils/semanticVersioning';
import { getScreenSafeAreaInsets, SafeAreaInsets } from '../utils/safeAreaInsets';
import { getSpacing } from '../designSystems/tokens';

export interface EmotionTheme {
  colors: A11yState['colors'];
  isHighContrast: boolean;
  colorRoles: ColorRolesVersion;
  themeVersion: SemanticVersion;
  window: WindowWidthValues;
  safeAreaInsets: SafeAreaInsets;
  spacing: typeof getSpacing;
}

interface EmotionThemeProviderProps {
  theme?: Partial<EmotionTheme>;
  version?: SemanticVersion | string;
  colorsOverrides?: Partial<EmotionTheme['colors']>;
}

const InnerEmotionThemeProvider: FunctionComponent<EmotionThemeProviderProps> = ({
  children,
  theme,
  colorsOverrides,
  version = '' as SemanticVersion,
}) => {
  const { colors: themeColors, isHighContrast } = useA11yState();
  const colorVersion = getColorNames(version, theme?.isHighContrast || isHighContrast);
  const colorRolesVersion = getColorRoles(version);
  const windowWidth = useWindowWidthState();

  const colors = useMemo(() => {
    return {
      ...themeColors,
      ...colorVersion,
      ...colorsOverrides,
    };
  }, [themeColors, colorVersion, colorsOverrides]);

  const safeAreaInsets = getScreenSafeAreaInsets();

  const finalTheme: EmotionTheme = {
    colors,
    isHighContrast,
    themeVersion: version as SemanticVersion,
    colorRoles: colorRolesVersion,
    window: windowWidth,
    spacing: getSpacing,
    safeAreaInsets,
    ...theme,
  };

  return <ThemeProvider theme={finalTheme}>{children}</ThemeProvider>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const EmotionThemeProvider: FunctionComponent<EmotionThemeProviderProps> = ({
  version = '' as SemanticVersion,
  ...props
}) => (
  <WindowWidthContextProvider version={version}>
    <InnerEmotionThemeProvider version={version} {...props} />
  </WindowWidthContextProvider>
);

export default EmotionThemeProvider;

// eslint-disable-next-line @typescript-eslint/ban-types
export type ExtendEmotionTheme<Theme extends object = {}> = Theme & EmotionTheme;

export type CreateStyled<Theme extends EmotionTheme> = CS<Theme>;

export const styled = s as CS<EmotionTheme>;
