/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

import Input from '../Input';

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

type MaskType = 'europeDate' | 'date';

interface RHFInputProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> extends React.ComponentPropsWithoutRef<'input'> {
  fieldName: FieldName;
  label: string;
  errorMessage?: string;
  value?: string;
  placeholder?: string;
  hidePlaceholder?: boolean;
  tooltip?: string;
  tooltipLabel?: string;
  containerStyle?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
  inputStyle?: EmotionStyle;
  inputType?: string;
  min?: number;
  max?: number;
  labelStyle?: EmotionStyle;
  tooltipStyle?: EmotionStyle;
  tooltipMessageStyle?: EmotionStyle;
  tooltipTextColor?: string;
  tooltipBackgroundColor?: string;
  tooltipBorder?: boolean;
  tooltipBorderColor?: string;
  placeHolderStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  readOnly?: boolean;
  dateFormat?: string;
  maskType?: MaskType;
  isDisabled?: boolean;
  isErrorAlert?: boolean;
  hint?: string;
  shouldShowErrorIcon?: boolean;
  handleInputFocus?: (e) => void;
  enterKeyHint?: InputHTMLAttributes<HTMLInputElement>['enterKeyHint'];
}

const RHFInput = <TFieldValues extends Record<string, string>>(
  props: RHFInputProps<TFieldValues>
) => {
  const {
    fieldName,
    label,
    errorMessage,
    value,
    placeholder,
    hidePlaceholder,
    tooltip,
    tooltipLabel,
    containerStyle,
    wrapperStyle,
    inputStyle,
    inputType,
    min,
    max,
    labelStyle,
    tooltipStyle,
    tooltipMessageStyle,
    tooltipTextColor,
    tooltipBackgroundColor,
    tooltipBorder,
    tooltipBorderColor,
    placeHolderStyle,
    errorStyle,
    onChange,
    isRequired,
    readOnly,
    dateFormat,
    maskType,
    isDisabled,
    isErrorAlert,
    hint,
    handleInputFocus,
    enterKeyHint,
    shouldShowErrorIcon = false,
    ...otherProps
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>();

  const { colors } = useEmotionTheme();

  const isError = !!errors[fieldName];
  const field = register(fieldName);
  return (
    <Input
      readOnly={readOnly}
      placeholder={hidePlaceholder ? '' : placeholder || label}
      dataQa={`${fieldName}Input`}
      ariaRequired={isRequired}
      placeHolderStyle={{ color: colors.baliHaiGrey, ...placeHolderStyle }}
      date-format={dateFormat}
      maskType={maskType}
      onFocus={handleInputFocus}
      shouldShowErrorIcon={shouldShowErrorIcon}
      wrappedInputProps={{
        label,
        errorMessage: errorMessage || errors[fieldName]?.message,
        isError,
        tooltip,
        tooltipLabel: tooltipLabel || `More info about ${label} field`,
        containerStyle: { width: '100%', maxWidth: 335, marginBottom: 12, ...containerStyle },
        inputStyle: { width: '100%', color: colors.black, ...inputStyle },
        labelStyle: { marginBottom: 0, ...labelStyle },
        wrapperStyle,
        tooltipStyle,
        tooltipMessageStyle,
        tooltipTextColor,
        tooltipBackgroundColor,
        tooltipBorder,
        tooltipBorderColor,
        errorStyle,
        isErrorAlert,
        hint,
      }}
      type={inputType}
      min={min}
      max={max}
      value={value}
      {...field}
      onChange={(e) => {
        field.onChange(e);
        onChange?.(e);
      }}
      inputRef={maskType === 'date' ? field.ref : undefined}
      isDisabled={isDisabled}
      enterKeyHint={enterKeyHint}
      {...otherProps}
    />
  );
};

export default RHFInput;
