import { WizardScheme } from 'stepWizard';

import EmergencyContactForm from './components/EmergencyContactForm';

const teenEmergencyContactScheme: (disableAddressAutocomplete: boolean) => WizardScheme = (
  disableAddressAutocomplete
) => {
  return {
    wizardModalTitle: 'emergency contact',
    wizardVersion: 3,
    wizardType: 'emergencyContact',
    disablePersist: true,
    steps: [
      {
        title: 'What is your full name?',
        inputType: 'form',
        inputState: 'name',
        inputFields: [
          {
            inputType: 'text',
            inputDataQa: 'firstNameInput',
            inputState: 'firstName',
            inputLabel: 'First name',
            inputTypePlaceholder: 'First Name',
            validationRequired: true,
          },
          {
            inputType: 'text',
            inputDataQa: 'middleNameInput',
            inputState: 'middleName',
            inputLabel: 'Middle name',
            inputTypePlaceholder: 'Middle Name',
            validationRequired: false,
          },
          {
            inputType: 'text',
            inputDataQa: 'lastNameInput',
            inputState: 'lastName',
            inputLabel: 'Last name',
            inputTypePlaceholder: 'Last Name',
            validationRequired: true,
          },
        ],
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        backOption: 'back',
        name: 'name',
      },
      {
        title: 'What is your date of birth?',
        inputType: 'date',
        inputState: 'dateOfBirth',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        validationOptions: {
          minYearsFromNow: -120,
          maxYearsFromNow: -13, // NOTE: minimum age: 13
        },
        name: 'Date of birth',
        backOption: 'back',
      },
      {
        title: 'What is your address?',
        inputType: disableAddressAutocomplete ? 'address' : 'addressAutocomplete',
        validationRequired: disableAddressAutocomplete,
        inputState: 'address',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        name: 'address',
        backOption: 'back',
      },
      {
        title: 'What is your phone number?',
        inputType: 'phone',
        inputTypePlaceholder: '(123) 123-1234',
        inputState: 'phone',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: 'Skip',
          actionDispatch: 'next',
          defaultValue: null,
        },
        name: 'phone',
        backOption: 'back',
      },
      {
        title: 'Which race or ethnicity do you identify with?',
        inputType: 'select',
        inputState: 'raceOrEthnicity',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'raceOrEthnicityOptions',
        name: 'raceOrEthnicity',
        backOption: 'back',
        inputTypePlaceholder: 'Select',
      },
      {
        title: 'What is your gender identity?',
        inputType: 'select',
        inputOptions: 'genderOptions',
        inputState: 'gender',
        name: 'gender',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        backOption: 'back',
      },
      {
        title: 'What school do you attend?',
        subtitle: 'We’ll never share information about your therapy with your school',
        inputType: 'school',
        inputState: 'school',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: 'Skip',
          actionDispatch: 'next',
          defaultValue: null,
        },
        name: 'school',
        backOption: 'back',
      },
      {
        title: 'What is your graduation year?',
        subtitle: 'We’ll never share information about your therapy with your school',
        inputType: 'year',
        inputTypePlaceholder: 'Graduation year',
        inputState: 'graduationYear',
        name: 'graduationYear',
        validationRequired: true,
        validationOptions: {
          minYearsFromNow: 0,
          maxYearsFromNow: 10,
        },
        nextButton: {
          displayText: 'Continue',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: 'Skip',
          actionDispatch: 'next',
          defaultValue: null,
        },
        backOption: 'back',
      },
      {
        inputType: 'custom',
        bodyComponent: () => <EmergencyContactForm />,
        inputState: 'isValidEmergencyContact',
        name: 'emergencyContact',
        title: 'Who would you like to designate as your emergency contact?',
        subtitle:
          'Your emergency contact must be at least 18 years old and, in most cases, should be your parent or guardian',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/teen-emergency-contact/source/onboarding/submit`;
            }
            return '/teen-emergency-contact-wizard/submit';
          },
          whenVisible: 'controlled',
          visibilityControlKey: 'isValidEmergencyContact',
        },
      },
    ],
  };
};

export default teenEmergencyContactScheme;
