import { useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { RemoteUserTracks } from '../types/videoCallTypes';
import PromiseDebouncer from '../utils/PromiseDebouncer';
import { trackEvent } from '@/utils/analytics/eventTracker';

const useSpeakers = ({
  remoteStreamList,
  selectedAudioOut,
}: {
  remoteStreamList: RemoteUserTracks[];
  selectedAudioOut: MediaDeviceInfo | undefined;
}) => {
  const debouncer = useRef<PromiseDebouncer | null>(null);

  useEffect(() => {
    if (!debouncer.current) {
      debouncer.current = new PromiseDebouncer();
    }

    debouncer.current.debounce(async () => {
      await remoteStreamList.map(async ({ audioTrack }) => {
        if (!audioTrack) {
          return;
        }

        if (!audioTrack.isPlaying) {
          audioTrack.play();
        }

        if (selectedAudioOut?.deviceId) {
          // Private API call, not documented in Agora SDK
          (AgoraRTC as any).setParameter?.('RESTRICTION_SET_PLAYBACK_DEVICE', false);
          audioTrack.setPlaybackDevice(selectedAudioOut.deviceId).catch((error) => {
            trackEvent('Agora set playback device error', {
              errorMessage: error.message,
              deviceId: selectedAudioOut.deviceId,
              deviceName: selectedAudioOut.label,
            });
          });
        }
      });
    });
  }, [remoteStreamList, selectedAudioOut]);
};

export default useSpeakers;
