import { useEffect, FunctionComponent } from 'react';
import * as React from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { countries, states } from '@talkspace/configs';
import { isZipValid } from '../../utils/validators';
import View from '../View';
import RHFInput from '../RHFInput';
import RHFSelect from '../RHFSelect';
import styled from '../../core/styled';
import transformConfigToSelectOptions from '../../utils/transformConfigToSelectOptions';
import { AddressInputValue } from '../AddressInputAutocomplete';

interface AddressFields {
  address: string;
  address2: string | undefined;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}
enum AddressFieldNames {
  address = 'address',
  address2 = 'address2',
  city = 'city',
  state = 'state',
  zipcode = 'zipcode',
  country = 'country',
}
const ZIPCODE_IS_REQUIRED = 'Zip code is required';
const addressSchema: yup.SchemaOf<AddressFields> = yup.object().shape({
  address: yup.string().required(),
  address2: yup.string().optional(),
  city: yup.string().required(),
  state: yup.string().required(),
  zipcode: yup
    .string()
    .required(ZIPCODE_IS_REQUIRED)
    .test('is-required-zip-code', ZIPCODE_IS_REQUIRED, (zipCodeValue) => {
      if (zipCodeValue && zipCodeValue.length > 0) {
        return true;
      }
      return false;
    })
    .test('is-valid-zip-code', 'Zip code is invalid', (zipCodeValue, context) =>
      zipCodeValue
        ? isZipValid(zipCodeValue, context.parent.country)
        : new yup.ValidationError('Zip code is invalid', zipCodeValue, 'zipcode')
    ),
  country: yup.string().required(),
});

const Styled = {
  Container: styled(View)({}),
  InputRowContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      flexDirection: 'row',
      width: 156,
      gap: spacing('space100'),
    };
  }),
};

interface Props {
  value: AddressInputValue;
  setValue: (value: AddressInputValue) => void;
}
const AddressInput: FunctionComponent<Props> = ({ value, setValue }) => {
  const methods = useForm<AddressFields>({
    resolver: yupResolver(addressSchema),
    mode: 'onChange',
    defaultValues: {
      address: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'US',
    },
  });
  const { watch, setValue: setFormValue } = methods;

  // prefill values when done fetching
  useEffect(() => {
    if (value?.addressFromApi) {
      Object.entries(value.addressFromApi).forEach(([key, v]) => {
        if (v) {
          setFormValue(key as keyof AddressFields, v);
        }
      });
    }
  }, [setFormValue, value?.addressFromApi]);

  // watch all fields changes
  const [address, address2, city, state, zipcode, country] = watch([
    AddressFieldNames.address,
    AddressFieldNames.address2,
    AddressFieldNames.city,
    AddressFieldNames.state,
    AddressFieldNames.zipcode,
    AddressFieldNames.country,
  ]);
  const isUS = country === 'US';

  // sync the value with form
  useEffect(() => {
    setValue({
      addressFromApi: {
        address,
        address2,
        city,
        state,
        zipcode,
        country,
        fullAddress: `${address} ${address2}, ${city}, ${state} ${zipcode}`,
      },
      addressString: address2, // NOTE: submitters historically expect addressString to be address2
    });
  }, [address, address2, city, state, zipcode, country, setValue]);

  return (
    <Styled.Container>
      <FormProvider {...methods}>
        <RHFInput
          fieldName={AddressFieldNames.address}
          label="Street address"
          placeholder="Street address"
        />
        <RHFInput
          fieldName={AddressFieldNames.address2}
          label="Street address line 2"
          placeholder="Apartment, suite, unit, floor"
        />
        <RHFInput fieldName={AddressFieldNames.city} label="City" />
        <Styled.InputRowContainer>
          {isUS ? (
            <RHFSelect
              registerOptionValue
              fieldName={AddressFieldNames.state}
              label="State"
              options={transformConfigToSelectOptions(states)}
            />
          ) : (
            <RHFInput fieldName={AddressFieldNames.state} label="State" />
          )}
          <RHFInput fieldName={AddressFieldNames.zipcode} label="Zip code" />
        </Styled.InputRowContainer>
        <RHFSelect
          registerOptionValue
          fieldName={AddressFieldNames.country}
          label="Country"
          options={transformConfigToSelectOptions(countries)}
        />
      </FormProvider>
    </Styled.Container>
  );
};

export default AddressInput;
