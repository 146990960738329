import RadioButton from './RadioButton';
import type { RadioButtonProps } from './RadioButton';
import RadioButtonV1 from './RadioButtonV1';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

export { RadioButtonProps };

const versionMap: VersionMap<React.ComponentProps<typeof RadioButton>> = {
  '2.0.0': RadioButtonV1,
  '1.0.0': RadioButtonV1,
  '0.0.0': RadioButton,
  DefaultComponent: RadioButton,
};

export default withVersioning(versionMap);
