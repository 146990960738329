import { FunctionComponent } from 'react';
import {
  Button,
  View,
  Large,
  ExtraHuge,
  Tiny,
  GraphicCard,
  useWindowWidthState,
  useUniqueID,
  useEmotionTheme,
  Huge,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import IntakeCompletedCheckMark from './icons/IntakeCompletedCheckMark';

interface Props {
  firstHeadlineRowText?: string;
  secondHeadlineRowText?: string;
  subHeadlineText?: string;
  headlineStyle?: {};
  subHeadlineStyle?: {};
  graphicCardStyles?: {};
  title?: string;
  description?: string;
  buttonText?: string | Element | undefined;
  buttonDataQa?: string;
  onButtonPress: () => void;
  imageURL: string;
  imageWidth?: number;
  imageHeight?: number;
  imageXPos?: number;
  imageYPos?: number;
  imageMobileXPos?: number;
  imageMobileYPos?: number;
  backgroundColor?: string;
  style?: {};
  isCompleted?: boolean;
  isLoading?: boolean;
}

const SplashWrapper = styled(View)(({ theme: { window } }) => {
  return {
    alignSelf: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'left .2s',
    marginTop: -13,
    width: window.isMobile ? 330 : 420,
    alignItems: 'center',
  };
});

const StyledIntakeCheckMark = styled(IntakeCompletedCheckMark)<{
  isMobile: boolean;
}>(({ isMobile }) => {
  return {
    position: 'absolute',
    top: 0,
    right: isMobile ? 12 : 0,
    zIndex: 999999,
  };
});

const WelcomeScreen: FunctionComponent<Props> = (props) => {
  const {
    firstHeadlineRowText,
    secondHeadlineRowText,
    subHeadlineText,
    headlineStyle = { color: 'white' },
    subHeadlineStyle = { color: 'white', fontWeight: 700, marginTop: 7 },
    graphicCardStyles,
    title,
    description,
    onButtonPress,
    buttonText,
    buttonDataQa,
    imageURL,
    imageWidth = 0,
    imageHeight = 0,
    imageXPos = 0,
    imageYPos = 0,
    imageMobileXPos = 0,
    imageMobileYPos = 0,
    backgroundColor,
    style,
    isCompleted = false,
    isLoading = false,
  } = props;

  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const flowDescriptionId = useUniqueID('flowDescriptionId');
  return (
    <SplashWrapper style={style}>
      {isCompleted && <StyledIntakeCheckMark isMobile={isMobile} />}
      <GraphicCard
        isResponsive={isMobile}
        bgImageURL={imageURL}
        bgHeight={imageHeight}
        bgWidth={imageWidth}
        bgX={isMobile ? imageMobileXPos : imageXPos}
        bgY={isMobile ? imageMobileYPos : imageYPos}
        isActive={false}
        style={{
          opacity: 1,
          transition: 'opacity .2s',
          backgroundColor,
          ...graphicCardStyles,
        }}
      >
        <View flex={1} style={{ padding: 28 }}>
          <ExtraHuge as="h1" style={{ ...headlineStyle }}>
            {firstHeadlineRowText}
            <br />
            {secondHeadlineRowText}
          </ExtraHuge>
          <Tiny as="h2" style={{ ...subHeadlineStyle }}>
            {subHeadlineText}
          </Tiny>
        </View>
      </GraphicCard>
      <>
        <View
          style={{
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {title && (
            <Huge
              style={{
                textAlign: 'center',
                marginTop: 17,
                marginBottom: 20,
              }}
            >
              {title}
            </Huge>
          )}
          <Large
            id={flowDescriptionId}
            variant="largeDarkGrey"
            style={{
              textAlign: 'center',
              marginTop: 17,
              marginBottom: 20,
            }}
          >
            {description}
          </Large>
        </View>
        <Button
          aria-describedby={flowDescriptionId}
          text={buttonText}
          onPress={onButtonPress}
          disabled={isCompleted}
          style={{ marginTop: 22, backgroundColor: colors.permaTalkspaceDarkGreen }}
          isLoading={isLoading}
          dataQa={buttonDataQa}
        />
      </>
    </SplashWrapper>
  );
};

export default WelcomeScreen;
