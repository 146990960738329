import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import TextDS from '../typography/TextDS';
import View from '../../../components/View';
import TextArea from '../../../components/TextArea';
import { getSpacing } from '../../tokens';
import Tooltip from '../Tooltip/Tooltipv2';
import { CircleExclamation } from '../../icons';

const StyledTextArea = styled(TextArea)<{ isError?: boolean }>(
  ({ theme: { colorRoles, spacing }, isError }) => {
    return {
      resize: 'none',
      width: '100%',
      padding: spacing('space150'),
      borderRadius: spacing('space100'),
      marginBottom: spacing('space050'),
      border: `1px solid ${
        isError
          ? colorRoles.surfaces.criticalBoldDefault
          : colorRoles.borders.borderInteractiveDefault
      }`,
    };
  }
);

export interface TextBoxProps {
  label: string;
  style?: EmotionStyle;
  isOptional?: boolean;
  supportingText?: string;
  text?: string | null;
  placeholderText?: string;
  isError?: boolean;
  tooltipText?: string;
  limit?: number;
  errorMessage?: string;
  containerStyle?: EmotionStyle;
  disabled?: boolean;
  onTextChange: (val: string) => void;
}

const TextBox = ({
  text,
  style,
  label,
  isError,
  isOptional,
  supportingText,
  placeholderText = 'Type something....',
  tooltipText,
  limit,
  errorMessage,
  containerStyle,
  disabled,
  onTextChange,
}: TextBoxProps) => {
  const { colorRoles } = useEmotionTheme();

  return (
    <View style={containerStyle}>
      <View row justify="space-between">
        <View row style={{ gap: getSpacing('space050'), marginBottom: getSpacing('space050') }}>
          <TextDS variant="bodySm">{label}</TextDS>
          {tooltipText && <Tooltip tip={tooltipText} />}
        </View>
        {isOptional && <TextDS variant="bodySm">(Optional)</TextDS>}
      </View>

      <StyledTextArea
        value={text ?? undefined}
        isError={isError}
        onChangeText={(value) => onTextChange(value)}
        style={{ color: !text && colorRoles.typography.textDefaultDisabled, ...style }}
        placeholder={placeholderText}
        disabled={disabled}
      />

      <View row justify="space-between">
        {isError && errorMessage ? (
          <View row align="center" style={{ gap: getSpacing('space050') }}>
            <CircleExclamation colorType="critical" />
            <TextDS colorRole="textCriticalDefault" variant="bodyXs">
              {errorMessage}
            </TextDS>
          </View>
        ) : (
          <TextDS colorRole={isError ? 'textCriticalDefault' : 'textSubtlest'} variant="bodyXs">
            {supportingText}
          </TextDS>
        )}

        <TextDS colorRole={isError ? 'textCriticalDefault' : 'textSubtlest'} variant="bodyXs">
          {limit && `${text?.length || 0}/${limit}`}{' '}
        </TextDS>
      </View>
    </View>
  );
};

export default TextBox;
