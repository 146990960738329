import { useState, useCallback } from 'react';
import View from '../../../components/View';
import RadioButton from '../RadioButton';
import TextDS from '../typography/TextDS';
import TouchableView from '../../../components/TouchableView';
import styled, { useEmotionTheme } from '../../../core/styled';
import { RoleVariantName } from '../../types';
import { getSurfaceStylesByVariant } from '../../styles/interactiveStateStyles';
import { OptionBlockProps } from './OptionBlock';

const OptionWrapper = styled(TouchableView)<{
  index: number;
  forceIdleState?: boolean;
  disabled?: boolean;
  variant?: RoleVariantName;
}>(
  ({
    index,
    disabled,
    forceIdleState,
    variant: surfaceRoleVariantName,
    theme: { colorRoles, spacing },
  }) => {
    const surfaceStates = forceIdleState
      ? {
          colorRoles,
          disabled: false,
          active: false,
          hovered: false,
          multiplyBackground: undefined,
        }
      : {
          colorRoles,
          disabled,
          active: undefined,
          hovered: undefined,
          multiplyBackground: undefined,
        };
    const surfaceStyles = getSurfaceStylesByVariant(surfaceRoleVariantName || 'default')(
      surfaceStates
    );
    return {
      minWidth: 335,
      maxWidth: 430,
      padding: spacing('space200'),
      borderRadius: 12,
      marginTop: index !== 0 ? spacing('space100') : 0,
      justifyContent: 'space-between',
      gap: 0,
      border: `1px solid ${colorRoles.system.borderSubtle}`,
      '&:hover:focus': {
        border: `1px solid ${colorRoles.system.borderSubtle}`,
      },
      ...surfaceStyles,
    };
  }
);

const OptionBlockV2 = <OptionValue extends unknown = string>({
  isActive,
  icon,
  displayTitle,
  description,
  value,
  onHandlePress,
  disabled = false,
  forceIdleState = false,
  onDisablePress = () => null,
  dataQa,
  questionMarkWhenDisabled = false,
}: OptionBlockProps<OptionValue>) => {
  const { colors, spacing } = useEmotionTheme();
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <OptionWrapper
      row
      index={0}
      tabIndex={-1}
      disabled={disabled}
      forceIdleState={forceIdleState}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        marginBottom: spacing('space100'),
        opacity: disabled ? 0.5 : 1,
        backgroundColor: disabled ? colors.permaGreyed : undefined,
      }}
      onPress={() => (disabled ? onDisablePress(value) : onHandlePress(value))}
      dataQa={`${dataQa}-${value}`}
    >
      {icon && (
        <View
          align="center"
          justify="center"
          style={{ width: 20, height: 20, paddingTop: 0, marginRight: 10 }}
        >
          {icon}
        </View>
      )}
      <View
        flex={1}
        align="start"
        justify="start"
        style={{ marginRight: spacing('space100'), gap: spacing('space050') }}
      >
        {typeof displayTitle === 'string' ? (
          <TextDS variant="headingMd">{displayTitle}</TextDS>
        ) : (
          displayTitle
        )}
        {typeof description === 'string' ? (
          <TextDS variant="bodySm" colorRole="textSubtle">
            {description}
          </TextDS>
        ) : (
          description
        )}
      </View>

      <View align="end" justify="center">
        <RadioButton
          isHovering={isHovering}
          isActive={isActive}
          onPress={() => !disabled && onHandlePress(value)}
          disabled={disabled}
          onDisablePress={() => onDisablePress(value)}
          dataQa={`${dataQa}-radio`}
          questionMarkWhenDisabled={questionMarkWhenDisabled}
        />
      </View>
    </OptionWrapper>
  );
};

export default OptionBlockV2;
