import { SkeletonLoader, styled, TextDS, View } from '@talkspace/react-toolkit';
import useQueryQuestionnaires from 'ts-frontend/hooks/questionnaires/useQueryQuestionnaires';
import SafetyPlanAnswerCard from '../SafetyPlanAnswerCard';
import { useParams } from '../../../core/routerLib';

const CardsContainer = styled(View)({ flexDirection: 'row', flexWrap: 'wrap', gap: 24 });

const SafetyPlanSection = () => {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: questionnaires, isLoading } = useQueryQuestionnaires({
    roomID: Number(roomID),
    questionnaireTypes: ['safety_plan'],
  });
  return (
    <>
      {questionnaires?.[0] && (
        <View style={{ margin: 24 }}>
          <TextDS variant="headingLg" style={{ marginBottom: 8 }}>
            My safety plan
          </TextDS>
          <TextDS style={{ maxWidth: 600, marginBottom: 24 }} variant="body">
            Your safety plan is designed to guide you through a crisis. Refer to this plan in case
            you need support getting through a difficult time.
          </TextDS>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <CardsContainer>
              {questionnaires?.[0].questionnaireAnswers
                .filter(
                  (it) =>
                    it.textOptions ||
                    (it.questionnaireQuestionOptions && it.questionnaireQuestionOptions.length > 0)
                )
                .map((answer) => (
                  <SafetyPlanAnswerCard
                    title={answer.questionnaireQuestion.text}
                    description={answer.questionnaireQuestion.description}
                    options={[...(answer.questionnaireQuestionOptions || [])]}
                    textOptions={answer.textOptions || []}
                  />
                ))}
            </CardsContainer>
          )}
        </View>
      )}
    </>
  );
};

export default SafetyPlanSection;
