import StackTrace from 'stacktrace-js';
import { CrashOptions, FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { safeIonicWrapper } from '../../ionicUtils';
import { crashlyticsLogger } from '../../loggers';

// Crash-related functions, no need to add safe-wrapper
export const causeNativeCrash = ({ message }: CrashOptions = { message: 'Debug - Native Crash' }) =>
  FirebaseCrashlytics.crash({
    message,
  });

const getErrorMessage = (error: Error, errorInfo?: React.ErrorInfo) => {
  if (errorInfo?.componentStack) {
    return `[${error.name}] ${error.message} - @${errorInfo.componentStack?.split('@')[0]}`;
  }
  return `[${error.name}] ${error.message}`;
};

export const crashlyticsErrorHandler = safeIonicWrapper(
  async (error: Error, errorInfo?: React.ErrorInfo) => {
    crashlyticsLogger.warn('Received uncaught exception', error, errorInfo);
    if (errorInfo) FirebaseCrashlytics.log({ message: errorInfo.componentStack });
    try {
      const stackFrame = await StackTrace.fromError(error, { offline: true });
      return await FirebaseCrashlytics.recordException({
        message: getErrorMessage(error, errorInfo),
        stacktrace: stackFrame,
      });
    } catch {
      return FirebaseCrashlytics.recordException({
        message: getErrorMessage(error, errorInfo),
      });
    }
  },
  Promise.resolve(undefined)
);

export const crashlyticsLog = safeIonicWrapper(FirebaseCrashlytics.log, Promise.resolve(undefined));

export const recordException = safeIonicWrapper(
  FirebaseCrashlytics.recordException,
  Promise.resolve()
);
