import { useEffect } from 'react';
import {
  STORAGE_KEY_APP_LOADED,
  STORAGE_KEY_CHECK_PASSCODE,
} from 'ts-ionic/modules/passcode/constants';

import { routePromise } from 'ts-frontend/helpers';
import { useHistory } from 'core/routerLib';
import { getAccessToken } from '@/auth/helpers/token';
import { useClientAuthState, useClientAuthActions } from '@/hooks/clientAuthContext';
import sessionStorage from '../../core/storage/sessionStorage';
import localStorage from '../../core/storage/localStorage';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import ssoHelper from '../../utils/sso/ssoHelper';
import { HAS_LOGGED_IN_BEFORE } from '../utils/twoFactorAuthenticationApiHelper';

const useRedirectAfterLogin = () => {
  const { isUpdating, redirectTo, isError, twoFAStatus, phoneNumberLast2Digits } =
    useClientAuthState();
  const history = useHistory();
  const { getShouldOpen2faVerifyAction, getShouldOpen2faReminderAction } = useClientAuthActions();

  useEffect(() => {
    // Successful login:
    sessionStorage.setItem(STORAGE_KEY_APP_LOADED, 'true');
    sessionStorage.removeItem(STORAGE_KEY_CHECK_PASSCODE);
    let goTo = '/login-success';
    const hasLoggedInBefore = localStorage.getItem(HAS_LOGGED_IN_BEFORE);
    if (redirectTo && !isUpdating && !isError) {
      routePromise(redirectTo);
      return;
    }
    if (
      !isUpdating &&
      !isError &&
      ((getAccessToken() && getAccessToken() !== ReactFrameService.instance().currentToken) ||
        twoFAStatus === 'on')
    ) {
      const redirect = sessionStorage.getItem('redirectTo');
      if (ssoHelper.isSSO()) {
        ssoHelper.loginAction();
      } else if (redirect) {
        sessionStorage.removeItem('redirectTo');

        // Ensure only one '?' exists and append `action=after-login` to it
        const action = redirect.includes('?action=')
          ? ''
          : `${redirect.includes('?') ? '&' : '?'}action=after-login`;

        goTo = `${redirect}${action}`;
      }
      if (getShouldOpen2faVerifyAction()) {
        sessionStorage.setItem('goTo', goTo);
        history.push('/2fa/verify');
      } else if (getShouldOpen2faReminderAction() && hasLoggedInBefore) {
        sessionStorage.setItem('goTo', goTo);
        history.push('/2fa/reminder');
      } else {
        routePromise(goTo);
      }
    }
  }, [
    isUpdating,
    isError,
    history,
    redirectTo,
    twoFAStatus,
    phoneNumberLast2Digits,
    getShouldOpen2faVerifyAction,
    getShouldOpen2faReminderAction,
  ]);
};

export default useRedirectAfterLogin;
