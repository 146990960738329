import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M6.70813 16.7049C6.31755 17.0955 5.68323 17.0955 5.29265 16.7049L2.29294 13.7052C1.90235 13.3146 1.90235 12.6803 2.29294 12.2897C2.68353 11.8991 3.31784 11.8991 3.70843 12.2897L5.00205 13.5833V3.9999C5.00205 3.44683 5.44888 3 6.00195 3C6.55502 3 7.00186 3.44683 7.00186 3.9999V13.5833L8.29548 12.2897C8.68607 11.8991 9.32038 11.8991 9.71097 12.2897C10.1016 12.6803 10.1016 13.3146 9.71097 13.7052L6.71126 16.7049H6.70813ZM17.7071 6.2903C18.0976 6.68089 18.0976 7.3152 17.7071 7.70579C17.3165 8.09638 16.6822 8.09638 16.2916 7.70579L15.0011 6.41529V15.9987C15.0011 16.5518 14.5542 16.9986 14.0012 16.9986C13.4481 16.9986 13.0013 16.5518 13.0013 15.9987V6.41529L11.7076 7.70891C11.3171 8.0995 10.6827 8.0995 10.2922 7.70891C9.90157 7.31833 9.90157 6.68401 10.2922 6.29343L13.2919 3.29372C13.6825 2.90313 14.3168 2.90313 14.7074 3.29372L17.7071 6.29343V6.2903Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const ArrowUpArrowDown = withDSIconMaker(pathConfig, 'arrow up arrow down');

export default ArrowUpArrowDown;
