// Copied from 2storybook/components
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { TouchableView } from '@talkspace/react-toolkit';
import { withRouter } from '@/core/routerLib';
import Back from '../Back';

const BackButton: FunctionComponent<RouteComponentProps & {}> = ({ history }) => {
  const handlePress = () => {
    history.goBack();
  };
  return (
    <TouchableView
      onPress={handlePress}
      aria-label="back"
      style={{ display: 'inline' }}
      dataQa="stepWizardBackButton"
    >
      <Back />
    </TouchableView>
  );
};

export default withRouter(BackButton);
