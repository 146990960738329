import { styled, Tag, TextDS, View } from '@talkspace/react-toolkit';
import { QuestionnaireQuestionOption } from 'ts-frontend/hooks/questionnaires/types';

const CardWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    border: `1px solid ${colors.permaLondonGray}`,
    borderRadius: 12,
    padding: 24,
    width: 320,
  };
});

const Options = styled(View)({ flexDirection: 'row', flexWrap: 'wrap', gap: 2, marginBottom: 12 });

const SafetyPlanAnswerCard = ({
  title,
  options,
  textOptions,
  description,
}: {
  title: string;
  options: QuestionnaireQuestionOption[];
  textOptions: string[];
  description: string;
}) => (
  <CardWrapper>
    <TextDS style={{ marginBottom: 12 }} variant="headingLg">
      {title}
    </TextDS>
    <Options>
      {options.map((option) => (
        <Tag text={option.text} color="brandDefault" />
      ))}
      {textOptions.map((textOption) => (
        <Tag text={textOption} color="brandDefault" />
      ))}
    </Options>
    <TextDS variant="bodyXs" colorRole="textSubtlest">
      {description}
    </TextDS>
  </CardWrapper>
);

export default SafetyPlanAnswerCard;
