import { FunctionComponent } from 'react';
import { View, TouchableView, Small, useEmotionTheme } from '@talkspace/react-toolkit';
import DownCaretIcon from '../icons/DownCaretIcon';
import { EmotionStyle } from '../../core/styled';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { ID_ACCOUNT_MENU_DROPDOWN_BUTTON, ID_ACCOUNT_MENU_DROPDOWN } from '../../utils/IDConstants';
import { useA11y } from './DropDownMenuButton.a11y';

interface DropdownMenuButtonProps extends RouteComponentProps {
  style?: EmotionStyle;
  displayName: string;
}

const DropdownMenuButton: FunctionComponent<DropdownMenuButtonProps> = ({ style, displayName }) => {
  const { setDropdownMenuVisible } = useMainActions();
  const { isDropdownMenuVisible } = useMainState();
  const { colors } = useEmotionTheme();
  const { touchableRef } = useA11y();

  return (
    <View style={{ position: 'relative', ...style }}>
      <TouchableView
        id={ID_ACCOUNT_MENU_DROPDOWN_BUTTON}
        aria-label="Account Menu"
        aria-haspopup
        aria-controls={ID_ACCOUNT_MENU_DROPDOWN}
        aria-expanded={isDropdownMenuVisible}
        onPress={() => setDropdownMenuVisible(true)}
        ref={touchableRef}
        dataQa="accountMenuDropdownButton"
      >
        <View flex={1} row style={{ width: 150 }} justify="end">
          <Small
            style={{
              whitespace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 400,
              color: colors.black,
            }}
            as="h1"
          >
            {displayName}
          </Small>
          <DownCaretIcon
            style={{ marginTop: 6, marginLeft: 5, minWidth: 12 }}
            color={colors.bermudaGray}
          />
        </View>
      </TouchableView>
    </View>
  );
};

export default withRouter(DropdownMenuButton);
