import { useEffect, useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import useMutationTalktrackFeedback from './useMutationTalktrackFeedback';
import storage from '@/core/storage';

type UserType = 'provider' | 'client';

interface SubmitPayloadVariables {
  modalType: string;
  freeText: string;
  feedbackPayload: Record<string, boolean>;
}

export const FEEDBACK_REMINDER_FOR_TALKTRACK_ID = 'feedbackReminderForTalktrackID';

export type TalktrackFeedbackType = 'notGood' | 'justOK' | 'helpful';

export default function useTalktrackFeedbackReminder(userType: UserType, userID: number) {
  const [showTalktrackReminder, setShowReminder] = useState(false);

  const { talktracks } = useFlags();

  const { mutate: submitTalktrackFeedback } = useMutationTalktrackFeedback();
  const talktrackID = storage.getItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID);

  const handleTalktrackClosedEvent = () => {
    const storedTalktrackID = storage.getItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID);
    if (storedTalktrackID) {
      setShowReminder(true);
    }
  };

  useEffect(() => {
    if (!talktracks) {
      return;
    }
    handleTalktrackClosedEvent();
  }, [talktracks]);

  useEffect(() => {
    document.addEventListener('talktrackClosed', handleTalktrackClosedEvent);

    return () => {
      document.removeEventListener('talktrackClosed', handleTalktrackClosedEvent);
    };
  }, []);

  const handleSubmitTalktrackFeedback = (payload: SubmitPayloadVariables) => {
    const { freeText, modalType, feedbackPayload } = payload;

    const dataToSubmit = {
      userID: Number(userID),
      talktrackID: Number(talktrackID),
      [userType]: {
        feedback: modalType,
        feedbackDescription: freeText || null,
        ...feedbackPayload,
      },
    };
    submitTalktrackFeedback({
      ...dataToSubmit,
    });

    if (feedbackPayload) {
      setShowReminder(false);
      storage.removeItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID);
    }
  };

  const dismissTalktrackReminder = (shouldSendData: boolean) => {
    if (shouldSendData) {
      const dataToSubmit = {
        userID: Number(userID),
        talktrackID: Number(talktrackID),
        [userType]: {
          feedback: 'dismissed',
        },
      };

      submitTalktrackFeedback({
        ...dataToSubmit,
      });
    }

    setShowReminder(false);
    storage.removeItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID);
  };

  const handleDidntListen = () => {
    const dataToSubmit = {
      userID: Number(userID),
      talktrackID: Number(talktrackID),
      [userType]: {
        feedback: 'didntListen',
      },
    };

    submitTalktrackFeedback({
      ...dataToSubmit,
    });

    setShowReminder(false);
    storage.removeItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID);
  };

  return {
    showTalktrackReminder,
    dismissTalktrackReminder,
    handleSubmitTalktrackFeedback,
    handleDidntListen,
  };
}
