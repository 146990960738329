import {
  ComponentProps,
  ComponentType,
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefObject,
  useState,
} from 'react';
import { ColorRolesVersion, RoleVariantName } from '../types';
import styled, { EmotionStyle, EmotionTheme } from '../../core/styled';
import TouchableView from '../../components/TouchableView';

interface GetStylesParams {
  colorRoles: ColorRolesVersion;
  colors?: EmotionTheme['colors'];
  disabled?: boolean;
  active?: boolean;
  /** If the parent is being hovered. Container hover styles are handled by CSS */
  hovered?: boolean;
  multiplyBackground?: boolean;
}

const getSurfaceStyles =
  ({
    defaultColorRole,
    activeColorRole,
    hoveredColorRole,
    disabledColorRole,
  }: {
    defaultColorRole: keyof ColorRolesVersion['surfaces'];
    activeColorRole: keyof ColorRolesVersion['surfaces'];
    hoveredColorRole: keyof ColorRolesVersion['surfaces'];
    disabledColorRole: keyof ColorRolesVersion['surfaces'];
  }) =>
  ({ colorRoles, active, disabled, hovered, multiplyBackground }: GetStylesParams) => {
    let backgroundColor: string | undefined;
    let styles: EmotionStyle = {};

    if (disabled) backgroundColor = colorRoles.surfaces[disabledColorRole];
    else if (active) backgroundColor = colorRoles.surfaces[activeColorRole];
    else if (hovered) backgroundColor = colorRoles.surfaces[hoveredColorRole];
    else backgroundColor = colorRoles.surfaces[defaultColorRole];

    if (multiplyBackground) {
      styles = {
        ...styles,
        mixBlendMode: 'multiply',
      };
    }

    if (active || hovered || disabled)
      return {
        backgroundColor,
        ...styles,
      };

    // Use CSS styles
    return {
      backgroundColor,
      '&:hover:not(:active):not(:focus)': {
        backgroundColor: colorRoles.surfaces[hoveredColorRole],
        ...styles,
      },
      '&:focus, &:active': {
        backgroundColor: colorRoles.surfaces[activeColorRole],
        ...styles,
      },
    };
  };

export const getSurfaceStylesByVariant = (
  variantName: RoleVariantName | keyof ColorRolesVersion['surfaces']
) => {
  switch (variantName) {
    case 'brandSubtle':
      return getSurfaceStyles({
        disabledColorRole: 'brandSubtleDefault', // TODO: brandSubtle disabled surface
        activeColorRole: 'brandSubtleDefault', // TODO: brandSubtle active surface
        hoveredColorRole: 'brandSubtleDefault', // TODO: brandSubtle hovered surface
        defaultColorRole: 'brandSubtleDefault',
      });
    case 'brand':
      return getSurfaceStyles({
        disabledColorRole: 'brandDefault', // TODO: brand disabled surface
        activeColorRole: 'brandDefault', // TODO: brand active surface
        hoveredColorRole: 'brandDefault', // TODO: brand hovered surface
        defaultColorRole: 'brandDefault',
      });
    case 'neutral':
      return getSurfaceStyles({
        disabledColorRole: 'neutralDisabled',
        activeColorRole: 'neutralPressed',
        hoveredColorRole: 'neutralHovered',
        defaultColorRole: 'neutralDefault',
      });
    case 'interactive':
      return getSurfaceStyles({
        disabledColorRole: 'surfaceInteractiveDisabled',
        activeColorRole: 'surfaceInteractivePressed',
        hoveredColorRole: 'surfaceInteractiveHovered',
        defaultColorRole: 'surfaceInteractiveDefault',
      });
    case 'subtle':
      return getSurfaceStyles({
        disabledColorRole: 'defaultSubtleDisabled',
        activeColorRole: 'defaultSubtlePressed',
        hoveredColorRole: 'defaultSubtleHovered',
        defaultColorRole: 'defaultSubtleDefault',
      });
    case 'default':
      return getSurfaceStyles({
        disabledColorRole: 'defaultDisabled',
        activeColorRole: 'defaultPressed',
        hoveredColorRole: 'defaultHovered',
        defaultColorRole: 'default',
      });
    default:
      return getSurfaceStyles({
        disabledColorRole: variantName,
        activeColorRole: variantName,
        hoveredColorRole: variantName,
        defaultColorRole: variantName,
      });
  }
};

export const getSurfaceDefaultStyles = getSurfaceStyles({
  disabledColorRole: 'defaultDisabled',
  activeColorRole: 'defaultPressed',
  hoveredColorRole: 'defaultHovered',
  defaultColorRole: 'default',
});

export const getSurfaceNeutralStyles = getSurfaceStyles({
  disabledColorRole: 'neutralDisabled',
  activeColorRole: 'neutralPressed',
  hoveredColorRole: 'neutralHovered',
  defaultColorRole: 'neutralDefault',
});

export const getSurfaceSubtleStyles = getSurfaceStyles({
  disabledColorRole: 'defaultSubtleDisabled',
  activeColorRole: 'defaultSubtlePressed',
  hoveredColorRole: 'defaultSubtleHovered',
  defaultColorRole: 'defaultSubtleDefault',
});

export const getSurfaceInteractiveStyles = getSurfaceStyles({
  disabledColorRole: 'surfaceInteractiveDisabled',
  activeColorRole: 'surfaceInteractivePressed',
  hoveredColorRole: 'surfaceInteractiveHovered',
  defaultColorRole: 'surfaceInteractiveDefault',
});

type WithTouchableStylesRenderProps = {
  isHovering: boolean;
  isActive: boolean;
};
type EventHandlerProps = Pick<
  ComponentProps<typeof TouchableView>,
  | 'onMouseEnter'
  | 'onTouchStart'
  | 'onMouseLeave'
  | 'onTouchEnd'
  | 'onTouchCancel'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'onFocus'
  | 'onBlur'
>;

interface WrappableStyledComponent extends EventHandlerProps {
  className?: string;
  style?: EmotionStyle;
  children?: React.ReactNode | ((renderProps: WithTouchableStylesRenderProps) => ReactNode);
  isActive?: boolean;
  isHovering?: boolean;
  isDisabled?: boolean;
  colorRoleType: keyof typeof colorRoleMapping;
  disableSurfaceStyles?: boolean;
  multiplyBackground?: boolean;
}

const colorRoleMapping = {
  surfaceDefault: getSurfaceDefaultStyles,
  surfaceNeutral: getSurfaceDefaultStyles,
  surfaceSubtle: getSurfaceSubtleStyles,
} as const;

type GetParameterType<T> = T extends (arg: infer U) => unknown ? U : never;

// eslint-disable-next-line @typescript-eslint/ban-types
export const withTouchableStyles = <P extends object>(
  Component: ComponentType<P>
): React.ComponentType<P & WrappableStyledComponent> => {
  type Props = P & WrappableStyledComponent;

  const WrappedComponent = styled<
    typeof Component,
    // TypeScript does not accept Props for some reason. Using this method of typing to get around it.
    // We should avoid this if possible and do styled(Component)<Props> instead of the below
    Omit<Props, 'children' | 'style' | 'className'> & { theme: EmotionTheme }
  >(Component)(
    ({
      colorRoleType,
      isActive,
      isHovering,
      isDisabled,
      theme: { colorRoles },
      disableSurfaceStyles,
      multiplyBackground,
    }) => {
      if (disableSurfaceStyles) return {};
      return {
        ...colorRoleMapping[colorRoleType]({
          colorRoles,
          active: isActive,
          hovered: isHovering,
          disabled: isDisabled,
          multiplyBackground,
        }),
      };
    }
  );

  const WithTouchableStyles = (props: Props, ref: RefObject<HTMLElement>) => {
    const {
      style,
      children,
      isActive,
      isDisabled,
      onMouseDown,
      onMouseUp,
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      onTouchCancel,
      onFocus,
      onBlur,
      isHovering: isHoveringProp,
      colorRoleType,
      disableSurfaceStyles,
      ...otherProps
    } = props;
    const [isHovering, setIsHovering] = useState(false);
    const [isPressed, setIsPressed] = useState(false);

    const handlePress =
      <T extends keyof EventHandlerProps>(pressed: boolean, originalProp?: EventHandlerProps[T]) =>
      (event: GetParameterType<NonNullable<typeof originalProp>>) => {
        setIsPressed(pressed);
        // Couldn't figure out a better way to handle the type here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        originalProp?.(event as any);
      };

    const handleHover =
      <T extends keyof EventHandlerProps>(hovering: boolean, originalProp?: EventHandlerProps[T]) =>
      (event: GetParameterType<NonNullable<typeof originalProp>>) => {
        setIsHovering(hovering);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        originalProp?.(event as any);
      };

    const extraProps: EventHandlerProps = {
      onMouseEnter: handleHover(true, onMouseEnter),
      onMouseLeave: handleHover(false, onMouseLeave),
      onMouseDown: handlePress(true, onMouseDown),
      onMouseUp: handlePress(false, onMouseUp),
      onTouchStart: handlePress(true, onTouchStart),
      onTouchEnd: handlePress(false, onTouchEnd),
      onTouchCancel: handlePress(false, onTouchCancel),
      onFocus: handleHover(true, onFocus),
      onBlur: handleHover(false, onBlur),
    };

    const finalIsActive = !!(isActive || isPressed);
    const finalIsHovering = !!(isHovering || isHoveringProp);

    const finalProps = {
      ...otherProps,
      ...extraProps,
      colorRoleType,
      isDisabled,
      isActive: finalIsActive,
      isHovering: finalIsHovering,
      disableSurfaceStyles,
      // Had to cast, had trouble with the generics
    } as ComponentProps<typeof WrappedComponent>;

    if (typeof children === 'function') {
      return (
        <WrappedComponent {...finalProps} ref={ref}>
          {children({ isHovering: finalIsHovering, isActive: finalIsActive })}
        </WrappedComponent>
      );
    }
    return (
      <WrappedComponent {...finalProps} ref={ref}>
        {children}
      </WrappedComponent>
    );
  };

  return forwardRef(WithTouchableStyles) as ForwardRefExoticComponent<Props>;
};
