import { FunctionComponent, useState, useEffect } from 'react';
import VideoPlayerView, { VideoPlayerViewProps } from './VideoPlayerView';
import globalEventService, { GlobalEvent } from '../../utils/GlobalEvents';

interface VideoPlayerProps extends Partial<VideoPlayerViewProps> {
  url: string;
  thumbnailURL?: string;
  fallbackBlob?: Blob;
}

const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  url,
  playing,
  thumbnailURL,
  ...otherProps
}) => {
  const [isPlaying, setIsPlaying] = useState(!!playing);
  useEffect(() => {
    const onOtherMediaPlaying = () => {
      setIsPlaying(false);
    };

    return globalEventService.addEventListener('playMedia', onOtherMediaPlaying);
  }, []);

  const onPlay = () => {
    globalEventService.dispatchEvent(new GlobalEvent('playMedia'));
    setIsPlaying(true);
  };

  const onPause = () => {
    setIsPlaying(false);
  };
  return (
    <VideoPlayerView
      source={url}
      onPlay={onPlay}
      onPause={onPause}
      playing={isPlaying}
      thumbnailURL={thumbnailURL}
      {...otherProps}
    />
  );
};

export default VideoPlayer;
