import { FunctionComponent } from 'react';
import { TouchableView, View } from '@talkspace/react-toolkit';
import VideoCallCamera from '../Icons/VideoCallCamera';
import VideoCallMicrophone from '../Icons/VideoCallMicrophone';
import EndCall from '../Icons/EndCall';
import styled from '@/core/styled';

const Wrapper = styled(View)<{ isMinimized: boolean }>(({ isMinimized }) => {
  return {
    width: isMinimized ? 114 : 266,
    marginLeft: isMinimized ? 8 : 0,
    marginBottom: isMinimized ? 8 : 20,
  };
});

interface CallControlsProps {
  isAudioOn: boolean;
  isVideoOn: boolean;
  areOtherUsersInCall: boolean;
  isMinimized: boolean;
  toggleAudioVideoAction: (isAudioOn: boolean, isVideoOn: boolean) => void;
  endCallAction: () => void;
}
const CallControls: FunctionComponent<CallControlsProps> = ({
  isAudioOn,
  isVideoOn,
  areOtherUsersInCall,
  toggleAudioVideoAction,
  endCallAction,
  isMinimized,
}) => {
  const sizeStyles = isMinimized ? { width: 34, height: 34 } : {};
  return (
    <Wrapper isMinimized={isMinimized} row justify="space-between">
      <TouchableView
        onPress={() => toggleAudioVideoAction(!isAudioOn, isVideoOn)}
        dataQa="videoCallToggleAudioButton"
      >
        <VideoCallMicrophone
          {...sizeStyles}
          isOn={isAudioOn}
          isBackgroundDark={areOtherUsersInCall}
        />
      </TouchableView>
      <TouchableView
        onPress={() => toggleAudioVideoAction(isAudioOn, !isVideoOn)}
        dataQa="videoCallToggleVideoButton"
      >
        <VideoCallCamera {...sizeStyles} isOn={isVideoOn} isBackgroundDark={areOtherUsersInCall} />
      </TouchableView>
      <TouchableView onPress={() => endCallAction()} dataQa="videoCallEndCallButton">
        <EndCall {...sizeStyles} />
      </TouchableView>
    </Wrapper>
  );
};

export default CallControls;
