import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackEvent } from '@/utils/analytics/eventTracker';

import OnboardingPage from './OnboardingPage';
import TeenMedicalHistory from '../assets/TeenMedicalHistory';

const OnboardingPageTeenTreatmentIntake = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & {
  totalSteps: number;
  currentStep: number;
}) => {
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<TeenMedicalHistory />}
      title={tTeenOnboarding(
        'talkAboutMentalHealth.title',
        'Just a few last questions...',
        undefined
      )}
      description={tTeenOnboarding(
        'talkAboutMentalHealth.description',
        'These questions will help your therapist best know how to support you',
        undefined
      )}
      ctaText={tTeenOnboarding('talkAboutMentalHealth.continue', 'Continue', undefined)}
      onPress={() => {
        trackEvent(
          'Start Medical History',
          {
            actionName: 'onboardingStep',
            roomID,
          },
          ['tsAnalytics']
        );
        history.push(`/room/${roomID}/onboarding/teen-mental-health/source/onboarding`);
      }}
    />
  );
};

export default withRouter(OnboardingPageTeenTreatmentIntake);
