import { useState } from 'react';

import SearchGooglePlacesInput from '../SearchGooglePlacesInput';
import TSInput from '../TSInput';
import Label from '../Label';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  onSelectCallback: (value: string) => void;
  disableAutoComplete: boolean;
}
const SchoolSearch = ({ onSelectCallback, disableAutoComplete }: Props) => {
  const [searchText, setSearchText] = useState('');
  const schoolTextInputID = useUniqueID('schoolTextInput');

  const onSelect = (school: string) => {
    setSearchText(school);
    onSelectCallback(school);
  };

  const onChange = (value) => {
    setSearchText(value);
    if (disableAutoComplete) {
      onSelectCallback(value);
    }
  };

  return disableAutoComplete ? (
    <>
      <Label id={schoolTextInputID}>School</Label>
      <TSInput
        dataQa="stepWizardSchoolTextInput"
        maxLength={500}
        aria-labelledby={schoolTextInputID}
        value={searchText}
        onChange={onChange}
        placeholder="Enter school name"
      />
    </>
  ) : (
    <SearchGooglePlacesInput
      label="School"
      placeholder="Search schools..."
      dataQa="schoolSearch"
      value={searchText}
      searchOptions={{
        types: ['school'],
      }}
      onChange={onChange}
      onSelect={onSelect}
      error={undefined}
    />
  );
};

export default SchoolSearch;
