import { useFlags } from 'launchDarkly/FlagsProvider';

import SessionsLeftBanner from './SessionsLeftBanner';

const AnnouncementsBanner = ({ userID, featureViewsData, isDesktop, isMobile, isTablet }) => {
  const { eapToBhActivationFf } = useFlags();

  if (!eapToBhActivationFf || !eapToBhActivationFf.variant) {
    return null;
  }

  return (
    <SessionsLeftBanner
      featureViewsData={featureViewsData}
      isDesktop={isDesktop}
      isMobile={isMobile}
      isTablet={isTablet}
      userID={userID}
    />
  );
};

export default AnnouncementsBanner;
