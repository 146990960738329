/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import { Controller, useFormContext } from 'react-hook-form';
import { Styles } from 'react-select';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import Label from '../Label';
import { OptionType } from '../Select/types';
import SelectRounded from '../SelectRounded';
import { Tiny } from '../Typography';
import View from '../View';
import { useUniqueID } from '../../hooks/a11yHelper';
import Tooltip, { TooltipProps } from '../Tooltip';
import { SelectProps } from '../Select';

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

interface RHFSelectProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> extends Omit<SelectProps, 'onChange'> {
  fieldName: FieldName;
  isDisabled?: boolean;
  label: string;
  tooltip?: string;
  tooltipLabel?: string;
  containerStyle?: EmotionStyle;
  labelStyle?: EmotionStyle;
  tooltipStyle?: EmotionStyle;
  selectStyle?: Partial<Styles<OptionType, boolean>>;
  labelContainerStyle?: EmotionStyle;
  allowMobileSearch?: boolean;
  findOption?: (options: OptionType[], value: string | null) => OptionType | undefined;
  onChange?: (
    newValue: OptionType<string> | string | number | string[] | number[] | OptionType[]
  ) => void;
  // by default, a dropdown should register a full option object. There are certain cases were we want just the option.value string, so we use this prop
  registerOptionValue?: boolean;
  /** Enable if there are multiple options with the same value. This fixes manual selection but disables the functionality of setValue(). */
  hasDuplicateOptionValues?: boolean;
  tooltipProps?: TooltipProps;
  isAutoCompleteDisabled?: boolean;
}

const Container = styled(View)({
  display: 'flex',
  width: '100%',
  maxWidth: 335,
  marginBottom: 12,
});

const findOptionByValue = (
  options: OptionType[],
  value: string | number | string[] | number[] | null,
  isMultiByValue?: boolean
): OptionType | OptionType[] | undefined => {
  if (isMultiByValue && Array.isArray(value)) {
    const valuesArray: OptionType[] = [];
    value.forEach((val) => {
      options.every((option) => {
        if (option.value === val) {
          valuesArray.push(option);
          return false;
        }
        return true;
      });
    });
    return valuesArray;
  }
  return options.find((option) => option?.value === value);
};

const StyledError = styled(Tiny)<{ isAutoCompleteDisabled?: boolean }>(
  ({ theme: { colors }, isAutoCompleteDisabled }) => {
    return {
      alignSelf: 'self-start',
      marginTop: 0,
      color: isAutoCompleteDisabled ? colors.permaErrorRed : colors.torchRed,
    };
  }
);

const RHFSelect = <TFieldValues extends Record<string, string>>(
  props: RHFSelectProps<TFieldValues>
) => {
  const {
    fieldName,
    options,
    placeholder = 'Select',
    label,
    tooltip,
    tooltipLabel,
    containerStyle,
    labelStyle,
    tooltipStyle,
    selectStyle,
    labelContainerStyle,
    allowMobileSearch = false,
    findOption = findOptionByValue,
    onChange,
    registerOptionValue,
    tooltipProps,
    isMulti,
    hasDuplicateOptionValues,
    ...otherSelectProps
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>();

  const { colors } = useEmotionTheme();
  const inputID = useUniqueID('inputID');
  const labelID = useUniqueID('labelID');
  const errorID = useUniqueID('errorID');
  const keyID = useUniqueID('keyID');

  const isError = !!errors[fieldName];
  return (
    <Container style={containerStyle}>
      <View
        row
        style={{
          alignItems: 'center',
          marginBottom: 4,
          ...labelContainerStyle,
        }}
      >
        <Label
          isDefaultStyle
          id={labelID}
          htmlFor={inputID}
          style={{
            paddingBottom: 0,
            ...labelStyle,
          }}
        >
          {label}
        </Label>
        {tooltip && (
          <Tooltip
            tip={tooltip}
            label={tooltipLabel || `More info about ${label} field`}
            dataQa={`${fieldName}Tooltip`}
            roundedFocusStyle
            toggleSize={15}
            buttonStyle={{
              display: 'flex',
              borderRadius: 5,
              color: colors.permaWildBlueYonder,
              margin: '0px 0px 0px 2px',
              ...tooltipStyle,
            }}
            {...tooltipProps}
          />
        )}
      </View>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { ref, value, ...otherFieldProps } }) => {
          let finalValue;
          // If there are duplicate option values, keep finalValue undefined to prevent it from overriding the manually selected option
          if (!hasDuplicateOptionValues) {
            // find the appropriate option if the value is set to a string or and array of strings or numbers using setValue(), otherwise leave the value as an object or null
            if (
              // the value has been explicitly set as a string corresponding to option.value of one of the options
              (value && typeof value === 'string') ||
              // the value has been explicitly set as an array whose entries correspond to option.value of multiple options
              (value &&
                Array.isArray(value) &&
                (typeof value[0] === 'string' || typeof value[0] === 'number'))
            ) {
              finalValue = findOption(options, value, isMulti && registerOptionValue);
            } else {
              finalValue = value || null;
            }
          }

          return (
            <SelectRounded
              key={`${keyID}-${fieldName}`}
              dataQa={`${fieldName}Dropdown`}
              wrapperStyle={{ margin: 0, width: '100%', ...selectStyle }}
              inputId={inputID}
              placeholder={placeholder}
              options={options}
              isError={isError}
              // TODO: @Eric upgrade react-select so that other aria-attributes work and add props for aria-invalid and aria-required
              // hack to allow screen readers to announce error when present. Typically we would use aria-describedby but react-select doesn't make it available
              aria-labelledby={errors[fieldName]?.message ? `${labelID} ${errorID}` : undefined}
              {...otherFieldProps}
              value={finalValue}
              onChange={(newValue: OptionType<string> | OptionType[]) => {
                if (isMulti && registerOptionValue) {
                  const chosenOptionValues = (newValue as OptionType[]).map(
                    (option) => option.value
                  );
                  otherFieldProps.onChange(chosenOptionValues);
                  onChange?.(chosenOptionValues);
                } else {
                  otherFieldProps.onChange(
                    registerOptionValue ? (newValue as OptionType<string>)?.value : newValue
                  );
                  onChange?.(
                    registerOptionValue ? (newValue as OptionType<string>)?.value : newValue
                  );
                }
              }}
              refCallback={(el) => {
                ref(el?.select?.inputRef);
              }}
              isInputReadOnly={!allowMobileSearch}
              styles={{
                singleValue: (provided) => {
                  return {
                    ...provided,
                    overflow: 'initial',
                  };
                },
                multiValue: (provided) => {
                  return {
                    ...provided,
                    lineHeight: 'normal',
                  };
                },
                ...selectStyle,
              }}
              isMulti={isMulti}
              {...otherSelectProps}
            />
          );
        }}
      />
      {errors[fieldName]?.message && (
        <StyledError isAutoCompleteDisabled id={errorID}>
          {errors[fieldName]?.message}
        </StyledError>
      )}
    </Container>
  );
};

export default RHFSelect;
