import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { OtherWizardGenericActions } from '../../utils/genericWizardActions';
import { State } from '../reducers/QuestionnaireWizardState';
import { QuestionnaireQuestion } from '../types';

const initState = async (api, state): Promise<Partial<State>> => {
  const questionnaireQuestions = (
    await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/rooms/${
        state.roomID
      }/questionnaires/types/${state.questionnaireType.replace('-', '_')}/questions`
    )
  )?.data;

  const questionsOptionsMapper = {};
  (questionnaireQuestions as Array<QuestionnaireQuestion>).forEach((question) => {
    questionsOptionsMapper[`questionnaireQuestion${question.order}Options`] =
      question.questionnaireQuestionOptions.map((option) => {
        return {
          label: option.text,
          value: option.id,
        };
      });
  });

  return {
    questionnaireQuestions,
    ...questionsOptionsMapper,
  };
};

const QuestionnaireWizardActions: OtherWizardGenericActions<State> = {
  initState,
};

export default QuestionnaireWizardActions;
