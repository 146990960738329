import { EmotionThemeProvider } from '@talkspace/react-toolkit';
import { LaunchDarklyThemeVersioningJSON } from 'ts-frontend/types';
import { useFlags } from './FlagsProvider';

type ThemedFlagsProviderProps = {
  versionKey: keyof LaunchDarklyThemeVersioningJSON;
  isHighContrast?: boolean;
};

// TODO: This should have been named "FlaggedThemeProvider" or something similar
// this provides a theme, not flags
export const ThemedFlagsProvider: React.FC<ThemedFlagsProviderProps> = ({
  versionKey,
  isHighContrast,
  children,
}) => {
  const flags = useFlags();

  const version = flags.themeProviderVersions[versionKey] || flags.themeProviderVersions.default;

  return (
    <EmotionThemeProvider theme={{ isHighContrast }} version={version}>
      {children}
    </EmotionThemeProvider>
  );
};

export default ThemedFlagsProvider;
