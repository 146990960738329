import { useCallback, useEffect, useState } from 'react';
import { TouchableView, View } from '@talkspace/react-toolkit';
import {
  getIonicPasscode,
  setIonicStorage,
  PASSCODE_ATTEMPTS,
  getIonicPasscodeAttempts,
  removeIonicStorage,
} from '../../../plugins/secureStorage';
import { getUserData } from '@/auth/helpers/token';
import PasscodeView from './PasscodeView';
import {
  STORAGE_KEY_CHECK_PASSCODE,
  STORAGE_KEY_APP_LOADED,
  PASSCODE_LENGTH,
  MAX_PASSCODE_ATTEMPTS,
} from '../constants';
import styled from '@/core/styled';
import { logout, refreshToken } from '@/auth/auth';

const StyledTouchableView = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    textAlign: 'center',
    color: colors.accessibilityGreenDark,
    fontSize: 14,
    fontWeight: 600,
    marginTop: 20,
  };
});

const EnterPasscode = ({
  handleAppResumed,
  isClient,
}: {
  handleAppResumed: (val: boolean) => void;
  isClient?: boolean;
}) => {
  const [passcode, setPasscode] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { id: userID } = getUserData();
  const keySuffix = isClient && userID ? `${userID}` : undefined;

  const handleLoginWithEmail = useCallback(() => {
    sessionStorage.removeItem(STORAGE_KEY_CHECK_PASSCODE);
    sessionStorage.removeItem(STORAGE_KEY_APP_LOADED);
    logout();
  }, []);

  const handleCorrectCode = useCallback(() => {
    setIsSuccess(true);
    setTimeout(() => {
      handleAppResumed(false);
      removeIonicStorage(PASSCODE_ATTEMPTS, keySuffix);
      sessionStorage.removeItem(STORAGE_KEY_CHECK_PASSCODE);
      refreshToken();
    }, 1000);
  }, [handleAppResumed, keySuffix]);

  const handleWrongCode = useCallback(async () => {
    const currentNumberOfAttempts = await getIonicPasscodeAttempts(keySuffix);
    if (currentNumberOfAttempts === MAX_PASSCODE_ATTEMPTS) {
      handleLoginWithEmail();
    } else {
      await setIonicStorage({
        key: PASSCODE_ATTEMPTS,
        value: (currentNumberOfAttempts + 1).toString(),
        keySuffix,
      });
    }
    setErrorMessage('Incorrect passcode.\nPlease try again.');
  }, [handleLoginWithEmail, keySuffix]);

  useEffect(() => {
    if (passcode?.length === PASSCODE_LENGTH) {
      const validatePasscode = async () => {
        const { value } = await getIonicPasscode(keySuffix);
        if (passcode !== value) {
          handleWrongCode();
        } else {
          handleCorrectCode();
        }
      };
      validatePasscode();
    }
  }, [passcode, handleCorrectCode, handleWrongCode, keySuffix]);

  const handleSetPasscode = (otpKey: string) => {
    setErrorMessage('');
    setPasscode(otpKey);
  };

  return (
    <View>
      <PasscodeView
        title="Enter passcode"
        handleChange={handleSetPasscode}
        errorMessage={errorMessage}
        shouldShowBackArrow={false}
        isSuccess={isSuccess}
      />
      <StyledTouchableView onPress={handleLoginWithEmail}>Log in with email</StyledTouchableView>
    </View>
  );
};

export default EnterPasscode;
