import { VoidFunctionComponent } from 'react';
import {
  View,
  BaseButton,
  Button,
  Large,
  Big,
  useEmotionTheme,
  BookingTimeZone,
} from '@talkspace/react-toolkit';

import { useTranslation } from '@talkspace/i18n';
import BottomButtonContainer from '../BottomButtonContainer';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';

interface Props {
  onClosePress: () => void;
  bookWithIntroSession?: boolean;
  isReschedule: boolean;
  timezone: string;
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
}

const ConfirmBookingWithoutPolicy: VoidFunctionComponent<Props> = ({
  onClosePress,
  timezone,
  bookWithIntroSession = false,
  isReschedule,
  handleReserveClick,
  onClickCancellationPolicy,
}) => {
  const { isLoading, room } = useInRoomSchedulingState();
  const { colorRoles } = useEmotionTheme();
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} />
        {!room?.isEAP && (
          <BaseButton style={{ marginTop: 40 }} onPress={onClickCancellationPolicy}>
            <Large
              variant="largeBoldWideGreen"
              style={{
                color: colorRoles.typography.textBrandDefault,
              }}
            >
              {tBookingScreen('available.review', 'Review cancellation policy', undefined)}
            </Large>
          </BaseButton>
        )}
      </View>

      <BottomButtonContainer>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text={
            isReschedule
              ? tBookingScreen('available.confirmReschedule', 'Confirm rescheduling', undefined)
              : tBookingScreen('available.bookSession', 'Book session', undefined)
          }
          dataQa={`inRoomSchedulingConfirmBooking${isReschedule ? 'Reschedule' : 'Schedule'}`}
          style={{ backgroundColor: colorRoles.system.actionPrimaryDefault }}
          stretch
        />
        {bookWithIntroSession && (
          <BaseButton
            style={{ marginTop: 20 }}
            onPress={onClosePress}
            data-qa="inRoomSchedulingConfirmBookingReserveSessionLater"
          >
            <Big variant="bigMedium">
              {tBookingScreen(
                'available.reserveLater',
                'I’ll reserve this session later',
                undefined
              )}
            </Big>
          </BaseButton>
        )}
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingWithoutPolicy;
