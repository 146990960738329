import { useState } from 'react';
import ChatModalRoutes from 'chat/ModalRoutes';
import { Modal, View, COLORS, useWindowWidthState } from '@talkspace/react-toolkit';

import { useNewMemberNav, useIsTeenspaceActive } from 'launchDarkly/hooks';
import { ThemedFlagsProvider } from 'launchDarkly';
import {
  EmergencyContactWizard,
  MentalHealthWizard,
  TreatmentIntakeLauncher,
  MedicalHealthWizard,
  TeenMentalHealthWizard,
} from '@/treatmentIntake';
import ParentalConsentResubmit from './ParentalConsentResubmit';
import NextRenewalPaymentDetails from '@/myAccount/components/NextRenewalPaymentDetails';
import VoucherCouponContainer from './voucherCoupon';
import { useModalFocusTrap, useCloseModal, CloseModal } from './utils/ModalsContextProvider';
import SwitchWizard from './switchWizard';
import StripeErrorScreen from './screens/StripeErrorScreen';
import { PersonalInformationContextProvider } from './myAccount/hooks/usePersonalInformation';
import PersonalInformationEditMyInformationModal from './myAccount/containers/PersonalInformationEditMyInformationModal';
import PersonalInformationEditEmergencyContactModal from './myAccount/containers/PersonalInformationEditEmergencyContactModal';
import PersonalInformationEditTeenspaceCommunityModal from './myAccount/containers/PersonalInformationEditTeenspaceCommunityModal';
import MatchingIntakeWizard from './matchingIntakeWizard';
import WhatToExpect from './whatToExpect';
import EligibilityWidget from './eligibilityWidget';
import FriendReferral from './friendReferral';
import EmailSent from './emailVerification/screens/EmailSent';
import styled from './core/styled';
import { Route, RouteComponentProps, Redirect, useHistory } from './core/routerLib';
import CreateSessionReportModalContainer from './containers/CreateSessionReportModalContainer';
import InRoomSchedulingRoomModal from './components/inRoomSchedulingRoomModal';
import SwitchApp from './components/SwitchApp';
import { MainContextProvider } from './hooks/mainContext';
import LogoutAetnaPopup from './components/SSOLogoutPopups/LogoutAetnaPopup';
import ClinicalProgressRoomModal from './clinicalProgress/components/ClinicalProgressRoomModal';
import { SurveyResultsContainer, MoreInfoModalContainer } from './clinicalProgress';
import ClientChatModalRoutes from './clientChat/ModalRoutes';
import NPSWizard from './NPSWizard';
import PostAsyncPrompt from './PostAsyncPrompt';
import DTERenewal from './DTERenewal';
import CheckInWizard from './CheckInWizard';
import EligibilityWarning from './EligibilityWarning';
import EligibilityWarningSuccess from './EligibilityWarning/containers/EligibilityWarningSuccessContainer';
import InRoomSchedulingWrapper from './components/InRoomSchedulingWrapper';
import TherapistDetails from './containers/TherapistDetails';
import HomeModalRoutes from './home/HomeModalRoutes';
import RoomDetails from './containers/RoomDetails';
import StarredMessages from './containers/StarredMessages';
import SharedFilesContainer from './containers/SharedFilesContainer';
import ReviewTherapist from './containers/ReviewTherapist';
import { RoomInviteProvider, RoomInvitesContainer } from './roomInvites';
import CommunityRouter from './community/Router';
import ScheduleChooseProviderContainer from './containers/ScheduleChooseProvider';
import WelcomeBackReturningUser from '@/welcomeBackReturningUser';
import QuestionnaireWizard from './QuestionnaireWizard';

const modalAnimateWhiteList = ['/home/account'];

const Container = styled(View)<{
  fullScreen: boolean;
  transparent: boolean;
  shouldAnimateModal: boolean;
  modalHasEntered: boolean;
}>(
  ({
    fullScreen,
    transparent,
    shouldAnimateModal,
    modalHasEntered,
    theme: {
      window: { isMobile },
    },
  }) => {
    const getAnimateStyles = () =>
      shouldAnimateModal && {
        transform: 'translateY(100%)',
        transition: 'transform 0.25s ease-in-out',
        ...(modalHasEntered && { transform: 'translateY(0)' }),
      };

    return {
      overflow: 'hidden',
      height: fullScreen || isMobile ? '100%' : undefined,
      position: isMobile ? undefined : 'absolute',
      width: fullScreen || isMobile ? '100%' : undefined,
      backgroundColor: !transparent ? COLORS.white : undefined,
      ...getAnimateStyles(),
    };
  }
);
// TODO: remove Container component. Modals rendered with Modal.Panel should have fullScreen={false} transparent={true} which essentially makes <Container> a trivial and useless wrapper
export default function ModalRoutes({ fullScreen, transparent }) {
  const closeModal = useCloseModal();
  const dismissModal = (data) => closeModal(data);
  const { focusTrapPaused } = useModalFocusTrap();
  const [modalHasEntered, setModalHasEntered] = useState(false);

  const history = useHistory();

  const { pathname } = history.location;
  const useNewNav = useNewMemberNav();
  const isTeenspaceActive = useIsTeenspaceActive();
  const useNewNavModal = useNewNav && pathname.includes('home');
  const shouldAnimateModal = modalAnimateWhiteList.includes(pathname);
  const { isMobile } = useWindowWidthState();
  const isMobileNewNav = isMobile && useNewNav;

  const onModalDismiss: CloseModal = (data) => {
    setModalHasEntered(false);
    setTimeout(() => dismissModal(data), 250);
  };
  const onModalEntry = () => {
    setModalHasEntered(true);
  };

  const handleBackButtonPress = () => history.goBack();

  return (
    <Route path="/">
      <Modal
        isVisible
        focusTrapPaused={focusTrapPaused}
        onBackdropPress={fullScreen && !transparent ? undefined : dismissModal}
        isMobileFullscreen={useNewNavModal || fullScreen}
        onEnter={onModalEntry}
      >
        <Container
          fullScreen={fullScreen}
          transparent={transparent}
          shouldAnimateModal={shouldAnimateModal}
          modalHasEntered={modalHasEntered}
        >
          <Route
            path="/treatment-intake-launcher/room/:roomID/source/:source"
            component={TreatmentIntakeLauncher}
          />
          <Route path="/parental-consent-resubmit" component={ParentalConsentResubmit} />
          <Route
            path={[
              '/emergency-contact-wizard/room/:roomID/source/:source',
              '/emergency-contact-wizard/submit',
            ]}
            component={EmergencyContactWizard}
          />
          <Route
            path={['/mental-health-wizard/room/:roomID/source/:source', '/mental-health-wizard']}
            component={MentalHealthWizard}
          />
          <Route
            path={[
              '/teen-mental-health-wizard/room/:roomID/source/:source',
              '/teen-mental-health-wizard',
            ]}
            component={TeenMentalHealthWizard}
          />
          <Route
            path={['/medical-health-wizard/room/:roomID/source/:source', '/medical-health-wizard']}
            component={MedicalHealthWizard}
          />
          <Route path="/friend-referral" component={FriendReferral} />
          <Route path="/voucher-coupon/:code/room/:roomID" component={VoucherCouponContainer} />
          <Route
            path="/email-verification/sent"
            render={(props) => {
              const { email, roomID } = (
                props as RouteComponentProps<{}, {}, { email: string; roomID: number }>
              ).location.state;
              return <EmailSent email={email} roomID={roomID} clickFromRoomBanner />;
            }}
          />
          <Route path="/switch-app" component={SwitchApp} />
          <Route
            path="/room/:roomID/survey-results/:userRoomSurveyID"
            component={SurveyResultsContainer}
          />
          <Route path="/survey-more-info" component={MoreInfoModalContainer} />
          <Route path="/stripe-error" component={StripeErrorScreen} />
          <Route path="/clinical-progress-rooms" component={ClinicalProgressRoomModal} />
          <Route path="/in-room-scheduling-rooms" component={InRoomSchedulingRoomModal} />
          <Route
            path="/switch-provider/room/:roomID"
            render={(props) => {
              const { match, location } = props as RouteComponentProps<
                { roomID: string },
                {},
                { source: string }
              >;
              const { roomID } = match.params;
              const {
                state: { source },
              } = location;
              return (
                <Redirect
                  to={{
                    pathname: '/switch-provider',
                    state: {
                      roomID: Number(roomID),
                      source,
                      contextID: '',
                    },
                  }}
                />
              );
            }}
          />
          <Route
            path="/switch-provider"
            render={(props) => {
              const { location: RouteLocation, history: RouteHistory } =
                props as RouteComponentProps<
                  {},
                  {},
                  { roomID: number; source: string; contextID?: string }
                >;
              const { roomID, source, contextID } = RouteLocation.state;
              return (
                <SwitchWizard
                  roomID={roomID}
                  source={source}
                  contextID={contextID}
                  history={RouteHistory}
                />
              );
            }}
          />
          <Route
            path={[
              '/check-in/room/:roomID/source/:source/check-in-source/:checkInSource/video-call/:videoCallID',
              '/check-in/room/:roomID/source/:source/check-in-source/:checkInSource/user-prompt/:userPromptID',
              '/check-in/done/:source',
              '/v3/check-in/room/:roomID',
              '/v3/check-in/done/:source',
              '/v3/check-in/change-provider',
            ]}
            render={(props) => (
              <MainContextProvider>
                <CheckInWizard {...props} />
              </MainContextProvider>
            )}
          />
          <Route
            path={[
              '/nps/room/:roomID/source/:source/user-prompt/:userPromptID',
              '/nps/done/:source',
            ]}
            component={NPSWizard}
          />
          <Route
            path="/post-async-prompt/room/:roomID/source/:source/user-prompt/:userPromptID"
            component={PostAsyncPrompt}
          />
          <Route
            path={[
              '/matching-intake/room/:roomID/source/:source',
              '/matching-intake/submit',
              '/matching-intake/under13',
              '/matching-intake/under18',
            ]}
            component={MatchingIntakeWizard}
          />
          <Route
            path="/what-to-expect"
            render={() => (
              <MainContextProvider>
                <WhatToExpect />
              </MainContextProvider>
            )}
          />
          <Route
            path="/welcome-back-returning-user"
            render={() => (
              <MainContextProvider>
                <WelcomeBackReturningUser />
              </MainContextProvider>
            )}
          />
          <Route
            path={['/eligibility-widget/room/:roomID', '/eligibility-widget']}
            component={EligibilityWidget}
          />
          <Route path={['/next-renewal-payment-details']} component={NextRenewalPaymentDetails} />
          <Route
            path="/create-session-report/room/:roomID"
            component={CreateSessionReportModalContainer}
          />
          <Route path="/sso/logout-popups/aetna" component={LogoutAetnaPopup} />
          <Route path="/dte-renewal" component={DTERenewal} />
          <PersonalInformationContextProvider>
            {isTeenspaceActive && (
              <Route
                path="/edit-teenspace-community"
                render={() => (
                  <PersonalInformationEditTeenspaceCommunityModal
                    handleOnClose={isMobileNewNav ? handleBackButtonPress : closeModal}
                  />
                )}
              />
            )}
            <Route
              path="/edit-my-information"
              render={() => (
                <PersonalInformationEditMyInformationModal
                  handleOnClose={isMobileNewNav ? handleBackButtonPress : closeModal}
                />
              )}
            />
            <Route
              path="/edit-emergency-contact"
              render={() => (
                <PersonalInformationEditEmergencyContactModal
                  handleOnClose={isMobileNewNav ? handleBackButtonPress : closeModal}
                />
              )}
            />
          </PersonalInformationContextProvider>
          <ClientChatModalRoutes />
          <ChatModalRoutes isTherapistChat={false} />
          <Route exact path="/eligibility-warning/:roomID" component={EligibilityWarning} />
          <Route
            path="/eligibility-warning/:roomID/success"
            component={EligibilityWarningSuccess}
          />
          <Route path="/in-room-scheduling/room/:roomID" component={InRoomSchedulingWrapper} />
          <Route
            path="/in-room-scheduling/choose-provider"
            render={() => (
              <ThemedFlagsProvider versionKey="homePage">
                <ScheduleChooseProviderContainer />
              </ThemedFlagsProvider>
            )}
          />
          <Route
            path="/provider-profile/room/:roomID"
            render={() => (
              <MainContextProvider>
                <TherapistDetails isChatHidden isMobile={false} isModal />
              </MainContextProvider>
            )}
          />
          <Route
            exact
            path="/room/:roomID/room-details"
            render={() => <RoomDetails isModal isChatHidden />}
          />
          <Route
            path="/room/:roomID/therapist-details"
            render={() => <TherapistDetails isModal isChatHidden />}
          />
          <Route
            path={[
              '/room/:roomID/starred-messages/:starredRoomID',
              '/room/:roomID/starred-messages',
            ]}
            component={StarredMessages}
          />
          <Route path="/room/:roomID/shared-files" component={SharedFilesContainer} />
          <Route path="/room/:roomID/review-therapist" component={ReviewTherapist} />
          <Route
            exact
            path="/room/:roomID/invite-partner"
            render={(routeProps) => (
              <RoomInviteProvider>
                <RoomInvitesContainer {...routeProps} />
              </RoomInviteProvider>
            )}
          />

          <Route
            path={[
              '/questionnaire-wizard/type/:questionnaireType/room/:roomID/source/:source',
              '/questionnaire-wizard/complete',
            ]}
            component={QuestionnaireWizard}
          />
          {isTeenspaceActive && <Route path="/community" component={CommunityRouter} />}
          <HomeModalRoutes
            closeModal={onModalDismiss}
            handleBackButtonPress={handleBackButtonPress}
          />
        </Container>
      </Modal>
    </Route>
  );
}
