import StackTrace from 'stacktrace-js';
import { mediaLogger } from '../../loggers';
import { crashlyticsLog, recordException } from '../crashlytics';

export const mediaLoggerWithCrashlytics = (
  type: 'log' | 'warn' | 'error' | 'debug',
  ...args: any[]
) => {
  mediaLogger[type](...args);
  crashlyticsLog({ message: [type, ...args].join(' | ') });
};

export const reportError = async (errorMessage: string, error?: Error) => {
  mediaLogger.error(errorMessage, error);
  await recordException({
    message: errorMessage,
    stacktrace: error
      ? await StackTrace.fromError(error, { offline: true }).catch(() => {
          // Sometimes fromError can fail if the error is from a third party
          crashlyticsLog({
            message: errorMessage,
          });
          return undefined;
        })
      : undefined,
  });
};
