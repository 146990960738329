import { useQuery, UseQueryResult } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { QuestionnaireType } from './types';
import { questionnairesByClientQueryKey } from './queryKeys';
import { FetchQuestionnairePesponse } from './useQueryQuestionnaires';

export interface FetchQuestionnaireByClientParams {
  clientID: number;
  questionnaireTypes?: Array<QuestionnaireType>;
}

const fetchQuestionnairesByClient =
  (params: FetchQuestionnaireByClientParams) => async (): Promise<FetchQuestionnairePesponse> => {
    const { clientID, questionnaireTypes } = params;
    const data = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientID}/rooms/questionnaires${
        questionnaireTypes?.length ? '?questionnaireTypes[]=' : ''
      }${questionnaireTypes || ''}`
    );
    return data?.data || [];
  };

const useQueryQuestionnairesByClient = (
  params: FetchQuestionnaireByClientParams
): UseQueryResult<FetchQuestionnairePesponse, Error> =>
  useQuery<FetchQuestionnairePesponse, Error>({
    queryKey: [questionnairesByClientQueryKey(params.questionnaireTypes || [], params.clientID)],
    queryFn: fetchQuestionnairesByClient(params),
  });

export default useQueryQuestionnairesByClient;
