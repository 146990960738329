import { ComponentProps } from 'react';
import ProgressBarV2, { ProgressBarV2Props } from './ProgressBarV2';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

export type { ProgressBarV2Props };

const versionMap: VersionMap<ComponentProps<typeof ProgressBarV2>> = {
  DefaultComponent: ProgressBarV2,
};

export default withVersioning(versionMap);
