import { useEffect, useState } from 'react';
import { useA11yActions } from '@talkspace/react-toolkit';
import AcksSocketService from 'chat/redux/helpers/AcksSocketService';
import MessagesSocketService from 'chat/redux/helpers/MessagesSocketService';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import { useQueryClient } from 'react-query';
import { EMessagePreview } from 'ts-frontend/entities/MessagePreview';
import { useMainState, useMainActions } from './mainContext';
import { getUserData } from '../auth/helpers/token';
import UserSettingsSocketService from '../clientChat/utils/UserSettingsSocketService';
import UserEventsSocketService from '../clientChat/utils/UserEventsSocketService';
import { onboardingQueryKey } from '../utils/queryKeys';
import { IncomingSocketData } from '../utils/socket/socketTypes';
import {
  popIsOnboardingDismissed,
  popIsOnboardingDismissedTestAccount,
} from '../onboarding/util/onboardingStorage';

const socketListeners: {
  [roomID: string]: {
    messagesSocket: MessagesSocketService;
    acksSocket: AcksSocketService;
  };
} = {};

let pollingRunning = false;

interface UseDashboardSocketOptions {
  getLastMessages?: (clientUserID: number) => Promise<null>;
  getAllRooms?: (clientUserID: number) => Promise<null>;
  getBooking?: (bookingID: string) => Promise<null>;
  getOnboarding?: () => Promise<null>;
  getClientInfo?: () => Promise<null>;
  watchRoomIDs?: string[];
}

export default function useDashboardSocket(options: UseDashboardSocketOptions = {}) {
  const [isAcking, setIsAcking] = useState(false);
  const [lastMessageReceived, setLastMessageReceived] = useState<EMessagePreview>();
  const {
    watchRoomIDs: optionWatchRoomIDs,
    getLastMessages: optionsGetLastMessages,
    getAllRooms: optionsGetAllRooms,
    getBooking: optionsGetBooking,
    getOnboarding: optionsGetOnboarding,
    getClientInfo: optionsGetClientInfo,
  } = options;

  const { setColorContrast } = useA11yActions();
  const { roomsByID } = useMainState();
  const watchRoomIDs = optionWatchRoomIDs || Object.keys(roomsByID);
  const roomsByIDSize = watchRoomIDs.length;
  const { id: clientUserID } = getUserData();
  const {
    getInformedConsentStatus,
    getLastMessages,
    receiveLastMessage,
    updateLastReadAck,
    getAllRooms,
    setNickname,
  } = useMainActions();
  const lastMessageReceivedID = lastMessageReceived ? lastMessageReceived.messageID : 0;

  useEffect(() => {
    if (!lastMessageReceived) return;
    const { roomID, messageID } = lastMessageReceived;
    if (isAcking && socketListeners[roomID]) {
      socketListeners[roomID].acksSocket.receiveAck(messageID);
    }
  }, [isAcking, lastMessageReceived, lastMessageReceivedID]);

  useEffect(() => {
    const pollingLimiter = async () => {
      if (pollingRunning) return;
      pollingRunning = true;
      if (optionsGetLastMessages) {
        await optionsGetLastMessages(clientUserID).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
      } else {
        await getLastMessages(clientUserID).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
      }
      pollingRunning = false;
    };

    const updateAcks = (acks, roomID: number, lastReadAckedMessageID: number): void => {
      updateLastReadAck(roomID, lastReadAckedMessageID);
    };

    const requestMessages = (roomID: number, data: any): void => {
      if (data) {
        const messagePreview = new EMessagePreview(data);
        receiveLastMessage(messagePreview);
        setLastMessageReceived(messagePreview);
        if (optionsGetLastMessages) {
          (async () => {
            await optionsGetLastMessages(clientUserID).catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
          })();
        }
      } else {
        pollingLimiter();
      }
    };

    // unsubscribe from old rooms
    Object.keys(socketListeners).forEach((roomID) => {
      if (watchRoomIDs.includes(roomID)) return;
      socketListeners[roomID].acksSocket.unmount();
      socketListeners[roomID].messagesSocket.unmount(true);
      delete socketListeners[roomID];
    });

    // subscribe to new rooms
    watchRoomIDs.forEach((roomID) => {
      if (!socketListeners[roomID]) {
        socketListeners[roomID] = {
          messagesSocket: new MessagesSocketService(requestMessages, Number(roomID), () => {}),
          acksSocket: new AcksSocketService(updateAcks, Number(roomID), clientUserID, false),
        };
      }
    });
  }, [
    clientUserID,
    watchRoomIDs,
    updateLastReadAck,
    roomsByIDSize,
    receiveLastMessage,
    getLastMessages,
    optionsGetLastMessages,
  ]);

  const { data: treatmentIntakeInOnboardingActive } = useTSAdminConfig(
    AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    const updateUserSettings = (data) => {
      setIsAcking(data && data.sendMessagesReceipts);
      if (data && typeof data.highContrast === 'boolean') setColorContrast(data.highContrast);
    };

    const updateAllRooms = () => {
      if (optionsGetAllRooms) {
        // eslint-disable-next-line no-console
        optionsGetAllRooms(clientUserID).catch((err) => console.error(err));
      } else {
        // eslint-disable-next-line no-console
        getAllRooms(clientUserID).catch((err) => console.error(err));
      }
    };

    const checkInformedConsentStatus = () => {
      getInformedConsentStatus(clientUserID);
    };

    const handleChangedTherapistEvent = ({ message }) => {
      if (treatmentIntakeInOnboardingActive) {
        popIsOnboardingDismissed(message.private_talk_id);
        popIsOnboardingDismissedTestAccount(message.private_talk_id);
        if (optionsGetOnboarding) {
          optionsGetOnboarding();
        }
        queryClient.refetchQueries(
          onboardingQueryKey({ roomID: message.private_talk_id, userID: clientUserID }),
          { exact: true }
        );
      } else {
        checkInformedConsentStatus();
      }
    };

    const handleRefetchSessionStatus = () => {
      const refetchSessionStatusEvent = new CustomEvent('refetchSessionStatus', {
        detail: {},
      });
      window.dispatchEvent(refetchSessionStatusEvent);
    };

    const handleRefetchBookings = ({ message }) => {
      const { bookingID } = message;
      if (optionsGetBooking) {
        optionsGetBooking(bookingID);
      }
    };

    const handleChangedNickname = (data: IncomingSocketData['changedNickname']) => {
      setNickname(data);
      if (optionsGetClientInfo) {
        optionsGetClientInfo();
      }
    };

    UserSettingsSocketService.instance().addListener(updateUserSettings);

    // in case sockets is down poll updateAllRooms every 2 minutes
    const userEventsSocketService = new UserEventsSocketService(120);
    userEventsSocketService.addListener(updateAllRooms, false);

    userEventsSocketService.on('changedNickname', handleChangedNickname);
    userEventsSocketService.on('roomCreated', updateAllRooms);
    userEventsSocketService.on('changedTherapist', handleChangedTherapistEvent);
    userEventsSocketService.on('joinedRoom', updateAllRooms);
    userEventsSocketService.on('refetchSessionStatus', handleRefetchSessionStatus);
    userEventsSocketService.on('bookingUpdate', handleRefetchBookings);

    return () => {
      userEventsSocketService.off('changedNickname', setNickname);
      userEventsSocketService.off('roomCreated', updateAllRooms);
      userEventsSocketService.off('changedTherapist', handleChangedTherapistEvent);
      userEventsSocketService.off('joinedRoom', updateAllRooms);
      userEventsSocketService.off('refetchSessionStatus', handleRefetchSessionStatus);
      userEventsSocketService.off('bookingUpdate', handleRefetchBookings);

      userEventsSocketService.unmount(false);

      UserSettingsSocketService.instance().destroy();

      Object.keys(socketListeners).forEach((roomID) => {
        socketListeners[roomID].acksSocket.unmount();
        socketListeners[roomID].messagesSocket.unmount(true);
        delete socketListeners[roomID];
      });
    };
  }, [
    setNickname,
    getAllRooms,
    clientUserID,
    setColorContrast,
    getInformedConsentStatus,
    optionsGetBooking,
    queryClient,
    treatmentIntakeInOnboardingActive,
    optionsGetAllRooms,
    optionsGetOnboarding,
    optionsGetClientInfo,
  ]);

  return {};
}
