import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const ChangePlan = ({ width = 23, height = 19, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'change plan';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.white}
          stroke={colors.periwinkleGrey}
          strokeWidth="0.5"
          d="M638.75 158.003c0-5.661 4.595-10.253 10.258-10.253h480.984c5.667 0 10.258 4.587 10.258 10.253v454.994c0 5.661-4.595 10.253-10.258 10.253H649.008c-5.667 0-10.258-4.587-10.258-10.253V158.003z"
          transform="translate(-677 -559)"
        />
        <Path
          fill={colors.green}
          stroke={colors.green}
          strokeWidth="0.25"
          d="M6.884.089a.657.657 0 00-.502.232L4.224 2.736a.668.668 0 000 .888l2.372 2.655c.243.272.66.294.93.05a.668.668 0 00.05-.938L6.208 3.856h6.694c2.679 0 3.78 1.583 3.78 3.145s-1.101 3.153-3.78 3.153H5.097c-3.235 0-5.094 2.22-5.097 4.465-.003 2.245 1.854 4.456 5.08 4.456h6.744l-1.169 1.311a.668.668 0 00.05.937c.27.245.686.223.93-.05l2.157-2.414a.668.668 0 000-.888l-2.371-2.655a.656.656 0 00-1.104.187.668.668 0 00.124.7l1.383 1.544H5.08c-2.664 0-3.764-1.577-3.763-3.128.002-1.552 1.105-3.137 3.78-3.137h7.806c3.24 0 5.097-2.227 5.097-4.481s-1.856-4.473-5.097-4.473H6.176l1.185-1.32A.668.668 0 007.488.49.659.659 0 006.884.09h0z"
          opacity="0.9"
          transform="translate(-677 -559) translate(675 467) rotate(90 -34 58.5) translate(0 .155)"
        />
      </g>
    </Svg>
  );
};

export default ChangePlan;
