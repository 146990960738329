import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface CreateQuestionnaireParams {
  roomID: number;
  createdBy: number;
  answers: Array<QuestionnaireAnswerData>;
  type: 'cssrs_screener' | 'safety_plan';
}

export interface QuestionnaireAnswerData {
  questionID: number;
  booleanAnswer?: boolean;
  options?: Array<number>;
}

async function createQuestionnaire(params: CreateQuestionnaireParams): Promise<void> {
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${params.roomID}/questionnaires/create`,
    params
  );
}

const QuestionnaireWizardApiHelper = {
  createQuestionnaire,
};

export default QuestionnaireWizardApiHelper;
