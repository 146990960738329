import { useEffect, useState } from 'react';

import { Spinner, View } from '@talkspace/react-toolkit';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import { useStripeTs, isStripeUsingLink } from 'stripe/stripeContext';
import { routePromise } from 'ts-frontend/helpers/routePromise';
import paymentAPI from 'offer/utils/paymentApiHelper';
import { ThemedFlagsProvider } from 'launchDarkly';
import { RouteComponentProps, Route, withRouter, useLocation } from '@/core/routerLib';
import { getUserData } from '@/auth/helpers/token';
import {
  EmergencyContactWizard,
  MedicalHealthWizard,
  MentalHealthWizard,
  TeenMentalHealthWizard,
  TeenEmergencyContactWizard,
} from '@/treatmentIntake';
import InformedConsentContainer from './InformedConsentContainer';
import BookSessionContainer from './BookSessionContainer';
import BookAsyncSessionContainer from './BookAsyncSessionContainer';
import MeetYourProviderContainer from './MeetYourProviderContainer';
import BookAndActivateContainer from './BookAndActivateContainer';
import AsyncSessionStartedContainer from './AsyncSessionStartedContainer';

import Onboarding from '../components/Onboarding';
import OnboardingPageParentalConsentDetails from '../components/OnboardingPageParentalConsentDetails';
import useQueryOnboarding from '../hooks/useQueryOnboarding';
import { useOnboardingActions, useOnboardingState } from '../hooks/onboardingContext';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { MeetYourProviderProvider } from '../../meetYourProvider/hooks/meetYourProviderContext';
import { checkIfTeensIntake } from '../util/onboardingStorage';
import useShowOnboardingImprovements from '../../hooks/useBHOnboardingImprovements';

const OnboardingContainer = ({
  history,
  match: {
    params: { roomID },
  },
}: RouteComponentProps<{ roomID: number }>) => {
  const { id: userID } = getUserData();
  const { getClient } = useMainActions();
  const { roomsByID } = useMainState();
  const location = useLocation();

  const { data: onboardingData, isLoading } = useQueryOnboarding({
    roomID,
    userID,
    disableRefetchOnWindowFocus: true,
  });
  const { advanceToNextStep, setOnboardingState, reset, setIsLoading, setIsRedirectToRoom } =
    useOnboardingActions();
  const { hasStarted } = useOnboardingState();

  const { getAllRooms } = useMainActions();

  const [useStripeLink, setUseStripeLink] = useState(true);
  const stripe = useStripeTs();

  const shouldSeeBHOnboardingImprovements = useShowOnboardingImprovements(roomID, userID);

  useEffect(() => {
    getAllRooms(userID);
    setUseStripeLink(isStripeUsingLink(stripe));
  }, [userID, getAllRooms, stripe]);

  const onLoadStripeLink = async () => {
    const { id } = getUserData();
    const res = await paymentAPI.getSetupIntent({ userID: id });
    return res;
  };

  useEffect(() => {
    if (isLoading) {
      setIsLoading();
    }
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    // when hitting /onboarding/next, advance to next step and go to /onboarding
    if (history.location.pathname.endsWith('/onboarding/next')) {
      advanceToNextStep();
      const pathSteps = history.location.pathname.split('/');
      history.replace(pathSteps.splice(0, pathSteps.length - 1).join('/'));
    }
  }, [history, history.location.pathname, advanceToNextStep]);

  useEffect(() => {
    if (onboardingData && hasStarted === false) {
      setOnboardingState({
        ...onboardingData,
        isTeensIntake: checkIfTeensIntake(onboardingData.steps),
      });
    }
  }, [onboardingData, isLoading, setOnboardingState, hasStarted]);

  useEffect(() => {
    if (!isLoading && onboardingData && onboardingData.steps.length === 0) {
      if (hasStarted) {
        // useQueryOnboarding will refetch on mount to ensure updated onboarding steps
        // At the end of the onboarding, the room reload will cause onboardingData to refetch.
        // if hasStarted is still true and there are no more onboarding steps we know we have
        // recently finished an onboarding flow.
        reset();
      } else {
        // Onbording is stuck. Hard reload to rooms page
        routePromise(`/rooms`);
      }
    }
  }, [onboardingData, isLoading, reset, hasStarted]);

  useEffect(() => {
    getClient(userID);
  }, [userID, getClient]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('redirectToRoom') === 'true') {
      setIsRedirectToRoom(true);
    }
  }, [setIsRedirectToRoom, location]);

  useEffect(() => {
    if (roomID && roomsByID[roomID]) {
      const onboardingRoom = roomsByID[roomID];
      const queryParams = new URLSearchParams(location.search);
      if (onboardingRoom.updatedFromRoomID || queryParams.get('redirectToRoom') === 'true') {
        setIsRedirectToRoom(true);
      }
    }
  }, [roomID, roomsByID, setIsRedirectToRoom, location]);

  if (isLoading)
    return (
      <View align="center" justify="center" style={{ height: '100vh' }}>
        <Spinner />
      </View>
    );

  return (
    <ThemedFlagsProvider
      versionKey={shouldSeeBHOnboardingImprovements ? 'quickmatchOrIntakeBHFlow' : 'default'}
    >
      <Route exact path="/room/:roomID/onboarding" component={Onboarding} />
      <Route
        path="/room/:roomID/onboarding/mental-health/source/:source"
        component={MentalHealthWizard}
      />
      <Route
        path="/room/:roomID/onboarding/teen-mental-health/source/:source"
        component={TeenMentalHealthWizard}
      />
      <Route
        path="/room/:roomID/onboarding/medical-health/source/:source"
        component={MedicalHealthWizard}
      />
      <Route
        path="/room/:roomID/onboarding/emergency-contact/source/:source"
        component={EmergencyContactWizard}
      />
      <Route
        path="/room/:roomID/onboarding/teen-emergency-contact/source/:source"
        component={TeenEmergencyContactWizard}
      />
      <Route
        path="/room/:roomID/onboarding/meet-your-provider"
        component={MeetYourProviderContainer}
      />
      <Route
        path="/room/:roomID/onboarding/informed-consent"
        component={InformedConsentContainer}
      />
      <Route
        path="/room/:roomID/onboarding/book-and-activate"
        component={BookAndActivateContainer}
      />
      <Route
        path="/room/:roomID/onboarding/async-session-started"
        component={AsyncSessionStartedContainer}
      />
      <Route
        path="/room/:roomID/onboarding/parental-consent"
        component={OnboardingPageParentalConsentDetails}
      />
      <MeetYourProviderProvider isOnboarding>
        <InRoomSchedulingProvider>
          <Route path="/room/:roomID/onboarding/book-session" component={BookSessionContainer} />
          <Route
            path="/room/:roomID/onboarding/book-async-session"
            render={() => (
              <BookAsyncSessionContainer
                useStripeLink={useStripeLink}
                onLoadStripeLink={onLoadStripeLink}
              />
            )}
          />
        </InRoomSchedulingProvider>
      </MeetYourProviderProvider>
    </ThemedFlagsProvider>
  );
};

export default withRouter(OnboardingContainer);
