import { useState, useMemo, useEffect, useCallback } from 'react';
import { CheckV2, TextDS, TouchableView, View } from '../../../components';
import { SelectViewProps } from '../../../components/Select/SelectView';
import styled, { useEmotionTheme } from '../../../core/styled';
import TabRadioGroup from '../TabRadioGroup';
import { withHover, WithHoverProps } from '../../../hoc';
import { getSelectListStyles } from '../../../index';
import Checkbox from '../Checkbox/Checkbox';

export interface OptionType {
  value: string | JSX.Element;
  label: string;
}
export interface SelectListProps extends SelectViewProps {
  variant?: 'default' | 'checkbox' | 'withSlot';
  isMultiSelect?: boolean;
  onSubmitCallback?: (selectedOptions: OptionType[]) => void; // When an option is selected and if the input is in single select mode, call the callback
  optionsList?: OptionType[] | OptionType;
  isDisabled?: boolean;
  selectedOptions?: OptionType[];
  dataQa: string;
}

interface SelectListItemProps extends WithHoverProps {
  variant: 'default' | 'checkbox' | 'withSlot';
  key: string;
  value: string | JSX.Element;
  label: string;
  onPress: () => void;
  isDisabled?: boolean;
  isSelected: boolean;
  dataQa: string;
}

const Styled = {
  Text: styled(View)(() => {
    return {
      width: '343px',
    };
  }),
  Card: styled(View)<
    {
      variant: 'default' | 'checkbox' | 'withSlot';
      selected: boolean;
      removeTransition?: boolean;
      isDisabled?: boolean;
      isHovering?: boolean;
    } & WithHoverProps
  >(
    ({
      variant,
      selected,
      isHovering,
      isDisabled,
      removeTransition,
      theme: { spacing, colorRoles },
    }) => {
      return {
        flexDirection: variant === 'checkbox' ? 'row' : 'column',
        justifyContent: 'space-between',
        padding: `${spacing('space200') + spacing('space025')}px ${spacing('space300')}px`,
        margin: !isDisabled && selected ? '-1px' : '0',
        color: colorRoles.typography.textSubtle,
        background: colorRoles.surfaces.surfaceInteractiveDefault,
        border: `1px solid ${colorRoles.borders.borderInteractiveDefault}`,
        borderRadius: '12px',
        minWidth: '104px',
        ...getSelectListStyles(colorRoles, {
          isSelected: selected,
          isHovering,
          isDisabled,
          removeTransition,
        }),
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    }
  ),
  Checkbox: styled(Checkbox)<WithHoverProps>(({ theme: { spacing } }) => {
    return {
      marginRight: `${spacing('space075')}px`,
      marginBottom: 0,
    };
  }),
};

const SelectListItem: React.FC<SelectListItemProps> = ({
  variant,
  key,
  label,
  value,
  onPress,
  isDisabled,
  isHovering,
  isSelected,
  dataQa,
}) => {
  const { colorRoles } = useEmotionTheme();

  return (
    <TouchableView
      dataQa={dataQa}
      className={`select-list-item-${key || label}`}
      style={{
        borderRadius: '12px',
        margin: '8px 0px',
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
      onPress={onPress}
      role="option"
      aria-selected={isSelected}
      aria-disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
    >
      <Styled.Card
        variant={variant}
        selected={isSelected}
        isDisabled={isDisabled}
        removeTransition={false}
        isHovering={isHovering}
      >
        {variant === 'checkbox' && (
          <Styled.Checkbox
            alignCenter
            label={
              <TextDS variant="headingMd" inheritColor>
                {label}
              </TextDS>
            }
            isLabelOnRight
            isChecked={!isDisabled && isSelected}
            setIsChecked={onPress}
            hasHoverStyles
            hoverColors={
              !isDisabled
                ? {
                    backgroundColor: colorRoles.surfaces.surfaceInteractiveHovered,
                    borderColor: colorRoles.borders.borderInteractiveDefault,
                  }
                : {
                    backgroundColor: '',
                    borderColor: '',
                  }
            }
            checkedColor={colorRoles.surfaces.surfaceInteractiveSelectedBold}
            checkComponent={<CheckV2 />}
            isDisabled={isDisabled}
            isHovering
            checkboxStyle={{
              borderRadius: '4px',
              borderWidth: '1.5px',
              borderColor: isSelected
                ? `${colorRoles.borders.borderInteractiveSelectedBold}`
                : `${colorRoles.borders.borderInteractiveDefault}`,
              ...(isDisabled
                ? {
                    backgroundColor: colorRoles.surfaces.surfaceInteractiveDisabledSubtle,
                    border: `1.5px solid ${colorRoles.borders.borderDefaultDisabled}`,
                  }
                : {}),
            }}
            shouldFocus={false}
          />
        )}
        <Styled.Text flex={1} align="start">
          {variant !== 'checkbox' && (
            <TextDS variant="headingMd" inheritColor>
              {label}
            </TextDS>
          )}
        </Styled.Text>
        {variant === 'withSlot' && value}
      </Styled.Card>
    </TouchableView>
  );
};

const SelectListItemWithHover = withHover<
  WithHoverProps & {
    variant: 'default' | 'checkbox' | 'withSlot';
    label: string;
    onPress: () => void;
    isDisabled?: boolean;
    value?: string | JSX.Element;
    isSelected: boolean;
    dataQa: string;
  }
>(SelectListItem);

const SelectList: React.FC<SelectListProps> = ({
  variant = 'default',
  optionsList,
  isMultiSelect = false,
  onSubmitCallback,
  isDisabled = false,
  selectedOptions,
  dataQa,
}) => {
  const [selectedOptionsList, setSelectedOptionsList] = useState<OptionType[]>([]);
  // Accommodate single or multiple options and prevent rerenders
  const options: OptionType[] = useMemo(
    () =>
      (Array.isArray(optionsList) ? optionsList : [optionsList]).filter(
        (option): option is OptionType => option !== undefined
      ),
    [optionsList]
  );

  useEffect(() => {
    if (selectedOptions) {
      setSelectedOptionsList(selectedOptions);
    }
  }, [selectedOptions]);

  const handleOptionPress = useCallback(
    (option: OptionType) => {
      if (isDisabled) {
        return;
      }

      let newSelectedOptions: OptionType[] = [];

      if (isMultiSelect) {
        // matching on label instead of value since older components use answerValue instead of value
        const isSelected = selectedOptionsList.some((v) => v.label === option.label);
        newSelectedOptions = isSelected
          ? selectedOptionsList.filter((v) => v.label !== option.label)
          : [...selectedOptionsList, option];
      } else {
        newSelectedOptions = [option];
      }

      setSelectedOptionsList(newSelectedOptions);
      onSubmitCallback && onSubmitCallback(newSelectedOptions);
    },
    [isDisabled, isMultiSelect, onSubmitCallback, selectedOptionsList]
  );

  return (
    <View row={false} role="listbox" aria-multiselectable={isMultiSelect}>
      <TabRadioGroup>
        {options.map((option, index) => (
          <SelectListItemWithHover
            dataQa={dataQa ? `${dataQa}RadioButton${index}` : `radioButton${index}`}
            variant={variant}
            key={variant === 'withSlot' ? option.label : `${option.value}-${index}`}
            value={option.value}
            label={option.label}
            onPress={() => handleOptionPress(option)}
            isDisabled={isDisabled}
            isSelected={selectedOptionsList.some((v) => v.label === option.label)}
          />
        ))}
      </TabRadioGroup>
    </View>
  );
};

export default SelectList;
