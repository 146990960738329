import React from 'react';
import { View, spacing, TouchableView } from '@talkspace/react-toolkit';
import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import WeightedSentence from './WeightedSentence';
import styled from '@/core/styled';

const { space200 } = spacing;
const { regular } = fontWeight;

interface DailyPromptProps {
  sentence: string;
  replyCount: number | string;
  handleDailyPromptReveal?: () => void;
  onPress?: () => void;
}

const Count = styled(View)(({ theme: { colorRoles } }) => {
  return {
    fontSize: '14px',
    fontWeight: regular,
    color: colorRoles.typography.textDefault,
    opacity: '0.7',
    marginTop: space200,
  };
});

const Prompt = styled(View)(() => {
  return {
    textAlign: 'center',
    lineHeight: 1,
  };
});

const DailyPrompt: React.FC<DailyPromptProps> = ({
  sentence,
  replyCount,
  handleDailyPromptReveal,
  onPress,
}) => (
  <TouchableView onPress={onPress} dataQa="dailyPromptTouchableView">
    <Prompt justify="center" align="center" onClick={handleDailyPromptReveal}>
      <WeightedSentence sentence={sentence} />
      <Count>{replyCount} </Count>
    </Prompt>
  </TouchableView>
);
export default DailyPrompt;
