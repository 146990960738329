import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Eye = ({ width = 27, height = 20, color, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'eye';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 27 20"
      {...props}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.white}
          stroke={color || colors.periwinkleGrey}
          d="M10 0h315c5.523 0 10 4.477 10 10v30c0 5.523-4.477 10-10 10H10C4.477 50 0 45.523 0 40V10C0 4.477 4.477 0 10 0z"
          transform="translate(-293 -15)"
        />
        <Path
          fill={color || colors.periwinkleGrey}
          d="M13.5 0C20.73.018 27.027 6.379 27 10c-.027 3.62-6.27 10.074-13.5 10C6.27 19.924.016 13.62 0 10-.016 6.379 6.27-.018 13.5 0zm0 3.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm0 1.5a5 5 0 110 10 5 5 0 010-10z"
        />
      </G>
    </Svg>
  );
};

export default Eye;
