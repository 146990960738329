import {
  Button,
  Screen,
  EmotionThemeProvider,
  TextDS,
  View,
  Grid,
  Row,
  Col,
} from '@talkspace/react-toolkit';
import { causeNativeCrash } from 'ts-ionic/plugins/crashlytics';
import { useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import styled from '@/core/styled/styled';

const ColumnWrapper = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    padding: spacing('space200'),
    borderRadius: spacing('space200'),
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: spacing('space200'),
    backgroundColor: colorRoles.surfaces.neutralDefault,
  };
});

// JSON Viewer
const TextArea = styled('textarea')(({ theme: { colorRoles, spacing } }) => {
  return {
    width: '100%',
    height: '100%',
    minHeight: 150,
    padding: spacing('space200'),
    backgroundColor: colorRoles.surfaces.neutralDefault,
    border: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
    borderRadius: spacing('space100'),
  };
});

// Assume this component will be accessed by real users. Do not add the Krabby Patty secret formula here.
const Debug = () => {
  const [triggerError, setTriggerError] = useState(false);
  const flags = useFlags();

  return (
    <EmotionThemeProvider version="2.0.0">
      <Screen safeAreaVariant="setHeightAndAddTop" title="Debug menu">
        <Grid rowSpacing="space150">
          <Row>
            <Col lg={6}>
              <ColumnWrapper>
                <TextDS variant="headingMd">Testing Crashes</TextDS>
                <Button
                  variant="secondary"
                  sizeDS="medium"
                  text="JS Crash"
                  onPress={() => {
                    setTriggerError(true);
                  }}
                />
                {triggerError && <TextDS>{(undefined as any).missingPropertyTEST}</TextDS>}
                <Button
                  variant="secondary"
                  sizeDS="medium"
                  text="Native Crash"
                  onPress={() => causeNativeCrash()}
                />
              </ColumnWrapper>
            </Col>
            <Col lg={6}>
              <ColumnWrapper>
                <TextDS variant="headingMd">Flags</TextDS>
                {/* Create a JSON viewer using TextArea */}
                <TextArea disabled>{JSON.stringify(flags, null, 2)}</TextArea>
              </ColumnWrapper>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <ColumnWrapper>
                <TextDS variant="headingMd">Testing</TextDS>
                <Button variant="secondary" sizeDS="medium" text="Button" />
              </ColumnWrapper>
            </Col>
          </Row>
        </Grid>
      </Screen>
    </EmotionThemeProvider>
  );
};

export default Debug;
