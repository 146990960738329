import SelectRounded, { SelectRoundedProps } from './SelectRounded';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import SelectRoundedV1 from './SelectRoundedV1';

export type { SelectRoundedProps };

const versionMap: VersionMap<React.ComponentProps<typeof SelectRounded>> = {
  '2.0.0': SelectRoundedV1,
  '1.0.0': SelectRoundedV1,
  DefaultComponent: SelectRounded,
};

export default withVersioning(versionMap);
