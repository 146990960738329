import { useState, Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import DefaultSelect, {
  mergeStyles,
  components as defaultComponents,
  StylesConfig,
  ValueType,
  OptionsType,
} from 'react-select';
import { Props as ReactSelectProps } from 'react-select/base';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import commonStyles from '../../constants/commonStyles';
import Text from '../Text';
import View from '../View';
import PasswordX from '../Svgs/PasswordX';
import Lock from '../Svgs/Lock';
import ErrorIcon from '../Svgs/ErrorIcon';
import { OptionType } from './types';
import styled, { EmotionStyle, EmotionTheme, useEmotionTheme } from '../../core/styled';

const { regular, header } = commonStyles.general.text;

// https://react-select.com/styles
const defaultTSSelectStyles = (
  colors: EmotionTheme['colors']
): StylesConfig<OptionType, boolean> => {
  return {
    container: (provided, { selectProps }) => {
      return {
        ...provided,
        marginTop: selectProps.placeholderIsTwoLines && !selectProps.placeholderUp ? 10 : 16,
        borderWidth: 0,
        flex: 1,
        width: '100%',
      };
    },
    control: (provided) => {
      return {
        ...provided,
        borderRadius: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        boxShadow: '0 0',
        borderColor: colors.extraLightGrey,
        '&:hover': {
          borderColor: colors.extraLightGrey,
        },
        paddingBottom: 1,
        minHeight: 35,
        backgroundColor: 'transparent', // to override isDisabled
        flex: 1,
        cursor: 'pointer',
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    loadingIndicator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    clearIndicator: (provided, props) => {
      return {
        ...provided,
        ...regular,
        display: props.isMulti ? 'none' : 'block',
        padding: '8px 0 3px 0',
        color: colors.placeholderGrey,
      };
    },
    valueContainer: (provided, { selectProps }) => {
      const { isMulti, placeholderUp, placeholderIsTwoLines, isSearchable, placeHolderHeight } =
        selectProps;
      const topOffset = (() => {
        if (isMulti || placeholderIsTwoLines) {
          return placeHolderHeight && !placeholderUp ? placeHolderHeight : 11;
        }
        return 0;
      })();
      const placeholderUpOffset = placeholderUp ? 13 : 0;
      const twoLinesOffset = placeholderIsTwoLines ? 14 : 0;
      const isSearchableOffset = isSearchable ? -9 : 0;
      return {
        ...provided,
        padding: `2px 0`,
        marginLeft: -2,
        paddingTop: topOffset + isSearchableOffset - placeholderUpOffset + twoLinesOffset,
        marginTop: placeholderUp ? undefined : twoLinesOffset,
        minHeight: topOffset + placeholderUpOffset + twoLinesOffset,
      };
    },
    option: (provided, { isSelected, isDisabled }) => {
      let textColor;
      if (isSelected) {
        textColor = colors.white;
      } else {
        textColor = isDisabled ? colors.dustyGrey : colors.black;
      }
      return {
        ...provided,
        ...regular,
        minHeight: 50,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isSelected ? colors.extraLightGrey : colors.white,
        color: textColor,
        fontWeight: isSelected ? 500 : 400,
        '&:hover': {
          backgroundColor: isDisabled ? '' : colors.lightPurple,
          color: isDisabled ? colors.dustyGrey : colors.white,
        },
        width: 'calc(100% - 2px)',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
    menu: (provided, { selectProps }) => {
      const { placeholderUp, placeHolderHeight, menuPlacement } = selectProps;
      return {
        ...provided,
        transform:
          placeHolderHeight && placeholderUp && menuPlacement === 'top'
            ? 'translateY(-10px)'
            : undefined, // Moves the menu higher
        marginTop: 4,
        paddingRight: 2,
        overflow: 'hidden',
        border: `1px solid ${colors.extraLightGrey}`,
        boxShadow: '2px 11px 13px 0 rgba(0,0,27,0.15)',
        cursor: 'pointer',
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        maxHeight: 280,
        padding: 0,
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          width: 10,
        },
        '&::-webkit-scrollbar': {
          width: 6,
          borderRadius: 3,
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-button': {
          width: 0,
          height: 0,
          display: 'none',
        },
        '&::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 200,
          backgroundClip: 'padding-box',
          backgroundColor: colors.extraLightGrey,
        },
        '::-webkit-scrollbar-track-piece': {
          backgroundColor: 'transparent',
          margin: 2,
        },
        cursor: 'pointer',
      };
    },
    input: (provided) => {
      return {
        ...provided,
        ...regular,
        caretColor: colors.purple,
        cursor: 'pointer',
      };
    },
    noOptionsMessage: (provided) => {
      return {
        ...provided,
        ...regular,
        height: 50,
        display: 'flex',
        alignItems: 'center',
      };
    },
    loadingMessage: (provided) => {
      return {
        ...provided,
        ...regular,
        height: 50,
        display: 'flex',
        alignItems: 'center',
      };
    },
    groupHeading: () => {
      return {
        ...header,
        color: colors.extraLightGrey,
        paddingLeft: 12,
        paddingRight: 12,
        height: 50,
        display: 'flex',
        alignItems: 'center',
      };
    },
    group: (provided) => {
      return {
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        ...regular,
      };
    },
  };
};

const PlaceholderText = styled(Text)<{ placeholderUp: boolean }>(
  ({ placeholderUp, theme: { colors } }) => {
    return {
      ...regular,
      position: 'absolute',
      transition: 'all .25s',
      pointerEvents: 'none',
      top: placeholderUp ? 0 : 24,
      fontSize: placeholderUp ? 12 : 15,
      color: colors.slateBlueGray,
      overflow: 'hidden',
      whiteSpace: placeholderUp ? 'nowrap' : 'normal',
      textOverflow: placeholderUp ? 'ellipsis' : 'clip',
      maxWidth: '100%',
    };
  }
);

const SelectContainer = styled(View)<{ placeholderUp: boolean; hidden: boolean }>(
  ({ placeholderUp, hidden }) => {
    return {
      flexDirection: 'row',
      transition: 'margin-top .2s',
      marginTop: placeholderUp ? 9 : 0,
      position: 'relative',
      display: hidden ? 'none' : 'flex',
      alignItems: 'flex-end',
    };
  }
);

const MultiValueRemoveComponent = (props) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3px 0px 3px 6px',
        cursor: 'pointer',
      }}
      {...props}
    >
      <PasswordX color={colors.white} height={8} width={8} />
    </View>
  );
};

const getSelectComponent = (isAsync: boolean, isCreatable: boolean): typeof DefaultSelect => {
  let finalSelect:
    | typeof AsyncCreatableSelect
    | typeof AsyncSelect
    | typeof CreatableSelect
    | typeof DefaultSelect;

  if (isAsync && isCreatable) {
    finalSelect = AsyncCreatableSelect;
  } else if (isAsync && !isCreatable) {
    finalSelect = AsyncSelect;
  } else if (!isAsync && isCreatable) {
    finalSelect = CreatableSelect;
  } else {
    finalSelect = DefaultSelect;
  }
  return finalSelect as typeof DefaultSelect;
};

const MultiValue = styled(View)<{
  defaultStyles: EmotionStyle;
  chipIsDisabled: boolean;
  multiValueStyles?: EmotionStyle;
  preferThemeStyles?: boolean;
}>(({ defaultStyles, chipIsDisabled, multiValueStyles, theme: { colors }, preferThemeStyles }) => {
  return {
    ...(preferThemeStyles ? {} : defaultStyles),
    flexDirection: 'row',
    transition: 'background-color .075s',
    color: colors.white,
    borderRadius: 5,
    padding: '3px 8px',
    alignItems: 'center',
    cursor: 'default',
    maxWidth: 263,
    backgroundColor: chipIsDisabled ? colors.placeholderGrey : colors.a11yRoyalBlue,
    '&:hover': {
      backgroundColor: chipIsDisabled ? colors.placeholderGrey : colors.permaDarkRoyalBlue,
    },
    ...(preferThemeStyles ? defaultStyles : {}),
    ...multiValueStyles,
  };
});

const MultiValueLabel = styled(View)<{
  defaultStyles: EmotionStyle;
  maxWidth?: number | string;
  labelStyle?: EmotionStyle;
  preferThemeStyles?: boolean;
}>(({ defaultStyles, maxWidth, theme: { colors }, labelStyle, preferThemeStyles }) => {
  return {
    ...(preferThemeStyles ? {} : defaultStyles),
    ...regular,
    padding: 0,
    margin: '0 12px 0 0',
    flexDirection: 'row',
    fontWeight: 700,
    color: colors.white,
    fontSize: 16,
    // overflow, width (or maxWidth), display and whiteSpace all needed to achieve textOverflow: 'ellipsis'
    overflow: 'hidden',
    maxWidth: maxWidth || 225,
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...(preferThemeStyles ? defaultStyles : {}),
    ...labelStyle,
  };
});

const getMultiValueComponent = ({
  disabledSelectedValues,
  multiValueStyles,
  maxWidth,
  labelStyle,
  preferThemeStyles,
  components,
}: {
  disabledSelectedValues: OptionsType<OptionType>;
  multiValueStyles?: EmotionStyle;
  maxWidth?: number | string;
  labelStyle?: EmotionStyle;
  preferThemeStyles?: boolean;
  components?: ReactSelectProps<OptionType, boolean>['components'];
}) =>
  function MultiValueComponent(props) {
    const { getStyles, children, removeProps, data } = props;
    const chipIsDisabled = disabledSelectedValues.some((value) => value.value === data.value);
    const RemoveComponent = components?.MultiValueRemove || MultiValueRemoveComponent;
    return (
      <MultiValue
        multiValueStyles={multiValueStyles}
        defaultStyles={getStyles('multiValue', props)}
        chipIsDisabled={chipIsDisabled}
        preferThemeStyles={preferThemeStyles}
      >
        <MultiValueLabel
          maxWidth={maxWidth}
          defaultStyles={getStyles('multiValueLabel', props)}
          labelStyle={labelStyle}
          preferThemeStyles={preferThemeStyles}
        >
          {children}
        </MultiValueLabel>
        {chipIsDisabled ? (
          <Lock style={{ marginRight: 6 }} />
        ) : (
          <RemoveComponent
            defaultStyles={getStyles('multiValueRemove', props)}
            preferThemeStyles={preferThemeStyles}
            {...removeProps}
          />
        )}
      </MultiValue>
    );
  };

const Option = ({
  children,
  ...props
}: React.ComponentProps<typeof defaultComponents['Option']>) => {
  const {
    innerProps: { onMouseMove, onMouseOver, ...rest },
  } = props;
  const newProps = Object.assign(props, { innerProps: rest });
  return <defaultComponents.Option {...newProps}>{children}</defaultComponents.Option>;
};

function isValueDisabled<T>(
  value: OptionType<T>,
  disabledSelectedValues: OptionsType<OptionType<T>> = []
) {
  return disabledSelectedValues.some(({ value: val }) => val === value.value);
}

function sortDisabledFirst<T>(
  value: ValueType<OptionType<T>, boolean>,
  isMulti = false,
  disabledSelectedValues: OptionsType<OptionType<T>> = []
) {
  if (isMulti && Array.isArray(value)) {
    return value.sort((valueA, valueB) => {
      if (isValueDisabled(valueA, disabledSelectedValues)) return -1;
      if (isValueDisabled(valueB, disabledSelectedValues)) return 1;
      return 0;
    });
  }
  return value;
}

function shouldBackspaceRemoveValues<T>(
  value: ValueType<OptionType<T>, boolean>,
  isMulti = false,
  disabledSelectedValues: OptionsType<OptionType<T>> = [],
  backspaceRemovesValue = false
) {
  if (
    backspaceRemovesValue == null &&
    isMulti &&
    value &&
    'length' in value &&
    disabledSelectedValues.length
  ) {
    const disabledValues = value.filter((val) => isValueDisabled(val, disabledSelectedValues));
    if (disabledValues.length === value.length) {
      return false;
    }
    return true;
  }
  return backspaceRemovesValue;
}

export interface SelectViewProps extends ReactSelectProps<OptionType, boolean> {
  onCreateOption?: (option: string) => void;
  formatCreateValue?: (value: string) => string;
  formatCreateLabel?: (label: string) => string;
  isValidNewOption?: (
    inputValue: string,
    selectValue: OptionType[],
    selectOptions: OptionType[]
  ) => boolean;
  validateNewOption?: (option: string) => boolean;
  disabledSelectedValues?: OptionsType<OptionType>;
  placeholderIsTwoLines?: boolean;
  placeHolderHeight?: number;
  isValid?: boolean;
  isCreatable?: boolean;
  isAsync?: boolean;
  displayStaticPlaceholder?: boolean;
  showIndicatorBeforeClick?: boolean;
  setDecreaseTopMargin?: Dispatch<SetStateAction<boolean>>;
  width?: number | string;
  dataQa?: string;
  wrapperStyle?: EmotionStyle;
  lockStyles?: EmotionStyle;
  isClearable?: boolean;
  multiValueStyles?: EmotionStyle;
  labelStyle?: EmotionStyle;
  preferThemeStyles?: boolean;
  isAlwaysHoverStyle?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectView = ({
  value,
  refCallback,
  placeholder,
  placeholderUp,
  isMulti,
  components,
  formatCreateLabel,
  isDisabled,
  hidden,
  placeholderIsTwoLines,
  placeHolderHeight,
  displayStaticPlaceholder,
  isAsync = false,
  isCreatable = false,
  disabledSelectedValues = [],
  styles = {},
  backspaceRemovesValue: propBackspaceRemovesValue,
  isValid = true,
  showIndicatorBeforeClick = false,
  dataQa,
  lockStyles,
  isClearable = true,
  multiValueStyles,
  width,
  dataTestID,
  labelStyle,
  preferThemeStyles,
  isAlwaysHoverStyle,
  ...otherProps
}: SelectViewProps) => {
  const Select = getSelectComponent(isAsync, isCreatable);

  const [hovering, setHovering] = useState(false || isAlwaysHoverStyle);
  const handleMouseEnter = () => setHovering(true);
  const handleMouseLeave = () => setHovering(false || isAlwaysHoverStyle);
  const IndicatorsContainer = (props) => (
    <View style={{ opacity: hovering || showIndicatorBeforeClick ? 1 : 0 }}>
      <defaultComponents.IndicatorsContainer {...props} />
    </View>
  );
  const defaultTSComponentOverrides = isMulti
    ? {
        MultiValue: getMultiValueComponent({
          disabledSelectedValues,
          multiValueStyles,
          maxWidth: width,
          labelStyle,
          preferThemeStyles,
          components,
        }),
        Option,
        MultiValueRemove: MultiValueRemoveComponent,
      }
    : { Option, IndicatorsContainer };
  const shouldDisplayLock = isDisabled && hovering;
  const backspaceRemovesValue = shouldBackspaceRemoveValues(
    value,
    isMulti,
    disabledSelectedValues,
    propBackspaceRemovesValue
  );
  const { colors } = useEmotionTheme();
  return (
    <SelectContainer
      data-testid={dataTestID}
      data-qa={dataQa}
      placeholderUp={!displayStaticPlaceholder && placeholderUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      hidden={hidden}
    >
      <Select
        styles={mergeStyles(defaultTSSelectStyles(colors), styles)}
        {...otherProps}
        ref={refCallback}
        value={sortDisabledFirst(value, isMulti, disabledSelectedValues)}
        backspaceRemovesValue={backspaceRemovesValue}
        // displayStaticPlaceholder=false, uses the placeholder component below this
        placeholder={displayStaticPlaceholder ? placeholder : ''}
        isMulti={isMulti}
        blurInputOnSelect={false}
        components={{ ...defaultTSComponentOverrides, ...components }}
        closeMenuOnSelect={!isMulti}
        formatCreateLabel={formatCreateLabel}
        isDisabled={isDisabled}
        disabledSelectedOptions={disabledSelectedValues}
        placeholderIsTwoLines={placeholderIsTwoLines}
        placeHolderHeight={placeHolderHeight}
        placeholderUp={placeholderUp}
        isClearable={isClearable}
      />
      {!displayStaticPlaceholder && (
        <PlaceholderText placeholderUp={placeholderUp}>{placeholder}</PlaceholderText>
      )}
      {shouldDisplayLock && (
        <Lock
          color={colors.placeholderGrey}
          style={{
            position: 'absolute',
            right: 4,
            top: 28,
            ...lockStyles,
          }}
        />
      )}
      <ErrorIcon isValid={isValid} style={{ right: 12 }} />
    </SelectContainer>
  );
};

export default SelectView;
