import Tag from './Tag';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof Tag>> = {
  DefaultComponent: Tag,
};

export type { TagProps, TagColorName } from './Tag';
export default withVersioning(versionMap);
